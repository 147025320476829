import ViewerToolkit from './Viewer.Toolkit'

/**
 * @readonly
 * @enum {number}
 * @alias NODE_TYPE
 * @property {number} NODE_TYPE_ASSEMBLY Real world object as assembly of sub-objects
 * @property {number} NODE_TYPE_INSERT Insert of multiple-instanced object
 * @property {number} NODE_TYPE_LAYER A layer (specific abstraction collection)
 * @property {number} NODE_TYPE_COLLECTION An abstract collection of objects (e.g. “Doors”)
 * @property {number} NODE_TYPE_COMPOSITE A real world object whose internal structure is not relevant to end user
 * @property {number} NODE_TYPE_MODEL Root of tree representing an entire Model. An aggregate model can contain multiple nested models.
 * @property {number} NODE_TYPE_GEOMETRY Leaf geometry node
 * @property {number} NODE_TYPE_BITS mask for all bits used by node type
 */
export const NODE_TYPE = {
    NODE_TYPE_ASSEMBLY   : 0x0,
    NODE_TYPE_INSERT     : 0x1,
    NODE_TYPE_LAYER      : 0x2,
    NODE_TYPE_COLLECTION : 0x3,
    NODE_TYPE_COMPOSITE  : 0x4,
    NODE_TYPE_MODEL      : 0x5,
    NODE_TYPE_GEOMETRY   : 0x6,
    NODE_TYPE_BITS       : 0x7
};

export default ViewerToolkit
