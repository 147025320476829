<template>
    <div>
        <v-btn @click="openReport()" size="small" color="success">
            <v-icon class="mr-1">mdi-file-chart</v-icon>
            <span>Report</span>
        </v-btn>
        <v-dialog v-model="dialog" width="auto" persistent>
            <v-card>
                <v-card-title class="d-flex justify-space-between">
                    <div class="d-flex flex-column">
                        {{ request.modelName }}
                        <v-divider></v-divider>
                        <div class="mt-2">Progress: {{ getProgress() }}</div>
                    </div>
                    <v-btn @click="dialog = false" compact flat size="30">
                        <v-icon size="large">mdi-close-circle-outline </v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text v-if="elements?.length">
                    <v-table theme="dark">
                        <thead>
                            <tr>
                                <th>
                                    Revit Id
                                </th>
                                <th>
                                    Element Name
                                </th>
                                <th>
                                    Details
                                </th>
                                <th>
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="elem in elements" :key="elem.id">
                                <td>{{ elem.RevitId }}</td>
                                <td>{{ elem.Name }}</td>
                                <td>{{ elem.ManipulationType }}</td>
                                <td class="d-flex justify-center align-center">
                                    <v-icon size="large" :icon="getIcon(elem)" :color="getColor(elem)"></v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>

                </v-card-text>

                <v-card-text v-else>
                    <div class="py-16 d-flex align-center justify-center flex-column h-100">
                        <v-img width="300" max-height="350px" contain src="/img/No Data.svg"></v-img>
                        <div class="pt-4 title">No Elements Found</div>
                    </div>

                </v-card-text>
            </v-card>
            <!-- <v-btn @click="dialog = false" color="success">
                Close
            </v-btn> -->
        </v-dialog>
    </div>
</template>
 


<script>
// import modelService from '@Models/service/model.service';
// import { toast } from 'vue3-toastify';
// import { mapActions, mapGetters } from 'vuex';
// import MessageBox from '@/components/MessageBox.vue';
// import '@mdi/font/css/materialdesignicons.css';
// import { $download } from '@Utils';

export default {
    props: ['request'],
    data() {
        return {
            dialog: false,
            elements: []
        };
    },
    methods: {
        openReport() {
            this.dialog = true
            this.elements = JSON.parse(this.request.generatedReport).ForgeElements
            // modelService.getById(this.request.oldForgeModelId)
            //     .then((data) => {
            //         this.$store.dispatch('GetManipulatedElements', data.urn).then((elements) => {
            //             this.elements = elements
            //         });
            //     })
            //     .catch((error) => {
            //         console.error(error)
            //     });
        },
        isDone(elem) {
            return elem.RunStatus == 'Done'
        },
         getColor(elem) {
             return this.isDone(elem) ? 'success' : 'red'
        },
         getIcon(elem) {
             return this.isDone(elem) ? 'mdi-check-circle' : 'mdi-close-circle'
        },

        getProgress() {
            return `${this.elements.filter(x => this.isDone(x)).length} / ${this.elements.length}`
        }
    },
    created() {
      
       
    },

    computed: {
        // isImage() {
        //     return this.currentMedia?.attachementType && this.currentMedia.attachementType?.startsWith('image');
        // },
    },
    watch: {
        // currentIndexx(value) {
        //     this.currentIndex = value;
        // }
    }
};
</script>
