// Command Interface
import store from '@/store';
import ElementClone2D from '@/extensions/Viewing.Extension.Manipulation2D/tool';
class Command {
    execute() {
        throw new Error('Execute method should be implemented');
    }

    undo() {
        throw new Error('Undo method should be implemented');
    }
}

// Concrete Command for adding an element
export class AddElementCommand extends Command {
    constructor(element,manipulatedItem,points, viewer, model) {
        super();
        this.item = element;
        this.viewer = viewer;
        this.model = model;
        this.manipulatedItem=manipulatedItem;
        this.points=points;
    }

    execute(ElementClone2D) {
        ElementClone2D.viewer=this.viewer;
        this.manipulatedItem.id=0;
        store.commit('ADD_MANIPULATED_ELEMENT', this.manipulatedItem);
        let cloned=ElementClone2D.insertObjectBlock(this.item,true);
        this.item=cloned;
    }

    undo(ElementClone2D) {
   
        ElementClone2D.viewer=this.viewer;
        ElementClone2D.deleteObject(this.item)
    }
}
export class DeleteElementCommand extends Command {
    constructor(element, viewer, model) {
        super();
        this.element = element;
        this.viewer = viewer;
        this.model = model;
    }

    execute(ElementClone2D) {
        ElementClone2D.viewer=this.viewer;
        ElementClone2D.deleteObject(this.item)
    }

    undo(ElementClone2D) {
        ElementClone2D.viewer=this.viewer;
        this.manipulatedItem.id=0;
        store.commit('ADD_MANIPULATED_ELEMENT', this.manipulatedItem);
        let cloned=ElementClone2D.insertObjectBlock(this.item,true);
        this.item=cloned;
    }
}

// Invoker
export class CommandManager {
    constructor() {
        this.undoStack = [];
        this.redoStack = [];
        this.ElementClone2D=new ElementClone2D(window.NOP_VIEWER,null,null);
    }

    executeCommand(command) {
        //command.execute();
        this.undoStack.push(command);
        this.redoStack = []; // Clear redo stack on new action
    }

    undo() {
        const command = this.undoStack.pop();
        if (command) {
            command.undo(this.ElementClone2D);
            this.redoStack.push(command);
        }
    }

    redo() {
        const command = this.redoStack.pop();
        if (command) {
            command.execute(this.ElementClone2D);
            this.undoStack.push(command);
        }
    }
    clear(){
        this.undoStack = [];
        this.redoStack = [];
    }
}
