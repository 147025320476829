<template>
    <v-app theme="dark">
        <top-bar></top-bar>
        <v-main>
            <v-progress-linear
                v-if="loading"
                color="success"
                height="3"
                indeterminate></v-progress-linear>
            <slot v-if="!loading"></slot>
            <v-sheet class="text--center" v-if="loading" height="100%" width="100%">
                <v-row align="center" justify="center" class="fill-height">
                    <v-col cols="12" class="d-flex justify-center">
                        <v-progress-circular indeterminate color="success"></v-progress-circular>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-main>
        <nav-bar></nav-bar>
    </v-app>
</template>
<script>
    import TopBar from '@Models/components/TopBar.vue';
    import NavBar from '@Models/components/NavBar.vue';
    import { toast } from 'vue3-toastify';
    export default {
        name: 'models-layout',
        components: {
            TopBar,
            NavBar,
        },
        data() {
            return {
                loading: false,
            };
        },
        created() {
            console.log(this.$route.params.id);
            this.loading = true;
            this.$store
                .dispatch('PROJECTS/GetProjectData', this.$route.params.id)
                .then(() => {
                    this.loading = false;
                })
                .catch(() => {
                    toast.error('Project not found');
                    this.$router.push({ name: 'projects' });
                    this.loading = false;
                });
        },
    };
</script>
<style lang="css">
    main {
        background-color: #212121;
    }
</style>
