import axios from "axios";
import AppConfig from "../../appConfig";
import { $getFromLocal, $removeFromLocal } from ".";
import { toast } from "vue3-toastify";

class HttpService {
    constructor() {
        const headers = this.getAuthHeaders()
        const service = axios.create({
            baseURL: AppConfig.API_URL,
            headers: headers,
            withCredentials: true,
            timeout: 0
        });

        service.interceptors.response.use(this.handleSuccess, this.handleError);

        this.service = service;
    }

    getAuthHeaders() {
        const token = $getFromLocal("token")

        if (!token) return {};
        
        return {
            "Authorization": `Bearer ${token}`,
        };
    }

    handleToken() {
        this.service.defaults.headers = this.getAuthHeaders();
    }

    handleSuccess = (response) => {
        return response?.data;
    }

    handleError = (error) => {
        switch (error.response?.status) {
            case 302:
                // Redirect
                this.redirectTo(error.response?.headers?.location);
                break;
            case 401:
                // Token expired
                if (error.config.baseURL === AppConfig.API_URL) {
                    delete this.service.defaults.headers["Authorization"];
                    $removeFromLocal("token");
                    $removeFromLocal("user");
                    toast.error("Your session has expired. Please login again.");          
                    this.redirectTo("/");          
                }
                break;
            case 404:
                // Not found
                //this.redirectTo("/404");
                break;
            default:
                // Internal server error
                //this.redirectTo("/500");
                break;
        }

        return Promise.reject(error.response?.data?.message ?? error.message);
    }

    redirectTo(url) {
        window.location.href = url;
    }

    get(config) {
        if (config instanceof String || typeof config === 'string') {
            return this.service.get(config);
        }

        return this.service.request({ method: 'GET', ...config });
    }

    post(config) {
        return this.service.request({ method: 'POST', ...config });
    }

    put(config) {
        return this.service.request({ method: 'PUT', ...config });
    }

    patch(config) {
        return this.service.request({ method: 'PATCH', ...config });
    }

    delete(config) {
        return this.service.request({ method: 'DELETE', ...config });
    }

    request(...args) {
        return this.service.request(...args);
    }
}

export default new HttpService();