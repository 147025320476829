/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-labels */

const Autodesk = window.Autodesk;

import Block2dLibraryExtension from "@/extensions/Block2dLibraryExtension/Block2dLibraryExtension";
import { toast } from 'vue3-toastify';

export const Blocks2dLibrary = {
    state: {
        blocksTree: [],
        blockTreeSearch: '',
        addBlockCallBack: null,
        blocksNotification: null,
    },
    getters: {
        BLOCKS_TREE(state) {
            return state.blocksTree;
        },
        BLOCKS_TREE_SEARCH(state) {
            return state.blockTreeSearch;
        },
        ADD_BLOCK_CALLBACK(state) {
            return state.addBlockCallBack;
        },
        BLOCKS_NOTIFICATION(state) {
            return state.blocksNotification;
        },
    },
    mutations: {
        SET_BLOCKS_TREE(state, blocksTree) {
            state.blocksTree = blocksTree;
        },
        SET_BLOCKS_TREE_SEARCH(state, search) {
            state.blockTreeSearch = search;
        },
        SET_ADD_BLOCK_CALLBACK(state, callback) {
            state.addBlockCallBack = callback;
        },
        SET_BLOCKS_NOTIFICATION(state, notification) {
            state.blocksNotification = notification;
        },
    },
    actions: {
        IMPORT_BLOCKS_FROM_VIEWS({ getters, commit, rootState }, { viewer, views, document }) {
            commit('SET_PROGRESS_BAR_ACTIVE', true);
            commit('SET_PROGRESS_BAR_VALUE', 0);

            return new Promise((resolve, reject) => {
                const blocksExtension = window.NOP_VIEWER.getExtension(
                    Block2dLibraryExtension.extensionId,
                );
                if (!blocksExtension) {
                    return;
                }
                let loaded = false;

                if (!views?.length) {
                    console.error('No views to load');
                    commit('SET_PROGRESS_BAR_ACTIVE', false);
                    resolve();
                    return;
                }

                const getModelBlocks = async (e) => {
                    await blocksExtension.onModelLoaded(viewer, viewer.model);
                    loaded = true;
                };

                const loadView = async (view) => {
                    loaded = false;
                    try {
                        if (!window.NOP_VIEWER) return;

                        viewer.loadDocumentNode(document, view);

                        await new Promise((resolve) => {
                            const interval = setInterval(() => {
                                if (loaded) {
                                    clearInterval(interval);
                                    resolve();
                                }
                            }, 50);
                        });
                    } catch (error) {
                        console.log(error);
                        resolve();
                    }
                };

                const onBlocksLoaded = () => {
                    viewer.removeEventListener(
                        Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
                        getModelBlocks,
                    );
                    resolve();
                };

                const loadedViews = [];

                const loadNextView = async (index) => {
                    if (index >= views.length || !window.NOP_VIEWER) {
                        onBlocksLoaded();
                        commit('SET_PROGRESS_BAR_ACTIVE', false);                       
                        resolve(loadedViews);
                        return;
                    }

                    commit('SET_PROGRESS_BAR_VALUE', (index / views.length) * 100);

                    try {
                        await loadView(views[index]);
                        loadedViews.push(views[index]);
                        loadNextView(index + 1);
                    } catch (error) {
                        console.error('Error loading view:', error);
                        commit('SET_PROGRESS_BAR_ACTIVE', false);
                        reject(error);
                    }
                };

                viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, getModelBlocks);

                commit('SET_BLOCKS_TREE', []);

                loadNextView(0);
            });
        },
        ADD_BLOCK_TO_TREE({ getters, commit, state, rootState }, block) {
            const { family, type, category } = block;

            let _category;

            _category = state.blocksTree.find((c) => c.name === category);

            if (!_category) {
                _category = { name: category, children: [] };
                state.blocksTree.push(_category);
            }

            let _family = _category.children.find((f) => f.name === family);

            if (!_family) {
                _family = { name: family, children: [] };
                _category.children.push(_family);
            }

            let _type = _family.children.find((t) => t.name === type);

            if (!_type) {
                _family.children.push(block);
            }
        },
        PLACING_BLOCKS_DONE({ getters, commit, state, rootState }, block) {
            let addingCallback = getters['ADD_BLOCK_CALLBACK'];
            let notification = getters['BLOCKS_NOTIFICATION'];
            window.NOP_VIEWER.canvas.removeEventListener('mouseup', addingCallback);
            window.NOP_VIEWER.canvas.removeEventListener('touched', addingCallback);
            toast.remove(notification);
            commit('SET_ADD_BLOCK_CALLBACK', null);
            commit('SET_BLOCKS_NOTIFICATION', null);
        },
        GET_BLOCK_NODE_BY_SEARCH({ getters }, searchObj) {
            const tree = getters.BLOCKS_TREE;
            let result = null;

            const searchTree = (node) => {
                const isMatch = Object.keys(searchObj).every((key) => node[key] === searchObj[key]);
                if (isMatch) {
                    result = node;
                    return;
                }
                node.children?.forEach((child) => {
                    if (!result) searchTree(child);
                });
            };

            tree.forEach((rootNode) => {
                searchTree(rootNode);
            });

            return result;
        },
    },
};

export default Blocks2dLibrary;