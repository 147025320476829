/* eslint-disable no-unused-labels */
import HttpService from "@Utils/httpService"

export const Buckets = {
    state() {
        buckets: []
    },
    getters: {
        BUCKETS(state) {
            return state.buckets;
        }        
    },
    mutations: {
        SET_BUCKETS(state, buckets) {
            state.buckets = buckets;
        }
    },
    actions: {
        GetBuckets({ commit }) {
            HttpService.get('api/buckets')
                .then(buckets => {
                    commit('SET_BUCKETS', buckets.map(bucket => ({
                        id: bucket.id,
                        name: bucket.name,
                        type: bucket.type,
                        parent: bucket.children,
                        children: []
                    })))
                })
                .catch(error => {
                    console.log(error)
                })
        },
        GetBucketObjects(_, bucketKey) {
            return new Promise((resolve, reject) => {
                HttpService.get(`api/buckets/${bucketKey}/objects`)
                    .then(objects => {
                        resolve(objects)
                    })
                    .catch(error => {
                        reject(error)
                    })
            });
        }
    }
}