<template lang="html">
    <v-sheet class="projects-view h-100" theme="dark">
        <v-container>
            <template v-if="isLoading">
                <v-col v-for="i in 4" :key="i" cols="12">
                    <v-skeleton-loader class="mx-auto border" type="article"></v-skeleton-loader>
                </v-col>
            </template>
            <v-row v-if="!isLoading">
                <v-spacer></v-spacer>
                <v-col cols="12" xl="2" lg="3" md="4" sm="6">
                    <v-text-field
                        class="custom-input mb-2"
                        color="primary"
                        placeholder="Search Requests"
                        hide-details
                        density="compact"
                        v-model="searchQuery"
                        autofocus
                        clearable
                        prepend-inner-icon="fa:fas fa-search fa-lg">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-table v-if="!isLoading" theme="dark">
                <thead>
                    <tr>
                        <th>Request Type</th>
                        <th>Project Name</th>
                        <th>Old Version</th>
                        <th>New Version</th>
                        <th>Date Created</th>
                        <th>User Created</th>
                        <th>Progress Status</th>
                        <th>Old Project</th>
                        <th style="text-align: center">New Project</th>
                        <th>Report</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in requests" :key="item.id">
                        <td>{{ getItemStatus(item) }}</td>
                        <td>{{ item.modelName }}</td>
                        <td>
                            <v-chip v-if="item.oldVersion" color="success">
                                {{ item.oldVersion?.split(':')[0] }}
                            </v-chip>
                            <span v-else> {{ item.oldVersion }}</span>
                        </td>
                        <td>
                            <v-chip v-if="item.version" color="success">
                                {{ item.version?.split(':')[0] }}
                            </v-chip>
                            <span v-else> {{ item.version }}</span>
                        </td>
                        <td>{{ formatDate(item.createdOn) }}</td>
                        <td>{{ item.userName }}</td>
                        <td>
                            <v-chip :color="getStatusColor(item)">
                                {{ item.status }}
                            </v-chip>
                        </td>
                        <td>
                            <v-btn
                                color="primary"
                                size="small"
                                @click="openModel(item.oldForgeModelId)"
                                ><v-icon class="mr-1">fa:fa-solid fa-right-to-bracket</v-icon> Open
                            </v-btn>
                        </td>
                        <td
                            style="
                                gap: 5px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            ">
                            <v-btn
                                v-if="item.saveNewVersion"
                                :disabled="item.status != 'Done'"
                                size="small"
                                color="success"
                                @click="openModel(item.forgeModelId)"
                                ><v-icon class="mr-1">fa:fa-solid fa-right-to-bracket</v-icon> Open
                            </v-btn>
                            <v-btn
                                color="success"
                                size="small"
                                :disabled="item.status == 'In Progress'"
                                @click="handleDownload(item)"
                                ><v-icon class="mr-1">fa:fa-solid fa-download</v-icon>Download
                                Report
                            </v-btn>
                        </td>
                        <td>
                            <!-- <v-btn v-if="item.saveNewVersion" :disabled="item.status != 'Done'" size="small"
                                color="success" @click="openReportView(item.forgeModelId)"><v-icon
                                    class="mr-1">fa:fa-solid
                                    fa-right-to-bracket</v-icon> Show Report
                            </v-btn> -->

                            <report-view :request="item"></report-view>
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <v-row v-if="!isLoading">
                <v-col cols="11">
                    <v-pagination v-model="currentPage" :length="totalPages"></v-pagination>
                </v-col>
                <v-col cols="1">
                    <v-select
                        class="mt-1 border-0"
                        hide-details
                        density="compact"
                        v-model="itemsPerPage"
                        :items="PageCount"></v-select>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>
<script>
    import { mapGetters } from 'vuex';
    import ReportView from './../../../components/ReportView.vue';

    export default {
        name: 'projects-view',
        components: {
            ReportView,
        },
        data() {
            return {
                requests: [],
                itemsPerPage: 6,
                isLoading: false,
                searchQuery: '',
                showReport: false,
                currentPage: 1,
                startIndex: 0,
                PageCount: [1, 5, 25, 50, 100, 200, 500],
            };
        },
        computed: {
            ...mapGetters(['ALL_REQUEST_DATA']),
            ...mapGetters(['ALL_REQUEST_DATA_COUNT']),
            ...mapGetters(['REQUEST_TABLE_COUNT']),
            totalPages() {
                return Math.ceil(this.ALL_REQUEST_DATA_COUNT / this.itemsPerPage);
            },
           
        },
        methods: {
            openReportView() {
                this.showReport = true;
            },
            updateProjectsList() {
                this.isLoading = true;
                this.$store
                    .dispatch('GetAllRequestsDataFiltered', {
                        skip: this.startIndex,
                        search: this.searchQuery,
                        count: this.itemsPerPage,
                    })
                    .then(() => {
                        this.isLoading = false;
                    });
            },
            handleDownload(request) {
                this.$store.dispatch('DownloadRequestReport', request.id).then((response) => {
                    const fileDownloadName = request.reportUrl.split('\\').pop().split('/').pop();
                    const blob = new Blob([response], { type: 'application/octect-stream' });
                    console.log(fileDownloadName);
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.setAttribute('download', fileDownloadName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                });
            },
            openModel(originalModelId) {
                this.$router.push({ name: 'ModelViewer', params: { id: originalModelId } });
            },
            formatDate(date) {
                const dateObj = new Date(date);
                return dateObj.toLocaleString();
            },
            getStatusColor(item) {
                return item.status == 'Done'
                    ? 'success'
                    : item.status == 'In Progress' || item.status == 'Pending'
                    ? 'yellow'
                    : 'error';
            },
            getItemStatus(item) {
                return item.saveNewVersion ? 'Save New Version' : 'Sync Model';
            },
        },
        mounted() {
            if (this.REQUEST_TABLE_COUNT) {
                this.itemsPerPage=this.REQUEST_TABLE_COUNT
            }
            this.updateProjectsList();
        },
        watch: {
            $route() {
                this.updateProjectsList();
            },
            ALL_REQUEST_DATA: {
                handler: function () {
                    this.requests = this.ALL_REQUEST_DATA;
                },
                immediate: true,
                deep: true,
            },
            REQUEST_TABLE_COUNT(value){
                if (value) {
                  this.itemsPerPage=value;
                }
            },
            currentPage(value) {
                this.startIndex = (value - 1) * this.itemsPerPage;
                this.updateProjectsList();
            },
            searchQuery() {
                this.startIndex = 0;
                this.updateProjectsList();
            },
            itemsPerPage(value) {
                this.$store.dispatch("SetRequestTableCount",value)
                this.updateProjectsList();
            },
        },
    };
</script>

<style lang="css">
    .recent-model-img .v-img__img {
        object-fit: cover;
    }

    .v-card-actions .fa-solid.fa-star {
        color: yellow;
    }

    .v-card-actions .v-btn--icon:hover .fa-star {
        color: yellow;
    }

    .bottom-navigation .v-btn__content {
        gap: 5px;
    }

    i.v-icon {
        color: inherit;
    }

    .text--gray--1 {
        color: #cccccc;
        font-size: 1rem;
    }
</style>
