// Styles
import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { aliases, fa } from 'vuetify/iconsets/fa'
import { aliases as mdiAliases, mdi } from 'vuetify/iconsets/mdi'

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    defaults: {
      global: {
        transition: false,
        ripple: true,
      },
    },
    icons: {
      // defaultSet: 'mdi',
      aliases: {
        ...aliases,
        ...mdiAliases,
      },
      sets: {
        fa,
        mdi
      },
    },
    theme: {
      dark: true,
      themes: {
        light: {
          primary: '#1976D2',
          secondary: '#FFA726',
          background: '#FFFFFF',
          surface: '#EEEEEE',
          error: '#FF5252',
          text: '#333333',
        },
        dark: {
          primary: '#90CAF9',
          secondary: '#FFC107',
          background: '#121212',
          surface: '#333',
          error: '#FF5252',
          text: '#FFFFFF',
        },
      },
    }, 
  }
)
