/* eslint-disable no-unused-vars */
const THREE = window.THREE;
const Autodesk = window.Autodesk;

class Transition2DTo3D {
    constructor(extension) {
        this.ext = extension;
        this.viewer = extension.viewer;
    }

    async transition2Dto3D(sheet2DModel) {
        if (!this.viewer.impl.is2d) return;

        const nodeAndViewportPromise = this.ext.getNodeAndViewport(sheet2DModel);
        let bubbleNode, viewport;

        this.viewer.setNavigationLock(true);
        await this._flyToFitView(this.viewer, sheet2DModel);

        try {
            ({bubbleNode, viewport} = await nodeAndViewportPromise);
        } catch (error) {
            this.viewer.setNavigationLock(false);
            this.ext.displayError(Autodesk.Viewing.i18n.translate("No suitable model found for transition."));
            console.warn(error);
            return;
        }

        this.viewer.impl.setDoNotCut(sheet2DModel, true);
        this.ext.setSelectedViewport(viewport);

        const model3D = await this.ext.showModel(bubbleNode);
        const unitScale = sheet2DModel.getUnitScale();
        let transformMatrix = viewport.get2DTo3DMatrix(unitScale);

        if (viewport.isMatrixInLocalCoords()) {
            const modelToViewerTransform = model3D.getModelToViewerTransform();
            if (modelToViewerTransform) {
                transformMatrix = transformMatrix.clone().multiplyMatrices(modelToViewerTransform, transformMatrix);
            }
        }

        sheet2DModel.setPlacementTransform(transformMatrix);
        this._updateCamera(this.viewer, transformMatrix);
        await this._transitionCamera(this.viewer, transformMatrix);

        this.viewer.setNavigationLock(false);
        this.viewer.impl.changePaperVisibility(sheet2DModel, false, true, null, 2.5);

        const viewportBounds = viewport.getViewportBounds(unitScale);
        if (viewportBounds) {
            await this._fitToView(this.viewer, sheet2DModel, viewportBounds, 2.5);
        }

        this._trackAnalytics(viewport, sheet2DModel, bubbleNode);
    }

    async _flyToFitView(viewer, model) {
        const visibleBounds = model.getVisibleBounds();
        const camera = viewer.getCamera();
        const fit = viewer.navigation.computeFit(camera.position, camera.target, camera.fov, visibleBounds, camera.aspect);
        const newView = {
            position: fit.position,
            target: fit.target,
            up: camera.up
        };

        return new Promise((resolve) => {
            Autodesk.Viewing.Private.flyToView(viewer, newView, 1, () => {
                setTimeout(resolve);
            }, false);
        });
    }

    _updateCamera(viewer, matrix) {
        const camera = viewer.getCamera();
        const position = new THREE.Vector3().setFromMatrixPosition(matrix);
        const target = new THREE.Vector3().setFromMatrixColumn(matrix, 2).add(position);
        const up = new THREE.Vector3().setFromMatrixColumn(matrix, 1);

        camera.position.copy(position);
        camera.target.copy(target);
        camera.up.copy(up);
        camera.lookAt(target);
    }

    async _transitionCamera(viewer, matrix) {
        return new Promise((resolve) => {
            const position = new THREE.Vector3().setFromMatrixPosition(matrix);
            const target = new THREE.Vector3().setFromMatrixColumn(matrix, 2).add(position);
            const up = new THREE.Vector3().setFromMatrixColumn(matrix, 1);

            const newView = { position, target, up };

            Autodesk.Viewing.Private.flyToView(viewer, newView, 1, () => {
                setTimeout(resolve);
            }, false);
        });
    }

    async _fitToView(viewer, model, bounds, duration) {
        return new Promise((resolve) => {
            viewer.fitToView([model], bounds, duration, resolve);
        });
    }

    _trackAnalytics(viewport, sheet2DModel, model3DNode) {
        Autodesk.Viewing.Private.analytics.track(Autodesk.ModelSheetTransition.AnalyticsEventName, {
            action: "View",
            alignment: viewport instanceof Autodesk.AEC.ViewportsExtension.AecModelDataViewport ? "Automatic" : "Manual",
            from: "Sheet",
            file_2d_extension: sheet2DModel.getDocumentNode().getInputFileType(),
            file_3d_extension: model3DNode.getInputFileType()
        });
    }
}

export default Transition2DTo3D;