/* eslint-disable no-unused-vars */
import store from '@/store'
import EventsEmitter from '../EventsEmitter'
import './TransformGizmos'
import { ManipulatedElement, ManipulationTypes } from '@/models/ForgeModels'
const THREE = window.THREE
const Autodesk = window.Autodesk
import ViewerToolkit from '../Viewer.Toolkit';
import { isTouchDevice } from '@/services/compat';
export default class TransformTool extends EventsEmitter {
    /////////////////////////////////////////////////////////////////
    // Class constructor
    //
    /////////////////////////////////////////////////////////////////
    constructor(viewer) {
        super();

        this.active = false;

        this._viewer = viewer;

        this._hitPoint = null;

        this._isDragging = false;

        this.fullTransform = true;

        this._transformMesh = null;

        this._transformControlTx = null;

        this._selectedFragProxyMap = {};

        this.saveTimeout = null;

        this.snapshotTimeout = null;

        this.currentSelectionProperties = null;

        this.onTxChange = this.onTxChange.bind(this);

        this.onAggregateSelectionChanged = this.onAggregateSelectionChanged.bind(this);

        this.onCameraChanged = this.onCameraChanged.bind(this);

        this.lastPosition = null;
        this.saveTimeout = null;
        this.startMoving = false
    }

    /////////////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////////////
    getNames() {
        return ['Viewing.Transform.Tool'];
    }

    /////////////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////////////
    getName() {
        return 'Viewing.Transform.Tool';
    }

    ///////////////////////////////////////////////////////////////////////////
    // Creates a dummy mesh to attach control to
    //
    ///////////////////////////////////////////////////////////////////////////
    createTransformMesh() {
        var material = new THREE.MeshPhongMaterial({ color: 0xff0000 });

        this._viewer.impl.matman().addMaterial('transform-tool-material', material, true);

        var sphere = new THREE.Mesh(new THREE.SphereGeometry(0.0001, 5), material);

        sphere.position.set(0, 0, 0);

        return sphere;
    }

    translateModelElement(dbId, position, originalPosition) {
        const model = this._viewer.model;

        const it = model.getInstanceTree();

        let translate = new THREE.Vector3(
            position.x - originalPosition.x,
            position.y - originalPosition.y,
            position.z - originalPosition.z,
        );
        it.enumNodeFragments(
            dbId,
            (fragId) => {
                var fragProxy = this._viewer.impl.getFragmentProxy(model, fragId);

                fragProxy.getAnimTransform();

                let worldMatrix = new THREE.Matrix4();

                fragProxy.getWorldMatrix(worldMatrix);

                let originalPosition = worldMatrix.getPosition();

                let fragPosition = new THREE.Vector3(translate.x, translate.y, translate.z);

                fragProxy.position = fragPosition;

                fragProxy.updateAnimTransform();

                this._viewer.impl.sceneUpdated(true);
            },
            true,
        );
    }

    resetTranslationAndRotation(dbId, position, originalPosition) {
        const model = this._viewer.model;

        const it = model.getInstanceTree();
        let translate = new THREE.Vector3(
            position.x - originalPosition.x,
            position.y - originalPosition.y,
            position.z - originalPosition.z,
        );
        it.enumNodeFragments(
            dbId,
            (fragId) => {
                var fragProxy = this._viewer.impl.getFragmentProxy(model, fragId);

                fragProxy.getAnimTransform();

                let worldMatrix = new THREE.Matrix4();

                fragProxy.getWorldMatrix(worldMatrix);

                let originalPosition = worldMatrix.getPosition();

                let fragPosition = new THREE.Vector3(0, 0, 0);

                fragProxy.position = fragPosition;

                fragProxy.quaternion = new THREE.Quaternion();

                fragProxy.updateAnimTransform();

                this._viewer.impl.sceneUpdated(true);
            },
            true,
        );

        this.clearSelection();
    }

    ///////////////////////////////////////////////////////////////////////////
    // on translation change
    //
    ///////////////////////////////////////////////////////////////////////////
    async onTxChange() {
        if (this._isDragging && this._transformControlTx.visible) {
            this.startMoving = true;
            const model = this._selection.model.clone();
            let controlPosition = new THREE.Vector3(
                this._transformControlTx.position.x,
                this._transformControlTx.position.y,
                this._transformControlTx.position.z,
            );

            if (this.fullTransform) {
                let familyInstance = store.state.familiesInstances.find(
                    (family) => family.id === this._familyInstanceId,
                );
                familyInstance.position = controlPosition;
            }

            var translation = new THREE.Vector3(
                this._transformMesh.position.x - this._selection.model.offset.x,
                this._transformMesh.position.y - this._selection.model.offset.y,
                this._transformMesh.position.z - this._selection.model.offset.z,
            );

            let isSceneBuilder = this._selection.model.myData?.isSceneBuilder;

            if (isSceneBuilder) {
                const modelBuilder = store.getters.MODEL_BUILDER;

                for (let i = 0; i < this._selection.dbIdArray.length; i++) {
                    let dbId = this._selection.dbIdArray[i];

                    let meshes = modelBuilder.fragList.vizmeshes.filter(
                        (vizmesh) => vizmesh?.dbId === dbId,
                    );
                    let trans = new THREE.Vector3(
                        this._transformControlTx.position.x - this.lastPosition.x,
                        this._transformControlTx.position.y - this.lastPosition.y,
                        this._transformControlTx.position.z - this.lastPosition.z,
                    );

                    for (let i = 0; i < meshes.length; i++) {
                        let mesh = meshes[i];
                        let translationMatrix = mesh.matrixWorld.clone();

                        let p = translationMatrix.getPosition();
                        p = new THREE.Vector3(p.x + trans.x, p.y + trans.y, p.z + trans.z);

                        translationMatrix = translationMatrix.setPosition(p);

                        mesh.matrix = translationMatrix;

                        mesh.matrixAutoUpdate = false;

                        modelBuilder.updateMesh(mesh, false, false);

                        modelBuilder.sceneUpdated(true, false);
                    }
                    let manipulatedElement = store.getters.MANIPULATED_ELEMENTS.find(
                        (element) => element.dbId == dbId,
                    );

                    if (manipulatedElement) {
                        manipulatedElement.position = controlPosition;
                    }
                    this.lastPosition = new THREE.Vector3(
                        this._transformControlTx.position.x,
                        this._transformControlTx.position.y,
                        this._transformControlTx.position.z,
                    );
                }
            } else {
                const selectedFragProxyMap = { ...this._selectedFragProxyMap };

                for (let _dbId in selectedFragProxyMap) {
                    let fragIds_proxy = selectedFragProxyMap[_dbId].fragIds_proxy;

                    for (let index = 0; index < fragIds_proxy.length; index++) {
                        let fragProxy = fragIds_proxy[index].fragProxy;
                        let position = new THREE.Vector3(
                            this._transformMesh.position.x - fragProxy.offset.x,
                            this._transformMesh.position.y - fragProxy.offset.y,
                            this._transformMesh.position.z - fragProxy.offset.z,
                        );

                        fragProxy.position = position;

                        fragProxy.updateAnimTransform();
                    }
                }
                this._viewer.impl.sceneUpdated(true);
            }

            this.emit('transform.translate', {
                model: model,
                translation: translation,
            });

            if (isSceneBuilder) {
                const modelBuilder = store.getters.MODEL_BUILDER;

                modelBuilder.sceneUpdated(true, false);
            }

            // if (this.saveTimeout) {
            //     clearTimeout(this.saveTimeout);
            // }

            // this.saveTimeout = setTimeout(() => {
            //     store.dispatch('SaveLocalModelChanges');
            // }, 3000);
        }

        this._viewer.impl.sceneUpdated(true);
    }
    updateManipulatedElements() {
        if (this._transformControlTx.visible && this._selectedFragProxyMap) {
            let model = this._selection.model;
            const clonedSelectionProperties = [...this.currentSelectionProperties];
            const selectedFragProxyMap = { ...this._selectedFragProxyMap };

            let i = -1;
            for (let _dbId in selectedFragProxyMap) {
                i++;
                let fragIds_proxy = selectedFragProxyMap[_dbId].fragIds_proxy;
                let originalPos = selectedFragProxyMap[_dbId].originalPosition;

                var fragList = this._viewer.model.getFragmentList();
                let fragIds = fragIds_proxy.map((x) => x.fragId);
                var nodebBox = this.geWorldBoundingBox(fragIds, fragList);
                const center = nodebBox.getCenter();

                let dbId = parseInt(_dbId);
                let manipulatedElement = store.getters.MANIPULATED_ELEMENTS.find(
                    (element) => element.dbId === dbId,
                );
                if (!manipulatedElement) {
                    ViewerToolkit.getViewabelIdsOfElement(model, dbId, store).then((_viewGuids) => {
                        manipulatedElement = new ManipulatedElement();
                        manipulatedElement.viewType = '3d';
                        manipulatedElement.viewId =
                            window.NOP_VIEWER.model.getDocumentNode()?.data?.guid;
                        manipulatedElement.manipulationType = ManipulationTypes.MANIPULATE_EXISTING;
                        manipulatedElement.name = clonedSelectionProperties[i]?.name;
                        manipulatedElement.modelId = this._viewer.model.getData().urn;
                        manipulatedElement.dbId = dbId;
                        manipulatedElement.originalDbId = dbId;
                        manipulatedElement.originalExternalId =
                            clonedSelectionProperties[i]?.externalId;
                        manipulatedElement.position = center.clone();
                        if (!manipulatedElement.originalPosition)
                            manipulatedElement.originalPosition = originalPos.clone();
                        manipulatedElement.viewGuids = _viewGuids;
                        store.commit('ADD_MANIPULATED_ELEMENT', manipulatedElement);
                    });
                } else {
                    manipulatedElement.position = center.clone();
                }
            }

            if (this.saveTimeout) {
                clearTimeout(this.saveTimeout);
            }

            this.saveTimeout = setTimeout(() => {
                store.dispatch('SaveLocalModelChanges');
            }, 3000);
        }
    }
    forceUpdate() {
        let newPosition = store.getters['VIEWER/CURRENT_MANIPULATION_POSITION'];
      
         let controlPosition = new THREE.Vector3(
             this._transformControlTx.position.x,
             this._transformControlTx.position.y,
             this._transformControlTx.position.z,
         );

               var translation = new THREE.Vector3(
                   this._transformMesh.position.x - this._selection.model.offset.x,
                   this._transformMesh.position.y - this._selection.model.offset.y,
                   this._transformMesh.position.z - this._selection.model.offset.z,
               );

               let isSceneBuilder = this._selection.model.myData?.isSceneBuilder;

               if (isSceneBuilder) {
                   const modelBuilder = store.getters.MODEL_BUILDER;

                   for (let i = 0; i < this._selection.dbIdArray.length; i++) {
                       let dbId = this._selection.dbIdArray[i];

                       let meshes = modelBuilder.fragList.vizmeshes.filter(
                           (vizmesh) => vizmesh?.dbId === dbId,
                       );
                       let trans = new THREE.Vector3(
                           -this._transformControlTx.position.x + newPosition.x,
                           -this._transformControlTx.position.y + newPosition.y,
                           -this._transformControlTx.position.z + newPosition.z,
                       );

                       for (let i = 0; i < meshes.length; i++) {
                           let mesh = meshes[i];
                           let translationMatrix = mesh.matrixWorld.clone();

                           let p = translationMatrix.getPosition();
                           p = new THREE.Vector3(p.x + trans.x, p.y + trans.y, p.z + trans.z);

                           translationMatrix = translationMatrix.setPosition(p);

                           mesh.matrix = translationMatrix;

                           mesh.matrixAutoUpdate = false;

                           modelBuilder.updateMesh(mesh, false, false);

                           modelBuilder.sceneUpdated(true, false);
                       }
                       let manipulatedElement = store.getters.MANIPULATED_ELEMENTS.find(
                           (element) => element.dbId == dbId,
                       );

                       if (manipulatedElement) {
                           manipulatedElement.position = newPosition;
                       }
                        if (this.saveTimeout) {
                            clearTimeout(this.saveTimeout);
                        }

                        this.saveTimeout = setTimeout(() => {
                            store.dispatch('SaveLocalModelChanges');
                        }, 500);
                   }
               } else {
                   const selectedFragProxyMap = { ...this._selectedFragProxyMap };

                   for (let _dbId in selectedFragProxyMap) {
                       let fragIds_proxy = selectedFragProxyMap[_dbId].fragIds_proxy;

                       for (let index = 0; index < fragIds_proxy.length; index++) {
                           let fragProxy = fragIds_proxy[index].fragProxy;
                           let position = new THREE.Vector3(
                               newPosition.x - fragProxy.offset.x,
                               newPosition.y - fragProxy.offset.y,
                               newPosition.z - fragProxy.offset.z,
                           );

                           fragProxy.position = position;

                           fragProxy.updateAnimTransform();
                       }
                   }
                   this._viewer.impl.sceneUpdated(true);
                   this.updateManipulatedElements(); 
               }
                 this._transformControlTx.setPosition(newPosition);
    }
    ///////////////////////////////////////////////////////////////////////////
    // on camera changed
    //
    ///////////////////////////////////////////////////////////////////////////
    onCameraChanged() {
        if (this._transformControlTx) this._transformControlTx.update();
    }

    ///////////////////////////////////////////////////////////////////////////
    // item selected callback
    //
    ///////////////////////////////////////////////////////////////////////////
    onAggregateSelectionChanged(event) {
        if (!event.selections || !event.selections.length) {
            this.clearSelection();
            return;
        }

        this._selection = event.selections[0];

        let selectedModelId = this._selection?.model?.id;
        let familyInstance = store.state.familiesInstances.find(
            (family) => family.loadedModelId === selectedModelId,
        );

        this._familyInstanceId = familyInstance?.id;

        this.fullTransform = !!this._familyInstanceId;

        if (this.fullTransform) {
            this._selection.fragIdsArray = [];

            var fragCount = this._selection.model.getFragmentList().fragments.fragId2dbId.length;

            for (var fragId = 0; fragId < fragCount; ++fragId) {
                this._selection.fragIdsArray.push(fragId);
            }

            this._selection.dbIdArray = [];

            this.currentSelectionProperties = null;

            var instanceTree = this._selection.model.getData().instanceTree;

            var rootId = instanceTree.getRootId();

            this._selection.dbIdArray.push(rootId);

            this._hitPoint = {
                x: familyInstance.position.x,
                y: familyInstance.position.y,
                z: familyInstance.position.z,
            };
        } else {
            this._viewer.model.getBulkProperties(this._selection.dbIdArray, null, (result) => {
                this.currentSelectionProperties = result;
            });

            var fragList = this._selection.model.getFragmentList();

            var nodebBox = this.geWorldBoundingBox(this._selection.fragIdsArray, fragList);

            this._hitPoint = nodebBox.getCenter();
        }

        this.emit('transform.modelSelected', this._selection);

        this.initializeSelection(this._hitPoint);
    }

    initializeSelection(hitPoint) {
        this._selectedFragProxyMap = {};

        var modelTransform = this._selection.model.transform || {
            translation: { x: 0, y: 0, z: 0 },
        };

        this._selection.model.offset = {
            x: hitPoint.x - modelTransform.translation.x,
            y: hitPoint.y - modelTransform.translation.y,
            z: hitPoint.z - modelTransform.translation.z,
        };
        this.lastPosition = hitPoint;
        this._transformControlTx.visible = true;

        this._transformControlTx.setPosition(hitPoint);

        this._transformControlTx.addEventListener('change', this.onTxChange);

        this._viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.onCameraChanged);

        if (this.active) {
            store.commit('VIEWER/SET_CURRENT_MANIPULATION_POSITION', hitPoint.clone());
            store.commit('VIEWER/SET_START_MANIPULATION_POSITION', hitPoint.clone());
        }

        const it = this._viewer.model.getInstanceTree();
        var fragList = this._selection.model.getFragmentList();
        this._selection.dbIdArray.forEach((dbId) => {
            let fragIds = [];
            it.enumNodeFragments(
                dbId,
                (fragId) => {
                    fragIds.push(fragId);
                },
                true,
            );
            let fragIds_proxy = [];
            var nodebBox = this.geWorldBoundingBox(fragIds, fragList);
            const center = nodebBox.getCenter();
            fragIds.forEach((fragId) => {
                var fragProxy = this._viewer.impl.getFragmentProxy(this._selection.model, fragId);

                fragProxy.getAnimTransform();

                fragProxy.offset = {
                    x: hitPoint.x - fragProxy.position.x,
                    y: hitPoint.y - fragProxy.position.y,
                    z: hitPoint.z - fragProxy.position.z,
                };
                fragIds_proxy.push({ fragId: fragId, fragProxy: fragProxy });
            });
            this._selectedFragProxyMap[dbId] = { fragIds_proxy, originalPosition: center };
        });
        // this._selection.fragIdsArray.forEach((fragId) => {
        //     var fragProxy = this._viewer.impl.getFragmentProxy(this._selection.model, fragId);

        //     fragProxy.getAnimTransform();

        //     fragProxy.offset = {
        //         x: hitPoint.x - fragProxy.position.x,
        //         y: hitPoint.y - fragProxy.position.y,
        //         z: hitPoint.z - fragProxy.position.z,
        //     };

        //     this._selectedFragProxyMap[fragId] = fragProxy;
        // });
    }

    clearSelection() {
        if (!this.active) return;

        store.commit('VIEWER/SET_CURRENT_MANIPULATION_POSITION', null);

        store.commit('VIEWER/SET_START_MANIPULATION_POSITION', null);
        this.currentSelectionProperties = null;

        this._selection = null;

        this._selectedFragProxyMap = {};

        this._transformControlTx.visible = false;

        this._transformControlTx.removeEventListener('change', this.onTxChange);

        this._viewer.removeEventListener(
            Autodesk.Viewing.CAMERA_CHANGE_EVENT,
            this.onCameraChanged,
        );

        this._viewer.impl.sceneUpdated(true);
    }

    ///////////////////////////////////////////////////////////////////////////
    // normalize screen coordinates
    //
    ///////////////////////////////////////////////////////////////////////////
    normalize(screenPoint) {
        var viewport = this._viewer.navigation.getScreenViewport();

        var n = {
            x: (screenPoint.x - viewport.left) / viewport.width,
            y: (screenPoint.y - viewport.top) / viewport.height,
        };

        return n;
    }

    ///////////////////////////////////////////////////////////////////////////
    // get 3d hit point on mesh
    //
    ///////////////////////////////////////////////////////////////////////////
    getHitPoint(event) {
        var screenPoint = {
            x: event.clientX,
            y: event.clientY,
        };

        var n = this.normalize(screenPoint);

        var hitPoint = this._viewer.utilities.getHitPoint(n.x, n.y);

        return hitPoint;
    }

    selectCurrent() {
        let selection = this._viewer.getAggregateSelection();

        this._viewer.clearSelection();

        if (selection.length) {
            this._viewer.select(selection[0].selection, selection[0].model);
        }
    }

    ///////////////////////////////////////////////////////////////////
    //
    //
    ///////////////////////////////////////////////////////////////////
    activate() {
        if (this.active) {
            this.selectCurrent();
            return;
        }

        this.active = true;

        var bbox = this._viewer.model.getBoundingBox();

        this._viewer.impl.createOverlayScene('TransformToolOverlay');

        this._transformControlTx = new THREE.TransformControls(
            this._viewer.impl.camera,
            this._viewer.impl.canvas,
            'translate',
        );

        this._transformControlTx.setSize(bbox.getBoundingSphere().radius * 5);

        this._transformControlTx.visible = false;

        this._viewer.impl.addOverlay('TransformToolOverlay', this._transformControlTx);

        this._transformMesh = this.createTransformMesh();

        this._transformControlTx.attach(this._transformMesh);

        this._viewer.addEventListener(
            Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
            this.onAggregateSelectionChanged,
        );

        this.selectCurrent();
    }

    ///////////////////////////////////////////////////////////////////////////
    // deactivate tool
    //
    ///////////////////////////////////////////////////////////////////////////
    deactivate() {
        if (!this.active) return;

        this.active = false;

        store.commit('VIEWER/SET_CURRENT_MANIPULATION_POSITION', null);

        store.commit('VIEWER/SET_START_MANIPULATION_POSITION', null);

        this._viewer.impl.removeOverlay('TransformToolOverlay', this._transformControlTx);

        this._transformControlTx.removeEventListener('change', this.onTxChange);

        this._viewer.impl.removeOverlayScene('TransformToolOverlay');

        this._viewer.removeEventListener(
            Autodesk.Viewing.CAMERA_CHANGE_EVENT,
            this.onCameraChanged,
        );

        this._viewer.removeEventListener(
            Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
            this.onAggregateSelectionChanged,
        );
    }

    ///////////////////////////////////////////////////////////////////////////
    //
    //
    ///////////////////////////////////////////////////////////////////////////
    handleButtonDown(event, button) {
        console.log('ponterDown');
        this._hitPoint = this.getHitPoint(event);

        this._isDragging = true;

        if (this._transformControlTx.onPointerDown(event)) return true;

        return false;
    }

    ///////////////////////////////////////////////////////////////////////////
    //
    //
    ///////////////////////////////////////////////////////////////////////////
    handleButtonUp(event, button) {
        if(this.startMoving){
        
            this.updateManipulatedElements();
            this.startMoving = false;
        }
        this._isDragging = false;

        if (this._transformControlTx.onPointerUp(event)) return true;

        return false;
    }

    ///////////////////////////////////////////////////////////////////////////
    //
    //
    ///////////////////////////////////////////////////////////////////////////
    handleMouseMove(event) {
        if (this._isDragging) {
            if (this._transformControlTx.onPointerMove(event)) {
                return true;
            }

            return false;
        }

        if (this._transformControlTx.onPointerHover(event)) return true;

        return false;
    }
    handleGesture(event) {
        if (!isTouchDevice()) return false;

        if (!this._selection) return false;

        console.log('Gesture event triggered from Translate2DTool');

        switch (event.type) {
            case 'dragstart':
                var accepted = this.transformControlTx?.onPointerDown(event);

                this.handleButtonDown(event, 0);

                this.touchType = 'drag';

                return accepted;

            case 'dragmove':
                return this.handleMouseMove(event);

            case 'dragend':
                if (this.touchType === 'drag') {
                    this.handleButtonUp(event, 0);

                    this.touchType = null;

                    return true;
                }

                return false;
        }

        return false;
    }
    ///////////////////////////////////////////////////////////////////////////
    // returns bounding box as it appears in the viewer
    // (transformations could be applied)
    //
    ///////////////////////////////////////////////////////////////////////////
    geWorldBoundingBox(fragIds, fragList) {
        var fragbBox = new THREE.Box3();
        var nodebBox = new THREE.Box3();

        fragIds.forEach((fragId) => {
            fragList.getWorldBounds(fragId, fragbBox);
            nodebBox.union(fragbBox);
        });

        return nodebBox;
    }
}
