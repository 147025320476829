/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import { createStore } from 'vuex';
import config from '../../appConfig.js';
import { InstanceProperties } from './modules/InstanceProperties';
import { CameraAttachementStore } from './modules/CameraAttachement';
import { VersionsStore } from './modules/VersionsStore.js';
import { PreventSelectionStore } from './modules/PreventSelectionStore.js';
// import { AdminStore } from './modules/AdminStore.js';
import { SETTINGS } from './modules/SettingsListStore';
import { Buckets } from './modules/Buckets';
import HttpService from '@Utils/httpService';
import modelService from '@Models/service/model.service';
import { Hubs } from './modules/Hubs.js';
import hubService from '@/hubService.js';
import { toast } from 'vue3-toastify';
import { ForgeService } from './modules/ForgeService';
import Block2dLibraryExtension from '@/extensions/Block2dLibraryExtension/Block2dLibraryExtension.js';
import ManipulationExtension from '@/extensions/Viewing.Extension.Manipulation';
import Manipulation2DExtension from '@/extensions/Viewing.Extension.Manipulation2D/index.js';
import ElementClone2D from '@/extensions/Viewing.Extension.Manipulation2D/tool.js';
import { ManipulationTypes } from '@/models/ForgeModels.js'; 
const Autodesk = window.Autodesk;
const THREE = window.THREE;
import { UpdateForgeElementsColors } from '@/utils/forgeElementsColors.js';
import '../extensions/ModifyProperties/ModifyPropertiesExtension.js';
import '../extensions/ShowCameras/ShowCamerasExtension.js';
import '../extensions/Viewing.Extension.Manipulation/MarkupExtension.js';
import ViewerToolkit from '@/extensions/Viewer.Toolkit';
import PreventSelectionExtension from '@/extensions/PreventSelection/PreventSelectionExtension.js';
import NestedFamilySelectionExtension from '@/extensions/NestedFamilySelectionExtension/NestedFamilySelectionExtension.js';
 import {
     NewFamilyColor,
     ManipulatedColor,
     ClonedColor,
     DeletedColor,
 } from './../utils/forgeElementsColors.js';

import Blocks2dLibrary from './modules/Blocks2dLibrary.js';
import { isTouchDevice } from '@/services/compat.js';
import { ACCOUNT } from '@Account/modules/account.modules.js';
import { MODELS } from '@Models/modules/models.modules.js';
import { AdminStore } from '@Admin/modules/admin.modules.js';
import { VIEWER } from '@Home/modules/viewer.modules.js';
import { MARKUPDATA } from '@/features/Markups/modules/markups.modules.js';
import { MARKUPEDITOR } from '@/features/Markups/modules/markups.edit.modules.js';
import router from '@/router/index.js';
import { PROJECTS } from '@/features/Home/modules/projects.modules.js';
import { COLORINGSYSTEMS } from '@/features/ColoringSystems/modules/ColoringSystems.js';

import { MODELBROWSER } from '@/features/ModelBrowser/modules/ModelBrowser.js';
import { CommandManager } from '@/utils/undoRedoManager.js';
import { CUSTOMPROPERTIES } from '@/features/KTSProperties/modules/CutomProperties.js';
import ModelSheetTransitionExtension from '@/extensions/ModelSheetTransition/ModelSheetTransition.js';
const store = createStore({
    state() {
        return {
            viewer: null,
            displayUnit: 'm',
            hubId: 'kts',
            modelObjectId: null,
            modelId: null, // Urn
            modelGuid: null,
            modelStorageUrl: null,

            showMainOverlay: false,
            isProgressBarActive: false,
            progressBarValue: 0,

            currentViewName: '',
            viewerNodePath: '',

            parallelViewer: null,

            clone3dTool: null,
            clone2dTool: null,

            families: [],
            familiesInstances: [],

            // Cloned Elements OR Elements that are being manipulated by moving or rotating.
            manipulatedElements: [],

            openInstancesPanel: false,

            newVersionFileName: null,

            modelBuilder: null,

            root2dItems: [],
            D2Items: [],
            D3Items: [],
            document: null,

            isSaving: false,
            savingError: null,
            added: false,

            showDefaultColors: true,
            // allRequestData: [],
            NestedFamilyTree: [],
            ShowLoader: false,
            CommandManager: new CommandManager(),

            onManipulationDoneCallBacks: [],

            selectedElementName: null,

            is3d: false,
        };
    },
    getters: {
        VIEWER(state) {
            return state.viewer;
        },
        IS_TOUCH_DEVICE(state) {
            return window.matchMedia('(pointer: coarse)').matches || isTouchDevice();
        },
        DOCUMENT(state) {
            return state.document;
        },
        HUB_ID(state) {
            return state.hubId;
        },
        MODEL_OBJECT_ID(state) {
            return state.modelObjectId;
        },
        MODEL_ID(state) {
            return state.modelId;
        },
        MODEL_GUID(state) {
            return state.modelGuid;
        },
        SHOW_MAIN_OVERLAY(state) {
            return state.showMainOverlay;
        },
        IS_PROGRESS_BAR_ACTIVE(state) {
            return state.isProgressBarActive;
        },
        PROGRESS_BAR_VALUE(state) {
            return state.progressBarValue;
        },
        MODEL_STORAGE_URL(state) {
            return state.modelStorageUrl;
        },
        DISPLAY_UNIT(state) {
            return state.displayUnit;
        },
        FAMILIES(state) {
            return state.families;
        },
        FAMILIES_INSTANCES(state) {
            return state.familiesInstances;
        },
        MANIPULATED_ELEMENTS(state) {
            return state.manipulatedElements;
        },
        CLONED_Elements(state) {
            return state.manipulatedElements.filter(
                (e) => e.manipulationType === ManipulationTypes.CLONED_ELEMENT,
            );
        },
        OPEN_INSTANCES_PANEL(state) {
            return state.openInstancesPanel;
        },
        NEW_VERSION_FILE_NAME(state) {
            return state.newVersionFileName;
        },
        MODEL_BUILDER(state) {
            return state.modelBuilder;
        },
        CURRENT_VIEW_NAME(state) {
            return state.currentViewName;
        },
        VIEWER_NODE_PATH(state) {
            return state.viewerNodePath;
        },
        ROOT_2D_ITEMS(state) {
            return state.root2dItems;
        },
        D2ViewItems(state) {
            return state.D2Items;
        },
        D3ViewItems(state) {
            return state.D3Items;
        },
        IS_SAVING(state) {
            return state.isSaving;
        },
        SAVING_ERROR(state) {
            return state.savingError;
        },
        SHOW_DEFAULT_COLORS(state) {
            return state.showDefaultColors;
        },
        NESTED_FAMILY_TREE(state) {
            return state.NestedFamilyTree;
        },
        SHOW_LOADER(state) {
            return state.ShowLoader;
        },
        COMMAND_MANAGER(state) {
            return state.CommandManager;
        },
        ON_MANIPULATION_DONE_CALLBACKS(state) {
            return state.onManipulationDoneCallBacks;
        },

        SELECTED_ELEMENT_NAME(state) {
            return state.selectedElementName;
        },
        IS_3D(state) {
            return state.is3d;
        }
    },
    mutations: {
        SET_VIEWER(state, viewer) {
            state.viewer = viewer;
        },
        SET_DOCUMENT(state, document) {
            state.document = document;
        },
        SET_HUB_ID(state, hubId) {
            state.hubId = hubId;
        },
        SET_MODEL_OBJECT_ID(state, modelObjectId) {
            state.modelObjectId = modelObjectId;
        },
        SET_MODEL_ID(state, modelId) {
            state.modelId = modelId;
        },
        SET_MODEL_GUID(state, modelGuid) {
            state.modelGuid = modelGuid;
        },
        SET_SHOW_MAIN_OVERLAY(state, value) {
            state.showMainOverlay = value;
        },
        SET_PROGRESS_BAR_ACTIVE(state, value) {
            state.isProgressBarActive = value;
        },
        SET_PROGRESS_BAR_VALUE(state, value) {
            state.progressBarValue = value;
        },
        SET_MODEL_STORAGE_URL(state, modelStorageUrl) {
            state.modelStorageUrl = modelStorageUrl;
        },
        SET_DISPLAY_UNIT(state, unit) {
            state.displayUnit = unit;
        },
        SET_FAMILIES(state, families) {
            state.families = families;
        },
        ADD_FAMILY(state, family) {
            state.families.unshift(family);
        },
        SET_FAMILIES_INSTANCES(state, instances) {
            state.familiesInstances = instances;
        },
        ADD_FAMILY_INSTANCE(state, instance) {
            state.familiesInstances.push(instance);
        },
        SET_MANIPULATED_ELEMENTS(state, elements) {
            state.manipulatedElements = elements;
        },
        ADD_MANIPULATED_ELEMENT(state, element) {
            state.manipulatedElements = state.manipulatedElements.filter(
                (e) => e.dbId !== element.dbId,
            );
            state.manipulatedElements.push(element);
        },
        SET_OPEN_INSTANCES_PANEL(state, value) {
            state.openInstancesPanel = value;
        },
        SET_NEW_VERSION_FILE_NAME(state, fileName) {
            state.newVersionFileName = fileName;
        },
        SET_MODEL_BUILDER(state, modelBuilder) {
            state.modelBuilder = modelBuilder;
        },
        SET_CURRENT_VIEW_NAME(state, name) {
            state.currentViewName = name;
        },
        SET_VIEWER_NODE_PATH(state, path) {
            state.viewerNodePath = path;
        },
        SET_ROOT_2D_ITEMS(state, root2dItems) {
            state.root2dItems = root2dItems;
        },
        SET_D2Items(state, d2Items) {
            state.D2Items = d2Items;
        },
        SET_D3Items(state, d3Items) {
            state.D3Items = d3Items;
        },
        SET_IS_SAVING(state, value) {
            state.isSaving = value;
        },
        SET_SAVING_ERROR(state, error) {
            state.savingError = error;
        },
        SET_SHOW_DEFAULT_COLORS(state, isDefault) {
            state.showDefaultColors = isDefault;
        },
        SET_NESTED_FAMILY_TREE(state, arr) {
            state.NestedFamilyTree = arr;
        },
        SET_SHOW_LOADER(state, ShowLoader) {
            state.ShowLoader = ShowLoader;
        },
        ADD_ON_MANIPULATION_DONE_CALLBACK(state, callback) {
            state.onManipulationDoneCallBacks.push(callback);
        },
        REMOVE_ON_MANIPULATION_DONE_CALLBACK(state, callback) {
            state.onManipulationDoneCallBacks = state.onManipulationDoneCallBacks.filter(
                (x) => x != callback,
            );
        },

        SET_SELECTED_ELEMENT_NAME(state, name) {
            state.selectedElementName = name;
        },
        SET_IS_3D(state, is3d) {
            state.is3d = is3d;
        }
    },
    actions: {
        Test() {
            console.log(config.API_URL);
        },
        GetFamilies({ commit, dispatch, getters }, modelId) {
            return new Promise((resolve, reject) => {
                HttpService.get({
                    url: `api/families`,
                    params: {
                        hubId: getters.HUB_ID,
                    },
                })
                    .then((response) => {
                        commit('SET_FAMILIES', response);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
        },
        UploadFamily({ commit, dispatch, getters }, { file, onProgress }) {
            if (!file) {
                console.error('No file provided.');
                return;
            }

            return new Promise((resolve, reject) => {
                let formData = new FormData();

                let user = store.getters['ACCOUNT/USER'];

                formData.append('file', file);
                formData.append('hubId', getters.HUB_ID);
                formData.append('name', file.name.split('.').slice(0, -1).join('.'));
                formData.append('userId', user.userId);
                formData.append('userName', user.name);

                HttpService.post({
                    url: `api/families/uploadFamily`,
                    data: formData,
                    headers: {
                        'Content-Type': false,
                        processData: false,
                    },
                    onUploadProgress: function (progressEvent) {
                        if (progressEvent.event.lengthComputable) {
                            if (onProgress) onProgress(progressEvent.loaded, progressEvent.total);
                        }
                    },
                })
                    .then((familyId) => {
                        dispatch('StartProcessingFamily', familyId);
                        resolve(familyId);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        StartProcessingFamily({ commit, dispatch, getters }, familyId) {
            return new Promise((resolve, reject) => {
                HttpService.post({
                    url: `api/aps/designautomation/startprocessfamily`,
                    params: {
                        familyId: familyId,
                        connectionId: hubService.connection.connectionId,
                    },
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        SaveFamilyInstanceToDB({ commit, dispatch, getters }, instance) {
            return new Promise((resolve, reject) => {
                HttpService.post({
                    url: `api/families/addFamilyInstance`,
                    data: instance,
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        AddFamilyInstanceToViewer({ dispatch, commit }, familyInstance) {
            return new Promise((resolve, reject) => {
                dispatch('AddViewableToViewer', familyInstance)
                    .then((document) => {
                        resolve(familyInstance);
                    })
                    .catch((error) => {
                        console.error('Error adding family to viewer:', error);
                        reject(error);
                        toast.error('Error adding family to viewer');
                    });
            });
        },
        Add2DManipulatedElementToViewer({ commit, dispatch, getters, state }, element) {
            return new Promise((resolve, reject) => {
                if (
                    element.manipulationType === ManipulationTypes.CLONED_ELEMENT &&
                    !getters.IS_PROGRESS_BAR_ACTIVE
                ) {
                    const tool = window.NOP_VIEWER.getExtension(
                        Manipulation2DExtension.ExtensionId,
                    ).tool;
                    tool.addClonedElement(element, true).then(() => resolve());
                } else if (
                    element.manipulationType === ManipulationTypes.MANIPULATE_EXISTING &&
                    element.position
                ) {
                    window.NOP_VIEWER.getExtension(
                        Manipulation2DExtension.ExtensionId,
                    ).tool.moveElement(element);
                    if (element?.rotation) {
                        const center = ViewerToolkit.worldToSheet(
                            new THREE.Vector3().copy(element.position),
                            window.NOP_VIEWER.model,
                            getters['VIEWER/TRANSFORM_MATRIX'],
                        );
                        let rotate2dTool =
                            window.NOP_VIEWER.toolController.getTool('Viewing.Rotate2D.Tool');
                        rotate2dTool.rotateModelElement(
                            element.dbId,
                            'Z',
                            element.rotation.z,
                            center,
                        );
                    }
                    resolve();
                }
            });
        },
        async Add3DManipulatedElementToViewer({ commit, dispatch, state }, element) {
            if (element.manipulationType === ManipulationTypes.CLONED_ELEMENT) {
                // if (element.viewType !== '3d') return;
                state.clone3dTool.addClonedElement(
                    element.originalDbId,
                    element.dbId,
                    element.position,
                    element.rotation,
                    element.name,
                    element.familyType,
                    true,
                );
            } else if (element.manipulationType === ManipulationTypes.MANIPULATE_EXISTING) {
                let transformExtension = window.NOP_VIEWER.getExtension(
                    'Viewing.Extension.Transform',
                );

                if (!transformExtension) {
                    console.error('Transform extension not found.');
                    return;
                }

                console.log(
                    `%c Moving element`,
                    'color: green; font-weight: bold; font-size: 1.5em; background-color: #000; padding: 5px;',
                );

                if (element.position) {
                    transformExtension.translateTool.translateModelElement(
                        element.dbId,
                        element.position,
                        element.originalPosition,
                    );
                    if (element.rotation) {
                        let rotateTool =
                            window.NOP_VIEWER.toolController.getTool('Viewing.Rotate.Tool');
                        const it = window.NOP_VIEWER.model.getInstanceTree();
                        let frags = [];
                        it.enumNodeFragments(
                            element.dbId,
                            (fragId) => {
                                frags.push(fragId);
                            },
                            true,
                        );
                        rotateTool.rotateFragments(
                            window.NOP_VIEWER.model,
                            frags,
                            new THREE.Vector3(0, 0, 1),
                            element.rotation.z,
                            element.position,
                            true,
                        );
                    }
                }
            }
        },
        AddManipulatedElementToViewer({ commit, dispatch, state }, element) {
            return new Promise((resolve, reject) => {
                commit('ADD_MANIPULATED_ELEMENT', element);

                if (!state.viewer?.model) {
                    reject('No model loaded.');
                    return;
                }

                if (state.viewer?.model?.is2d()) {
                    dispatch('Add2DManipulatedElementToViewer', element)
                        .then(() => {
                            resolve(element);
                        })
                        .catch((error) => {
                            console.error('Error adding manipulated element to viewer:', error);
                            reject(error);
                            toast.error('Error adding manipulated element to viewer');
                        });
                } else {
                    dispatch('Add3DManipulatedElementToViewer', element)
                        .then(() => {
                            resolve(element);
                        })
                        .catch((error) => {
                            console.error('Error adding manipulated element to viewer:', error);
                            reject(error);
                            toast.error('Error adding manipulated element to viewer');
                        });
                }

                // # Add element to the model if cloned.

                // # Update element in the model if manipulated.
            });
        },
        GetManifest({ state, dispatch, commit }, urn) {
            return new Promise((resolve, reject) => {
                dispatch('ACCOUNT/GetAccessToken').then(({ access_token }) => {
                    HttpService.get({
                        baseURL: `https://developer.api.autodesk.com/modelderivative/v2/designdata/${urn}/manifest`,
                        headers: { Authorization: 'Bearer ' + access_token },
                    })
                        .then((manifest) => {
                            let derivatives = manifest.derivatives;
                            let root = derivatives.find(
                                (derivative) =>
                                    derivative.outputType === 'svf' ||
                                    derivative.outputType === 'svf2',
                            );
                            console.log(manifest);
                            resolve(manifest);
                        })
                        .catch((error) => {
                            console.log(error);
                            reject(error);
                        });
                });
            });
        },
        CheckIsModelTranslated({ dispatch }, urn) {
            return new Promise((resolve, reject) => {
                dispatch('ACCOUNT/GetAccessToken').then(({ access_token }) => {
                    HttpService.get({
                        baseURL: `https://developer.api.autodesk.com/modelderivative/v2/designdata/${urn}/manifest`,
                        headers: { Authorization: 'Bearer ' + access_token },
                    })
                        .then((response) => {
                            if (response.status === 'success') {
                                resolve({ status: true, progress: 100 });
                            } else {
                                resolve({ status: false, progress: response.progress });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            reject(error);
                        });
                });
            });
        },
        GetThumbnail({ dispatch }, url) {
            return new Promise((resolve, reject) => {
                dispatch('ACCOUNT/GetAccessToken').then(({ access_token }) => {
                    HttpService.get({
                        baseURL: url,
                        headers: { Authorization: 'Bearer ' + access_token },
                    })
                        .then((response) => {
                            resolve(response);
                        })
                        .catch((error) => {
                            console.log(error);
                            reject(error);
                        });
                });
            });
        },
        loadDocumentNode(store, data) {
            console.log('load document');
            store.dispatch('MARKUPDATA/resetMarkupViewData');
            store.dispatch('MARKUPEDITOR/ExitMarkup');

            store.commit('SET_IS_3D', !data.is2d);

            router.replace({ query: { view: window.NOP_VIEWER.model.getDocumentNode().guid() } });

            var list = data.is2d == true ? window.d2items : window.d3items;
            let selectedItem = list.filter((c) => c.data.guid == data.guid)[0];
            if (data.extra?.add) {
                let modelboxX = window.NOP_VIEWER.getAllModels().lastItem.myData.bbox.max.x;
                let modelWidth =
                    window.NOP_VIEWER.getAllModels().lastItem.myData.bbox.max.x -
                    window.NOP_VIEWER.getAllModels().lastItem.myData.bbox.min.x;
                let boxX = modelboxX + modelWidth / 10;

                window.NOP_VIEWER.loadDocumentNode(window.loadedForgeDocument, selectedItem, {
                    keepCurrentModels: true,
                    preserveView: true, // 2D drawings
                    modelSpace: true, // 2D drawings
                    applyRefPoint: true, // 3D shared coordinates
                    applyScaling: 'm', // force all models to same scale
                    globalOffset: { x: 0, y: 0, z: 0 }, // force all models to origin
                    placementTransform: new THREE.Matrix4().setPosition({ x: boxX, y: 0, z: 0 }),
                });
                if (!window.addedModels) {
                    window.addedModels = [];
                }
                window.addedModels.push({ guid: selectedItem.data.guid, X: boxX });
            } else if (data.extra?.remove) {
                window.NOP_VIEWER.unloadDocumentNode(selectedItem);
                let removedItem = window.addedModels.filter(
                    (c) => c.guid == selectedItem.data.guid,
                )[0];
                if (removedItem) {
                    const index = window.addedModels.indexOf(removedItem);
                    if (index > -1) {
                        window.addedModels.splice(index, 1);
                    }
                }
            } else {
                window.NOP_VIEWER.loadDocumentNode(window.loadedForgeDocument, selectedItem);
                if (data.is2d) {
                    window.parallelViewer.loadDocumentNode(
                        window.loadedForgeDocument,
                        selectedItem,
                    );
                } else {
                    window.parallel3dViewer.loadDocumentNode(
                        window.loadedForgeDocument,
                        selectedItem,
                    );
                }
                window.loadedModel = selectedItem;
                window.addedModels = [];
                //window.addedModels.push({guid:selectedItem.data.guid,X:0});
                setTimeout(() => {
                    window.NOP_VIEWER.loadExtension('Autodesk.Viewing.MarkupsCore').then(function (
                        markupsExt,
                    ) {
                        window.markup = markupsExt;
                    });
                }, 2000);
                store.dispatch('MARKUPEDITOR/loadSavedMarkups');
            }
        },
        OpenViewWithId({ commit, state, dispatch }, viewId) {
            if (!window.NOP_VIEWER?.model) return;

            console.log('Open Document >>>', viewId);

            let root = window.NOP_VIEWER.model.getDocumentNode().getRootNode();

            let views = root.search({
                guid: viewId,
            });

            if (views.length) {
                let view = views[0];
                window.NOP_VIEWER.loadDocumentNode(window.loadedForgeDocument, view);
            } else {
                toast.warn('View not found');
            }
        },
        InvalidateViewers() {
            store.commit('MODELS/SET_CURRENT_MODEL_DATA', null);
            store.commit('SET_View_Id', null);
            store.commit('SET_LATEST_VERSION', null);
            store.commit('SET_CURRENT_VIEW_NAME', null);
            store.commit('SET_SELECTED_ELEMENT_NAME', null);

            window.NOP_VIEWERS?.forEach((v) => {
                v.finish();
            });

            window.loadedModel = undefined;
            store.dispatch('MARKUPDATA/resetMarkupViewData');
            store.dispatch('MARKUPDATA/setMarkupClosed');
            store.dispatch('MARKUPEDITOR/ExitMarkup');
            store.commit('MARKUPDATA/SET_LOADED_MODEL_NAME', null);
            store.commit('SET_Show_Camera_Attachement', false);
        },
        StartParallelViewer({ state, dispatch, getters }, URN) {
            // Create parallel viewer to load blocks without affecting the main viewer.
            let parallel = document.getElementById('parallelForgeViewer');

            let config = {
                skipPropertyDb: true,
                extensions: [
                    //   'Autodesk.LayerManager',
                    //   'Autodesk.BimWalk',
                    //   'Autodesk.Snapping',
                    //   // 'Autodesk.DocumentBrowser',
                    //   'Autodesk.Measure',
                    //   'ModifyPropertiesExtension',
                    Block2dLibraryExtension.extensionId,
                    //   'PreventSelectionExtension',
                    //   'NestedFamilySelectionExtension',
                    //   'ShowCamerasExtension',
                    //   'MarkupToolExtension',
                    // 'Autodesk.AEC.LevelsExtension',
                    'Autodesk.AEC.ViewportsExtension',
                    //   'Autodesk.AEC.Minimap3DExtension',
                ],
            };
            let parallelViewer = new Autodesk.Viewing.GuiViewer3D(parallel, config);

            parallelViewer.start();

            const onParallelDocumentLoadSuccess = async (document) => {
                console.log(
                    `%c Parallel viewer loaded`,
                    'color: green; font-weight: bold; font-size: 1.5em; background-color: #000; padding: 5px;',
                );
                await document.downloadAecModelData();
                let views = document.getRoot().search(
                    {
                        type: 'geometry',
                        role: '2d',
                    },
                    true,
                );

                let payload = { viewer: parallelViewer, document, views };

                dispatch('IMPORT_BLOCKS_FROM_VIEWS', payload).then(() => {
                    console.log(
                        `%c Blocks imported`,
                        'color: green; font-weight: bold; font-size: 1.5em; background-color: #000; padding: 5px;',
                    );

                    // parallelViewer.finish();

                    if (getters.VIEWER?.model?.is2d()) {
                        let clonedObjects = state.manipulatedElements.filter(
                            (e) => e.manipulationType === ManipulationTypes.CLONED_ELEMENT,
                        );

                        if (clonedObjects.length) {
                            clonedObjects.forEach((element) => {
                                dispatch('Add2DManipulatedElementToViewer', element);
                            });

                            toast.success('Blocks loaded successfully to the view.');
                        }
                    } else {
                        let clonedObjects = state.manipulatedElements.filter(
                            (e) => e.manipulationType === ManipulationTypes.CLONED_ELEMENT,
                        );

                        if (clonedObjects.length) {
                            clonedObjects.forEach((element) => {
                                if (element.viewGuids.split(',').find((x) => x == getters.View_Id))
                                    return;
                                dispatch('Add3DManipulatedElementToViewer', element);
                            });

                            toast.success('Blocks loaded successfully to the view.');
                        }
                    }
                    const view = views.find(
                        (x) => x.data.guid == getters.VIEWER?.model.getDocumentNode().guid(),
                    );

                    if (view) parallelViewer.loadDocumentNode(window.loadedForgeDocument, view);
                });
            };

            Autodesk.Viewing.Document.load(URN, onParallelDocumentLoadSuccess, console.error);

            window.parallelViewer = parallelViewer;
            state.parallelViewer = parallelViewer;
        },
        async InitializePdfViewer(
            { state, dispatch, commit, getters },
            { id, urn, ref, modelId = '', viewId, img, hasMarkup = false },
        ) {
            //window.pdfLoadedURNDetails={id:id,urn:urn,ref:ref};

            let viewer = '';

            let getAccessToken = (onSuccess) => {
                dispatch('ACCOUNT/GetAccessToken')
                    .then(({ expires_in, access_token }) => {
                        onSuccess(access_token, expires_in);
                    })
                    .catch((error) => {
                        console.error('Error fetching access token:', error);
                    });
            };

            let options = {
                getAccessToken: getAccessToken,
                env: 'AutodeskProduction2',
                api: 'streamingV2',
            };

            let config = {
                skipPropertyDb: true,
            };

            let onDocumentLoadSuccess = async (document) => {
                var d2items = document.getRoot().search(
                    {
                        type: 'geometry',
                        role: '2d',
                    },
                    true,
                );

                var d3items = document.getRoot().search(
                    {
                        type: 'geometry',
                        role: '3d',
                    },
                    true,
                );

                var defaultModel = document.getRoot().getDefaultGeometry();

                if (viewId) {
                    let views = document.getRoot().search({
                        guid: viewId,
                    });

                    if (views.length) {
                        defaultModel = views[0];
                    }
                }

                if (hasMarkup) {
                    if (d2items.find((c) => c.data.guid == modelId) != undefined) {
                        defaultModel = d2items.filter((c) => c.data.guid == modelId)[0];
                    }
                    if (d3items.find((c) => c.data.guid == modelId) != undefined) {
                        defaultModel = d3items.filter((c) => c.data.guid == modelId)[0];
                    }
                }
                viewer.loadDocumentNode(document, defaultModel);

                viewer.loadExtension('Autodesk.Viewing.MarkupsCore').then(function (markupsExt) {
                    window.attachMarkup = markupsExt;
                    window.OriginalAttachMarkup = markupsExt;
                    const namespace = Autodesk.Viewing.Extensions.Markups.Core;
                    window.attachDefaultMarkupStyle = markupsExt.getDefaultStyle();
                    window.attachDefaultMarkupStyle['font-size'] = 0.13;
                    window.attachDefaultMarkupStyle['stroke-width'] = 0.05;
                    window.attachDefaultMarkupStyle['fill-opacity'] = 0;
                });

                function imageToBase64(url, callback) {
                    fetch(url)
                        .then((response) => response.blob())
                        .then((blob) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(blob);
                            reader.onloadend = () => {
                                const base64String = reader.result;
                                callback(base64String);
                            };
                        });
                }
                imageToBase64(img, function (base64String) {
                    console.log('base64String', base64String);
                    let immmage = new Image();
                    immmage.src = base64String;
                    immmage.onload = () => {
                        var texture = THREE.ImageUtils.loadTexture(immmage.src);
                        texture.needsUpdate = true;
                        texture.minFilter = THREE.NearestFilter;
                        console.log('text', texture);
                        let material = new THREE.MeshBasicMaterial({ map: texture });
                        let width = 30;
                        let height = (width * immmage.height) / immmage.width;

                        const geometry = new THREE.PlaneGeometry(width, height, 3);
                        const planeMesh = new THREE.Mesh(geometry, material);
                        planeMesh.position.set(2, 7, 3);
                        viewer.overlays.addScene('custom-scene');
                        viewer.overlays.addMesh(planeMesh, 'custom-scene');
                        let min = new THREE.Vector3(-width / 2 + 2 + 1, -height / 2 + 7 + 2, 0);
                        let max = new THREE.Vector3(width / 2 + 2 - 1, height / 2 + 7 - 2, 0);
                        let box = new THREE.Box3(min, max);
                        dispatch('MARKUPEDITOR/setBoundingBox', {
                            min: min,
                            max: max,
                            boundingBoxAssigned: true,
                        });
                        dispatch('MARKUPEDITOR/getByAttachId', getters['MARKUPEDITOR/ATTACH_ID']);
                        setTimeout(() => {
                            viewer.setBackgroundColor(255, 255, 255, 255, 255, 255);
                            viewer.navigation.fitBounds(false, box, false, false);
                            //viewer.impl.invalidate(true, true, true);
                        }, 1000);
                    };
                });
            };

            let onDocumentLoadFailure = () => {
                console.error('Failed fetching Forge manifest');
            };

            let URN = `urn:${urn}`;

            Autodesk.Viewing.Initializer(options, () => {
                let htmlDiv = document.getElementById('pdfPopup');
                function image2ToBase64(url, callback) {
                    fetch(url)
                        .then((response) => response.blob())
                        .then((blob) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(blob);
                            reader.onloadend = () => {
                                const base64String = reader.result;
                                callback(base64String);
                            };
                        });
                }
                image2ToBase64(img, function (base64String) {
                    console.log('base64String', base64String);
                    let immmage = new Image();
                    immmage.src = base64String;
                    immmage.onload = () => {
                        let width = 30;
                        let height = (width * immmage.height) / immmage.width;
                        let htmlDiv = window.document.getElementById('pdfPopup');
                        let htmlDiv2 = window.document.getElementById('pdfCard');
                        let ratio = height / width;
                        dispatch('MARKUPEDITOR/SetAttacheViewerRatio', ratio);
                        htmlDiv.setAttribute(
                            'style',
                            'height:' + htmlDiv.offsetWidth * ratio + 'px',
                        );
                        viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv, config);
                        setTimeout(() => {
                            viewer.start();
                            window.attachViewer = viewer;
                            Autodesk.Viewing.Document.load(
                                URN,
                                onDocumentLoadSuccess,
                                onDocumentLoadFailure,
                            );
                        }, 500);
                    };
                });
            });
        },
        async InitializeViewer(
            { state, dispatch, commit, getters },
            { id, urn, ref, modelId = '', viewId, hasMarkup = false, markupData = null },
        ) {
            window.loadedURNDetails = { id: id, urn: urn, ref: ref };
            let viewer = state.viewer;

            dispatch('GetAllModelVersions');
            dispatch('CheckSyncVersion');

            if (viewer) {
                console.log(
                    '%cCleaning up existing viewer',
                    'color: red; font-weight: bold; font-size: 1.5em; background-color: #000; padding: 5px;',
                );
                window.NOP_VIEWER?.finish();
            }

            let getAccessToken = (onSuccess) => {
                dispatch('ACCOUNT/GetAccessToken')
                    .then(({ expires_in, access_token }) => {
                        onSuccess(access_token, expires_in);
                    })
                    .catch((error) => {
                        console.error('Error fetching access token:', error);
                    });
            };

            let options = {
                getAccessToken: getAccessToken,
                env: 'AutodeskProduction2',
                api: 'streamingV2',
            };

            let config = {
                skipPropertyDb: true,
                extensions: [
                    'Autodesk.LayerManager',
                    'Autodesk.BimWalk',
                    'Autodesk.Snapping',
                    'Autodesk.BoxSelection',
                    // 'Autodesk.DocumentBrowser',
                    'Autodesk.Measure',
                    'ModifyPropertiesExtension',
                    Block2dLibraryExtension.extensionId,
                    // ModelSheetTransitionExtension.extensionId,
                    'PreventSelectionExtension',
                    'NestedFamilySelectionExtension',
                    'ShowCamerasExtension',
                    'MarkupToolExtension',
                    // 'Autodesk.AEC.LevelsExtension',
                    'Autodesk.AEC.ViewportsExtension',
                    'Autodesk.AEC.Minimap3DExtension',
                ],
                onTriggerSingleTapCallback: () => {
                    if (window.NOP_VIEWER.model.is2d() && isTouchDevice()) {
                        window.NOP_VIEWER.impl.selector.clearSelection();
                        window.NOP_VIEWER.impl.selector.setSelection([]);
                    }
                },
                // disabledExtensions: {
                //      measure: true,
                //      section: true,
                // },
            };

            let onDocumentLoadSuccess = async (document) => {
                dispatch('MODELS/UpdateModel');

                await document.downloadAecModelData();

                var rootItem = document.getRoot();

                console.log(`Root item:`, rootItem);

                var d2items = document.getRoot().search(
                    {
                        type: 'geometry',
                        role: '2d',
                    },
                    true,
                );

                var d3items = document.getRoot().search(
                    {
                        type: 'geometry',
                        role: '3d',
                    },
                    true,
                );
                d2items.forEach((c) => {
                    let url = document.getThumbnailPath(c.data, 600, 600);
                    //to cache images
                    dispatch('GetThumbnail', url)
                        .then((res) => {})
                        .catch();
                    c['imgUrl'] = url;
                });
                let mImgsrc = '';
                d3items.forEach((c) => {
                    let url = document.getThumbnailPath(c.data, 600, 600);
                    //to cache images

                    dispatch('GetThumbnail', url)
                        .then((res) => {})
                        .catch();
                    c['imgUrl'] = url;
                    window.mImgsrc = url;
                });

                window.d2items = d2items;
                window.d3items = d3items;
                commit('SET_D3Items', d3items);
                commit('SET_D2Items', d2items);

                commit('SET_D2Items', d2items);
                commit('SET_D3Items', d3items);

                commit('SET_ROOT_2D_ITEMS', d2items);
                var defaultModel = document.getRoot().getDefaultGeometry();

                if (viewId) {
                    let views = document.getRoot().search({
                        guid: viewId,
                    });

                    if (views.length) {
                        defaultModel = views[0];
                    }
                }

                commit(
                    'MARKUPDATA/SET_LOADED_MODEL_NAME',
                    document.getRoot().children[0].data.name,
                );

                if (hasMarkup) {
                    if (d2items.find((c) => c.data.guid == modelId) != undefined) {
                        defaultModel = d2items.filter((c) => c.data.guid == modelId)[0];
                    }
                    if (d3items.find((c) => c.data.guid == modelId) != undefined) {
                        defaultModel = d3items.filter((c) => c.data.guid == modelId)[0];
                    }
                }
                console.log('default module', defaultModel);
                viewer.loadDocumentNode(document, defaultModel);

                commit('SET_DOCUMENT', document);
                window.loadedModel = defaultModel;
                window.loadedForgeDocument = document;
                dispatch('MARKUPEDITOR/loadSavedMarkups');

                commit('SET_MODEL_OBJECT_ID', id);

                commit('SET_MODEL_ID', urn);

                commit('SET_MODEL_STORAGE_URL', ref);

                dispatch('SETTINGS/GetModelSettings');

                const modelData = store.getters['MODELS/CURRENT_MODEL_DATA'];
                const combinedId = `${modelData.modelName.replace(
                    /\s/g,
                    '_',
                )}_${modelData.projectName.replace(/\s/g, '_')}`;
                commit('SET_MODEL_GUID', combinedId);

                // await Autodesk.Viewing.EventUtils.waitUntilGeometryLoaded(viewer);

                // dispatch('GetFamiliesInstances', urn).then(instances => {
                //     commit('SET_FAMILIES_INSTANCES', []);

                //     instances.forEach(instance => {
                //         dispatch('AddFamilyInstanceToViewer', instance);
                //     });
                // });
            };

            let onDocumentLoadFailure = () => {
                console.error('Failed fetching Forge manifest');
            };
            let URN = `urn:${urn}`;

            console.log('Initializing viewer with URN:', URN);

            Autodesk.Viewing.Initializer(options, () => {
                let htmlDiv = document.getElementById('forgeViewer');

                viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv, config);

                state.viewer = viewer;

                viewer.start();

                Autodesk.Viewing.Document.load(URN, onDocumentLoadSuccess, onDocumentLoadFailure);

                viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, (event) => {
                    if (event.model !== viewer.model) return;

                    let ext = viewer.getExtension('Autodesk.BoxSelection');
                    if (ext) {
                        let group = viewer.toolbar.getControl('selection-toolbar-group');
                        if (!group) {
                            group = new Autodesk.Viewing.UI.ControlGroup('selection-toolbar-group');
                            this.viewer.toolbar.addControl(group);
                        }
                        group.addControl(ext.boxSelectionToolButton, { index: 0 });
                    } else {
                        console.log('not created');
                    }

                    console.log(
                        `%c Object tree created`,
                        'color: green; font-weight: bold; font-size: 1.5em; background-color: #000; padding: 5px;',
                    );

                    let interval = null;

                    let callback = () => {
                        if (!event.model.isLoadDone()) return;

                        clearInterval(interval);

                        dispatch('GetFamiliesInstances', urn).then((instances) => {
                            commit('SET_FAMILIES_INSTANCES', []);

                            instances.forEach((instance) => {
                                if (event.model.is2d()) {
                                    // TODO - Add 2D family instance to viewer.
                                    commit('ADD_FAMILY_INSTANCE', instance);
                                } else {
                                    dispatch('AddFamilyInstanceToViewer', instance);
                                }
                            });
                        });
                        dispatch('GetAndStoreManipulatedElements');
                        dispatch('GetManipulatedElements', getters.MODEL_ID).then(() => {
                            UpdateForgeElementsColors();
                        });
                        dispatch('PopulateNestedFamilyTree', viewer);
                    };

                    interval = setInterval(() => {
                        callback();
                    }, 100);

                    dispatch('GetPreventSelectionDbIds').then((elements) => {
                        const preventedSelection = store.getters.Prevented_Selection_DBIDS;
                        viewer.lockSelection(preventedSelection, true);
                    });
                    dispatch('MODELBROWSER/InitiateModelBrowser');
                    dispatch('COLORINGSYSTEMS/GetModelProps', 1);
                    dispatch('COLORINGSYSTEMS/GetCustomProp');
                });
                viewer.addEventListener(Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT, (event) => {
                    if (!event.model.getDocumentNode()) return;

                    commit('SET_View_Id', event.model.getDocumentNode().data.guid);

                    dispatch('GetAllCameraElements').then((elements) => {
                        const extension = viewer.getExtension('ShowCamerasExtension');
                        extension?._removeAllSprites();
                        extension?.addCameraTool?._addAllSpriteToView(elements);
                    });

                    dispatch('GetAllCameraElementsInTheModel');
                });
                viewer.addEventListener(Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT, (event) => {
                    // commit('SET_IS_3D', !event.model.is2d());

                    if (!viewer.navigation.getReverseZoomDirection()) {
                        viewer.navigation.setReverseZoomDirection(true);
                    }

                    let markupData = getters['MARKUPDATA/MARKUP_VIEW_DATA'];
                    if (markupData) {
                        dispatch('MARKUPEDITOR/addMarkupModelToViewer', markupData?.id);
                    }

                    viewer
                        .loadExtension('Autodesk.Viewing.MarkupsCore')
                        .then(function (markupsExt) {
                            window.markup = markupsExt;
                            const namespace = Autodesk.Viewing.Extensions.Markups.Core;
                            // markupsExt.editFrame.addDocumentEventListener(namespace.EVENT_EDITFRAME_EDITION_START, function (ev) {
                            //     alert("DD")

                            // });

                            window.defaultMarkupStyle = markupsExt.getDefaultStyle();
                            //window.originalMarkupStyle = markupsExt.getDefaultStyle();

                            // window.defaultMarkupStyle['font-size'] = 0.13;
                            // window.defaultMarkupStyle['stroke-width'] = 0.05;
                            // window.defaultMarkupStyle['fill-opacity'] = 0;
                            if (markupData && hasMarkup) {
                                if (markupData.isAttach) {
                                    dispatch('MARKUPDATA/setMarkupImg', markupData.img);
                                    dispatch('MARKUPEDITOR/setAttachData', markupData);
                                    dispatch('MARKUPDATA/setOpenMarkupImg', true);

                                    const selected = getters['ALL_Camera_Elements'].find((c) =>
                                        c.cameraAttachements.find(
                                            (z) => z.id == markupData.attachId,
                                        ),
                                    );
                                    if (selected) {
                                        let indexx = selected.cameraAttachements.findIndex(
                                            (c) => c.id == markupData.attachId,
                                        );
                                        if (indexx > -1) {
                                            commit('SET_CAMERA_INDEX', indexx);
                                        }

                                        setTimeout(() => {
                                            commit('SET_SELECTED_CAMERA_ELEMENT', selected);
                                            commit('SET_Show_Camera_Attachement', true);
                                        }, 2000);
                                    }
                                } else {
                                    dispatch('MARKUPEDITOR/initializeMarkup', {
                                        data: {
                                            id: markupData.id,
                                            markupsPersist: markupData.markupsPersist,
                                            viewerStatePersist: markupData.viewerStatePersist,
                                            modelId: markupData.modelId,
                                        },
                                    });
                                }
                            }
                        });
                    if (event.model !== viewer.model) return;

                    commit('SET_MODEL_ID', event.model.getSeedUrn());

                    console.log(
                        `%c Model root loaded`,
                        'color: green; font-weight: bold; font-size: 1.5em; background-color: #000; padding: 5px;',
                    );

                    let addingCallback = store.getters['ADD_BLOCK_CALLBACK'];
                    if (addingCallback) {
                        dispatch('PLACING_BLOCKS_DONE');
                    }

                    let viewId = event.model.getDocumentNode().guid();
                    router.replace({ query: { view: viewId, markupId: markupData?.id } });

                    commit('SET_CURRENT_VIEW_NAME', event.model.getDocumentNode()?.name() ?? '');

                    if (event.model.is2d()) {
                        commit('SET_IS_3D', false);

                        if (state.modelBuilder?.model) {
                            viewer.unloadModel(state.modelBuilder.model);
                        }

                        if (viewer.getExtension(ModelSheetTransitionExtension.extensionId)) {
                            viewer.unloadExtension(ModelSheetTransitionExtension.extensionId);
                        }

                        if (viewer.getExtension('Viewing.Extension.Transform')) {
                            viewer.unloadExtension('Viewing.Extension.Transform');
                        }

                        if (viewer.getExtension(ManipulationExtension.ExtensionId)) {
                            viewer.unloadExtension(ManipulationExtension.ExtensionId);
                        }
                        if (!viewer.getExtension(Manipulation2DExtension.ExtensionId)) {
                            viewer.loadExtension(Manipulation2DExtension.ExtensionId);
                        }
                        // viewer.loadExtension(ElementMove2DExtension.ExtensionId);

                        //   store.state.clone2dTool = new ElementClone2D(viewer);
                        //   store.state.clone2dTool.enable(true);
                        // store.getters.MANIPULATED_ELEMENTS.filter(x=>x.manipulationType == ManipulationTypes.DELETED).forEach((el)=>{
                        //         viewer.impl.visibilityManager.setNodeOff(el.dbId, true);
                        // });
                        console.log('2D Model loaded.');
                    } else {
                        commit('SET_IS_3D', true);

                        commit('VIEWER/SET_GLOBAL_OFFSET', event.model.getGlobalOffset());
                        commit(
                            'VIEWER/SET_TRANSFORM_MATRIX',
                            event.model.getInverseModelToViewerTransform(),
                        );

                        window.NOP_VIEWER.prefs.set('viewCube', true, true)

                        if (state.clone2dTool) {
                            state.clone2dTool.enable(false);
                            store.state.clone2dTool = null;
                        }

                        if (!viewer.getExtension(ModelSheetTransitionExtension.extensionId)) {
                            viewer.loadExtension(ModelSheetTransitionExtension.extensionId);
                        }

                        if (!viewer.getExtension('Viewing.Extension.Transform')) {
                            viewer.loadExtension('Viewing.Extension.Transform');
                        }

                        if (!viewer.getExtension(ManipulationExtension.ExtensionId)) {
                            viewer.loadExtension(ManipulationExtension.ExtensionId);
                        }

                        console.log('3D Model loaded.');
                    }
                });
                const removeButtons = () => {
                    const modelTools = viewer.toolbar.getControl('modelTools');
                    modelTools.removeControl('toolbar-levelsTool');
                    modelTools.removeControl('toolbar-pdf-text-selection');

                    const settingsTools = viewer.toolbar.getControl('settingsTools');
                    settingsTools.removeControl('toolbar-propertiesTool');
                };
                viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, (event) => {
                    if (viewer.toolbar) {
                        if (event.extensionId === 'Autodesk.AEC.LevelsExtension') {
                            const modelTools = viewer.toolbar.getControl('modelTools');
                            modelTools.removeControl('toolbar-levelsTool');
                            modelTools.removeControl('toolbar-pdf-text-selection');
                        } else if (event.extensionId === 'Autodesk.PropertiesManager') {
                            const settingsTools = viewer.toolbar.getControl('settingsTools');
                            settingsTools.removeControl('toolbar-propertiesTool');
                        }
                    }
                    viewer.unregisterContextMenuCallback('propertiesmanager');
                });
                viewer.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, removeButtons);
                viewer.addEventListener(
                    Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
                    (event) => {
                        if (
                            event.selections.length == 1 &&
                            event.selections[0].dbIdArray.length == 1
                        ) {
                            let selection = event.selections[0];
                            let dbId = selection.dbIdArray[0];
                            let model = selection.model;
                            let elementName = model.getInstanceTree()?.getNodeName(dbId);
                            model.getBulkProperties(
                                [dbId],
                                { propFilter: ['Type Name'] },
                                (result) => {
                                    if (result.length == 0) return;
                                    let type = result[0].properties[0].displayValue;
                                    let name = `${elementName} - (${type})`;
                                    store.commit('SET_SELECTED_ELEMENT_NAME', name);
                                },
                            );
                        } else {
                            commit('SET_SELECTED_ELEMENT_NAME', null);
                        }
                    },
                );

                dispatch('VIEWER/StartParallel3dViewer', URN);

                dispatch('StartParallelViewer', URN);
            });
        },
        PopulateNestedFamilyTree({ dispatch, getters, commit }, viewer) {
            getAllLeafComponents(viewer);
            function getAllLeafComponents(viewer, callback) {
                var cbCount = 0; // count pending callbacks
                var components = []; // store the results
                var res = []; // store the results
                var tree; // the instance tree
                const result = [];
                function getLeafComponentsRec(parent) {
                    cbCount++;
                    if (tree.getChildCount(parent) != 0) {
                        tree.enumNodeChildren(
                            parent,
                            function (children) {
                                res.push({
                                    parentId: parent,
                                    parentName: tree.getNodeName(parent),
                                    childId: children,
                                    childName: tree.getNodeName(children),
                                });
                                getLeafComponentsRec(children);
                            },
                            false,
                        );
                    } else {
                        components.push(parent);
                    }
                    if (--cbCount == 0 && callback) callback(components);
                }
                viewer.getObjectTree(function (objectTree) {
                    tree = objectTree;
                    getLeafComponentsRec(tree.getRootId());

                    const groupByParentId = res.reduce((result, item) => {
                        if (!result[item.parentId]) {
                            result[item.parentId] = [];
                        }
                        result[item.parentId].push(item);
                        return result;
                    }, {});

                    let sameChilds = [];
                    for (const parentId in groupByParentId) {
                        const items = groupByParentId[parentId];
                        if (
                            items.length > 1 &&
                            items.every((x) => x.childName == items[0].childName)
                        ) {
                            sameChilds[parentId] = items;
                        }
                    }

                    let nestedFamilies = [];
                    for (const parentId in sameChilds) {
                        const items = sameChilds[parentId];
                        for (let i = 0; i < items.length; i++) {
                            nestedFamilies[items[i].childId] = [];
                            tree.enumNodeChildren(
                                items[i].childId,
                                function (dbid) {
                                    nestedFamilies[items[i].childId].push(dbid);
                                },
                                true /* recursively enumerate children's children as well */,
                            );
                        }
                    }
                    commit('SET_NESTED_FAMILY_TREE', nestedFamilies);
                });
            }
        },
        AddViewableToViewer({ dispatch, getters, commit }, familyInstance) {
            const isExist = getters.FAMILIES_INSTANCES.find((i) => i.id === familyInstance.id);

            if (isExist) {
                console.log(
                    '%cFamily instance already exists in viewer',
                    'color: green; font-weight: bold; font-size: 1.5em; background-color: #000; padding: 5px;',
                );
                return;
            }

            let { position, rotation } = familyInstance;
            let { urn } = familyInstance.forgeFamily;

            return new Promise(function (resolve, reject) {
                if (!window.NOP_VIEWER) {
                    reject('Viewer not initialized.');
                    return;
                }

                if (!urn) {
                    reject('No URN provided.');
                    return;
                }

                if (!window.NOP_VIEWER?.model) {
                    reject('No model loaded.');
                    return;
                }

                dispatch('CheckIsModelTranslated', urn)
                    .then(({ status, progress }) => {
                        if (!status) {
                            console.log('Model not translated. Progress:', progress);
                            toast.warning('Model translation is in progress');
                            return;
                        }

                        let state = window.NOP_VIEWER.getState({ viewport: true });

                        async function onDocumentLoadSuccess(doc) {
                            const viewable = doc.getRoot().getDefaultGeometry();
                            // const globalOffset = window.NOP_VIEWER.model.getGlobalOffset();
                            const options = {
                                preserveView: true,
                                keepCurrentModels: true,
                                // applyScaling: { to: getters.DISPLAY_UNIT },
                                globalOffset: { x: 0, y: 0, z: 0 },
                            };

                            let transform = new THREE.Matrix4();

                            position = position || { x: 0, y: 0, z: 0 };
                            rotation = rotation || { x: 0, y: 0, z: 0 };

                            let translate = new THREE.Vector3(position.x, position.y, position.z);
                            let euler = new THREE.Euler(rotation.x, rotation.y, rotation.z, 'XYZ');

                            transform.setPosition(translate);

                            options.placementTransform = transform;

                            const viewer = window.NOP_VIEWER;

                            viewer
                                .loadDocumentNode(doc, viewable, options)
                                .then((model) => {
                                    familyInstance.loadedModelId = model.id;

                                    commit('ADD_FAMILY_INSTANCE', familyInstance);

                                    viewer.restoreState(state, { viewport: true }, true);

                                    viewer.impl.toggleGhosting(false);

                                    function onGeometryLoaded(evt) {
                                        viewer.removeEventListener(
                                            Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
                                            onGeometryLoaded,
                                        );

                                        dispatch('RotateModelByEuler', { model, euler });
                                    }

                                    viewer.addEventListener(
                                        Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
                                        onGeometryLoaded,
                                    );

                                    resolve(doc);
                                })
                                .catch(reject);
                        }

                        function onDocumentLoadFailure(code) {
                            reject(`Could not load document (${code}).`);
                        }

                        Autodesk.Viewing.Document.load(
                            `urn:${urn}`,
                            onDocumentLoadSuccess,
                            onDocumentLoadFailure,
                        );
                    })
                    .catch(reject);
            });
        },
        AddModelToViewer({ dispatch }, { urn }) {
            return new Promise((resolve, reject) => {
                console.log(
                    '%cCheck if model translated',
                    'color: green; font-weight: bold; font-size: 1.5em; background-color: #000; padding: 5px;',
                );
                dispatch('CheckIsModelTranslated', urn)
                    .then(({ status, progress }) => {
                        if (!status) {
                            console.log('Model not translated. Progress:', progress);
                            toast.warning('Model translation is in progress');
                            return;
                        }

                        console.log(
                            '%cLoading model to viewer',
                            'color: green; font-weight: bold; font-size: 1.5em; background-color: #000; padding: 5px;',
                        );

                        let onDocumentLoadSuccess = (document) => {
                            var defaultModel = document.getRoot().getDefaultGeometry();
                            window.NOP_VIEWER.loadDocumentNode(document, defaultModel);
                            resolve();
                        };

                        let onDocumentLoadFailure = () => {
                            console.error('Failed fetching Forge manifest');
                            reject();
                        };

                        let URN = `urn:${urn}`;

                        console.log('Adding model to viewer with URN:', URN);

                        Autodesk.Viewing.Document.load(
                            URN,
                            onDocumentLoadSuccess,
                            onDocumentLoadFailure,
                        );
                    })
                    .catch((error) => {
                        console.error('Error checking if model is translated:', error);
                        reject(error);
                    });
            });
        },
        GetFamiliesInstances({ commit, dispatch, getters }, modelId) {
            return new Promise((resolve, reject) => {
                HttpService.get({
                    url: `api/${modelId}/families/instances`,
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
        },
        getLinkedLevel(vp) {
            Autodesk.AEC.AecModelData.findViewportsOnSheet(window.NOP_VIEWER.model)[0].extensions
                .viewRange.cutPlane.levelGuid;

            window.NOP_VIEWER.getExtension('Autodesk.AEC.LevelsExtension').floorSelector
                .floorData[0].zMax;
        },
        GetAndStoreManipulatedElements({ commit, dispatch, getters }) {
            dispatch('GetManipulatedElements', getters.MODEL_ID).then((elements) => {
                commit('SET_MANIPULATED_ELEMENTS', []);

                let promises = [];
                elements.forEach((element, i) => {
                    if (
                        window.NOP_VIEWER.model.is2d() &&
                        !element.viewGuids.split(',').find((x) => x == getters.View_Id)
                    )
                        return;

                    promises.push(dispatch('AddManipulatedElementToViewer', element));
                });

                Promise.all(promises).then(() => {
                    for (let i = 0; i < getters.ON_MANIPULATION_DONE_CALLBACKS.length; i++) {
                        getters.ON_MANIPULATION_DONE_CALLBACKS[i]();
                    }
                });
            });
        },
        GetManipulatedElements({ commit, dispatch, getters }, modelId) {
            return new Promise((resolve, reject) => {
                HttpService.get({
                    url: `api/elements/${modelId}/manipulated`,
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
        },
        SaveLocalModelChanges({ dispatch, getters, commit }) {
            commit('SET_IS_SAVING', true);
            commit('SET_SAVING_ERROR', null);

            let promises = [];

            promises.push(dispatch('UpdateModelFamiliesInstances'));
            promises.push(dispatch('UpdateModelManipulatedElements'));

            return Promise.all(promises)
                .then(() => {
                    commit('SET_IS_SAVING', false);
                    commit('SET_SAVING_ERROR', null);
                })
                .catch((error) => {
                    commit('SET_SAVING_ERROR', error);
                    commit('SET_IS_SAVING', false);
                });
        },
        UpdateModelFamiliesInstances({ getters }) {
            return new Promise((resolve, reject) => {
                let familiesInstances = getters.FAMILIES_INSTANCES.map((i) => {
                    let instance = { ...i };

                    delete instance.loadedModelId;
                    delete instance.forgeFamily;

                    return instance;
                });

                if (!familiesInstances.length) {
                    resolve();
                    return;
                }

                HttpService.post({
                    url: `api/families/${getters.MODEL_ID}/updateModelFamilyInstances`,
                    data: familiesInstances,
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        UpdateModelManipulatedElements({ getters, dispatch, commit }) {
            return new Promise((resolve, reject) => {
                let manipulatedElements = getters.MANIPULATED_ELEMENTS;

                if (!manipulatedElements.length) {
                    resolve();
                    return;
                }

                let promises = [];

                const _viewer = window.NOP_VIEWER.model.is2d()
                    ? window.parallelViewer
                    : window.parallel3dViewer;
                manipulatedElements.forEach((element) => {
                    if (!element.thumbnailUrl && element.position) {
                        promises.push(
                            dispatch('VIEWER/GeneratePointSnapshotBase64', {
                                point: element.position,
                                // viewer: window.NOP_VIEWER,
                                viewer: _viewer,
                                dbId: element.originalDbId,
                                instance: element,
                            }).then((data) => {
                                element.thumbnailUrl = data;
                            }),
                        );
                    }
                });

                Promise.all(promises).then(() => {
                    HttpService.post({
                        url: `api/elements/${getters.MODEL_ID}/updateModelManipulatedElements`,
                        data: manipulatedElements,
                    })
                        .then((response) => {
                            store.dispatch('COLORINGSYSTEMS/ReApplyTemplateColors');
                            // toast.success('Properties saved successfully');

                            manipulatedElements.forEach((element) => {
                                element.id = response.find((e) => e.dbId === element.dbId)?.id ?? 0;
                            });
                            resolve(response);
                        })
                        .catch((error) => {
                            toast.error('Failed to save properties');

                            reject(error);
                        });
                });
            });
        },
        RemoveFamilyInstance({ commit, dispatch, getters }, instanceId) {
            return new Promise((resolve, reject) => {
                HttpService.delete({
                    url: `api/families/instances/${instanceId}`,
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        ZoomToModelInstance({ dispatch, getters }, instance) {
            let model = window.NOP_VIEWER.getAllModels().find(
                (m) => m.id === instance.loadedModelId,
            );

            if (!model) {
                console.error('Model not found:', instance.loadedModelId);
                return;
            }

            let rootId = model.getInstanceTree()?.getRootId();

            if (!rootId) {
                console.error('Root ID not found:', rootId);
                return;
            }

            window.NOP_VIEWER.select([rootId], model);
            window.NOP_VIEWER.fitToView([rootId], model, true);
        },

        UpdateManipulatedElementPosition(state, { dbId, position, is2D }) {
            let element = state.getters.MANIPULATED_ELEMENTS.find((e) => e.dbId == dbId);

            if (!element) {
                console.error('Element not found:', dbId);
                return;
            }

            if (is2D && element.position?.z) {
                position.z = element.position.z;
            }

            element.position = position;
        },
        RemoveManipulatedElement({ commit, dispatch, getters }, dbId) {
            let element = getters.MANIPULATED_ELEMENTS.find((e) => e.dbId == dbId);

            if (!element) {
                console.error('Element not found:', dbId);
                return;
            }

            this.commit(
                'SET_MANIPULATED_ELEMENTS',
                getters.MANIPULATED_ELEMENTS.filter((e) => e != element),
            );

            return new Promise((resolve, reject) => {
                HttpService.delete({
                    url: `api/elements/${getters.MODEL_ID}/manipulated/${element.id}`,
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        RemoveManipulatedElements({ commit, dispatch, getters }, dbIds) {
            let elements = getters.MANIPULATED_ELEMENTS.filter((e) => dbIds.includes(e.dbId));

            if (elements.length == 0) {
                console.error('Element not found:', dbIds);
                return;
            }

            this.commit(
                'SET_MANIPULATED_ELEMENTS',
                getters.MANIPULATED_ELEMENTS.filter((e) => !elements.includes(e)),
            );

            return new Promise((resolve, reject) => {
                HttpService.delete({
                    url: `api/elements/${getters.MODEL_ID}/manipulated`,
                    data: {
                        ids: elements.map((x) => x.id),
                    },
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        RemoveExistingElement({ commit, dispatch, getters }, dbId) {
            let element = getters.MANIPULATED_ELEMENTS.find((e) => e.dbId == dbId);

            if (!element) {
                console.error('Element not found:', dbId);
                return;
            }

            this.commit(
                'SET_MANIPULATED_ELEMENTS',
                getters.MANIPULATED_ELEMENTS.filter((e) => e != element),
            );

            return new Promise((resolve, reject) => {
                HttpService.delete({
                    url: `api/elements/${getters.MODEL_ID}/manipulated/${element.id}`,
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
    },
    modules: {
        Buckets: Buckets,
        Hubs: Hubs,
        ForgeService: ForgeService,
        InstanceProperties: InstanceProperties,
        CameraAttachementStore: CameraAttachementStore,
        PreventSelectionStore,
        AdminStore,
        SETTINGS,
        Blocks2dLibrary: Blocks2dLibrary,
        ACCOUNT,
        MODELS,
        PROJECTS,
        VIEWER,
        MARKUPDATA,
        MARKUPEDITOR,
        COLORINGSYSTEMS,
        MODELBROWSER,
        CUSTOMPROPERTIES,
        VersionsStore,
    },
});

hubService.connection.on('NewFamily', (user, family) => {
    store.commit('ADD_FAMILY', family);
});

hubService.connection.on('FamilyInstanceAdded', (user, instance) => {
    if (instance.modelId !== store.state.modelId) return;
    store.commit('ADD_FAMILY_INSTANCE', instance);
});

hubService.connection.on('FamilyProcessed', (user, family) => {
    if (!family.urn) {
        console.error('Family not processed:', family);
        return;
    }

    console.log('Family processed:', family);
    store.state.families.find(f => f.id === family.id).urn = family.urn;
});

hubService.connection.on('FamilyInstanceRemoved', (user, instanceId) => {
    var instance = store.state.familiesInstances.find(i => i.id === instanceId);

    if (!instance)  return;

    var model = window.NOP_VIEWER.getAllModels().find(m => m.id === instance.loadedModelId);

    if (model) {
        window.NOP_VIEWER.impl.unloadModel(model);
    }

    store.state.familiesInstances = store.state.familiesInstances.filter(i => i.id !== instanceId);
});

hubService.connection.on('newModelVersionAvailable', (user, hubId, fileName) => {
    if (hubId === store.state.hubId) {
        console.log('New model version available:', fileName);
        toast.success(`New model version available for ${fileName}!`);
        store.commit('SET_NEW_VERSION_FILE_NAME', fileName);
    }
})
hubService.connection.on('requestsUpdated', (request) => {
    console.log('New model version available:', request);
    store.dispatch('GetAllRequestsDataFiltered', {
        skip: 0,
        search: "",
        count: localStorage.getItem("TablesRowsCount") ?? 5,
    })
});
// For debugging.
window.vuexStore = store;

export default store;
