<template lang="html">
    <v-progress-linear v-if="loading" color="success" height="3" indeterminate></v-progress-linear>
    <v-sheet class="h-100" v-else>
        <v-row no-gutters class="h-100 flex-nowrap" style="max-height: calc(100vh - 90px)">
            <v-col
                class="tree-container"
                xl="2"
                lg="3"
                md="3"
                sm="3"
                style="background: #212121; max-height: calc(100vh - 90px)">
                <folder-tree-node
                    ref="treeView"
                    :nodes="items"
                    @load-children="loadChildren"
                    @toggle-active="rowClick"></folder-tree-node>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col xl="10" lg="9" md="9" sm="9" class="w-100 h-100">
                <v-data-table
                    class="elevation-0 h-100 rounded-0 d-flex flex-column table-sticky custom-table"
                    :headers="filesHeaders"
                    :items="tableItems"
                    :loading="isTableLoading"
                    items-per-page="-1"
                    sort-asc-icon="mdi:mdi-arrow-up"
                    sort-desc-icon="mdi:mdi-arrow-down"
                    sticky>
                    <template #top>
                        <v-toolbar
                            style="border-bottom: 0.8px solid #4c4c4c"
                            color="grey-darken-4"
                            flat>
                            <v-toolbar-title v-if="backAvailable" style="max-width: fit-content">
                                <v-btn icon @click="backToFolder">
                                    <v-icon>mdi:mdi-keyboard-backspace</v-icon>
                                </v-btn>
                                /
                            </v-toolbar-title>
                            <v-toolbar-title v-if="activeFile">
                                <v-icon class="mr-1">mdi:mdi-file</v-icon>
                                {{ activeFile.name }}
                            </v-toolbar-title>
                            <v-toolbar-title v-else>
                                <v-icon class="mr-1">mdi:mdi-folder-open</v-icon>
                                {{ currentFolderName }}
                            </v-toolbar-title>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.version`]="{ value }">
                        <v-chip v-if="value" size="small" label color="success">
                            {{ value }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.name`]="{ value, item }">
                        <span
                            @click="rowClick(item)"
                            class="d-flex align-center table-name-cell"
                            :class="{ 'table-file-name': item.type.includes('file') }"
                            :style="`height:fit-content;`">
                            <v-icon size="20" class="mr-2">{{
                                !item.type
                                    ? 'mdi:mdi-cancel'
                                    : item.type.includes('file')
                                    ? 'mdi:mdi-file'
                                    : item.type.includes('folder')
                                    ? 'mdi:mdi-folder'
                                    : item.type.includes('versions')
                                    ? 'mdi:mdi-source-branch'
                                    : 'mdi:mdi-cancel'
                            }}</v-icon>
                            {{ value }}
                        </span>
                    </template>
                    <template v-slot:[`item.lastModifiedTime`]="{ value }">
                        <span>{{ getLocalDateFormat(value) }}</span>
                    </template>
                    <template v-slot:[`item.lastModifiedUserName`]="{ value }">
                        <v-chip v-if="value" size="small" label color="primary">
                            {{ value }}
                        </v-chip>
                    </template>
                    <template #bottom></template>
                    <template #no-data>
                        <div class="text-center d-flex flex-column align-center justify-center">
                            <img
                                src="/img/No data.svg"
                                width="300"
                                class="py-5 my-8"
                                style="opacity: 0.54" />
                            <p class="font-weight-medium">No Data Available!</p>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
    import FolderTreeNode from '@Models/components/FolderTreeNode.vue';
    import filesHeaders from '@Models/config/files.headers';
    import projectService from '@Home/service/project.service';
    import dayjs from 'dayjs';
    import { toast } from 'vue3-toastify';

    export default {
        name: 'ProjectFoldersViewer',
        components: {
            FolderTreeNode,
        },
        data() {
            return {
                loading: false,
                isTableLoading: false,
                filesHeaders,
                activeFile: null,
                visitedMap: {},
                tableItems: [],
                items: [],
            };
        },
        created() {
            this.openProject();
        },
        methods: {
            openProject() {
                this.loading = true;

                projectService
                    .getProjectFolders(this.currentProject.hubId, this.currentProject.id)
                    .then((folders) => {
                        folders = structuredClone(folders);

                        folders.forEach((folder) => {
                            folder.children = [];
                        });

                        this.items = folders;

                        this.loading = false;
                    });
            },
            getLocalDateFormat(date) {
                if (!date) return '';
                return dayjs(date).format('DD-MM-YYYY, hh:mm A');
            },
            loadChildren(item) {
                return new Promise((resolve) => {
                    if (item.children?.length || item.type !== 'folder') {
                        resolve();
                        return;
                    }

                    projectService
                        .getFolderItems(this.currentProject.projectId, item.id)
                        .then((items) => {
                            item.isLoading = false;
                            item.children = items.filter((i) => i.type !== 'file');
                            item.isLeaf = item.children.length === 0;
                            item.isExpanded = true;
                            resolve();
                        });
                });
            },
            loadFolderItems(folderId) {
                this.isTableLoading = true;

                if (!folderId) {
                    this.tableItems = [];
                    this.isTableLoading = false;
                    return;
                }

                projectService
                    .getFolderItems(this.currentProject.projectId, folderId)
                    .then((items) => {
                        this.activeFile = null;
                        this.tableItems = items;
                        this.isTableLoading = false;
                    });
            },
            rowClick(item) {
                if (item.type === 'unsupported') {
                    toast.warn('This item is not supported!', {
                        timeout: 3000,
                    });

                    return;
                }

                if (item.type === 'folder') {
                    this.activeFolder = item;
                    this.visitedMap[item.id] = item;
                } else if (item.type === 'file') {
                    // this.versionSheet = true;
                    this.activeFile = item;
                    this.tableItems = item.versions;
                    this.openVersion(item.versions[0]);
                } else if (item.type === 'versions') {
                    this.openVersion(item);
                } else {
                    toast.warn('This item is not supported!', {
                        timeout: 3000,
                    });
                }
            },
            backToFolder() {
                if (this.activeFile) {
                    this.activeFile = null;
                    this.loadFolderItems(this.activeFolder.id);
                } else if (this.activeFolder?.folderId) {
                    this.activeFolder = this.visitedMap[this.activeFolder.folderId];
                    this.loadFolderItems(this.activeFolder.id);
                }
            },
            openVersion(version) {
                let nodeData = {
                    versionId: version.id,
                    itemId: version.itemId,
                    hubName: this.currentProject.hubName,
                    projectId: this.currentProject.projectId,
                    projectName: this.currentProject.name,
                    modelName: this.activeFile.name,
                    version: version.name,
                    urn: version.urn,
                    storageUrl: version.ref,
                };

                this.$store.commit('MODELS/SET_CURRENT_MODEL_DATA', nodeData);

                this.$router.push({ name: 'Viewer' });

                setTimeout(() => {
                    this.$store.dispatch('MODELS/OPEN_SELECTED_MODEL');
                }, 1000);
            },
        },
        computed: {
            activeFolder: {
                get() {
                    return this.$store.getters['PROJECTS/ACTIVE_FOLDER'];
                },
                set(value) {
                    this.$store.commit('PROJECTS/SET_ACTIVE_FOLDER', value);
                },
            },
            currentProject: {
                get() {
                    return this.$store.getters['PROJECTS/CURRENT_PROJECT_DATA'];
                },
                set(value) {
                    this.$store.commit('PROJECTS/SET_CURRENT_PROJECT_DATA', value);
                },
            },
            currentFolderName() {
                return this.activeFolder?.name ?? 'No Folder Selected!';
            },
            backAvailable() {
                return !!this.visitedMap[this.activeFolder?.folderId];
            },
        },
        watch: {
            currentProject: {
                handler: function (value) {
                    if (value) {
                        this.openProject();
                    }
                },
                immediate: true,
            },
            activeFolder: {
                handler: function (value) {
                    if (value) this.loadFolderItems(value.id);
                },
            },
        },
    };
</script>
<style lang="scss">
    .v-table__wrapper {
        background: #202020;

        & thead tr th {
            background: #2b2b2b;
            color: #aacbfc;
            height: 40px;
            font-weight: 500;
            font-size: 1.1rem;
        }

        & tbody tr:nth-child(even) {
            background: #2b2b2b;
        }

        & tbody tr:not(.v-data-table-rows-no-data):hover {
            background: #3a3a3a;
        }
    }

    .table-file-name {
        color: #6cf170;
    }

    .table-name-cell {
        cursor: pointer;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
    }

    .table-name-cell:hover {
        color: #9fbdff;
    }

    .table-file-name.table-name-cell:hover {
        color: #91ec94;
    }

    .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        opacity: 0.8;
    }
</style>
