<template>
    <v-card>
        <div v-if="!isMarkupData" class="d-flex justify-center align-center pa-2" style="gap: 5px">
            <v-text-field
                dense
                placeholder="Search"
                hide-details
                density="compact"
                solo
                v-model="searchText"></v-text-field>
            <v-btn @click="removeMultipleInstance()" v-if="markupsCheckedCount > 0" size="30">
                <v-icon color="error">fa:fas fa-trash-alt</v-icon>
            </v-btn>

            <v-btn size="30" @click="isList = false">
                <i class="fa-solid fa-folder"></i>
            </v-btn>
            <v-btn size="30" @click="isList = true">
                <i class="fa-solid fa-list"></i>
            </v-btn>
        </div>
        <div>
            <v-checkbox
                theme="primary"
                v-model="selected"
                hide-details
                :label="`Select All (${markupsCheckedCount} items selected)`"></v-checkbox>
        </div>
        <v-expansion-panels
            v-if="!isMarkupData && groupedData && groupedData.length > 0 && !isList"
            v-model="panel"
            multiple>
            <v-expansion-panel
                v-for="item2 in groupedData"
                :key="item2.name"
                :title="item2.name"
                :id="item2.name">
                <template v-slot:header>
                    <div>Item</div>
                </template>
                <v-expansion-panel-text class="py-2">
                    <v-list-item v-for="item in item2.markupElements" :key="item.id">
                        <div @click="changeMarkup(item)" class="text--primary">
                            <v-hover v-slot="{ isHovering, props }">
                                <v-card
                                    v-bind="props"
                                    :elevation="isHovering ? 24 : 6"
                                    max-height="100"
                                    height="100"
                                    v-ripple
                                    link
                                    border="success md"
                                    class="card">
                                    <v-sheet
                                        v-bind="props"
                                        class="border-e-lg border-info d-flex align-center h-100"
                                        :class="{
                                            'pa-2': !item.imgPath,
                                        }"
                                        color="transparent">
                                        <v-img
                                            width="100"
                                            style="transition: width 0.3s ease-in-out"
                                            height="110"
                                            aspect-ratio="16/9"
                                            cover
                                            lazy-src="/img/autodesk.svg"
                                            :src="item.imgPath"></v-img>
                                        <v-checkbox
                                            @click.stop="checkGrouped(item)"
                                            v-model="item.checked"
                                            color="secondary"
                                            class="instances-checkbox"></v-checkbox>
                                        <v-card-text class="card-content">
                                            <div style="color: white" class="title mb-1">
                                                {{ item.name }}
                                            </div>
                                            <div style="color: white" class="subtitle">
                                                {{ item.createdByName }}
                                            </div>
                                            <div class="subtitle">
                                                {{ formatDateTime(item.createdOn) }}
                                            </div>

                                            <v-btn @click.stop="ConfirmDelete(item)" size="30">
                                                <v-icon color="error">fa:fas fa-trash-alt</v-icon>
                                            </v-btn>
                                            <v-chip
                                                v-if="item.isAttach"
                                                size="small"
                                                density="compact"
                                                border
                                                color="success"
                                                class="subtitle"
                                                width="100px">
                                                CA
                                            </v-chip>

                                            <!-- <v-btn color="error" icon @click.stop="removeInstance(item)">
                                                <v-icon>fas fa-trash-alt</v-icon>
                                            </v-btn> -->
                                        </v-card-text>
                                    </v-sheet>
                                </v-card>
                            </v-hover>
                        </div>
                    </v-list-item>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-list-item
            v-else-if="!isMarkupData && isList"
            v-for="item in SearchMarkups.markups"
            :key="item.id">
            <div @click="changeMarkup(item)" class="text--primary">
                <v-hover v-slot="{ isHovering, props }">
                    <v-card
                        v-bind="props"
                        :elevation="isHovering ? 24 : 6"
                        max-height="100"
                        height="100"
                        v-ripple
                        link
                        border="success md"
                        class="card">
                        <v-sheet
                            v-bind="props"
                            class="border-e-lg border-info d-flex align-center h-100"
                            :class="{
                                'pa-2': !item.imgPath,
                            }"
                            color="transparent">
                            <v-img
                                width="100"
                                style="transition: width 0.3s ease-in-out"
                                height="110"
                                aspect-ratio="16/9"
                                cover
                                lazy-src="/img/autodesk.svg"
                                :src="item.imgPath"></v-img>
                            <v-checkbox
                                @click.stop="check(item)"
                                v-model="item.checked"
                                color="secondary"
                                class="instances-checkbox"></v-checkbox>
                            <v-card-text class="card-content">
                                <div style="color: white" class="title mb-1">
                                    {{ item.name }}
                                </div>
                                <div style="color: white" class="subtitle">
                                    {{ item.createdByName }}
                                </div>
                                <div class="subtitle">
                                    {{ formatDateTime(item.createdOn) }}
                                </div>

                                <v-btn @click.stop="ConfirmDelete(item)" size="30">
                                    <v-icon color="error">fa:fas fa-trash-alt</v-icon>
                                </v-btn>
                                <v-chip
                                    v-if="item.isAttach"
                                    size="small"
                                    density="compact"
                                    border
                                    color="success"
                                    class="subtitle"
                                    width="100px">
                                    CA
                                </v-chip>
                            </v-card-text>
                        </v-sheet>
                    </v-card>
                </v-hover>
            </div>
        </v-list-item>
        <div
            v-if="!isMarkupData && !groupedData.length"
            class="py-16 d-flex align-center justify-center flex-column h-99">
            <v-img width="300" max-height="350px" contain src="/img/No Data.svg"></v-img>
            <div class="pt-4 title">No Markups Found</div>
        </div>
        <div v-for="index in 7" :key="index">
            <v-skeleton-loader
                :loading="GroupedMarkupNotLoaded || ListMarkupNotLoaded"
                v-if="GroupedMarkupNotLoaded || ListMarkupNotLoaded"
                type="list-item-three-line">
            </v-skeleton-loader>
        </div>

        <div id="backMarkups" v-if="isMarkupData" @click="backToMarkups()">
            <i style="margin-right: 5%" class="fa-solid fa-2x fa-arrow-left"></i>
            <span style="font-size: 20px">Back to markups</span>
        </div>
        <MarkupData :markupId="markupIdProp" v-if="isMarkupData" />
        <message-box
            ref="messageBox"
            :on-confirm="this.onConfirmMessage"
            title="Confirmation"
            message="Are you sure you want to delete?" />
    </v-card>
</template>

<script>
    import MarkupData from '@/features/Markups/components/MarkupData';
    import MessageBox from '@/components/MessageBox.vue';

    export default {
        data: () => ({
            data: [],
            filteredData: [],
            searchText: '',
            isMarkupData: false,
            markupIdProp: 0,
            markupId: 0,
            showAllBtn: true,
            hideAllBtn: false,
            panel: [],
            isList: false,
            modelId: 0,
            loading: true,
            onConfirmMessage: null,
            selected: false,
            allItems: [],
        }),
        components: {
            MarkupData,
            MessageBox,
        },
        computed: {
            markupsList() {
                return this.$store.getters['MARKUPEDITOR/SAVED_MARKUPS'];
            },
            markupsChanged: {
                get() {
                    return this.$store.getters['MARKUPEDITOR/MARKUPS_CHANGED'];
                },
            },
            markupData() {
                return this.markupId;
            },
            markupOpened() {
                return this.$store.getters['MARKUPDATA/MARKUP_OPENED'];
            },
            groupedData() {
                return this.$store.getters['MARKUPDATA/GROUPED_MARKUP'];
            },
            SearchMarkups() {
                return this.$store.getters['MARKUPDATA/SEARCH_MARKUP'];
            },
            GroupedMarkupNotLoaded() {
                return this.$store.getters['MARKUPDATA/GROUPED_MARKUPS_LOADED'];
            },
            ListMarkupNotLoaded() {
                return this.$store.getters['MARKUPDATA/List_MARKUPS_LOADED'];
            },
            markupsCheckedCount() {
                return this.SearchMarkups?.markups?.filter((c) => c['checked']).length;
            },
        },
        watch: {
            markupsList() {
                this.data = window.savedMarkups;
                this.filteredData = window.savedMarkups;
            },
            markupsChanged() {
                this.reloadMakrups();
            },
            markupData(value) {
                if (value > 0 && !this.markupOpened) {
                    this.markupIdProp = this.markupId;
                    this.isMarkupData = true;
                }
            },
            searchText(value) {
                //this.isList=true;
                this.$store.dispatch('MARKUPDATA/getAllSearch', {
                    search: value,
                    id: this.modelId,
                });
                this.$store.dispatch('MARKUPDATA/getGroupedMarkupMarkupById', {
                    search: this.searchText,
                    id: this.modelId,
                });
                this.panel = Array.from(Array(this.groupedData.length).keys());
            },
            selected(value) {
                this.SearchMarkups.markups.forEach((elem) => {
                    elem['checked'] = value;
                });
                this.groupedData.forEach((element) => {
                    element.markupElements.forEach((elem) => {
                        elem['checked'] = value;
                    });
                });
            },
            groupedData(value) {
                value.forEach((element) => {
                    element.markupElements.forEach((elem) => {
                        this.allItems.push(elem);
                    });
                });
            },
        },
        methods: {
            changeMarkup(_data) {
                this.markupIdProp = _data.id;
                this.isMarkupData = true;
                this.hideAllBtn = false;
                this.showAllBtn = true;
                let is2d = false;
                let time = 0;
                var Item = window.d3items.filter((c) => c.data.guid == _data.modelId)[0];
                if (!Item) {
                    Item = window.d2items.filter((c) => c.data.guid == _data.modelId)[0];
                    is2d = true;
                }
                if (is2d) {
                    this.changeNode(_data.modelId, is2d);
                    time = 1000;
                } else {
                    if (window.loadedModel.data.guid != _data.modelId) {
                        this.changeNode(_data.modelId, is2d);
                        time = 1000;
                    }
                }

                setTimeout(() => {
                    if (_data.isAttach) {
                        this.$store
                            .dispatch('MARKUPDATA/getMarkupById', { id: _data.id })
                            .then((res) => {
                                this.$store.dispatch('MARKUPDATA/setMarkupImg', res.img);
                                this.$store.dispatch('MARKUPEDITOR/setAttachData', res);
                                this.$store.dispatch('MARKUPDATA/setOpenMarkupImg', true);
                            });

                        const selected = this.$store.getters['ALL_Camera_Elements'].find((c) =>
                            c.cameraAttachements.find((z) => z.id == _data.attachId),
                        );
                        if (selected) {
                            let indexx = selected.cameraAttachements.findIndex(
                                (c) => c.id == _data.attachId,
                            );
                            if (indexx > -1) {
                                this.$store.commit('SET_CAMERA_INDEX', indexx);
                            }

                            setTimeout(() => {
                                this.$store.commit('SET_SELECTED_CAMERA_ELEMENT', selected);
                                this.$store.commit('SET_Show_Camera_Attachement', true);
                            }, 2000);
                        }
                    } else {
                        this.$store.dispatch('MARKUPEDITOR/addMarkupModelToViewer', _data.id);
                        this.$store.dispatch('MARKUPEDITOR/initializeMarkup', { data: _data });
                    }
                }, time);
            },
            backToMarkups() {
                this.isMarkupData = false;
                this.$store.dispatch('MARKUPDATA/setMarkupOpened');

                this.$store.dispatch('MARKUPEDITOR/ExitMarkup');
            },
            search() {
                this.$store.dispatch('MARKUPDATA/getGroupedMarkupMarkupById', {
                    search: this.searchText,
                    id: this.modelId,
                });
            },
            showAll() {
                this.$store.dispatch('MARKUPEDITOR/OpenAllMarkup');
                this.hideAllBtn = true;
                this.showAllBtn = false;
            },
            hideAll() {
                this.$store.dispatch('MARKUPEDITOR/ExitMarkup');
                this.hideAllBtn = false;
                this.showAllBtn = true;
            },
            formatDateTime(date) {
                return new Date(date).toLocaleString();
            },
            changeNode(guid, is2d) {
                let query = { ...this.$route.query };
                delete query.markupId;
                this.$router.replace({ query: query });
                this.$store.dispatch('loadDocumentNode', { is2d: is2d, guid: guid });
            },
            Delete() {
                this.$store.dispatch('MARKUPDATA/DeleteMarkupData', {
                    id: this.DeletedItem.id,
                    modelId: this.modelId,
                    reload: true,
                });
            },
            ConfirmDelete(item) {
                this.DeletedItem = item;
                this.onConfirmMessage = this.Delete;
                this.$refs.messageBox.show();
            },
            ConfirmMultipleDelete() {
                this.onConfirmMessage = this.DeleteMultipleInstance;
                this.$refs.messageBox.show();
            },
            DeleteMultipleInstance() {
                this.SearchMarkups.markups
                    .filter((c) => c['checked'])
                    .forEach((elem) => {
                        this.DeletedItem = elem;
                        this.Delete();
                    });
            },
            reloadMakrups() {
                this.$store.dispatch('MARKUPDATA/getAllSearch', {
                    search: this.searchText,
                    id: this.modelId,
                });
                this.$store.dispatch('MARKUPDATA/getGroupedMarkupMarkupById', {
                    search: this.searchText,
                    id: this.modelId,
                });
            },
            check(item) {
                this.allItems.filter((c) => c.id == item.id)[0]['checked'] = !item.checked;
            },
            checkGrouped(item) {
                this.SearchMarkups.markups.filter((c) => c.id == item.id)[0]['checked'] =
                    !item.checked;
            },
            removeMultipleInstance() {
                this.ConfirmMultipleDelete();
            },
        },

        mounted() {
            this.data = window.savedMarkups;
            this.filteredData = window.savedMarkups;
            const paramMarkupId = this.$route.query.markupId;
            const modelId = this.$route.params.id;
            this.modelId = modelId;
            if (modelId) {
                this.$store.dispatch('MARKUPDATA/getGroupedMarkupMarkupById', {
                    search: this.searchText,
                    id: this.modelId,
                });
                this.$store.dispatch('MARKUPDATA/getAllSearch', {
                    search: this.searchText,
                    id: modelId,
                });
            }
            if (paramMarkupId) {
                this.markupId = paramMarkupId;
                // this.$store.dispatch("MARKUPDATA/getMarkupData", {id:paramMarkupId});
            }
        },
    };
</script>

<style scoped lang="css">
    .v-tab.v-tab.v-btn {
        width: 50% !important;
    }
    #searchCont {
        width: 90%;
        margin: auto;
        margin-top: 2%;
        margin-bottom: -10%;
    }
    #backMarkups {
        cursor: pointer;
        margin-bottom: 4%;
    }
    .instances-list-drawer {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .instances-list-drawer .card {
        display: flex;
        overflow: hidden;
        max-width: 600px;
        border: 1px solid #69696973;
    }

    .instances-list-drawer .card-content {
        display: flex;
        flex-direction: column;
        padding: 8px 4px 0 4px;
        width: 100%;
    }

    .instances-list-drawer .card-content .buttons button {
        width: 25px;
        height: 25px;
    }

    .card-content .title {
        margin: 0;
        font-size: 0.9rem;
        font-weight: 600;
        color: #333;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }

    .card-content .subtitle {
        margin: 0;
        font-size: 0.7rem;
        color: #8f8f8f;
        line-height: 1rem;
    }

    .instances-list-drawer .buttons {
        margin-top: 2px;
        display: flex;
        justify-content: flex-end;
        min-height: auto;
        padding: 2px 0;
    }

    .instances-list-drawer .buttons button {
        padding: 2px;
        width: 25px;
        border: none;
        background-color: #e2e2e25e;
        border-radius: 5px;
        cursor: pointer;
    }

    .instances-list-drawer .buttons button:hover {
        background-color: #e2e2e2;
    }

    .instances-list-drawer .buttons button:disabled {
        background-color: #e2e2e25e;
        cursor: not-allowed;
    }

    .instances-list {
        position: relative;
        height: -webkit-fill-available;
        overflow: auto;
    }

    .actions {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 15px;
        height: 80px;
    }

    .warning-card {
        border-color: #ffbd5a;
        background-color: #fffdfb;
    }
    img.placeholder-image {
        padding: 15px;
    }
    .warning-card img.placeholder-image {
        border-right: 1px solid #ffedd2;
        filter: grayscale(100%);
    }

    /* ****************************** */

    .instances-list-drawer .v-expansion-panel--active:not(:first-child),
    .instances-list-drawer .v-expansion-panel--active + .v-expansion-panel {
        margin-top: 0px;
        border-top: 1px solid #3c3c3c;
    }

    .instances-list-drawer
        .v-expansion-panel--active
        > .v-expansion-panel-title:not(.v-expansion-panel-title--static) {
        min-height: 48px;
        border-bottom: 1px solid #2a2a2a;
    }

    .instances-list-drawer .v-expansion-panel-text__wrapper {
        padding: 0px !important;
    }

    .instances-list-drawer .v-card .v-icon {
        font-size: 1.2rem;
    }
    .instances-checkbox {
        position: absolute;
        top: -21%;
        left: -3%;
    }
</style>
