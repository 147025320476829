const { Autodesk, THREE } = window;

export const ForgeService = {
    state() {
        return {
        }
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        TranslateModelFragments(_, viewer, model, offset) {
            const fragCount = model.getFragmentList().fragments.fragId2dbId.length;

            for (let fragId = 0; fragId < fragCount; ++fragId) {
                const fragProxy = viewer.impl.getFragmentProxy(model, fragId);

                fragProxy.getAnimTransform();

                const position = new THREE.Vector3(fragProxy.position.x + offset.x, fragProxy.position.y + offset.y, fragProxy.position.z + offset.z);

                fragProxy.position = position;

                fragProxy.updateAnimTransform();
            }

            viewer.impl.sceneUpdated(true);
        },
        RotateModelFragments({ dispatch }, viewer, model, axis, angle, center) {
            const fragCount = model.getFragmentList().fragments.fragId2dbId.length;

            const fragIdsArray = Array.from({ length: fragCount }, (_, idx) => idx);

            dispatch('RotateFragments', { viewer, model, fragIdsArray, axis, angle, center });

            viewer.impl.sceneUpdated(true);
        },
        RotateFragments(_, model, fragIdsArray, axis, angle, center) {
            var quaternion = new THREE.Quaternion()

            quaternion.setFromAxisAngle(axis, angle)

            fragIdsArray.forEach((fragId, idx) => {

                var fragProxy = this.viewer.impl.getFragmentProxy(
                    model, fragId)

                fragProxy.getAnimTransform()

                var position = new THREE.Vector3(
                    fragProxy.position.x - center.x,
                    fragProxy.position.y - center.y,
                    fragProxy.position.z - center.z)

                position.applyQuaternion(quaternion)

                position.add(center)

                fragProxy.position = position

                fragProxy.quaternion.multiplyQuaternions(
                    quaternion, fragProxy.quaternion)

                if (idx === 0) {

                    var euler = new THREE.Euler()

                    euler.setFromQuaternion(
                        fragProxy.quaternion, 0)

                    this.emit('rotate', {
                        dbIds: this.selection.dbIdArray,
                        fragIds: fragIdsArray,
                        rotation: euler,
                        model
                    })
                }

                fragProxy.updateAnimTransform()
            })
        },
        RotateFamilyInstance({ getters, dispatch }, { familyInstanceId, euler}) {
            let instance = getters.FAMILIES_INSTANCES.find(instance => instance.id === familyInstanceId);
            
            if (!instance) {
                console.log('Family instance not found');
                return;
            }

            let model = window.NOP_VIEWER.getAllModels().find(model => model.id === instance.loadedModelId);

            if (!model) {
                console.log('Model not found');
                return;
            }

            let viewer = window.NOP_VIEWER;

            dispatch('RotateModelByEuler', { viewer, model, euler });
        },
        RotateModelByEuler(_, { model, euler }) {
            const fragList = model.getFragmentList();

            const fragCount = fragList.fragments.fragId2dbId.length;

            const fragIdsArray = Array.from({ length: fragCount }, (_, idx) => idx);

            let bBox = getWorldBoundingBox(fragIdsArray,fragList)
    
            let center = new THREE.Vector3((bBox.min.x + bBox.max.x) / 2, (bBox.min.y + bBox.max.y) / 2, (bBox.min.z + bBox.max.z) / 2);

            let quaternion = new THREE.Quaternion();
            quaternion.setFromEuler(euler);

            fragIdsArray.forEach((fragId) => {

                var fragProxy = window.NOP_VIEWER.impl.getFragmentProxy(model, fragId);
                fragProxy.getAnimTransform()

                var position = new THREE.Vector3(
                    fragProxy.position.x - center.x,
                    fragProxy.position.y - center.y,
                    fragProxy.position.z - center.z
                )

                position.applyQuaternion(quaternion)

                position.add(center)

                fragProxy.position = position

                fragProxy.quaternion.multiplyQuaternions(quaternion, fragProxy.quaternion);

                fragProxy.updateAnimTransform()
            })

            window.NOP_VIEWER.impl.sceneUpdated(true);
        },
        GetRevitElementId(_, dbId) {
            return new Promise((resolve, reject) => {
                window.NOP_VIEWER.getProperties(dbId, (props) => {
                    const splitArr = props.externalId.split('-');
                    const elementId = parseInt(splitArr[splitArr.length - 1], 16);
                    resolve(elementId);
                }, reject);
            });
        }
    }
}

export function getWorldBoundingBox(fragIds, fragList) {
    var fragbBox = new THREE.Box3()
    var nodebBox = new THREE.Box3()

    fragIds.forEach((fragId) => {
        fragList.getWorldBounds(fragId, fragbBox)
        nodebBox.union(fragbBox)
    })

    return nodebBox
}

export function getModelElementBoundingBox2D(dbId, model) {
    if (!model) {
        model = window.NOP_VIEWER.model;
    }

    const objectId = model.reverseMapDbIdFor2D(dbId);

    const it = model.getData().instanceTree;

    let bBox = new THREE.Box3();

    it.enumNodeFragments(dbId, (fragId) => {

        let renderProxy = window.NOP_VIEWER.impl.getRenderProxy(model, fragId);

        let vbr = new Autodesk.Viewing.Private.VertexBufferReader(renderProxy.geometry);

        let lines = [];

        vbr.enumGeomsForObject(objectId, {
            onLineSegment: (x1, y1, x2, y2) => {
                lines.push({ x1, y1, x2, y2 });
            }
        });

        const points = [];

        lines.forEach(line => {
            points.push(new THREE.Vector3(line.x1, line.y1, 0));
            points.push(new THREE.Vector3(line.x2, line.y2, 0));
        });
        
        let box = new THREE.Box3().setFromPoints(points);

        bBox.union(box);
    }, true);

    return bBox;
}