/* eslint-disable no-unused-vars */
import HttpService from "@Utils/httpService";
import { toast } from "vue3-toastify";
import store from "..";
import SettingsList from '@/components/SettingsList';
import vuetify from '../../plugins/vuetify'
import { ForgeSettings } from "@/models/ForgeModels";

export const SETTINGS = {
    namespaced: true,
    state() {
        return {
            userSettings: null,
            modelSettings: null,
        };
    },
    getters: {
        USER_SETTINGS(state) {
            return state.userSettings;
        },
        MODEL_SETTINGS(state) {
            return state.modelSettings;
        },        
    },
    mutations: {
        SET_USER_SETTINGS(state, settings) {
            state.userSettings = settings;
        },
        SET_MODEL_SETTINGS(state, settings) {
            state.modelSettings = settings;
        },        
    },
    actions: {
        GetModelSettings({ commit, getters }) {
            let modelId = 'General';
            HttpService.get(`api/ForgeSettings/${modelId}`)
                .then((response) => {

                    let settings = Object.assign(new ForgeSettings(), response)

                    commit("SET_MODEL_SETTINGS", settings);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        SaveModelSettings({ commit, getters }, settings) {
            return new Promise((resolve, reject) => {
                HttpService.post({
                    url: `api/ForgeSettings`,
                    data: settings
                })
                    .then(() => {
                        toast.success("Settings saved successfully", {
                            position: "top-center",
                            duration: 3000,
                        });

                        resolve();
                    })
                    .catch((error) => {
                        console.log(error);
                        reject();
                    });
            });
        }
    },
}