<template>
    <v-dialog v-model="dialogVisible" max-width="400" persistent theme="dark">
        <v-card border="lg" class="mx-auto">
            <v-card-title class="headline">{{ title }}</v-card-title>
            <v-divider :thickness="1" class="border-opacity-100" color="error"></v-divider>
            <v-card-text>
                <v-text-field v-model="inputValue" :label="label"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#D50000" text @click="cancel">Cancel</v-btn>
                <v-btn color="success" text @click="confirm">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['title', 'label', 'value', 'onConfirm', 'onDiscard'],

    data() {
        return {
            dialogVisible: false,
            inputValue: this.value,
        };
    },
    methods: {
        show() {
            this.dialogVisible = true;
        },
        hide() {
            this.inputValue = ''
            this.dialogVisible = false;
        },
        confirm() {
            this.onConfirm(this.inputValue)
        },
        cancel() {
            this.onDiscard()
        },
    },
};
</script>


<style scoped>
.mx-auto {
    background: rgb(48, 36, 36);
}
</style>