<template>
  <v-app>
    <top-bar></top-bar>
    <projects-popup></projects-popup>
    <v-main>
      <slot></slot>
    </v-main>
  </v-app>
</template>
<script>
import ProjectsPopup from '@Home/components/ProjectsPopup.vue';
import TopBar from '@Home/components/TopBar.vue';
export default {
  name: "projects-layout",
  components: {
    TopBar,
    ProjectsPopup
  },
  data: () => ({
    
  }),
  computed: {
    
  }
}
</script>
<style lang="css">
  main {
    background-color: #212121;
  }
</style>

