
import store from '@/store';
import HttpService from '@Utils/httpService';
import MarkupDataService from "../service/MarkupData.service";
import { toast } from 'vue3-toastify';

const Autodesk = window.Autodesk;
const THREE = window.THREE;

export const MARKUPEDITOR = {
  namespaced: true,
  state: {
    SavedMarkups: [],
    showMarkups: false,
    markupPop: false,
    Markupcase: false,
    IsNewMarkup:false,
    currentMakrup:null,
    showGlobalMarkup:false,
    isAttach:null,
    attachId:null,
    attachHasMarkup:false,
    boundingBoxX:0,
    boundingBoxY:0,
    boundingBoxAssigned:false,
    markupHref:"",
    attachName:"",
    hideAllMarkups:false,
    attacheViewerRatio:0,
    makrupAddedModels:[],
    drawingMode:"",
    toolsDisabled:true,
    currentStrokeWidth:0,
    selectedSliderValue:5
  },
  getters: {
    MARKUP_STATE(state) {
      return state.showMarkups;
    },
    MARKUP_POPUP(state) {
      return state.markupPop;
    },
    SAVED_MARKUPS(state) {
      return state.SavedMarkups;
    },
    MARKUPS_CHANGED(state) {
      return state.Markupcase;
    },
    IS_NEW_MARKUP(state){
      return state.IsNewMarkup;
    },
    CURRENT_MARK(state){
      if (state) {
      return window.markup;
      }
    },
    SHOW_GLOBAL_MARKUP(state){
      return state.showGlobalMarkup;
    },
    ATTACH_ID(state){
      return state.attachId;
    },
    ATTACH_NAME(state){
      return state.attachName;
    },
    IS_ATTACH(state){
      return state.isAttach;
    },
    ATTACH_HAS_MARKUP(state){
      return state.attachHasMarkup;
    },
    BOUNDING_BOX(state){
      return {min:state.boundingBoxX,max:state.boundingBoxY,assigned:state.boundingBoxAssigned}
    },
    MARKUP_HREF(state){
      return state.markupHref;
    },
    HIDE_ALL(state){
      return state.hideAllMarkups;

    },
    MARKUP_ADDED_MODEL(state){
      return state.makrupAddedModels;
    },
    TOOLS_DISABLED(state){
      return state.toolsDisabled;
    },
    SELECTED_SLIDER_VALUE(state){
        return state.selectedSliderValue;
    }
  },
  mutations: {
    SET_SHOW_MARKUPS(state, casee) {
      state.showMarkups = casee;
    },
    SET_IS_NEW_MARKUP(state, casee) {
      state.IsNewMarkup = casee;
    },
    SET_MARKUP_POPUP(state, cases) {
      state.markupPop = cases;
    },
    SET_SAVED_MARKUPS(state, data) {
      state.SavedMarkups = data;
      
    },
    SET_MARKUPS_CHANGED(state) {
      state.Markupcase = !state.Markupcase;
    },
    SET_SHOW_GLOBAL_MARKUP(state,value){
      state.showGlobalMarkup=value
    },
    SET_IS_ATTACH(state,value){
     state.isAttach=value;
    },
    SET_ATTACH_ID(state,value){
      state.attachId=value;
     },
     SET_ATTACH_NAME(state,value){
      state.attachName=value;
     },
     SET_ATTACH_HAS_MARKUP(state,value){
      state.attachHasMarkup=value;
     },
     SET_BOUNDING_BOX(state,value){
      state.boundingBoxX=value.min;
      state.boundingBoxY=value.max;
      state.boundingBoxAssigned=true;
    },
    SET_MARKUP_ADDED_MODEL(state,value){
       state.makrupAddedModels=value;
    },
    SET_TOOLS_DISABLED(state,value){
       state.toolsDisabled=value;
    }

  },
  actions: {
    setSelectedItemBorderWidth(store, data) {
      let markup=window.markup;
      let defaultStyle=window.defaultMarkupStyle;
      if (window.isMarkupAttach) {
        markup=window.attachMarkup;
        defaultStyle=window.attachDefaultMarkupStyle;
      }
      var selected = markup.markups.filter((c) => c.selected)[0];
      
      if (selected && selected.type != "label") {
        selected.style["stroke-width"] =store.state.currentStrokeWidth*data.value;
        defaultStyle["stroke-width"] = store.state.currentStrokeWidth*data.value;
       // markup.setStyle(defaultStyle);
        selected.updateStyle();
      } else if (selected) {
        selected.style["font-size"] =store.state.currentStrokeWidth*data.value;
        defaultStyle["font-size"] = store.state.currentStrokeWidth*data.value;
        //markup.setStyle(defaultStyle);
        selected.updateStyle();
      } else {
        defaultStyle["stroke-width"] = store.state.currentStrokeWidth*data.value;
        defaultStyle["font-size"] = store.state.currentStrokeWidth*data.value;
        //markup.setStyle(defaultStyle);
      }
      
      if (window.isMarkupAttach) {
       window.attachDefaultMarkupStyle=defaultStyle;
     }
     else{
      window.defaultMarkupStyle=defaultStyle
     }
     if (markup.markups.length>0) {
      if (selected) {
        markup.selectMarkup()
      }
      markup.setStyle(defaultStyle);
      if (selected) {
        markup.selectMarkup(selected)
      }
     }

    },
    setSelectedItemOpacity(store, data) {
  
      let markup=window.markup;
      let defaultStyle=window.defaultMarkupStyle;
      if (window.isMarkupAttach) {
        markup=window.attachMarkup;
        defaultStyle=window.attachDefaultMarkupStyle;
      }
      var selected = markup.markups.filter((c) => c.selected)[0];
      if (selected) {
        selected.style["fill-opacity"] = data.value;
        selected.updateStyle();
      }
      defaultStyle["fill-opacity"] = data.value;
      //markup.setStyle(defaultStyle);
      if (window.isMarkupAttach) {
        window.attachDefaultMarkupStyle=defaultStyle;
      }
      else{
       window.defaultMarkupStyle=defaultStyle
      }
      if (markup.markups.length>0) {
        if (selected) {
          markup.selectMarkup()
        }
        markup.setStyle(defaultStyle);
        if (selected) {
          markup.selectMarkup(selected)
        }
       }
       
    },
    setSelectedItemBorderColor(store, data) {
      let markup=window.markup;
      let defaultStyle=window.defaultMarkupStyle;
      if (window.isMarkupAttach) {
        markup=window.attachMarkup;
        defaultStyle=window.attachDefaultMarkupStyle;
      }
      var selected = markup.markups.filter((c) => c.selected)[0];
      if (selected) {
        selected.style["stroke-color"] = data.value;
        selected.updateStyle();
      }
      defaultStyle["stroke-color"] = data.value;
      
      if (window.isMarkupAttach) {
        window.attachDefaultMarkupStyle=defaultStyle;
      }
      else{
       window.defaultMarkupStyle=defaultStyle
      }
      if (markup.markups.length>0) {
        if (selected) {
          markup.selectMarkup()
        }
        markup.setStyle(defaultStyle);
        if (selected) {
          markup.selectMarkup(selected)
        }
       }
    },
    setSelectedItemFillColor(store, data) {
      let markup=window.markup;
      let defaultStyle=window.defaultMarkupStyle;
      if (window.isMarkupAttach) {
        markup=window.attachMarkup;
        defaultStyle=window.attachDefaultMarkupStyle;
      }
      var selected = markup.markups.filter((c) => c.selected)[0];
      if (selected) {
        selected.style["fill-color"] = data.value;
        selected.updateStyle();
      }
      defaultStyle["fill-color"] = data.value;
      
      if (window.isMarkupAttach) {
        window.attachDefaultMarkupStyle=defaultStyle;
      }
      else{
       window.defaultMarkupStyle=defaultStyle
      }
      if (markup.markups.length>0) {
        if (selected) {
          markup.selectMarkup()
        }
        markup.setStyle(defaultStyle);
        if (selected) {
          markup.selectMarkup(selected)
        }
       }
    },
    changePolyLocations(store){
      let markup=window.markup;
      let defaultStyle=window.defaultMarkupStyle;
      if (window.isMarkupAttach) {
        markup=window.attachMarkup;
        defaultStyle=window.attachDefaultMarkupStyle;
      }
      if (!markup) return;
      let polyMarkup=markup.markups[markup.markups.length-1];
      if (polyMarkup) {
        if (defaultStyle["stroke-width"]<1.5*store.state.currentStrokeWidth) {
          defaultStyle["stroke-width"]=5*store.state.currentStrokeWidth;
        }
        polyMarkup.style["stroke-width"] = defaultStyle["stroke-width"];
        polyMarkup.style["font-size"] = defaultStyle["font-size"];
        polyMarkup.style["fill-opacity"] = defaultStyle["fill-opacity"];
        polyMarkup.style["stroke-color"] = defaultStyle["stroke-color"];
        polyMarkup.style["fill-color"] = defaultStyle["fill-color"];


        polyMarkup.updateStyle();

      }
      // markup.selectMarkup()
      // markup.setStyle(defaultStyle);
      // markup.selectMarkup(polyMarkup)


      if (polyMarkup && store.state.drawingMode=="line" && polyMarkup.type=="polyline") {
        if (polyMarkup.locations.length==3) {
          if (window.layerName != null && window.layerName != "") {
            markup.enterEditMode(window.layerName);
          } else {
            markup.enterEditMode();
          }
          markup.changeEditMode(new Autodesk.Viewing.Extensions.Markups.Core.EditModePolyline(
            markup
          ));
         //markup.setStyle(defaultStyle);
        }
      }
    },
    changeMarkupMode(store, data) {
      let markup=window.markup;
   
      if (window.isMarkupAttach) {
        markup=window.attachMarkup;
      }
      if (data.markup == "delete") {
        var selected = markup.markups.filter((c) => c.selected)[0];
        if (selected) {
          markup.deleteMarkup(selected);
        }
        return;
      }
      if (window.layerName != null && window.layerName != "") {
        markup.enterEditMode(window.layerName);
      } else {
        markup.enterEditMode();
      }

      let type;
      // markup.removeEventListener('EVENT_HISTORY_CHANGED', () => {
      //   store.dispatch('changePolyLocations');
      // });
      store.state.drawingMode=data.markup;  
      markup.addEventListener('EVENT_HISTORY_CHANGED', () => {
        store.dispatch('changePolyLocations');
      });  
      markup.addEventListener('EVENT_MARKUP_SELECTED', () => {
        var selected = markup.markups.filter((c) => c.selected)[0];
        if (selected && !window.isMarkupAttach) {
          store.state.selectedSliderValue= selected.style["stroke-width"]/store.state.currentStrokeWidth;
          
        }
         store.commit('SET_TOOLS_DISABLED',false);
      });  
      markup.addEventListener('EVENT_MARKUP_DESELECT', () => {
        store.commit('SET_TOOLS_DISABLED',true);

      });  
      switch (data.markup) {
        
        case "arrow":
          type = new Autodesk.Viewing.Extensions.Markups.Core.EditModeArrow(
            markup
          );
          break;
        case "rect":
          type = new Autodesk.Viewing.Extensions.Markups.Core.EditModeRectangle(
            markup
          );
          break;
        case "circle":
          type = new Autodesk.Viewing.Extensions.Markups.Core.EditModeCircle(
            markup
          );
          break;
        case "cloud":
          type = new Autodesk.Viewing.Extensions.Markups.Core.EditModeCloud(
            markup
          );
          break;
        case "freehand":
          type = new Autodesk.Viewing.Extensions.Markups.Core.EditModeFreehand(
            markup
          );
          break;
        case "text":
          type = new Autodesk.Viewing.Extensions.Markups.Core.EditModeText(
            markup
          );
          break;
        case "polygon":
          type = new Autodesk.Viewing.Extensions.Markups.Core.EditModePolyline(
            markup
          );  
          break;
          case "line":
          type = new Autodesk.Viewing.Extensions.Markups.Core.EditModePolyline(
            markup
          );
         
          break;
        case "Highlight":
          type = new Autodesk.Viewing.Extensions.Markups.Core.EditModeHighlight(
            markup
          );
          break;

        default:
          break;
      }
      markup.changeEditMode(type);
      if (window.isMarkupReset==undefined) {
        window.isMarkupReset=true;
      }
      if (window.isMarkupReset && !window.isMarkupAttach) {
        window.defaultMarkupStyle=markup.getDefaultStyle();
        window.attachDefaultMarkupStyle=markup.getDefaultStyle();
        store.state.currentStrokeWidth=markup.getDefaultStyle()["stroke-width"];
        window.isMarkupReset=false;
      }
      if (window.isMarkupAttach) {
        store.state.currentStrokeWidth=0.025;
        
      }
      //markup.setStyle(defaultStyle);
    },
    ExitMarkup(store) {
      window.isMarkupReset=true;

      let markup=window.markup;
      if (window.isMarkupAttach) {
        markup=window.attachMarkup;
        window.attachDefaultMarkupStyle=markup.getDefaultStyle();
      }
      else{
        window.defaultMarkupStyle=markup.getDefaultStyle();
      }

      if (markup != null && markup != undefined) {
        markup.leaveEditMode();
        // hide markups (and restore Viewer tools)
        markup.hide();
        window.layerName = "";
      }
      store.commit("SET_IS_NEW_MARKUP", false);
      store.commit("SET_SHOW_MARKUPS", false);
      store.dispatch('resetAttachData');
      store.dispatch('removeAttachData');
    },
    undoMarkup() {
      let markup=window.markup;
      if (window.isMarkupAttach) {
        markup=window.attachMarkup;
      }
      markup.undo();
    },
    redoMarkup() {
      let markup=window.markup;
      if (window.isMarkupAttach) {
        markup=window.attachMarkup;
      }
      markup.redo();
    },
    async restoreStatePromise() {},
    generateSnapshot(_,data) {
      let viewer=window.NOP_VIEWER;
      let markup=window.markup;
      if (window.isMarkupAttach) {
        viewer=window.attachViewer;
        markup=window.attachMarkup;
      }
      let screenshot = new Image();
      screenshot.onload = async function () {
        // const markupCore = await viewer.loadExtension('Autodesk.Viewing.MarkupsCore');
        // markupCore.show();
   

        let canvas = document.createElement("canvas");
        canvas.width = viewer.container.clientWidth;
        canvas.height = viewer.container.clientHeight;
        let ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(screenshot, 0, 0, canvas.width, canvas.height);
        markup.renderToCanvas(
          ctx,
          function () {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = canvas.toDataURL();
            window.hrefTemp = a.href;
            if (data&&data.hasPopup) {
                store.commit("MARKUPEDITOR/SET_MARKUP_POPUP", true);
            }
            if (store.getters['MARKUPEDITOR/IS_ATTACH'] &&store.getters['MARKUPEDITOR/ATTACH_ID']) {
              store
              .dispatch('MARKUPEDITOR/saveMarkup', {
                  isAttach: store.getters['MARKUPEDITOR/IS_ATTACH'],
                  attachId: store.getters['MARKUPEDITOR/ATTACH_ID'],
                  title: store.getters['MARKUPEDITOR/ATTACH_NAME'],
                  ProjectName: store.getters['MARKUPDATA/LOADED_MODEL_NAME'],
              })
              .then(() => {
                  store.dispatch('MARKUPEDITOR/ExitMarkup');
                  store.dispatch("MARKUPDATA/setOpenMarkupImg",false);

              });
            }
            else if(store.getters['MARKUPEDITOR/ATTACH_HAS_MARKUP']){
                store.dispatch("MARKUPEDITOR/updateMarkup",{title:store.getters['MARKUPEDITOR/ATTACH_NAME']}).then(()=>{
                store.dispatch("MARKUPEDITOR/ExitMarkup")
                store.dispatch("MARKUPEDITOR/SetAttachHasMarkup",false);
                store.dispatch("MARKUPDATA/setOpenMarkupImg",false);


               })
            }
            store.state.markupHref=a.href;
            a.download = "markup.png";
            document.body.removeChild(a);
          },
          false
        );
      };
      viewer.getScreenShot(
        viewer.container.clientWidth,
        viewer.container.clientHeight,
        function (blobURL) {
          screenshot.src = blobURL;
        }
      );
    },
    SaveAndHideMarkups(store) {
      let viewer=window.NOP_VIEWER;
      let markup=window.markup;
      if (window.isMarkupAttach) {
        viewer=window.attachViewer;
        markup=window.attachMarkup;
      }
      // markups we just created as a string
      var markupsPersist = markup.generateData();
      window.markupsPersist = markupsPersist;
      // current view state (zoom, direction, sections)
      var viewerStatePersist = markup.viewer.getState();
      window.viewerStatePersist = viewerStatePersist;
  
      if (store.getters['BOUNDING_BOX']&&store.state.boundingBoxAssigned) {
       
       
          let htmlDiv = window.document.getElementById('pdfPopup');
          let htmlDiv2 = window.document.getElementById('pdfCard');
          htmlDiv2.setAttribute("style","width:40%;left:31%");

          htmlDiv.setAttribute("style","height:"+htmlDiv.offsetWidth*store.state.attacheViewerRatio+"px");
          viewer.resize()
          viewer.impl.invalidate(true, true, true);
          let box=new THREE.Box3(store.getters['BOUNDING_BOX'].min,store.getters['BOUNDING_BOX'].max);
          viewer.navigation.fitBounds(true,box,false,true);
          setTimeout(()=>{
            store.dispatch("generateSnapshot", viewer);
    
          },500)
      }
      else{
        
        store.dispatch("generateSnapshot", {viewer,hasPopup:true});
      }
    },
    SaveMarkupsViewData(store,isAttach) {
      let viewer=window.NOP_VIEWER;
      let markup=window.markup;
      if (window.isMarkupAttach) {
        viewer=window.attachViewer;
        markup=window.attachMarkup;
      }
      var markupsPersist = markup.generateData();
      window.markupsPersist = markupsPersist;
      var viewerStatePersist = markup.viewer.getState();
      window.viewerStatePersist = viewerStatePersist;
      if (store.getters['BOUNDING_BOX']&&(isAttach||store.state.boundingBoxAssigned)) {
          let htmlDiv = window.document.getElementById('pdfPopup');
          let htmlDiv2 = window.document.getElementById('pdfCard');
          htmlDiv2.setAttribute("style","width:40%;left:31%");
          htmlDiv.setAttribute("style","height:"+htmlDiv.offsetWidth*store.state.attacheViewerRatio+"px");
          viewer.resize()
          viewer.impl.invalidate(true, true, true);
          let box=new THREE.Box3(store.getters['BOUNDING_BOX'].min,store.getters['BOUNDING_BOX'].max);
          viewer.navigation.fitBounds(true,box,false,true);
          setTimeout(()=>{
            store.dispatch("generateSnapshot", viewer);
    
          },500)
         
      }
      else{
        store.dispatch("generateSnapshot", viewer);
      }
     
    },
    
    saveMarkup(_,data) {
      HttpService.post({
        url: `api/Markups`,
        data: {
          ViewerStatePersist: JSON.stringify(window.viewerStatePersist),
          MarkupsPersist: window.markupsPersist,
          Name: data.title,
          ModelId: window.loadedModel.data.guid,
          ImgPath: window.hrefTemp,
          ContainerId: window.loadedURNDetails.id,
          Urn: window.loadedURNDetails.urn,
          RefId: window.loadedURNDetails.ref,
          ProjectName:data.ProjectName,
          IsAttach:data.isAttach,
          AttachId:data.attachId,
          ForgeModelId:store.getters["MODELS/CURRENT_MODEL_DATA"].id,
          ModelName:window.loadedModel.data.name,
          addedModelsData:window.addedModels
        },
      })
        .then((response) => {
          if (response.isAttach) {
             store.dispatch("setselectedCameraAttachPath",{attachId:response.attachId,path:response.imgPath})

            // store.dispatch("MARKUPDATA/setOpenMarkupImg",false);
             
             store.dispatch("MARKUPEDITOR/setWindowNotIsAttachMarkup");
            }
            toast.success('Markup Saved Succefully');
         
            window.savedMarkups.push(response);
            store.commit("MARKUPEDITOR/SET_SAVED_MARKUPS", window.savedMarkups);
            setTimeout(() => {
              store.commit("MARKUPEDITOR/SET_MARKUPS_CHANGED");
            }, 500);
            
       
        })
        .catch((error) => {
          store.dispatch("MARKUPDATA/setOpenMarkupImg",false);
          toast.error('Error Saving Markup');

          console.error(error);
        });
    },
    updateMarkup(_, data) {
      
      HttpService.post({
        url: `api/Markups/update`,
        data: {
          ViewerStatePersist: JSON.stringify(window.viewerStatePersist),
          MarkupsPersist: window.markupsPersist,
          Name: data.title,
          ModelId: window.loadedModel.data.guid,
          ImgPath: window.hrefTemp,
          Id: window.loadedMarkup?.id,
        },
      })
        .then((response) => {
         
            let index = window.savedMarkups.findIndex((c) => c.id == response.id);
          if (index >= 0) {
            window.savedMarkups[index] = response;
            store.commit("MARKUPEDITOR/SET_MARKUPS_CHANGED");
          }

          store.commit("MARKUPEDITOR/SET_SAVED_MARKUPS", window.savedMarkups);
          if (response.isAttach) {
            store.dispatch("setselectedCameraAttachPath",{attachId:response.attachId,path:response.imgPath})
            //store.dispatch("MARKUPDATA/setOpenMarkupImg",false);
            toast.success('Markup Updated Succefully');

             store.dispatch("MARKUPEDITOR/setWindowNotIsAttachMarkup");
          }
          
        })
        .catch((error) => {
          store.dispatch("MARKUPDATA/setOpenMarkupImg",false);
          toast.error('Error Updating Markup');

          console.error(error);
        });
    },
    loadSavedMarkups(store) {
     
      HttpService.get({
        url: `api/Markups/${window.loadedModel.data.guid}`,
      })
        .then(async (response) => {
          store.commit("SET_SAVED_MARKUPS", response);
          window.savedMarkups = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async initializeMarkup(store, data) {
      let viewer=window.NOP_VIEWER;
      if (window.isMarkupAttach) {
        viewer=window.attachViewer;
      }
      window.loadedMarkup = data.data;
      window.loadedMarkupParent = data;
    
        setTimeout(async () => {
          store.dispatch("ExitMarkup").then(() => {
            window.layerName = "layerName";
          });

          var restorePromise = new Promise(function () {
            var JsonviewerStatePersist= JSON.parse(data.data.viewerStatePersist);
            JsonviewerStatePersist.objectSet[0].seedUrn=viewer.model?.getSeedUrn()
            viewer.restoreState(
              JsonviewerStatePersist
            );
            setTimeout(()=>{
              let markup=window.markup;
              if (window.isMarkupAttach) {
                markup=window.attachMarkup;
              }
                markup.show();
                // show the markups on a layer
                markup.loadMarkups(data.data.markupsPersist, "layerName");

                markup.enterEditMode("layerName");
                let type= new Autodesk.Viewing.Extensions.Markups.Core.EditModeArrow(window.markup);
                window.markup.changeEditMode(type)
                let defaultStyle=window.defaultMarkupStyle;
                markup.setStyle(defaultStyle);
                store.commit("SET_SHOW_MARKUPS", true);
                store.dispatch("setShowMarkupGlobal",true);

            },500)
          });

          await restorePromise;

    
        }, 500);
      
    },
    IntialzePdfMarkup(store,data){
      let viewer=window.NOP_VIEWER;
      if (window.isMarkupAttach) {
        viewer=window.attachViewer;
      }
      window.loadedMarkup = data;

      setTimeout(async () => {
        store.dispatch("ExitMarkup").then(() => {
          window.layerName = "layerName";
        });

        var restorePromise = new Promise(function () {
          var listener = function (event) {
            if (event.value.finalFrame) {
              viewer.removeEventListener(
                Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT,
                listener
              );
             
            }
          };

          // Wait for last render caused by camera changes
          viewer.addEventListener(
            Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT,
            listener
          );

          viewer.restoreState(
            JSON.parse(data.viewerStatePersist)
          );
          setTimeout(()=>{
            let markup=window.markup;
            if (window.isMarkupAttach) {
              markup=window.attachMarkup;
            }
              markup.show();
              // show the markups on a layer
              markup.loadMarkups(data.markupsPersist, "layerName");
              markup.enterEditMode("layerName");
              let type= new Autodesk.Viewing.Extensions.Markups.Core.EditModeArrow(window.markup);
              window.markup.changeEditMode(type)
              let defaultStyle=window.attachDefaultMarkupStyle;
              markup.setStyle(defaultStyle);

             store.commit("SET_SHOW_MARKUPS", true);

          },500)
        });

        await restorePromise;
  
      }, 500);
    },
    ShowMarkups(store) {
     
      store.commit("SET_SHOW_MARKUPS", true);
    },
    setIsNewMarkup(store){
     
      store.commit("SET_IS_NEW_MARKUP", true);
    },
    setSnapOn(){
      let markup=window.markup;
      if (window.isMarkupAttach) {
        markup=window.attachMarkup;
      }
      markup.snapper.activate()
    },
    setSnapOff(){
      let markup=window.markup;
      if (window.isMarkupAttach) {
        markup=window.attachMarkup;
      }
      markup.snapper.deactivate()
    },
    setShowMarkupGlobal(store,val)
    {
      store.commit("SET_SHOW_GLOBAL_MARKUP", val);
    },
    setWindowIsAttachMarkup(){
      window.isMarkupAttach=true;
    },
    setWindowNotIsAttachMarkup(){
      window.isMarkupAttach=false;
      // let viewer=window.NOP_VIEWER;
      // if (window.isMarkupAttach) {
      //   viewer=window.attachViewer;
      // }
     // viewer.finish();
    },
    setAttachData(store,data){
      
      store.commit("SET_ATTACH_ID", data.attachId);
      store.commit("SET_ATTACH_NAME", data.name);

      if (data.attachId && data.attachId!="") {
       store.commit("SET_IS_ATTACH", true);
      }
    },
    removeAttachData(store){
      store.commit("SET_IS_ATTACH", false);

    },
    getByAttachId(store,id){
    HttpService.get({
      url: `api/Markups/GetByAttachId/${id}`,
     })
      .then((response) => {
        if (response) {
          
          
          store.dispatch('IntialzePdfMarkup',response)
          store.commit('SET_ATTACH_HAS_MARKUP',true);
        }
        else{
          store.commit('SET_ATTACH_HAS_MARKUP',false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    },
    setBoundingBox(store,value){
      store.commit('SET_BOUNDING_BOX',value)
    },
    resetAttachData(store){
     store.state.boundingBoxAssigned=false;
     store.state.attachId=null;
     store.state.attachName=null;

    },
    OpenAllMarkup(store){
    
 
    
        setTimeout(async () => {
          store.dispatch("ExitMarkup").then(() => {
            window.layerName = "layerName";
          });

          var restorePromise = new Promise(function () {
           
              let markup=window.markup;
              if (window.isMarkupAttach) {
                markup=window.attachMarkup;
              }
                if (store.state.SavedMarkups&&store.state.SavedMarkups.length==0) {
                  toast.warning("There's no markups to show");
                  store.dispatch('setHideAll', false);
                } 
                else{
                  store.dispatch('setHideAll', true);
                  markup.show();

                }
                // show the markups on a layer
                store.state.SavedMarkups?.forEach((c,index)=>{markup.loadMarkups(c.markupsPersist, "layerName"+index);})

          
          });

          await restorePromise;

    
        }, 500);
    },
    setHideAll(store,value){
      store.state.hideAllMarkups=value;
    },
    SetAttacheViewerRatio(store,value){
      store.state.attacheViewerRatio=value;
    },
    SetAttachHasMarkup(store,value){
      store.commit('SET_ATTACH_HAS_MARKUP',value);
    },
    addMarkupModelToViewer(store,id){
      var list =  window.d2items;
      MarkupDataService.getMarkupAddedModels(id).then(
        (res)=>{
          store.commit('SET_MARKUP_ADDED_MODEL',res)
          if (!window.addedModels) {
            window.addedModels=[];
            }
          res.forEach(element => {
            let selectedItem = list.filter((c) => c.data.guid == element.modelGuid)[0];
           window.addedModels.push({guid:selectedItem.data.guid,X:element.xCordinate});

            window.NOP_VIEWER.loadDocumentNode(window.loadedForgeDocument, selectedItem,{
              keepCurrentModels: true,
              preserveView: true,  // 2D drawings
              modelSpace: true,    // 2D drawings
              applyRefPoint: true, // 3D shared coordinates
              applyScaling: 'm',   // force all models to same scale
              globalOffset: {x:0,y:0,z:0},  // force all models to origin
              placementTransform: (new THREE.Matrix4()).setPosition({x:element.xCordinate,y:0,z:0})
            });
           });
        }
      )
    }      
  },

};
