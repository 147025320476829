/* eslint-disable no-unused-vars */
const Autodesk = window.Autodesk;

class SheetContextMenu {
    constructor(viewer, onHideCallback) {
        this.viewer = viewer;
        this.contextMenu = new Autodesk.Viewing.Private.ContextMenu(this.viewer, {
            onHide: onHideCallback
        });
    }

    show(position, isSheetVisible, onShowHideCallback, onGoToSheetCallback) {
        const { x, y } = position;
        const menuItems = [];

        menuItems.push({
            icon: isSheetVisible ? "viewericon-model-sheet-eye-hide" : "viewericon-model-sheet-eye-show",
            title: isSheetVisible ? "Hide sheet" : "Show sheet",
            target: onShowHideCallback
        });

        menuItems.push({
            icon: "viewericon-model-sheet-sheet",
            title: "Go to sheet",
            target: onGoToSheetCallback
        });

        this.contextMenu.showMenu(menuItems, x, y);
        this.contextMenu.open = true;
    }

    hide() {
        if (this.contextMenu.open) {
            this.contextMenu.hide();
        }
    }

    destroy() {
        if (this.contextMenu) {
            this.contextMenu.destroy();
            this.contextMenu = null;
        }
    }
}

export default SheetContextMenu;