/* eslint-disable no-unused-vars */
import store from '@/store'
import { ManipulatedElement, ManipulationTypes } from '@/models/ForgeModels'
import EventsEmitter from '../EventsEmitter'
import ViewerToolkit from '../Viewer.Toolkit'

const THREE = window.THREE
const Autodesk = window.Autodesk
const VBR = Autodesk.Viewing.Private.VertexBufferReader;

var VBB_GT_TRIANGLE_INDEXED = 0,
    VBB_GT_LINE_SEGMENT = 1,
    VBB_GT_ARC_CIRCULAR = 2,
    VBB_GT_ARC_ELLIPTICAL = 3,
    VBB_GT_TEX_QUAD = 4,
    VBB_GT_ONE_TRIANGLE = 5;

var TAU = Math.PI * 2;


VBR.prototype.rotateObject = function (dbId, axis, angle, center) {
    if (this.useInstancing) {
        // Handling instanced rendering might be more complex and depend on your setup
        console.log(`rotateObject: ${dbId}`);
    } else {
        var i = 0;
        var ibArrayToRotate = [];
        while (i < this.ib.length) {
            var vi = this.ib[i];
            var flag = this.getVertexFlagsAt(vi);
            var geomType = (flag >> 8) & 0xff;
            var layerId = this.getLayerIndexAt(vi);
            var vpId = this.getViewportIndexAt(vi);
            var visible = this.getDbIdAt(vi) === dbId;
            // console.log(vi, flag, geomType, layerId, vpId, visible);
            if (geomType === VBB_GT_TRIANGLE_INDEXED) {
                //Triangles are encoded in three vertices (like a simple mesh) instead of 4 like everything else

                if (visible) {
                    ibArrayToRotate.push(this.ib[i]);
                    ibArrayToRotate.push(this.ib[i + 1]);
                    ibArrayToRotate.push(this.ib[i + 2]);

                    //do not translate at this moment because we have not yet known if the next primitive is
                    //one more triangle
                }

                //Advance to the next primitive
                i += 3;
            } else {
                if (ibArrayToRotate.length > 0) {
                    let unique_ibArrayToRotate = [...new Set(ibArrayToRotate)];
                    // this.rotateTriangleIndexed(unique_ibArrayToRotate, layerId, vpId, axis, angle);
                    this.rotateTriangleIndexed(
                        unique_ibArrayToRotate,
                        axis,
                        angle,
                        center,
                        layerId,
                        vpId,
                        axis,
                    );
                    ibArrayToRotate = [];
                }

                if (visible) {
                    // Handle other geometry types similarly, if they need rotation
                    switch (geomType) {
                        case VBB_GT_LINE_SEGMENT:
                            this.rotateLine(vi, layerId, vpId, angle, center);
                            break;
                        case VBB_GT_ARC_CIRCULAR:
                            //this.rotateCircleArc(vi, layerId, vpId, 1);
                            break;
                        case VBB_GT_ARC_ELLIPTICAL:
                            break;
                        //case VBB_GT_TEX_QUAD:            //TODO break;
                        //case VBB_GT_ONE_TRIANGLE:        //TODO break;
                        default:
                            break;
                    }
                }
                //Skip duplicate vertices (when not using instancing and the geometry is not a simple polytriangle,
                //each vertex is listed four times with a different vertexId flag
                i += 6;
            }
        }
    }

    // Update the source buffer of the vertex
    this.vb = this.vbf.buffer;
};

VBR.prototype.rotateTriangleIndexed = function (vertices, axis, angle, center) {
    // Rotate each vertex
    for (let i = 0; i < vertices.length; i++) {

        var baseOffset = this.stride * vertices[i];
        const point = new THREE.Vector3(this.vbf[baseOffset], this.vbf[baseOffset + 1], 0);
        const rotated = RotatePoint(point, center, angle);
        this.vbf[baseOffset] = rotated.x 
        this.vbf[baseOffset + 1] = rotated.y;
    }
}
function RotatePoint(point, around, angle)
{
    var start = new THREE.Vector3(point.x - around.x, point.y - around.y);
    point.x = Math.cos(angle) * start.x - Math.sin(angle) * start.y + around.x;
    point.y = Math.sin(angle) * start.x + Math.cos(angle) * start.y + around.y;
    return point;
}

   // Function to multiply matrix and point
   function multiplyMatrixAndPoint(matrix, point) {
       let result = [];
       for (let i = 0; i < matrix.length; i++) {
           result[i] = matrix[i][0] * point[0] + matrix[i][1] * point[1] + matrix[i][2] * point[2];
       }
       return result;
   }

//custom function: transform vertex of a line
//layer, vpId: reserved arguments
VBR.prototype.rotateLine = function (vindex, layer, vpId, angle, center) {
    var baseOffset = this.stride * vindex;
    var i = 0;
    while (i < 4) {
        const point = new THREE.Vector3(
            this.vbf[baseOffset + i * this.stride],
            this.vbf[baseOffset + i * this.stride + 1],
            0,
        );
        const rotated = RotatePoint(point, center, angle);

        this.vbf[baseOffset + i * this.stride] = rotated.x;
        this.vbf[baseOffset + i * this.stride + 1] = rotated.y;
        this.vbf[baseOffset + i * this.stride + 2] += (angle) / TAU;
        i++;
    }
};

// //custom function: transform vertex of a circle arc & circle 
// //layer, vpId: reserved arguments 
// VBR.prototype.rotateCircleArc = function (vindex, layer, vpId, trans) {

//     var baseOffset = this.stride * vindex;

//     //arc or circle is also fit by line segments.
//     // For a line segment (on desktop machine) 
//     //there will be four vertices that make a quad which corresponds to the line segment. 
//     var i = 0;
//     while (i < 4) {
//         this.vbf[baseOffset + i * this.stride] += 1;
//         this.vbf[baseOffset + i * this.stride + 1] += 1;
//         i++;
//     }
// }

export default class Rotate2DTool extends Autodesk.Viewing.ToolInterface {
    constructor(viewer, clone2dTool) {
        super();

        this.clone2dTool = clone2dTool;

        this.priority = 999;

        this.names = ['Viewing.Rotate2D.Tool'];

        this.keys = {};

        this.active = false;

        this.viewer = viewer;

        this.fullTransform = true;

        this.angleAccumulator = 0;

        this.viewer.toolController.registerTool(this);

        delete this.getNames;
        delete this.getName;
        delete this.activate;
        delete this.getPriority;
        delete this.deactivate;
        delete this.handleButtonDown;
        delete this.handleButtonUp;
        delete this.handleMouseMove;
        delete this.handleKeyDown;
        delete this.handleKeyUp;

        this.activate();
    }

    enable(enable) {
        var name = this.getName();

        if (enable) {
            this.viewer.toolController.activateTool(name);
        } else {
            this.viewer?.toolController?.deactivateTool(name);
        }
    }

    getPriority() {
        return this.priority;
    }

    getNames() {
        return this.names;
    }

    getName() {
        return this.names[0];
    }

    selectCurrent() {
        let selection = this.viewer.getAggregateSelection();

        this.viewer.clearSelection();

        if (selection.length) {
            this.viewer.select(selection[0].selection, selection[0].model);
        }
    }

    activate() {
        console.log('Rotate2D activated');
        if (!this.active) {
            this.active = true;
            this.addContextMenuOption();
        }
    }

    deactivate() {
        console.log('Rotate2D deactivate');
        if (this.active) {
            this.active = false;

            if (this.rotateControl) {
                this.rotateControl.remove();
                this.rotateControl = null;
            }

            this.removeContextMenuOption();
        }
    }

    removeContextMenuOption() {
        this.viewer.unregisterContextMenuCallback('RotateObjects2DContextMenu');
    }

    addContextMenuOption() {
        this.viewer.registerContextMenuCallback('RotateObjects2DContextMenu', (menu, status) => {
            if (status.hasSelected) {
                // menu.push({
                //     title: 'Rotate Element',
                //     target: () => this.rotateElement()
                // });
            }
            // else {
            //     if (!this.clone2dTool || !this.clone2dTool.selectedObject) return;

            //     menu.push({
            //         title: 'Rotate Element',
            //         target: () => this.rotateElement()
            //     });
            // }
        });
    }

    rotateElement() {
        console.log(`Rotate element`);

        this.angleAccumulator = 0;

        if (this.rotateControl && this.rotateControl.engaged) {
            this.rotateControl.engaged = false;

            return;
        }

        this.drawControl();
    }

    getSelectedBoundingBox() {
        const selection = this.viewer.getSelection();

        if (selection.length > 0) {
            const dbId = selection[0];

            if (!dbId) return;

            this.selection = dbId;

            return this.getModelElementBoundingBox(dbId);
        } else {
            if (!this.clone2dTool || this.clone2dTool.selectedObjects.length != 1) return;

            if (this.clone2dTool.selectedObjects.length > 1) return;

            this.selection = this.clone2dTool.selectedObjects[0];

            return this.getClonedElementBoundingBox(this.selection);
        }
    }

    getClonedElementBoundingBox() {
        if (this.clone2dTool.selectedObjects.length != 1) return;

        return new THREE.Box3().setFromObject(this.clone2dTool.selectedObjects[0]);
    }

    getModelElementBoundingBox(dbId) {
        const objectId = this.viewer.model.reverseMapDbIdFor2D(dbId);

        const it = this.viewer.model.getData().instanceTree;

        let bBox = new THREE.Box3();

        it.enumNodeFragments(
            dbId,
            (fragId) => {
                let renderProxy = this.viewer.impl.getRenderProxy(this.viewer.model, fragId);

                let vbr = new Autodesk.Viewing.Private.VertexBufferReader(renderProxy.geometry);

                let lines = [];

                vbr.enumGeomsForObject(objectId, {
                    onLineSegment: (x1, y1, x2, y2, vpId) => {
                        lines.push({ x1, y1, x2, y2 });
                    },
                });

                const points = [];

                lines.forEach((line) => {
                    points.push(new THREE.Vector3(line.x1, line.y1, 0));
                    points.push(new THREE.Vector3(line.x2, line.y2, 0));
                });

                let box = new THREE.Box3().setFromPoints(points);

                bBox.union(box);
            },
            true,
        );

        return bBox;
    }
     getModelElementBoundingBox2(viewer, model, dbId) {
        const objectId = model.reverseMapDbIdFor2D(dbId);

        const it = model.getData().instanceTree;

        let bBox = new THREE.Box3();

        it.enumNodeFragments(
            dbId,
            (fragId) => {
                let renderProxy = viewer.impl.getRenderProxy(model, fragId);

                let vbr = new Autodesk.Viewing.Private.VertexBufferReader(renderProxy.geometry);

                let lines = [];

                vbr.enumGeomsForObject(objectId, {
                    onLineSegment: (x1, y1, x2, y2) => {
                        lines.push({ x1, y1, x2, y2 });
                    },
                });

                const points = [];

                lines.forEach((line) => {
                    points.push(new THREE.Vector3(line.x1, line.y1, 0));
                    points.push(new THREE.Vector3(line.x2, line.y2, 0));
                });

                let box = new THREE.Box3().setFromPoints(points);

                bBox.union(box);
            },
            true,
        );

        return bBox;
    }
    clearSelection() {
        this.selection = null;

        if (this.rotateControl) {
            this.rotateControl.remove();

            this.rotateControl = null;

            this.viewer.impl.sceneUpdated(true);
        }
    }

    drawControl() {
        var bBox = this.getSelectedBoundingBox();

        if (!bBox) return;

        // ViewerToolkit.drawBoundingBox(this.viewer, bBox)

        this.center = new THREE.Vector3(
            (bBox.min.x + bBox.max.x) / 2,
            (bBox.min.y + bBox.max.y) / 2,
            (bBox.min.z + bBox.max.z) / 2,
        );

        var size =
            Math.max(bBox.max.x - bBox.min.x, bBox.max.y - bBox.min.y, bBox.max.z - bBox.min.z) *
            0.9;

        if (this.rotateControl) {
            this.rotateControl.remove();
        }

        this.rotateControl = new RotateControl(this.viewer, this.center, size);

        this.rotateControl.on('transform.rotate', ({ axis, angle }) => {
            if (this.selection instanceof THREE.Line) {
                this.rotateClonedElement(this.selection, axis, angle);
            } else {
                this.rotateModelElement(this.selection, axis, (angle * Math.PI) / 180, this.center);
            }

            this.viewer.impl.sceneUpdated(true);
        });
    }

    handleButtonDown(event, button) {
        if (this.rotateControl) {
            if (this.rotateControl.onPointerDown(event)) {
                return true;
            }
        }

        if (button === 0 && this.keys.Control) {
            this.isDragging = true;

            this.mousePos = {
                x: event.clientX,
                y: event.clientY,
            };

            return true;
        }

        return false;
    }

    handleButtonUp(event, button) {
        if (this.rotateControl) {
            this.rotateControl.onPointerUp(event);
            this.fixSelectedElementRotation();
        }

        if (button === 0) {
            this.isDragging = false;
        }

        return false;
    }

    handleMouseMove(event) {
        if (this.rotateControl) {
            this.rotateControl.onPointerHover(event);
        }

        if (this.isDragging) {
            if (this.selection) {
                var offset = {
                    x: this.mousePos.x - event.clientX,
                    y: event.clientY - this.mousePos.y,
                };

                this.mousePos = {
                    x: event.clientX,
                    y: event.clientY,
                };

                var angle = Math.sqrt(offset.x * offset.x + offset.y * offset.y);

                var sidewaysDirection = new THREE.Vector3();
                var moveDirection = new THREE.Vector3();
                var eyeDirection = new THREE.Vector3();
                var upDirection = new THREE.Vector3();
                var camera = this.viewer.getCamera();
                var axis = new THREE.Vector3();
                var eye = new THREE.Vector3();

                eye.copy(camera.position).sub(camera.target);

                eyeDirection.copy(eye).normalize();

                upDirection.copy(camera.up).normalize();

                sidewaysDirection.crossVectors(upDirection, eyeDirection).normalize();

                upDirection.setLength(offset.y);

                sidewaysDirection.setLength(offset.x);

                moveDirection.copy(upDirection.add(sidewaysDirection));

                axis.crossVectors(moveDirection, eye).normalize();

                if (this.selection instanceof THREE.Line) {
                    this.rotateClonedElement(this.selection, axis, angle);
                } else {
                    this.rotateModelElement(
                        this.selection,
                        axis,
                        (angle * Math.PI) / 180,
                        this.center,
                    );
                }

                this.viewer.impl.sceneUpdated(true);
            }

            return true;
        }

        return false;
    }

    handleKeyDown(event, keyCode) {
        this.keys[event.key] = true;

        return false;
    }

    handleKeyUp(event, keyCode) {
        this.keys[event.key] = false;

        return false;
    }

    rotateModelElement(dbId, axis, angle, center) {
        const objectId = this.viewer.model.reverseMapDbIdFor2D(dbId);
        const it = this.viewer.model.getData().instanceTree;

        it.enumNodeFragments(dbId, (fragId) => {
            let renderProxy = this.viewer.impl.getRenderProxy(this.viewer.model, fragId);

            let vbr = new Autodesk.Viewing.Private.VertexBufferReader(renderProxy.geometry);


             vbr.rotateObject(objectId, axis, angle, center);

             renderProxy.geometry.vbNeedsUpdate = true;

             this.viewer.impl.sceneUpdated();

            this.viewer.impl.invalidate(true);
        });
    }

    rotateClonedElement(mesh, axis, angle, ignoreUpdate = false, test = false) {
        const geometry = mesh.geometry;

        geometry.computeBoundingBox();

        const center = new THREE.Vector3();
        geometry.boundingBox.getCenter(center);

        const matrix = new THREE.Matrix4();
        const negatedCenter = center.clone().negate();

        matrix.makeTranslation(negatedCenter.x, negatedCenter.y, negatedCenter.z);
        geometry.applyMatrix4(matrix);

        const rotationMatrix = new THREE.Matrix4();
        rotationMatrix.makeRotationZ(angle);
        geometry.applyMatrix4(rotationMatrix);

        const inverseMatrix = new THREE.Matrix4();
        inverseMatrix.makeTranslation(center.x, center.y, center.z);
        geometry.applyMatrix4(inverseMatrix);

        geometry.attributes.position.needsUpdate = true;

        // mesh.updateMatrixWorld(true);

        if (!ignoreUpdate) {
            let manipulatedElement = store.state.manipulatedElements.find(
                (element) => element.dbId == mesh.name,
            );

            if (!manipulatedElement) return;

            if (!manipulatedElement?.rotation) manipulatedElement.rotation = { x: 0, y: 0, z: 0 };

            manipulatedElement.rotation.z += angle;

            while (manipulatedElement.rotation.z < 0) {
                manipulatedElement.rotation.z += TAU;
            }

            while (manipulatedElement.rotation.z > TAU) {
                manipulatedElement.rotation.z -= TAU;
            }
        }

        this.viewer.impl.sceneUpdated(true);
        this.viewer.impl.invalidate(true);
    }

    rotateSelectedClonedElement(angle, overrideSettings = false) {
        if (!this.selection || !(this.selection instanceof THREE.Line)) return;

        this.rotateClonedElement(this.selection, new THREE.Vector3(0, 0, 1), angle);

        if (overrideSettings) {
            let tool = this.viewer.toolController.getTool('Viewing.Clone2D.Tool');

            tool?._update();
        } else {
            this.fixSelectedElementRotation();
        }
    }

    fixSelectedElementRotation() {
        if (!this.selection || !(this.selection instanceof THREE.Line)) return;

        let manipulatedElement = store.state.manipulatedElements.find(
            (element) => element.dbId == this.selection.name,
        );

        let radAngle = manipulatedElement.rotation.z;

        if (radAngle == 0) return;

        let rotationAngle = store.getters['SETTINGS/MODEL_SETTINGS'].rotationSnappingDegree;

        let angleInDegrees = radAngle * (180 / Math.PI);

        let roundedAngle = Math.round(angleInDegrees / rotationAngle) * rotationAngle;

        let angleInRadiansFixed = roundedAngle * (Math.PI / 180);

        let angle = angleInRadiansFixed - radAngle;

        this.rotateClonedElement(this.selection, new THREE.Vector3(0, 0, 1), angle, false, true);

        let tool = this.viewer.toolController.getTool('Viewing.Clone2D.Tool');

        tool?._update();
    }

    async rotateFragments(model, fragIdsArray, axis, angle, center) {
        var quaternion = new THREE.Quaternion();

        quaternion.setFromAxisAngle(axis, angle);

        fragIdsArray.forEach((fragId, idx) => {
            var fragProxy = this.viewer.impl.getFragmentProxy(model, fragId);

            fragProxy.getAnimTransform();

            var position = new THREE.Vector3(
                fragProxy.position.x - center.x,
                fragProxy.position.y - center.y,
                fragProxy.position.z - center.z,
            );

            position.applyQuaternion(quaternion);

            position.add(center);

            fragProxy.position = position;

            fragProxy.quaternion.multiplyQuaternions(quaternion, fragProxy.quaternion);

            if (idx === 0) {
                var euler = new THREE.Euler();

                euler.setFromQuaternion(fragProxy.quaternion, 0);

                let familyInstance = store.state.familiesInstances.find(
                    (family) => family.loadedModelId == model.id,
                );

                if (familyInstance)
                    familyInstance.rotation = { x: euler.x, y: euler.y, z: euler.z };

                this.emit('transform.rotate', {
                    rotation: euler,
                    model,
                });
            }

            fragProxy.updateAnimTransform();
        });

        let familyInstance = store.state.familiesInstances.find(
            (family) => family.loadedModelId == model.id,
        );

        if (familyInstance) return;

        let isSceneBuilder = model.myData?.isSceneBuilder;

        if (isSceneBuilder) return;

        let dbId = this.selection.dbIdArray[0];
        let manipulatedElement = store.getters.MANIPULATED_ELEMENTS.find(
            (element) => element.dbId == dbId,
        );

        if (manipulatedElement) {
            let zRotation = (manipulatedElement.rotation?.z ?? 0) + angle;
            manipulatedElement.rotation = { x: 0, y: 0, z: zRotation };
        } else {
            const _viewGuids = await ViewerToolkit.getViewabelIdsOfElement(
                this.viewer.model,
                dbId,
                store,
            );
            this.viewer.getProperties(dbId, (props) => {
                manipulatedElement = new ManipulatedElement();
                manipulatedElement.viewType = '2d';
                manipulatedElement.viewId = window.NOP_VIEWER.model.getDocumentNode()?.data?.guid;
                manipulatedElement.manipulationType = ManipulationTypes.MANIPULATE_EXISTING;
                manipulatedElement.name = props.name;
                manipulatedElement.modelId = this.viewer.model.getData().urn;
                manipulatedElement.dbId = dbId;
                manipulatedElement.originalDbId = dbId;
                manipulatedElement.originalExternalId = props.externalId;
                manipulatedElement.originalPosition = null;
                manipulatedElement.position = null;
                manipulatedElement.rotation = { x: 0, y: 0, z: angle };
                manipulatedElement.viewGuids = _viewGuids;
                store.commit('ADD_MANIPULATED_ELEMENT', manipulatedElement);
            });
        }
    }

    geWorldBoundingBox(fragIds, fragList) {
        var fragbBox = new THREE.Box3();
        var nodebBox = new THREE.Box3();

        fragIds.forEach((fragId) => {
            fragList.getWorldBounds(fragId, fragbBox);
            nodebBox.union(fragbBox);
        });

        return nodebBox;
    }
}

class RotateControl extends EventsEmitter {

	constructor(viewer, center, size) {

		super()

		this.engaged = false

		this.overlayScene = 'rotateControlScene'
		this.domElement = viewer.impl.canvas
		this.camera = viewer.impl.camera
		this.viewer = viewer
		this.center = center
		this.size = size
		this.gizmos = []

		this.viewer.impl.createOverlayScene(
			this.overlayScene)

		// this.createAxis(
		// 	center, new THREE.Vector3(1, 0, 0),
		// 	size * 0.85, 0xFF0000)

		// this.createAxis(
		// 	center, new THREE.Vector3(0, 1, 0),
		// 	size * 0.85, 0x00FF00)

		// this.createAxis(
		// 	center, new THREE.Vector3(0, 0, 1),
		// 	size * 0.85, 0x0000FF)

		// World UP = Y

        this.gizmos.push(this.createGizmo(
            center,
            new THREE.Euler(0, 0, 0),
            size * 0.03,
            size * 0.8, 0x00F0F1,
            Math.PI * 2,
            new THREE.Vector3(0, 0, 1)));

		//this.createQuarterCircleGizmoWithArrow(
		//	center,
		//	new THREE.Euler(0, 0, - Math.PI / 2),
		//	size * 0.02,
		//	size, 0x00FFFF,
		//	'cw')
            

		// this.createQuarterCircleGizmoWithArrow(
		// 	center,
		// 	new THREE.Euler(0, 0, 0),
		// 	size * 0.02,
		// 	size, 0x00FFFF,
		// 	'acw')
		
		this.picker = this.createSphere(
			size * 0.02)

		var material = new THREE.LineBasicMaterial({
			color: 0xFFFF00,
			linewidth: 1,
			depthTest: false,
			depthWrite: false,
			transparent: true
		})

		this.angleLine =
			this.createLine(
				this.center,
				this.center,
				material)

		viewer.impl.sceneUpdated(true)
	}
    
	createLine(start, end, material) {

		var geometry = new THREE.Geometry()

		geometry.vertices.push(new THREE.Vector3(
			start.x, start.y, start.z))

		geometry.vertices.push(new THREE.Vector3(
			end.x, end.y, end.z))

		var line = new THREE.Line(geometry, material)

		this.viewer.impl.addOverlay(
			this.overlayScene, line)

		return line
	}
    
	createCone(start, dir, length, coneRadius, coneHeight, material) {
		dir.normalize();
	
		var end = new THREE.Vector3(
			start.x + dir.x * length,
			start.y + dir.y * length,
			start.z + dir.z * length
		);
	
		var orientation = new THREE.Matrix4();
	
		orientation.lookAt(
			start,
			end,
			new THREE.Object3D().up
		);
	
		var matrix = new THREE.Matrix4();
	
		matrix.set(
			1, 0, 0, 0,
			0, 0, 1, 0,
			0, -1, 0, 0,
			0, 0, 0, 1
		);
	
		orientation.multiply(matrix);
	
		var geometry = new THREE.CylinderGeometry( 0, coneRadius, coneHeight, 128);
	
		var cone = new THREE.Mesh(geometry, material);
	
		cone.applyMatrix4(orientation);
	
		cone.position.x = start.x + dir.x * length / 2;
		cone.position.y = start.y + dir.y * length / 2;
		cone.position.z = start.z + dir.z * length / 2;
	
		this.viewer.impl.addOverlay(this.overlayScene, cone);
	
		return cone;
	}

	createAxis(start, dir, size, color) {

		var end = {
			x: start.x + dir.x * size,
			y: start.y + dir.y * size,
			z: start.z + dir.z * size
		}

		var material = new THREE.LineBasicMaterial({
			color: color,
			linewidth: 3,
			depthTest: false,
			depthWrite: false,
			transparent: true
		})

		this.createLine(
			start, end, material)

		this.createCone(
			end, dir, size * 0.1, material)
	}

	createGizmo(center, euler, size, radius, color, range, axis) {

		var material = new GizmoMaterial({
			color: color
		})

		var subMaterial = new GizmoMaterial({
			color: color
		})

		var torusGizmo = new THREE.Mesh(
			new THREE.TorusGeometry(
				radius, size, 64, 64, range),
			material)

		var subTorus = new THREE.Mesh(
			new THREE.TorusGeometry(
				radius, size, 64, 64, 2 * Math.PI),
			subMaterial)

		subTorus.material.highlight(true)

		var transform = new THREE.Matrix4()

		var q = new THREE.Quaternion()

		q.setFromEuler(euler)

		var s = new THREE.Vector3(1, 1, 1)

		transform.compose(center, q, s)

		torusGizmo.applyMatrix(transform)

		subTorus.applyMatrix(transform)

		var plane = this.createBox(
			this.size * 100,
			this.size * 100,
			0.01)

		plane.applyMatrix(transform)

		subTorus.visible = false

		this.viewer.impl.addOverlay(
			this.overlayScene, torusGizmo)

		this.viewer.impl.addOverlay(
			this.overlayScene, subTorus)

		torusGizmo.subGizmo = subTorus
		torusGizmo.plane = plane
		torusGizmo.axis = axis

		return torusGizmo
	}

	createQuarterCircleGizmoWithArrow(center, euler, size, radius, color, direction) {
		var scene = this.overlayScene; // Assuming this variable is correctly initialized within your context
		var viewer = this.viewer; // Make sure this is your Forge Viewer instance
	
		// Material for both the torus and the cone (arrow)
		var material = new THREE.MeshBasicMaterial({
			color: color,
			side: THREE.DoubleSide
		});
	
		// 1/4 circle torus geometry
		var quarterTorus = new THREE.Mesh(new THREE.TorusGeometry(radius, size, 64, 64, Math.PI / 4), material);
	
		// Assuming center is a THREE.Vector3
		quarterTorus.position.set(center.x, center.y, center.z);
		quarterTorus.rotation.set(euler.x, euler.y, euler.z);
	
		// Arrow direction
		var arrowDir = new THREE.Vector3(-1, 0, 0);
		if (direction.toLowerCase() === 'acw') {
			arrowDir = new THREE.Vector3(1, 0, 0);
		}
	
		// Correctly position and orient the arrow based on the quarter torus
		var arrowStart = new THREE.Vector3(center.x + radius * Math.cos(euler.z), center.y + radius * Math.sin(euler.z), center.z);
		if (direction.toLowerCase() === 'acw') {
			arrowStart.set(center.x + radius * Math.cos(euler.z + Math.PI / 2), center.y + radius * Math.sin(euler.z + Math.PI / 2), center.z);
		}
	
		// Adjust the length and direction of the arrow
		var arrowLength = radius / 3;

		var coneRadius = arrowLength / 5; // reduce arrow head size
		var coneHeight = arrowLength / 3; // keep arrow head height same as arrow length
	
		// Use the createCone function to add the arrow
		this.createCone(arrowStart, arrowDir, arrowLength, coneRadius, coneHeight, material); // pass coneRadius and coneHeight
	
		// Add the quarter torus to the overlay scene
		viewer.impl.addOverlay(scene, quarterTorus);

		return quarterTorus;
	}
	
	

	createBox(w, h, d) {

		var material = new GizmoMaterial({
			color: 0x000000
		})

		var geometry = new THREE.BoxGeometry(w, h, d)

		var box = new THREE.Mesh(
			geometry, material)

		box.visible = false

		this.viewer.impl.addOverlay(
			this.overlayScene, box)

		return box
	}

	createSphere(radius) {

		var material = new GizmoMaterial({
			color: 0xFFFF00
		})

		var geometry = new THREE.SphereGeometry(
			radius, 32, 32)

		var sphere = new THREE.Mesh(
			geometry, material)

		sphere.visible = false

		this.viewer.impl.addOverlay(
			this.overlayScene, sphere)

		return sphere
	}

	pointerToRaycaster(pointer) {

		var pointerVector = new THREE.Vector3()
		var pointerDir = new THREE.Vector3()
		var ray = new THREE.Raycaster()

		var rect = this.domElement.getBoundingClientRect()

		var x = ((pointer.clientX - rect.left) / rect.width) * 2 - 1
		var y = -((pointer.clientY - rect.top) / rect.height) * 2 + 1

		if (this.camera.isPerspective) {

			pointerVector.set(x, y, 0.5)

			pointerVector.unproject(this.camera)

			ray.set(this.camera.position,
				pointerVector.sub(
					this.camera.position).normalize())

		} else {

			pointerVector.set(x, y, -1)

			pointerVector.unproject(this.camera)

			pointerDir.set(0, 0, -1)

			ray.set(pointerVector,
				pointerDir.transformDirection(
					this.camera.matrixWorld))
		}

		return ray
	}

	onPointerDown(event) {

		var pointer = event.pointers ? event.pointers[0] : event

		if (pointer.button === 0) {

			var ray = this.pointerToRaycaster(pointer)

			var intersectResults = ray.intersectObjects(
				this.gizmos, true)

			if (intersectResults.length) {

				this.gizmos.forEach((gizmo) => {

					gizmo.visible = false
				})

				this.selectedGizmo = intersectResults[0].object

				this.selectedGizmo.subGizmo.visible = true

				this.picker.position.copy(
					intersectResults[0].point)

				this.angleLine.geometry.vertices[1].copy(
					intersectResults[0].point)

				this.lastDir = intersectResults[0].point.sub(
					this.center).normalize()

				this.angleLine.geometry.verticesNeedUpdate = true

				this.angleLine.visible = true

				this.picker.visible = true

			} else {

				this.picker.visible = false
			}

			this.engaged = this.picker.visible

			this.viewer.impl.sceneUpdated(true)
		}

		return this.picker.visible
	}

	onPointerHover(event) {

		var pointer = event.pointers ? event.pointers[0] : event

		if (this.engaged) {

			var ray = this.pointerToRaycaster(pointer)

			var intersectResults = ray.intersectObjects(
				[this.selectedGizmo.plane], true)

			if (intersectResults.length) {

				var intersectPoint = intersectResults[0].point

				var dir = intersectPoint.sub(
					this.center).normalize()

				var cross = new THREE.Vector3()

				cross.crossVectors(this.lastDir, dir)

				var sign = Math.sign(
					cross.dot(this.selectedGizmo.axis))


				this.emit('transform.rotate', {
					angle: sign * dir.angleTo(this.lastDir),
					axis: this.selectedGizmo.axis
				})

				this.lastDir = dir

				var pickerPoint = new THREE.Vector3(
					this.center.x + dir.x * this.size * 0.8,
					this.center.y + dir.y * this.size * 0.8,
					this.center.z + dir.z * this.size * 0.8)

				this.picker.position.copy(
					pickerPoint)

				this.angleLine.geometry.vertices[1].copy(
					pickerPoint)
			}

			this.angleLine.visible = true

			this.angleLine.geometry.verticesNeedUpdate = true

		} else {

			this.angleLine.visible = false

			let ray = this.pointerToRaycaster(pointer)

			let intersectResults = ray.intersectObjects(
				this.gizmos, true)

			if (intersectResults.length) {

				this.picker.position.set(
					intersectResults[0].point.x,
					intersectResults[0].point.y,
					intersectResults[0].point.z)

				this.picker.visible = true

			} else {

				this.picker.visible = false
			}
		}

		this.viewer.impl.sceneUpdated(true)
	}

	onPointerUp(event) {

		this.angleLine.visible = false

		this.picker.visible = false

		this.gizmos.forEach((gizmo) => {

			gizmo.visible = true
			gizmo.subGizmo.visible = false
		})

		this.viewer.impl.sceneUpdated(true)

		setTimeout(() => {
			this.engaged = false
		}, 100)
	}
    
	normalize(screenPoint) {

		var viewport = this.viewer.navigation.getScreenViewport()

		var n = {
			x: (screenPoint.x - viewport.left) / viewport.width,
			y: (screenPoint.y - viewport.top) / viewport.height
		}

		return n
	}

	projectOntoPlane(worldPoint, normal) {

		var dist = normal.dot(worldPoint)

		return new THREE.Vector3(
			worldPoint.x - dist * normal.x,
			worldPoint.y - dist * normal.y,
			worldPoint.z - dist * normal.z)
	}

	remove() {

		this.viewer.impl.removeOverlayScene(
			this.overlayScene)
	}
}

class GizmoMaterial extends THREE.MeshBasicMaterial {

	constructor(parameters) {

		super()

		this.setValues(parameters)

		this.colorInit = this.color.clone()
		this.opacityInit = this.opacity
		this.side = THREE.FrontSide
		this.depthWrite = false
		this.transparent = true
		this.depthTest = false
	}
    
	highlight(highlighted) {

		if (highlighted) {

			this.color.setRGB(1, 230 / 255, 3 / 255)
			this.opacity = 1

		} else {

			this.color.copy(this.colorInit)
			this.opacity = this.opacityInit
		}
	}
}
