import Vue3Toasity, { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';


export default {
  install(app) {
    app.use(Vue3Toasity, {
      position: toast.POSITION.TOP_RIGHT,
      theme: toast.THEME.COLORED,
      timeout: 500,
      containerClassName: 'toast-container',
      containerSelector: '.toast-container',
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: true,
      hideProgressBar: false,
      closeOnClick: true,
      closeOnEscape: true,
      pauseOnHover: true,
      limit: 5,
      icon: true,
      transition: toast.TRANSITIONS.SLIDE,
      maxToasts: 5,
      allowHtml: true
    });
    app.config.globalProperties.$toast = toast;
  }
};