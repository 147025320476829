/* eslint-disable no-unused-vars */
import HttpService from "@Utils/httpService";
import { toast } from "vue3-toastify";
import store from "..";
import CameraAttachement from '@/components/CameraAttachement.vue';
import { createApp, h } from 'vue';
import vuetify from '../../plugins/vuetify'

export const CameraAttachementStore = {
    // namespaced: true,
    state() {
        return {
            addedCameraDBID: null,
            addedCameraLocation: null,

            addCameraModeOn: false,
            moveCameraModeOn: false,
            // isCameraAttachementPanelOpened: false,
            showCameraAttachement: false,
            showInputField: false,
            showRenameInputField: false,
            viewId: null,
            allCameraElements: [],
            allCameraElementsInModel: [],
            selectedCameraElement: null,
            cameraIndex: 0,
        };
    },
    getters: {
        Added_Camera_DBID(state) {
            return state.addedCameraDBID;
        },
        Added_Camera_Location(state) {
            return state.addedCameraLocation;
        },
        Add_Camera_Mode_On(state) {
            return state.addCameraModeOn;
        },
        Show_Input_Field(state) {
            return state.showInputField;
        },
        Show_Rename_Input_Field(state) {
            return state.showRenameInputField;
        },
        Is_Move_Camera_Mode_On(state) {
            return state.moveCameraModeOn;
        },
        Show_Camera_Attachement(state) {
            return state.showCameraAttachement;
        },
        ALL_Camera_Elements(state) {
            return state.allCameraElements;
        },
        ALL_Camera_Elements_IN_Model(state) {
            return state.allCameraElementsInModel;
        },
        SELECTED_CAMERA_ELEMENT(state) {
            return state.selectedCameraElement;
        },
        View_Id(state) {
            return state.viewId;
        },
        IS_CameraAttachement_PANEL_OPENED(state) {
            return state.isCameraAttachementPanelOpened;
        },
        CAMERA_INDEX(state) {
            return state.cameraIndex;
        },
    },
    mutations: {
        SET_Added_Camera_DBID(state, dbid) {
            state.addedCameraDBID = dbid;
        },
        SET_Added_Camera_Location(state, location) {
            state.addedCameraLocation = location;
        },
        SET_Show_Camera_Attachement(state, isShown) {
            state.showCameraAttachement = isShown;
        },
        SET_Show_InputField(state, isShown) {
            state.showInputField = isShown;
        },
        SET_Show_Rename_InputField(state, isShown) {
            state.showRenameInputField = isShown;
        },
        SET_Add_Camera_Mode_On(state, isOpened) {
            state.addCameraModeOn = isOpened;
        },
        TOGGLE_Add_Camera_Mode_On(state) {
            state.addCameraModeOn = !state.addCameraModeOn;
        },
        TOGGLE_Move_Camera_Mode_On(state) {
            state.moveCameraModeOn = !state.moveCameraModeOn;
        },
        SET_Move_Camera_Mode_On(state, moveCameraModeOn) {
            state.moveCameraModeOn = moveCameraModeOn;
        },
        SET_ALL_Camera_Elements(state, allCameraElements) {
            state.allCameraElements = allCameraElements;
        },
        SET_ALL_Camera_Elements_IN_Model(state, allCameraElementsInModel) {
            state.allCameraElementsInModel = allCameraElementsInModel;
        },
        SET_SELECTED_CAMERA_ELEMENT(state, selectedCameraElement) {
            state.selectedCameraElement = selectedCameraElement;
        },
        SET_View_Id(state, viewId) {
            state.viewId = viewId;
        },
        SET_IS_CameraAttachement_PANEL_OPENED(state, isCameraAttachementPanelOpened) {
            state.isCameraAttachementPanelOpened = !state.isCameraAttachementPanelOpened;
        },
        SET_CAMERA_INDEX(state, val) {
            state.cameraIndex = val;
        },
    },

    actions: {
        GetAllCameraElements({ commit, dispatch, getters }) {
            const modelId = getters['MODEL_GUID'];
            const viewId = getters['View_Id'];

            return new Promise((resolve, reject) => {
                HttpService.get({
                    url: `api/cameraElements/${modelId}/${viewId}`,
                })
                    .then((response) => {
                        const items = JSON.parse(JSON.stringify(response));
                        commit('SET_ALL_Camera_Elements', items);
                        resolve(items);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        GetAllCameraElementsInTheModel({ commit, dispatch, getters }) {
            const modelId = getters['MODEL_GUID'];

            return new Promise((resolve, reject) => {
                HttpService.get({
                    url: `api/cameraElements/${modelId}`,
                })
                    .then((response) => {
                        const items = JSON.parse(JSON.stringify(response));
                        commit('SET_ALL_Camera_Elements_IN_Model', items);
                        resolve(items);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        GetCameraElementById({ commit, dispatch, getters }) {
            const modelId = getters['MODEL_GUID'];
            const viewId = getters['View_Id'];
            let allElements = getters['ALL_Camera_Elements'];
            let allElementsInModel = getters['ALL_Camera_Elements_IN_Model'];
            const selectedElement = getters['SELECTED_CAMERA_ELEMENT'];

            return new Promise((resolve, reject) => {
                HttpService.get({
                    url: `api/cameraElements/${modelId}/${viewId}/${selectedElement.id}`,
                })
                    .then((response) => {
                        const item = JSON.parse(JSON.stringify(response));

                        allElements = allElements.filter((x) => x.id != selectedElement.id);
                        allElements.push(item);

                        allElementsInModel = allElementsInModel.filter(
                            (x) => x.id != selectedElement.id,
                        );
                        allElementsInModel.push(item);

                        commit('SET_ALL_Camera_Elements_IN_Model', allElementsInModel);
                        commit('SET_ALL_Camera_Elements', allElements);
                        commit('SET_SELECTED_CAMERA_ELEMENT', item);

                        resolve(item);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        DeleteCameraElementById({ commit, dispatch, getters }, id) {
            const modelId = getters['MODEL_GUID'];
            // const viewId = getters['View_Id']
            let allElements = getters['ALL_Camera_Elements'];
            let allElementsInModel = getters['ALL_Camera_Elements_IN_Model'];

            return new Promise((resolve, reject) => {
                HttpService.delete({
                    url: `api/cameraElements/${modelId}/${id}`,
                })
                    .then((response) => {
                        allElements = allElements.filter((x) => x.id != id);
                        allElementsInModel = allElementsInModel.filter((x) => x.id != id);

                        commit('SET_ALL_Camera_Elements', allElements);
                        commit('SET_ALL_Camera_Elements_IN_Model', allElementsInModel);

                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        DeleteCameraAttachementById({ commit, dispatch, getters }, attachementId) {
            const modelId = getters['MODEL_GUID'];
            // let allElements = getters['ALL_Camera_Elements']
            // let allElementsInModel = getters['ALL_Camera_Elements_IN_Model']

            return new Promise((resolve, reject) => {
                HttpService.delete({
                    url: `api/cameraElements/${modelId}/${attachementId}/deleteAttachement`,
                })
                    .then((response) => {
                        dispatch('GetCameraElementById').then((x) => {
                            resolve();
                        });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        AddCameraElement({ commit, dispatch, getters }, { dbId, location, Name }) {
            const modelId = getters['MODEL_GUID'];
            const viewId = getters['View_Id'];

            return new Promise((resolve, reject) => {
                HttpService.post({
                    url: `api/cameraElements`,
                    data: {
                        modelId: modelId,
                        viewId: viewId,
                        dbId: dbId,
                        position: location,
                        cameraName: Name,
                    },
                })
                    .then((response) => {
                        let allElements = getters['ALL_Camera_Elements'];
                        let allElementsInModel = getters['ALL_Camera_Elements_IN_Model'];

                        allElements.push(response);
                        allElementsInModel.push(response);
                        commit('SET_ALL_Camera_Elements', allElements);
                        commit('SET_ALL_Camera_Elements_IN_Model', allElementsInModel);

                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        ChangeCameraLocation({ commit, dispatch, getters }, { Id, location }) {
            const modelId = getters['MODEL_GUID'];
            const viewId = getters['View_Id'];

            return new Promise((resolve, reject) => {
                HttpService.put({
                    url: `api/cameraElements/${Id}/changeLocation/`,
                    data: {
                        // modelId: modelId,
                        // Id: Id,
                        position: location,
                    },
                })
                    .then((response) => {
                        dispatch('GetCameraElementById').then((x) => {
                            resolve();
                        });

                        // let allElements = getters['ALL_Camera_Elements']
                        // let allElementsInModel = getters['ALL_Camera_Elements_IN_Model']

                        // allElements.push(response)
                        // allElementsInModel.push(response)
                        // commit('SET_ALL_Camera_Elements', allElements)
                        // commit('SET_ALL_Camera_Elements_IN_Model', allElementsInModel)
                        // console.log(response)
                        // resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        ChangeCameraName({ commit, dispatch, getters }, { Id, name }) {
            return new Promise((resolve, reject) => {
                HttpService.put({
                    url: `api/cameraElements/${Id}/changeCameraName/`,
                    data: {
                        // modelId: modelId,
                        // Id: Id,
                        name: name,
                    },
                })
                    .then((response) => {
                        dispatch('GetCameraElementById').then((x) => {
                            resolve();
                        });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        uploadFile({ commit, dispatch, getters }, { files, _onUploadProgress }) {

            let formData = new FormData();

            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i]);
            }

            formData.append('ModelId', getters['MODEL_GUID']);
            formData.append('ViewId', getters['View_Id']);
            formData.append('DbId', getters['SELECTED_CAMERA_ELEMENT'].dbId);

            return new Promise((resolve, reject) => {
                HttpService.put({
                    url: `api/cameraElements`,
                    data: formData,
                    headers: {
                        'Content-Type': false,
                        processData: false,
                    },
                    onUploadProgress: (progressEvent) => _onUploadProgress(progressEvent),
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        setselectedCameraAttachPath(store, data) {
            store.state.selectedCameraElement.cameraAttachements.filter(
                (c) => c.id == data.attachId,
            )[0].path = data.path;
        },
        setCameraIndex(store, val) {
            store.commit('SET_CAMERA_INDEX', val);
        },
    },
};