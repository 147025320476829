/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import store from '../../store';
//import ViewerToolkit from '../Viewer.Toolkit';
import cameraImg from '@/assets/images/camera.png';
import cameraImg2 from '@/assets/images/cameraIcon.png';
import moveImg from '@/assets/images/move.png';
const Autodesk = window.Autodesk;
import MoveCameraTool from './MoveCameraTool'
import AddCameraTool from './AddCameraTool'

const MoveCameraToolName = 'move-camera-tool';
const AddCameraToolName = 'add-camera-tool';

class ShowCamerasExtension extends Autodesk.Viewing.Extension {
    constructor(viewer, options) {
        super(viewer, options);

        this.addCameraButton = null;
        this.moveCameraButton = null;

        this.viewer = viewer;
        
        this._onSpriteClicked = this._onSpriteClicked.bind(this);
        this._onSpriteClickedOut = this._onSpriteClickedOut.bind(this);
    }

    get menuId() {
        return 'Show Cameras';
    }


 
    

     load() {
     
        this.viewer.loadExtension("Autodesk.DataVisualization").then(x =>{
        
            this.viewer.addEventListener(Autodesk.DataVisualization.Core.MOUSE_CLICK, this._onSpriteClicked);
            this.viewer.addEventListener(Autodesk.DataVisualization.Core.MOUSE_CLICK_OUT, this._onSpriteClickedOut);
                this._dataVizExt = x
                this.moveCameraTool = new MoveCameraTool(this.viewer, this._dataVizExt)
                this.viewer.toolController.registerTool(this.moveCameraTool)
                
                this.addCameraTool = new AddCameraTool(this.viewer, this._dataVizExt)
                this.viewer.toolController.registerTool(this.addCameraTool)
            });


        return true;
    }

    unload() {

        if (this.addCameraButton) {
            this.removeToolbarButton(this.addCameraButton);
            this.addCameraButton = null;
        }
        if (this.moveCameraButton) {
            this.removeToolbarButton(this.moveCameraButton);
            this.moveCameraButton = null;
        }

        this.viewer.removeEventListener(Autodesk.DataVisualization.Core.MOUSE_CLICK, this._onSpriteClicked);
            this.viewer.removeEventListener(
                Autodesk.DataVisualization.Core.MOUSE_CLICK_OUT,
                this._onSpriteClickedOut,
            );

        this.viewer.toolController.deactivateTool(AddCameraToolName);
        this.viewer.toolController.deactivateTool(MoveCameraToolName);
        
        return true;
    }

    _onSpriteClicked(ev) {
        if (this.moveCameraTool.active || ev.dbId == 0) return;
        console.log('_onSpriteClicked from extension');

        const selected = store.getters['ALL_Camera_Elements'].find((x) => x.dbId == ev.dbId);
        store.commit('SET_SELECTED_CAMERA_ELEMENT', selected);
        store.commit('SET_Show_Camera_Attachement', true);

        this._dataVizExt.tool.selectedDbId = 0;
        ev.hasStopped = true;
    }
    _onSpriteClickedOut(event) {
        event.hasStopped = true;

        const viewablesToUpdate = [event.dbId];
        this._dataVizExt.invalidateViewables(viewablesToUpdate, () => {
            return {
                scale: 1.0, 
            };
        });
    }
    _removeAllSprites() {
        this._dataVizExt?.removeAllViewables();
    }

    onToolbarCreated() {
        const controller = this.viewer.toolController;
 
        this.addCameraButton = this.createToolbarButton('add-camera-button', cameraImg2, 'Add Camera');
        this.addCameraButton.onClick = () => {
            if (controller.isToolActivated(AddCameraToolName)) {
                controller.deactivateTool(AddCameraToolName);
                this.addCameraButton.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
            } else {
                controller.activateTool(AddCameraToolName);
                this.addCameraButton.setState(Autodesk.Viewing.UI.Button.State.ACTIVE);
                
                controller.deactivateTool(MoveCameraToolName);
                this.moveCameraButton.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
            }
        };

        this.moveCameraButton = this.createToolbarButton('move-camera-button', moveImg, 'Move Camera');
        this.moveCameraButton.onClick = () => {
            if (controller.isToolActivated(MoveCameraToolName)) {
                controller.deactivateTool(MoveCameraToolName);
             
                this.moveCameraButton.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
            } else {
                controller.activateTool(MoveCameraToolName);
                this.moveCameraButton.setState(Autodesk.Viewing.UI.Button.State.ACTIVE);
                
                controller.deactivateTool(AddCameraToolName);
                this.addCameraButton.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
            }
        };
    }

    createToolbarButton(buttonId, buttonIconUrl, buttonTooltip) {
        let group = this.viewer.toolbar.getControl('dashboard-toolbar-group');
        if (!group) {
            group = new Autodesk.Viewing.UI.ControlGroup('dashboard-toolbar-group');
            this.viewer.toolbar.addControl(group);
        }
        const button = new Autodesk.Viewing.UI.Button(buttonId);
        button.setToolTip(buttonTooltip);

        group.addControl(button);
        const icon = button.container.querySelector('.adsk-button-icon');
        if (icon) {
            icon.style.backgroundImage = `url(${buttonIconUrl})`; 
            icon.style.backgroundSize = `24px`; 
            icon.style.backgroundRepeat = `no-repeat`; 
            icon.style.backgroundPosition = `center`; 
        }
        return button;
    }

    removeToolbarButton(button) {
        const group = this.viewer.toolbar.getControl('dashboard-toolbar-group');
        group.removeControl(button);
    }    
}

Autodesk.Viewing.theExtensionManager.registerExtension('ShowCamerasExtension', ShowCamerasExtension);