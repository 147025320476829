<template>
 <div>
     <message-box ref="messageBox" :on-confirm="update"  :on-cancel="this.cancel" title="Confirmation"
            message="Do you want to save changes?" />
    <v-card
      title="Attachment Markup"

      style="padding: 10px"
      theme="dark"
      id="pdfCard"
      :style="`width: 95%;left:3%`">
      <div id="pdfPopup">
        <MarkupEditors ref="editors" stateInput="true" />
      </div>
      <div style="width:100%">
      <v-btn @click="update" v-if="isAttach" style="margin-top:0.5%;float:right" color="primary">update</v-btn>
      <v-btn @click="closePopup" :hidden="showClose" style="margin-top:0.5%;margin-right:0.5%;float:right" color="error">close</v-btn>
     </div>
    </v-card>
<div class="container-background" @mouseup="closeCameraAttachementl">
</div>
  </div>
</template>
<script>
import MarkupEditors from './MarkupEditors.vue'
import MessageBox from './../../../components/MessageBox.vue'
export default {
    data: () => ({
   showUpdate:false,
   width:40,
   left:30,
   showClose:false

  }),
    mounted(){

      this.$store.dispatch("MARKUPDATA/SetOvelayZIndex",500);

        window.isMarkupAttach=true;
        this.$store.dispatch('InitializePdfViewer', {
        id: "https://developer.api.autodesk.com/oss/v2/buckets/wip.dm.prod/objects/fb60540d-dff3-4acc-ae06-35035f055bc2.rvt?scopes=b360project.ea090251-8cb6-4d7b-95a4-12abf9ce0a75,O2tenant.10730739 ",
        urn: "dXJuOmFkc2sud2lwcHJvZDpmcy5maWxlOnZmLmRVUWw4R0s2U0dlUVJuSW5ycXRqbHc_dmVyc2lvbj0xNQ",
        ref: "",
        viewId: "a02fd08a-498b-4f59-ad17-33a88263d352-000ccc8e",
        img:this.img,
      })
      this.$store.dispatch("MARKUPEDITOR/ShowMarkups");
      this.$store.dispatch("MARKUPEDITOR/setWindowIsAttachMarkup");
      this.$store.dispatch("MARKUPEDITOR/setIsNewMarkup");
      this.$store.dispatch("MARKUPEDITOR/setShowMarkupGlobal",false);

      if (screen.width<1400) {
        this.width=60
         this.left=23

      }
    },
    props: {
    img:null
  },
  components:{
    MarkupEditors,
    MessageBox
  },
  methods:{
    cancel(){
      this.$store.dispatch("MARKUPEDITOR/ExitMarkup");
      this.$store.dispatch("MARKUPDATA/setOpenMarkupImg",false);
      this.$store.dispatch("MARKUPEDITOR/setWindowNotIsAttachMarkup");
      this.$store.dispatch("MARKUPEDITOR/SetAttachHasMarkup",false);


    },
    closePopup(){
      this.$refs.messageBox.show();

    },
    update(){
        
        if (!this.isAttach) {
          this.$refs.editors.save()
        }
        else{
             this.$store.dispatch("MARKUPEDITOR/SaveMarkupsViewData",true)
        }

    }
  },
  computed: {
    isAttach(){
      return this.$store.getters["MARKUPEDITOR/ATTACH_HAS_MARKUP"]
    }
  },
  watch:{
    isAttach(value){
      if (value ) {
        this.showUpdate=true;
      }
    }
  }

}
</script>
<style scoped>
  #pdfPopup {
    width: 100%;
    height: calc(100% - 90px);
    
    /* flex-grow: 1; */
    position: relative;
    max-height: 75vh;
    
  }
  #pdfCard{

    padding: 10px;
    position: fixed;
    z-index: 8;
    top: 4%;
  }
  .container-background {
    width: 100vw;
    height: 100vh;
    background: #161616ad;
    position: fixed;
    left: 0;
    z-index: 5;
    top: 0%;

}
  @media only screen and (min-width: 1400) {
    #pdfCard {
      width:40%;
      left: 30%;
    }
}
  @media only screen and (max-width: 1400) {
    #pdfCard {
      width:60%;
      left: 23%;

    }
}
</style>
