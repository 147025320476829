/* eslint-disable no-unused-vars */
const THREE = window.THREE;
const Autodesk = window.Autodesk;

class SheetMarker {
  constructor(extension, viewport, model3D, ui, boundsPlane) {
    this.extension = extension;
    this.viewer = extension.viewer;
    this.viewport = viewport;
    this.model3D = model3D;
    this.ui = ui;
    this.boundsPlane = boundsPlane;
    this.isSheetVisible = false;
    this.enterTimeout = null;
    this.matrix = new THREE.Matrix4();

    this.calculateMatrixAndBounds();
    this.createAndAddMarker();
    this.setVisible(true);

    this.onShowHide = this.onShowHide.bind(this);
    this.onGoToSheet = this.onGoToSheet.bind(this);
    this.update = this.update.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseWheel = this.onMouseWheel.bind(this);
  }

  calculateMatrixAndBounds() {
    this.viewportBounds = this.viewport.getViewportBounds(1);
    this.viewportMatrix = this.viewport.get2DTo3DMatrix(1);
  }

  createAndAddMarker() {
    this.button = new Autodesk.Viewing.UI.Button(`mst-b-${this.viewport.getGuid()}`);
    this.button.setToolTip(this.viewport.getName());
    this.container = this.button.container;
    this.container.classList.add("hyper-sheet-marker", "hyper-sheet-marker-icon");

    this.button.icon.remove("adsk-button-icon");
    this.container.addEventListener("click", this.onClick);
    this.container.addEventListener("mouseenter", this.onMouseEnter);
    this.container.addEventListener("mouseleave", this.onMouseLeave);
    this.container.addEventListener("mousewheel", this.onMouseWheel);

    this.viewer.container.appendChild(this.container);
    this.update();
  }

  setVisible(visible) {
    this.button.setVisible(visible);
  }

  setDefaultStyle() {
    this.container.classList.remove("clicked");
  }

  onClick(event) {
    this.boundsPlane.lock = true;
    const rect = this.viewer.container.getBoundingClientRect();
    if (Array.isArray(event.changedPointers) && event.changedPointers.length > 0) {
      event.clientX = event.changedPointers[0].clientX;
      event.clientY = event.changedPointers[0].clientY;
    }
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    this.container.classList.add("clicked");
    this.ui.show({ x, y }, this.isSheetVisible, this.onShowHide, this.onGoToSheet);
  }

  onShowHide() {
    const marker = "Marker";
    this.isSheetVisible ? this.hideSheet(marker) : this.showSheet(marker);
  }

  async getNode() {
    return this.nodePromise || (this.nodePromise = this.viewport.getParentSheetNode());
  }

  setMarkerSelected(selected) {
    if (selected) {
      this.container.classList.add("selected");
      this.isSheetVisible = true;
      this.extension.onMarkerSelected(this);
    } else {
      this.container.classList.remove("selected");
      this.isSheetVisible = false;
      this.extension.onMarkerDeselected(this);
    }
  }

  async hideSheet(marker) {
    if (!this.isSheetVisible) return;
    const node = await this.getNode();
    this.extension.hideSheet(node);
    this.setMarkerSelected(false);
  }

  async showSheet(marker) {
    if (this.isSheetVisible) return;
    this.setMarkerSelected(true);
    const node = await this.getNode();
    const result = await this.extension.showSheetAndSetTransform(node, this.viewport, this.model3D);
    return this.isSheetVisible ? result : (this.extension.hideModel(node), null);
  }

  async onGoToSheet() {
    let model = await this.getModel();
    if (this.isSheetVisible && model) return;
    model = await this.showSheet();
    this.extension.transitionModelToSheet(model);
  }

  onMouseEnter() {
    if (!this.isSheetVisible) {
      this.enterTimeout = setTimeout(() => {
        this.addPlane();
      }, 300);
    }
  }

  onMouseLeave() {
    if (!this.boundsPlane.lock) {
      clearTimeout(this.enterTimeout);
      this.enterTimeout = null;
      this.removePlane();
    }
  }

  addPlane() {
    const min = new THREE.Vector3(this.viewportBounds.min.x, this.viewportBounds.min.y, 0);
    const max = new THREE.Vector3(this.viewportBounds.max.x, this.viewportBounds.max.y, 0);
    const box = new THREE.Box3(min, max);
    const size = box.getSize(new THREE.Vector3());
    const scaleMatrix = new THREE.Matrix4().makeScale(size.x, size.y, 1);
    scaleMatrix.setPosition(box.getCenter(new THREE.Vector3()));
    const matrix = this.matrix.clone().multiply(scaleMatrix);

    if (this.viewport.isMatrixInLocalCoords()) {
      const modelTransform = this.model3D.getModelToViewerTransform();
      if (modelTransform) {
        matrix.multiplyMatrices(modelTransform, matrix);
      }
    }

    this.boundsPlane.setFromMatrix(matrix);
    this.boundsPlane.setVisible(true);
  }

  removePlane() {
    this.boundsPlane.setVisible(false);
  }

  onMouseWheel(event) {
    this.viewer.toolController.mousewheel(event);
  }

  async getModel() {
    const node = await this.getNode();
    return this.viewer.getAllModels().find(model => model.getDocumentNode() === node);
  }

  setWorldPosition() {
    const maxX = this.viewportBounds.max.x;
    const minY = this.viewportBounds.min.y;
    this.matrix.copy(this.viewportMatrix);
    const position = new THREE.Vector3(maxX, minY, 0).applyMatrix4(this.matrix);

    if (this.viewport.isMatrixInLocalCoords()) {
      const modelTransform = this.model3D.getModelToViewerTransform();
      if (modelTransform) {
        position.applyMatrix4(modelTransform);
      }
    }
    this.location = position;
  }

  updateClientLocation() {
    const clientPos = this.viewer.impl.worldToClient(this.location);
    this.container.style.transform = `translate(${clientPos.x}px, ${clientPos.y}px)`;
  }

  update() {
    this.setWorldPosition();
    this.updateClientLocation();
  }

  destroy() {
    if (this.container) {
      this.viewer.container.removeChild(this.container);
    }
    this.button = null;
  }
}

export default SheetMarker;