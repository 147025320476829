<template>
    <v-dialog v-model="dialogVisible" max-width="400" theme="dark">
        <v-card border="lg" class="mx-auto">
            <v-card-title class="headline">{{ title }}</v-card-title>
            <v-divider :thickness="1" class="border-opacity-100" color="error"></v-divider>
            <v-card-text>{{ message }}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#D50000" text @click="cancel">Cancel</v-btn>
                <v-btn color="success" text @click="confirm">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['title', 'message', 'onConfirm','onCancel'],
    data() {
        return {
            dialogVisible: false,
        };
    },
    methods: {
        show() {
            this.dialogVisible = true;
        },
        hide() {
            this.dialogVisible = false;
        },
        confirm() {
            this.onConfirm();
            this.hide();
        },
        cancel() {
            if (this.onCancel) this.onCancel();
            this.hide();
        },
    },
};
</script>

<style scoped>
.mx-auto {
    background: rgb(48, 36, 36);
}
</style>