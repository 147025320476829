<template lang="html">
    <div class="shaded-overlay" :style="overlayStyle">
        <v-row justify="center" align="center" class="h-100">
            <v-col justify="center" align="center" cols="12" sm="6" md="4">
                <v-progress-circular size="64" indeterminate color="indigo"></v-progress-circular>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    name: 'ShadedOverlay',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        opacity: {
            type: Number,
            default: 1
        }
    },
    computed: {
        overlayStyle() {
            return {
                display: this.show ? 'block' : 'none',
                opacity: this.opacity
            }
        }
    }
}
</script>
<style lang="css">
    
.shaded-overlay {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    position: absolute;
    z-index: 9;
    background: #d9d9d9;
    overflow: hidden;
}

</style>