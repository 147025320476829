<template>
    <v-layout class="layout-container" v-if="ready">
        <div v-if="Show_Camera_Attachement">
            <camera-attachement />
        </div>

        <input-field2 :title="inputFieldTitle" :value="inputFieldValue" :label="inputFieldLabel"
            :onConfirm="inputFieldHandleConfirm" :onDiscard="onCancelClicked" ref="textInputDialog" />
        <!-- <v-navigation-drawer theme="dark" permanent :width="1000" location="right" v-model="IS_PROPERTY_PANEL_OPENED">
        </v-navigation-drawer> -->
        <properties-list v-model="IS_PROPERTY_PANEL_OPENED"></properties-list>



        <v-main class="d-flex flex-column align-center justify-center" style="min-height: 300px;" theme="dark">
            <v-progress-linear id="main-progress-bar" :active="IS_PROGRESS_BAR_ACTIVE" :model-value="PROGRESS_BAR_VALUE"
                absolute striped rounded color="light-green-darken-2" height="5"></v-progress-linear>
            <overlay-shaded :show="SHOW_MAIN_OVERLAY"></overlay-shaded>
            <forge-viewer></forge-viewer>
        </v-main>

    </v-layout>
</template>

<script>
import OverlayShaded from '@/components/common/shaded-overlay.vue';

import ForgeViewer from '@/components/ForgeViewer/ForgeViewer.vue';
import PropertiesList from '@/components/PropertiesList.vue';
import CameraAttachement from '@/components/CameraAttachement.vue';
import InputField2 from '@/components/InputField2.vue';

import { mapGetters } from 'vuex';
import { isTouchDevice } from '@/services/compat';
import { toast } from 'vue3-toastify';

export default {
    name: 'MainView',
    components: {
        ForgeViewer,
        OverlayShaded,
        PropertiesList,
        InputField2,
        CameraAttachement
    },
    data() {
        return {
            ready: false,

            inputFieldTitle: '',
            inputFieldValue: '',
            inputFieldLabel: '',
            inputFieldHandleConfirm: null,
            toastId: null,
         
        }
    },
    methods: {

        handleNameSubmit(name) {
            if (!name) {
                toast.error('please enter a valid name');
                return
            }
            const sameName = this.$store.getters['ALL_Camera_Elements'].find(x => x.cameraName == name)
            if (sameName) {
                toast.error('camera name already exist');
                return
            }
            this.$refs.textInputDialog.hide();
            this.$store.commit('SET_Show_InputField', false);

            const location = this.$store.getters.Added_Camera_Location
            const dbId = this.$store.getters.Added_Camera_DBID

            this.$store.dispatch('AddCameraElement', { dbId, location, Name: name }).then(() => {
                this.$store.commit('TOGGLE_Add_Camera_Mode_On');

                const selected = this.$store.getters['ALL_Camera_Elements'].find(x => x.dbId == dbId)
                console.log(selected);
                this.$store.commit('SET_SELECTED_CAMERA_ELEMENT', selected);
                this.$store.commit('SET_Show_Camera_Attachement', true);
            });
        },
        onCancelClicked() {
            this.$store.commit('SET_Show_InputField', false);
            this.$refs.textInputDialog.hide();
            this.$store.commit('TOGGLE_Add_Camera_Mode_On');
            const viewId = this.$store.getters['View_Id']
            const ALL_Camera_Elements_IN_Model = this.$store.getters['ALL_Camera_Elements_IN_Model']
            const extension = window.NOP_VIEWER.getExtension('ShowCamerasExtension')
            // this.$store.commit('SET_View_Id', window.NOP_VIEWER.model.getDocumentNode().data.guid);
            let elements = ALL_Camera_Elements_IN_Model.filter(x => x.viewId == viewId)
            extension._removeAllSprites()
            extension.addCameraTool._addAllSpriteToView(elements)
        }
    },
    computed: {
        ...mapGetters('InstanceProperties', ['IS_PROPERTY_PANEL_OPENED']),
        ...mapGetters(['Show_Camera_Attachement', 'Show_Input_Field', 'SHOW_MAIN_OVERLAY']),
        ...mapGetters(['IS_PROGRESS_BAR_ACTIVE', 'PROGRESS_BAR_VALUE']),
        viewer: {
            get: function () {
                return this.$store.getters.VIEWER;
            },
        },
        isTouchDevice() {
            // return true;
            return isTouchDevice();
        }
    },
    mounted() {
        this.$store.dispatch('ACCOUNT/CheckAuth').then(() => {
            this.ready = true;
        });
    },
    watch: {
        modelsPanel: function () {
            setTimeout(() => {
                window.NOP_VIEWER?.resize();
            }, 200);
        },
        panel: function (panel) {
            if (!panel) {
                setTimeout(() => {
                    window.NOP_VIEWER?.resize();
                }, 200);
            }
        },
        familiesPanel: function () {
            setTimeout(() => {
                window.NOP_VIEWER?.resize();
            }, 200);
        },
        openInstancesPanel: function () {
            setTimeout(() => {
                window.NOP_VIEWER?.resize();
            }, 200);
        },
        IS_PROPERTY_PANEL_OPENED: function () {
            setTimeout(() => {
                window.NOP_VIEWER?.resize();
            }, 200);
        },
        cameraPanel: function () {
            setTimeout(() => {
                window.NOP_VIEWER?.resize();
            }, 200);
        },
        settingsPanel: function () {
            setTimeout(() => {
                window.NOP_VIEWER?.resize();
            }, 200);
        },
        selectedPanel: function () {
            setTimeout(() => {
                window.NOP_VIEWER?.resize();
            }, 200);
        },
        Show_Input_Field: function (neVal) {

            if (neVal == true) {
                this.inputFieldTitle = "Please enter camera name"
                this.inputFieldValue = ""
                this.inputFieldLabel = "Name"
                this.inputFieldHandleConfirm = this.handleNameSubmit

                this.$refs.textInputDialog.show();
            }
            //else {
            //     this.$refs.textInputDialog.hide();
            // }
        },
        IS_PROGRESS_BAR_ACTIVE: function (value) {
            if (value) {
                this.toastId = toast("Collecting model blocks..", {
                    "theme": "dark",
                    "type": "success",
                    "isLoading": true,
                    "position": "bottom-right",
                    "transition": "slide",
                    "autoClose": false,
                    "closeOnClick": false,
                })
            } else {
                toast.remove(this.toastId);
            }
        }
    }
}
</script>

<style scoped>
.layout-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.v-navigation-drawer {
    flex: 0 0 auto;
    border-right: 1px solid #8f8f8f85;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.v-main {
    flex: 1;
}

.v-toolbar-items {
    display: flex;
    height: inherit;
    align-self: stretch;
    justify-content: center;
    width: -webkit-fill-available;
}

#main-progress-bar {
    left: var(--v-layout-left);
    top: var(--v-layout-top);
    z-index: 2;
}
</style>
