import NotFoundView from "@Home/views/NotFoundView.vue";
import ProjectsView from "@Home/views/ProjectsView.vue";
import MainView from "@/views/MainView.vue";
import MarkupElements from './../../Markups/views/MarkupElements.vue'

export default [
    {
        path: '/',
        name: 'Home',
        redirect: '/projects',
        component: ProjectsView,
        meta: {
            layout: 'projects-layout',
            requiresAuth: true,
            title: 'Projects',
        },
    },
    {
        path: '/markups',
        name: 'markups',
        component: MarkupElements,
        meta: {
            layout: 'projects-layout',
            requiresAuth: true,
            title: 'markups',
        },
    },
    {
        path: '/projects',
        name: 'Projects',
        redirect: '/projects/recent',
        component: ProjectsView,
        meta: {
            layout: 'projects-layout',
            requiresAuth: true,
            title: 'Projects',
        },
    },
    {
        path: '/projects/recent',
        name: 'Recent',
        component: ProjectsView,
        meta: {
            layout: 'projects-layout',
            requiresAuth: true,
            title: 'Projects',
        },
    },
    {
        path: '/projects/user-recent',
        name: 'UserRecent',
        component: ProjectsView,
        meta: {
            layout: 'projects-layout',
            requiresAuth: true,
            title: 'Projects',
        },
    },
    {
        path: '/projects/favorites',
        name: 'Favorites',
        component: ProjectsView,
        meta: {
            layout: 'projects-layout',
            requiresAuth: true,
            title: 'Projects',
        },
    },
    {
        path: '/viewer',
        name: 'Viewer',
        component: MainView,
        meta: {
            layout: 'default-layout',
            requiresAuth: true,
            title: 'Viewer',
        },
    },
    {
        path: '/viewer/:id',
        name: 'ModelViewer',
        component: MainView,
        meta: {
            layout: 'default-layout',
            requiresAuth: true,
            title: 'Viewer',
        },
    },
    {
        path: '/viewer/markups/:markupId',
        name: 'MarkupViewer',
        component: MainView,
        meta: {
            layout: 'default-layout',
            requiresAuth: true,
            title: 'Viewer',
        },
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFoundView,
        meta: {
            layout: 'auth-layout',
            requiresAuth: false,
            title: 'Not Found',
        },
    },
];