import store from "@/store";
import projectService from "../service/project.service";
import { $handleError } from "@/utils";

export const PROJECTS = {
    namespaced: true,
    state: {
        currentProjectData: null,
        projectsList: [],
        projectsPopup: false,
        activeFolder: null,
    },
    getters: {
        CURRENT_PROJECT_DATA(state) {
            return state.currentProjectData;
        },
        PROJECTS_LIST(state) {
            return state.projectsList;
        },
        PROJECTS_POPUP(state) {
            return state.projectsPopup;
        },
        ACTIVE_FOLDER(state) {
            return state.activeFolder;
        }
    },
    mutations: {
        SET_CURRENT_PROJECT_DATA(state, value) {
            state.currentProjectData = value;
        },
        SET_PROJECTS_LIST(state, value) {
            state.projectsList = value;
        },
        SET_PROJECTS_POPUP(state, value) {
            state.projectsPopup = value;
        },
        SET_ACTIVE_FOLDER(state, value) {
            state.activeFolder = value;
        }
    },
    actions: {
        UpdateProject({ state, commit, dispatch }) {

            let project = structuredClone(state.currentProjectData);

            if (!project) return;

            let user = store.getters['ACCOUNT/USER'];

            project.userId = user.userId
            project.userName = user.userName

            projectService.updateProject(project)
                .then((data) => {
                    commit('SET_CURRENT_PROJECT_DATA', data)
                    dispatch('UserCheckInProject');
                })
                .catch((error) => {
                    $handleError(error);
                });
        },
        GetProjectData({ commit }, projectId) {
            return projectService.getById(projectId)
                .then((data) => {
                    commit('SET_CURRENT_PROJECT_DATA', data)
                })
                .catch((error) => {
                    $handleError(error);
                });

        },
        GetRecentProjects({ commit }, { query,favCount }) {
            return projectService.getRecentProjects(query,favCount).then(projects => {
                commit('SET_PROJECTS_LIST', projects)
            })
        },
        GetUserRecentProjects({ commit }, { query,favCount }) {
            let userId = store.getters["ACCOUNT/USER"]?.userId;
            return projectService.getUserRecentProjects(userId, query,favCount).then(projects => {
                commit('SET_PROJECTS_LIST', projects)
            })
        },
        GetFavoriteProjects({ commit }, { query,favCount }) {
            let userId = store.getters["ACCOUNT/USER"]?.userId;
            return projectService.getFavoriteProjects(userId, query,favCount).then(projects => {
                commit('SET_PROJECTS_LIST', projects)
            })
        },
        ToggleFavorite(_, project) {
            let userId = store.getters["ACCOUNT/USER"]?.userId;
            return projectService.updateUserProjectFavorite({
                userId,
                projectId: project.id,
                isFavorite: !project.isFavorite
            });
        },
    }
}