<template>
    <div class="context-menu" v-show="show" :style="style" ref="context" tabindex="0" @blur="close">
        <slot></slot>
    </div>
</template>
<script>
import { nextTick } from 'vue';
export default {
    name: 'ContextMenu',
    props: {

    },
    data() {
        return {
            left: 0,
            top: 0,
            show: false,
        };
    },
    computed: {
        style() {
            return {
                top: this.top + 'px',
                left: this.left + 'px',
            };
        },
    },
    methods: {
        close() {
            this.show = false;
            this.left = 0;
            this.top = 0;
        },
        open(evt) {
            this.left = evt.pageX || evt.clientX;
            this.top = (evt.pageY || evt.clientY) - 50;

            nextTick(() => this.$el.focus());
            this.show = true;
        },
    },
};
</script>
<style>
.context-menu {
    position: fixed;
    background: white;
    z-index: 999;
    outline: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    border-radius: 5px;
    width: fit-content;
    min-width: 130px;
}

.context-menu ul {
    list-style: none;
    margin: 2px;
    padding: 0px;
    font-size: 14px;
}

.context-menu ul li {
    padding: 5px 5px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.context-menu ul li:not(:last-child) {
    margin-bottom: 3px;
}

.context-menu ul li:hover {
    background-color: #f2f2f2;
}
</style>