import httpService from "@/utils/httpService"
export default{
    Get(){
        return httpService.get({
             url: `api/CutomProperties`,
         })
     },
     Add(data){
        return httpService.post({
             url: `api/CutomProperties`,
             data:data
         })
     },
     Update(data){
        return httpService.put({
             url: `api/CutomProperties`,
             data:data
         })
     },
     Delete(id){
        return httpService.delete({
             url: `api/CutomProperties/`+id
         })
     },
     GetElementProps(ModeId,RevitId){
        return httpService.get({
            url: `api/ElementCustomProp/GetPropsv/${ModeId}/GetProps/${RevitId}`
        })
     },
     GetALlElementProps(){
        return httpService.get({
             url: `api/ElementCustomProp`,
         })
     },
     AddElementProps(data){
        return httpService.post({
            url: `api/ElementCustomProp`,
            data:data
        })
     }
}