/////////////////////////////////////////////////////////////////////
// Viewing.Extension.CSSTVExtension
// by Philippe Leefsma, April 2016
//
/////////////////////////////////////////////////////////////////////
import TranslateTool from './Viewing.Tool.Translate'
import RotateTool from './Viewing.Tool.Rotate'

// import ExtensionBase from '../Viewer.ExtensionBase'
import ViewerToolkit from '../Viewer.Toolkit'
import { ManipulatedElement, ManipulationTypes } from '@/models/ForgeModels';

const THREE = window.THREE
const Autodesk = window.Autodesk
import store from '../../store';
// import { fa } from 'vuetify/lib/iconsets/fa.mjs';
class TransformExtension extends Autodesk.Viewing.Extension {
    /////////////////////////////////////////////////////////////////
    // Class constructor
    //
    /////////////////////////////////////////////////////////////////
    constructor(viewer, options = {}) {
        super(viewer, options);

        this._viewer = viewer;
        this._options = options;
        this.selectedObj = null;

        this.onAggregateSelectionChanged = this.onAggregateSelectionChanged.bind(this);
    }

    /////////////////////////////////////////////////////////////////
    // Extension Id
    //
    /////////////////////////////////////////////////////////////////
    static get ExtensionId() {
        return 'Viewing.Extension.Transform';
    }

    /////////////////////////////////////////////////////////////////
    // onToolbarCreated callback
    //
    /////////////////////////////////////////////////////////////////
    createDeleteButton() {
        this._deleteControl = ViewerToolkit.createButton(
            'toolbar-delete',
            'fa fa-trash',
            'Delete Element',
            () => {
                var txTool = this.translateTool.getName();
                var rxTool = this.rotateTool.getName();

                if (this.translateTool.active || this.rotateTool.active) {
                    this._viewer.toolController.deactivateTool(txTool);
                    this._txControl.container.classList.remove('active');

                    this._viewer.toolController.deactivateTool(rxTool);
                    this._rxControl.container.classList.remove('active');
                }
                // this.selectedObj.dbIdArray.forEach(x=> {

                //     this.deleteInstance(x);
                // })
                const aggSelection = this._viewer.getAggregateSelection();
                this.deleteInstances(aggSelection.map((x) => x.selection).flat(1));
                this._viewer.clearSelection();
            },
        );

        this.parentControl.addControl(this._deleteControl);
    }
    onToolbarCreated() {
        console.log(`Viewing.Extension.Transform onToolbarCreated`);
        this._txControl = ViewerToolkit.createButton(
            'toolbar-translate',
            'fa fa-arrows-alt',
            'Translate Tool',
            () => {
                var txTool = this.translateTool.getName();
                var rxTool = this.rotateTool.getName();

                if (this.translateTool.active) {
                    this._viewer.toolController.deactivateTool(txTool);
                    this._txControl.container.classList.remove('active');
                    //this._comboCtrl.container.classList.remove('active')
                } else {
                    this._viewer.toolController.activateTool(txTool);
                    this._txControl.container.classList.add('active');

                    this._viewer.toolController.deactivateTool(rxTool);
                    this._rxControl.container.classList.remove('active');

                    //this._comboCtrl.container.classList.add('active')
                }
            },
        );

        this._rxControl = ViewerToolkit.createButton(
            'toolbar-rotate',
            'fa fa-refresh',
            'Rotate Tool',
            () => {
                var txTool = this.translateTool.getName();
                var rxTool = this.rotateTool.getName();

                if (this.rotateTool.active) {
                    this._viewer.toolController.deactivateTool(rxTool);
                    this._rxControl.container.classList.remove('active');
                    //this._comboCtrl.container.classList.remove('active')
                } else {
                    this._viewer.toolController.activateTool(rxTool);
                    this._rxControl.container.classList.add('active');

                    this._viewer.toolController.deactivateTool(txTool);
                    this._txControl.container.classList.remove('active');

                    //this._comboCtrl.container.classList.add('active')
                }
            },
        );
        // this._deleteControl = ViewerToolkit.createButton(
        //     'toolbar-delete',
        //     'fa fa-trash',
        //     'Delete Element',
        //     () => {
        //         var txTool = this.translateTool.getName();
        //         var rxTool = this.rotateTool.getName();

        //         if (this.translateTool.active || this.rotateTool.active) {
        //             this._viewer.toolController.deactivateTool(txTool);
        //             this._txControl.container.classList.remove('active');

        //             this._viewer.toolController.deactivateTool(rxTool);
        //             this._rxControl.container.classList.remove('active');
        //         }

        //         this.deleteInstance(this.selectedObj.dbIdArray[0]);

        //         this._viewer.clearSelection();
        //     },
        // );
        this.parentControl = this._options.parentControl;

        if (!this.parentControl) {
            var viewerToolbar = this._viewer.getToolbar(true);

            this.parentControl = new Autodesk.Viewing.UI.ControlGroup('transform');

            this.parentControl.addControl(this._txControl);
            this.parentControl.addControl(this._rxControl);
            viewerToolbar.addControl(this.parentControl);
        }

        // this._comboCtrl = new Autodesk.Viewing.UI.ComboButton(
        //   'transform-combo')

        // this._comboCtrl.setToolTip('Transform Tools')

        // this._comboCtrl.icon.style.fontSize = '24px'
        // this._comboCtrl.icon.style.transform = 'rotateY(180Deg)'

        // this._comboCtrl.icon.className = 'fas fa-globe'

        // this._comboCtrl.addControl(this._txControl)
        // this._comboCtrl.addControl(this._rxControl)

        // var openCombo = this._comboCtrl.onClick

        // this._comboCtrl.onClick = () => {

        //   if(this._comboCtrl.container.classList.contains('active')) {

        //     this._txControl.container.classList.remove('active')
        //     this._rxControl.container.classList.remove('active')

        //     this._comboCtrl.container.classList.remove('active')

        //     var txTool = this.translateTool.getName()
        //     var rxTool = this.rotateTool.getName()

        //     this._viewer.toolController.deactivateTool(txTool)
        //     this._viewer.toolController.deactivateTool(rxTool)

        //   } else {

        //     openCombo()
        //   }
        // }

        //this.parentControl.addControl(this._comboCtrl)

        return true;
    }
    deleteInstance(dbId) {
        const manipulatedElements = store.getters.MANIPULATED_ELEMENTS;
        const instances = store.getters.FAMILIES_INSTANCES;
        let elements = [...instances, ...manipulatedElements];
        let instance = elements.filter((x) => x.dbId == dbId);

        if (instance.length == 0) {
            // this._viewer.impl.visibilityManager.setNodeOff(dbId, true);
            this.deleteExistingElement(dbId);
            return;
        }
        instance = instance[0];

        if (instance.manipulationType === 'New Family Instance') {
            store.dispatch('RemoveFamilyInstance', instance.dbId).then(() => {});
        } else {
            store.dispatch('RemoveManipulatedElement', instance.dbId).then(() => {
                if (window.NOP_VIEWER.model.is3d() && instance.viewType === '3d') {
                    if (instance.manipulationType === 'Cloned Element') {
                        let manipulationExtension = window.NOP_VIEWER.getExtension(
                            'Viewing.Extension.Manipulation',
                        );

                        if (manipulationExtension) {
                            manipulationExtension.deleteManipulatedElement(instance.dbId);
                        }
                    } else if (instance.manipulationType === 'Manipulated Existing Element') {
                        // this._viewer.impl.visibilityManager.setNodeOff(dbId, true);
                        this.deleteExistingElement(dbId);
                    }
                }
            });
        }
    }
    deleteInstances(dbIds) {
        store.commit('SET_IS_SAVING', true);
        store.commit('SET_SAVING_ERROR', null);

        const manipulatedElements = store.getters.MANIPULATED_ELEMENTS;
        const instances = store.getters.FAMILIES_INSTANCES;

        let elements = [...instances, ...manipulatedElements];

        let instanceToBeDeleted = elements.filter((x) => dbIds.includes(x.dbId));
        let notIncludedInElements = dbIds.filter((x) => !elements.find((c) => c.dbId == x));

        /// New Families
        const FamiliesToBeDeleted = instanceToBeDeleted.filter(
            (x) => x.manipulationType === 'New Family Instance',
        );
        if (FamiliesToBeDeleted.length > 0) {
            FamiliesToBeDeleted.forEach((x) => {
                store.dispatch('RemoveFamilyInstance', x.dbId).then(() => {});
            });
        }
        ///

        /// Manipulated Existing
         let existingPromise = new Promise((resolve) => {
            const manipulatedExistingToBeDeleted = instanceToBeDeleted
                .filter((x) => x.manipulationType === 'Manipulated Existing Element')
                .map((x) => x.dbId);

            const existing = [...notIncludedInElements, ...manipulatedExistingToBeDeleted];

            if (existing.length > 0) {
            
                this.deleteExistingElements(existing).then(()=> {
                    resolve()
                });
            
            }else{
                resolve();
            }       
        })
        ///

        ///cloned
        let clonedPromise = new Promise((resolve) => {
            const clonedToBeDeleted = instanceToBeDeleted.filter(
                (x) => x.manipulationType === 'Cloned Element',
            );
            if (clonedToBeDeleted.length > 0) {
                let manipulationExtension = window.NOP_VIEWER.getExtension(
                    'Viewing.Extension.Manipulation',
                );
                if (manipulationExtension) {
                    store
                        .dispatch(
                            'RemoveManipulatedElements',
                            clonedToBeDeleted.map((x) => x.dbId),
                        )
                        .then(() => {

                            clonedToBeDeleted.forEach((cl) =>
                                manipulationExtension.deleteManipulatedElement(cl.dbId),
                            );
                            resolve();
                        });
                }else{
                    resolve();
                }
            }else{
                resolve();
            }
        
        })

        
         Promise.all([existingPromise, clonedPromise]).then(() => {
             store.commit('SET_IS_SAVING', false);
             store.commit('SET_SAVING_ERROR', null);
         });
    }
    geWorldBoundingBox(fragIds, fragList) {
        var fragbBox = new THREE.Box3();
        var nodebBox = new THREE.Box3();

        fragIds.forEach((fragId) => {
            fragList.getWorldBounds(fragId, fragbBox);
            nodebBox.union(fragbBox);
        });

        return nodebBox;
    }
    async deleteExistingElement(dbId) {
        var fragList = this.selectedObj.model.getFragmentList();

        var nodebBox = this.geWorldBoundingBox(this.selectedObj.fragIdsArray, fragList);

        const elementPosition = nodebBox.getCenter();

        let manipulatedElement = store.getters.MANIPULATED_ELEMENTS.find(
            (element) => element.dbId == dbId,
        );

        if (!manipulatedElement) {
              const _viewGuids = await ViewerToolkit.getViewabelIdsOfElement(
                  this._viewer.model,
                  dbId,
                  store,
              );
            this._viewer.getProperties(dbId, (props) => {
                manipulatedElement = new ManipulatedElement();
                manipulatedElement.viewType = '3d';
                manipulatedElement.viewId = window.NOP_VIEWER.model.getDocumentNode()?.data?.guid;
                manipulatedElement.manipulationType = ManipulationTypes.DELETED;
                manipulatedElement.name = props.name;
                manipulatedElement.modelId = this._viewer.model.getData().urn;
                manipulatedElement.dbId = dbId;
                manipulatedElement.originalDbId = dbId;
                manipulatedElement.originalExternalId = props.externalId;
                manipulatedElement.position = elementPosition;
                manipulatedElement.originalPosition = elementPosition;
                manipulatedElement.rotation = null;
                manipulatedElement.viewGuids = _viewGuids;

                store.commit('ADD_MANIPULATED_ELEMENT', manipulatedElement);

                store.dispatch('UpdateModelManipulatedElements').then(() => {
                    this._viewer.impl.visibilityManager.setNodeOff(dbId, true);
                });
            });
        } else {
            manipulatedElement.manipulationType = ManipulationTypes.DELETED;
            store.dispatch('UpdateModelManipulatedElements').then(() => {
                this._viewer.impl.visibilityManager.setNodeOff(dbId, true);
            });
        }
    }
    deleteExistingElements(dbIds) {
        return new Promise((resolve, reject) => {

                let propTasks = [];
                const aggSelection = this._viewer.getAggregateSelection();
                dbIds.forEach((dbId) => {
                    const selection = aggSelection.find((x) => x.selection.includes(dbId));
                    if (!selection) return;

                    var fragList = selection.model.getFragmentList();

                    var frags = [];
                    selection.model
                        .getInstanceTree()
                        .enumNodeFragments(dbId, (fragId) => frags.push(fragId));

                    var nodebBox = this.geWorldBoundingBox(frags, fragList);

                    const elementPosition = nodebBox.getCenter();

                    let manipulatedElement = store.getters.MANIPULATED_ELEMENTS.find(
                        (element) => element.dbId == dbId,
                    );

                    if (!manipulatedElement) {
                        propTasks.push(this.getPropertiesAsync(selection.model, dbId, elementPosition));
                        // selection.model.getProperties(dbId, (props) => {
                        //     manipulatedElement = new ManipulatedElement();
                        //     manipulatedElement.viewType = '3d';
                        //     manipulatedElement.viewId = selection.model.getDocumentNode()?.data?.guid;
                        //     manipulatedElement.manipulationType = ManipulationTypes.DELETED;
                        //     manipulatedElement.name = props.name;
                        //     manipulatedElement.modelId = selection.model.getData().urn;
                        //     manipulatedElement.dbId = dbId;
                        //     manipulatedElement.originalDbId = dbId;
                        //     manipulatedElement.originalExternalId = props.externalId;
                        //     manipulatedElement.position = elementPosition;
                        //     manipulatedElement.rotation = null;

                        //     store.commit('ADD_MANIPULATED_ELEMENT', manipulatedElement);
                        // });
                    } else {
                        manipulatedElement.manipulationType = ManipulationTypes.DELETED;
                    }
                });
                if (propTasks.length > 0) {
                    Promise.all(propTasks).then((results) => {
                        results.forEach((r) => store.commit('ADD_MANIPULATED_ELEMENT', r));

                        store.dispatch('UpdateModelManipulatedElements').then(() => {
                            resolve();
                        });
                    }).catch((err) => reject(err));
                } else {
                    store
                        .dispatch('UpdateModelManipulatedElements')
                        .then(() => {
                            resolve();
                        })
                        .catch((err) => reject(err));
                }
            })
    }
    async getPropertiesAsync(model, dbId, elementPosition) {
        const _viewGuids = await ViewerToolkit.getViewabelIdsOfElement(
            model,
            dbId,
            store,
        );
        return new Promise((resolve, reject) => {
            model.getProperties(
                dbId,
                (props) => {
                    const manipulatedElement = new ManipulatedElement();
                    manipulatedElement.viewType = '3d';
                    manipulatedElement.viewId = model.getDocumentNode()?.data?.guid;
                    manipulatedElement.manipulationType = ManipulationTypes.DELETED;
                    manipulatedElement.name = props.name;
                    manipulatedElement.modelId = model.getData().urn;
                    manipulatedElement.dbId = dbId;
                    manipulatedElement.originalDbId = dbId;
                    manipulatedElement.originalExternalId = props.externalId;
                    manipulatedElement.position = elementPosition;
                    manipulatedElement.originalPosition = elementPosition;
                    manipulatedElement.rotation = null;
                    manipulatedElement.viewGuids = _viewGuids;
                    resolve(manipulatedElement);
                },
                (error) => {
                    reject(error);
                },
            );
        });
    }


    activateMoveTool() {
        var txTool = this.translateTool.getName();
        var rxTool = this.rotateTool.getName();

        this._viewer.toolController.activateTool(txTool);
        this._txControl.container.classList.add('active');

        this._viewer.toolController.deactivateTool(rxTool);
        this._rxControl.container.classList.remove('active');

        //this._comboCtrl.container.classList.add('active')
    }

    activateRotateTool() {
        var txTool = this.translateTool.getName();
        var rxTool = this.rotateTool.getName();

        this._viewer.toolController.activateTool(rxTool);
        this._rxControl.container.classList.add('active');

        this._viewer.toolController.deactivateTool(txTool);
        this._txControl.container.classList.remove('active');

        //this._comboCtrl.container.classList.add('active')
    }

    deactivateAllTools() {
        var txTool = this.translateTool.getName();
        var rxTool = this.rotateTool.getName();

        this._viewer.toolController.deactivateTool(txTool);
        this._txControl.container.classList.remove('active');

        this._viewer.toolController.deactivateTool(rxTool);
        this._rxControl.container.classList.remove('active');

        //this._comboCtrl.container.classList.remove('active')
    }

    activate() {
        return true;
    }
    onAggregateSelectionChanged(event) {
        if (event.selections && event.selections.length > 0) {
            if (!this._deleteControl) this.createDeleteButton();
        } else {
            this.parentControl.removeControl(this._deleteControl);
            this._deleteControl = null;
        }
        if (event.selections && event.selections.length) {
            this.selectedObj = event.selections[0];
        }
    }

    /////////////////////////////////////////////////////////////////
    // Load callback
    /////////////////////////////////////////////////////////////////
    load() {
        this._viewer.addEventListener(
            Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
            this.onAggregateSelectionChanged,
        );

        this.translateTool = new TranslateTool(this._viewer);
        this._viewer.toolController.registerTool(this.translateTool);

        this.rotateTool = new RotateTool(this._viewer);
        this._viewer.toolController.registerTool(this.rotateTool);

        console.log('Viewing.Extension.Transform loaded');
        return true;
    }

    /////////////////////////////////////////////////////////////////
    // Unload callback
    //
    /////////////////////////////////////////////////////////////////
    unload() {
        this._viewer.removeEventListener(
            Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
            this.onAggregateSelectionChanged,
        );
        //this.parentControl.removeControl(this._comboCtrl)
        this.parentControl.removeControl(this._rxControl);
        this.parentControl.removeControl(this._txControl);

        this._viewer.toolController.deactivateTool(this.translateTool.getName());

        this._viewer.toolController.deactivateTool(this.rotateTool.getName());

        console.log('Viewing.Extension.Transform unloaded');
    }
}

Autodesk.Viewing.theExtensionManager.registerExtension(TransformExtension.ExtensionId, TransformExtension)
