import { $getFromLocal, $handleError, $setToLocal, $removeFromLocal } from "@Utils";
import httpService from "@Utils/httpService";
import { toast } from "vue3-toastify";
import accountService from "@Account/service/account.service";

export const ACCOUNT = {
    namespaced: true,
    state: {
        user: $getFromLocal("user", true, null),
        loading: false,
        isLogin: !!$getFromLocal("token", false, null),
    },
    getters: {
        GET_IS_AUTH(state) {
            return !!state.user && state.isLogin;
        },
        USER(state) {
            return state.user;
        }
    },
    mutations: {
        SET_USER(state, { user, token }) {
            state.user = user;
            state.isLogin = true;
            $setToLocal("token", token, false);
            $setToLocal("user", user, true);
            httpService.handleToken();
        },
        SET_LOADING(state, value) {
            state.loading = value;
        },
    },
    actions: {
        CheckAuth() {
            return new Promise((resolve, reject) => {
                accountService.getUserProfile()
                    .then(() => {
                        resolve(true);
                    })
                    .catch((error) => {
                        $handleError(error);
                        reject();
                    });
            });
        },
        ExchangeCodeForToken({ commit }, code) {
            accountService.authenticateWithAutodeskCode(code)
                .then(({ user, token }) => {
                    commit('SET_USER', { user, token });
                    window.location.href = '/';
                }).catch(error => {
                    $handleError(error);
                    window.location.href = '/';
                });
        },
        RequestAutodeskToken() {
            accountService.requestAutodeskToken()
                .then(url => {
                    console.log(`Redirecting to ${url}`);
                    window.location.href = url;
                })
                .catch(error => {
                    toast.error(`${error}. \nPlease try again later.`);
                });
        },
        GetAccessToken() {
            return new Promise((resolve, reject) => {
                accountService.getAccessToken()
                    .then(token => {
                        resolve(token);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        Logout() {
            accountService.logout()
                .then(() => {
                    $removeFromLocal("user");
                    $removeFromLocal("token");
                    location.reload();
                })
                .catch(error => {
                    console.error(error);
                });
        }
    },
};
