<template lang="html">
  <v-app-bar class="home-top-bar" height="50" theme="dark">

     <VersionLogo />

    <v-btn v-bind="{
        color: $route.path.includes(`/projects`) ? 'primary' : '',
        outlined: !$route.path.includes(`/projects`),
      }" rounded="0" height="50" variant="flat" link small class="grey--text" :to="`/projects`">
      <v-icon left>fa:fa-solid fa-layer-group</v-icon> Projects
    </v-btn>
    <v-btn v-bind="{
        color: $route.path == `/markups` ? 'primary' : '',
        outlined: $route.path == `/markups`,
      }" rounded="0" height="50" link small class="grey--text" :to="`/markups`">
      <v-icon left>fa:fa-solid fa-pen-ruler</v-icon> Markups
    </v-btn>

    <v-btn v-bind="{
      color: $route.path == `/requests` ? 'primary' : '',
      outlined: $route.path == `/requests`,
    }" rounded="0" height="50" link small class="grey--text" :to="`/requests`">
      <v-icon left>fa:fa-solid fa-pen-ruler</v-icon> Requests
    </v-btn>
    <v-spacer></v-spacer>

    <template v-slot:append>
      <div class="d-flex ga-3 align-center">
        <v-btn @click="openProjectsPopup" color="success" outlined height="30" variant="flat" small class="grey--text">
          <v-icon left>fa:fa-regular fa-folder-open</v-icon> Open Project
        </v-btn>

        <v-btn icon @click="logout" class="ml-5">
          <i class="fa-solid fa-arrow-right-from-bracket"></i>
        </v-btn>
      </div>
    </template>
  </v-app-bar>
</template>

<script>
  import logo from '@/assets/images/logo.png';
   import VersionLogo from '@/features/Share/components/VersionLogo.vue';

  export default {
    name: 'top-bar',
    components: {
            VersionLogo
        },
    data() {
      return {
        logo,
      };
    },
    methods: {
      logout() {
        this.$store.dispatch('ACCOUNT/Logout');
      },
      openProjectsPopup() {
        this.$store.commit('PROJECTS/SET_PROJECTS_POPUP', true);
      },
    },
  };
</script>

<style lang="css">
  .home-top-bar .v-btn__content,
  .home-top-bar .v-btn__prepend,
  .home-top-bar .v-btn__append {
    gap: 8px;
  }

  .home-top-bar i {
    color: inherit !important;
  }
</style>
