<template>
  <span class="version">
    <span>
      <v-chip
        class="elevation-1 py-0 font-weight-bold d-flex"
        size='small'
        hide-details
        color="white bg-primary "
        v-if="isTestEnv || isProdEnv"
        style="line-height: 0">
        <v-icon size="13" class="d-flex mr-1" style="line-height: 0" left>fa:fa-solid fa-vial-virus</v-icon>
        <span class="version-number mx-1">v{{ version }} </span>
      </v-chip>
      <v-chip
        class="elevation-1 py-0 px-2 font-weight-bold d-flex"
        size='small'
        hide-details
        color="white bg-primary"
        v-else-if="isDevEnv"
        style="line-height: 0">
        <v-icon size="13" class="d-flex mr-1" style="line-height: 0" left>fa:fa-solid fa-code</v-icon>
        <span class="version-number mx-1">v{{ version }} </span>
      </v-chip>
    </span>
  </span>
</template>
<script>
  import { version } from '../../../../package.json';
  export default {
    name: 'version-component',
    data() {
      return {
        version,
      };
    },
    computed: {
      isProdEnv() {
        return process.env.VUE_APP_TYPE === 'production';
      },
      isTestEnv() {
        return process.env.VUE_APP_TYPE === 'staging';
      },
      isDevEnv() {
        return process.env.VUE_APP_TYPE === 'development';
      },
    },
  };
</script>
<style lang="css">
  .version {
    display: flex;
    width: fit-content;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    line-height: 0;
    margin-top: 0.35rem;
    margin-bottom: 0.25rem;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, 50%);
    position: fixed;
  }
</style>
