import httpService from "@Utils/httpService";

const baseUrl = 'api/ForgeModel';

export default {
    updateModel(model) {
        return httpService.post({
            url: `${baseUrl}/update`,
            data: model
        });
    },
    getById(modelId) {
        return httpService.get({
            url: `${baseUrl}/getById`,
            params: { id: modelId }
        });
    },
    userCheckInModel({userId, modelId}) {
        return httpService.post({
            url: `${baseUrl}/UserCheckInModel`,
            data: {
                userId, modelId
            }
        });
    },
    getUserRecentModels(userId, projectId, query) {
        return httpService.get({
            url: `${baseUrl}/${projectId}/getUserRecantModel`,
            params: { userId, query }
        });
    },
    getRecentModels(projectId, query) {
        return httpService.get({
            url: `${baseUrl}/${projectId}/getRecentModels`,
            params: { query }
        });
    },
    getFavoriteModels(userId, projectId, query) {
        return httpService.get({
            url: `${baseUrl}/${projectId}/getFavoriteModels`,
            params: { userId, query }
        });
    },
    updateUserModelFavorite({ userId, modelId, isFavorite }) {
        return httpService.post({
            url: `${baseUrl}/updateUserModelFavorite`,
            data: {
                userId, modelId, isFavorite
            }
        });
    }
}