<template>
    <div class="toast-dialog-container">
        <p class="toast-dialog-title">{{ toastProps.data.title }} ...</p>
        <button
            class="v-btn v-btn-dialog"
            @click="
                ($event) => {
                    closeToast && closeToast($event);
                    clear();
                }
            ">
            Done
        </button>
        <button title="redo" style="float: right" class="v-btn v-btn-dialog" @click="redo">
            
            <i  style="color: white" class="fa-solid fa-rotate-right"></i>
        </button>
        <button title="undo" style="float: right" class="v-btn v-btn-dialog" @click="undo">
            <i style="color: white" class="fa-solid fa-rotate-left"></i>
        </button>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                name: 'PlacingBlocksDialog',
            };
        },
        props: {
            closeToast: {
                type: Function,
                required: true,
            },
            toastProps: {
                type: Object,
                required: true,
            },
        },

        methods: {
            redo() {
                window.CommandManager?.redo();
            },
            undo() {
                window.CommandManager?.undo();
            },
            clear() {
                window.CommandManager?.clear();
            },
        },
        unmounted() {
            this.clear();
        },
    };
</script>
<style scoped>
    .toast-dialog-container {
        cursor: default;
    }
    .v-btn-dialog {
        background-color: #4caf50;
        border: none;
        color: white;
        padding: 5px 15px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 1rem;
        margin: 2px;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
    }

    .toast-dialog-subtitle {
        font-size: 1rem;
        color: #ccc;
    }

    .v-btn-dialog:hover {
        background-color: #458648;
    }

    .toast-dialog-title {
        font-size: 1.2rem;
        font-weight: bold;
        color: #ccc;
        margin-bottom: 10px;
    }
</style>
