<template>
  <div>
    <component :is="$route.meta.layout || 'default-layout'">
      <router-view></router-view>
    </component>
  </div>
</template>

<script>
import authLayout from '@Layouts/auth.vue';
import defaultLayout from '@Layouts/default.vue';
import projectsLayout from '@Layouts/projects.vue';
import modelsLayout from '@Layouts/models.vue';

export default {
  name: 'App',
  components: { authLayout, defaultLayout, projectsLayout, modelsLayout },
  data: () => {
    return {

    }
  },
  mounted() {
    console.log('App.vue mounted')
  }
}
</script>
<style lang="scss">
.slideout-panel {
  border-color: rgb(73, 73, 73) !important;
  background-color: #222222 !important;
  color: aliceblue;
}

.slideout-header {
  border-color: rgb(73, 73, 73) !important;
  align-items: center;
  height: auto;
}

.slideout-resize--handle {
  width: 15px !important;
}

.slideout-footer {
  border-color: rgb(73, 73, 73) !important;
  align-items: center;
  display: flex;
  height: auto;
}

.table-sticky {
    overflow: hidden;

    .v-data-table__wrapper {
      overflow: visible;
      overflow: auto;
      overflow: overlay;
      height: 100%;
      flex: 1 1 auto;

      .v-data-table-header {
        position: sticky;
        z-index: 6;
        top: 0;
      }
    }

    .v-data-footer {
      justify-content: flex-end;
      gap: 2rem;
      .v-data-footer__pagination {
        margin: 0;
      }
      .v-data-footer__icons-before .v-btn--icon.v-size--default,
      .v-data-footer__icons-after .v-btn--icon.v-size--default {
        width: 25px;
        height: 25px;
      }
      .v-data-footer__select {
        order: 0;
        margin: 0;
        text-align: right;
        display: flex;
        flex-direction: row-reverse;

        .v-input {
          margin: 0;
          margin-right: 0.5rem;
        }
        .v-select__selection {
          font-size: 12px !important;
        }

        .v-input__append-inner {
          padding: 0;
          width: 8px;
          min-width: 8px;

          .v-input__icon {
            width: 8px;
            min-width: 8px;
          }
        }
      }
      .v-data-footer__icons-before {
        order: 1;
      }
      .v-data-footer__pagination {
        order: 2;
      }
      .v-data-footer__icons-after {
        order: 3;
      }
    }
  }
  .custom-table {
    &.v-data-table > .v-data-table__wrapper > table tr {
      th,
      td {
        padding: 0 6px;
      }
    }

    tr {
      position: relative;
    }
    .v-data-table-header {
      background-color: var(--v-bg2-base);
    }
    &.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
      height: 28px;
    }
    &.v-data-table > .v-data-table__wrapper {
      background-color: rgb(255 255 255 / 4%);
      .v-input--checkbox .v-icon.v-icon {
        font-size: 20px;
      }
    }
    .v-data-footer {
      height: 33px;
      background-color: var(--v-bg2-base);
      .theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
        display: none;
      }
    }
    .v-data-table__checkbox .v-icon.v-icon {
      font-size: 20px;
    }
  }
</style>
