<template>
    <v-list density="compact" class="pa-0">
        <v-list-item
            v-for="node in nodes"
            :key="node.id"
            class="px-0 py-0"
            style="min-height: 30px">
            <v-list-item-title
                :class="{ 'active-folder': activeFolderId === node.id }"
                class="d-flex align-center w-100 pa-1 node-title mx-0"
                height="30px"
                @click="() => itemClicked(node)">
                <v-progress-circular
                    size="25"
                    width="2"
                    v-if="node.isLoading"
                    class="mr-2"
                    color="blue-lighten-3"
                    indeterminate></v-progress-circular>
                <v-icon
                    size="25"
                    class="node-icon mr-1"
                    v-else-if="node.type === 'folder' && !node.isLeaf"
                >
                    {{ node.isExpanded ? 'mdi:mdi-chevron-down' : 'mdi:mdi-chevron-right' }}
                </v-icon>
                <v-icon size="25" class="node-icon mr-1" v-else> </v-icon>
                <p class="w-100 trimmed-text">
                    <v-icon class="mr-1">
                        {{ node.isExpanded ? 'mdi:mdi-folder-open' : 'mdi:mdi-folder' }}
                    </v-icon>
                    {{ node.name }}
                </p>
            </v-list-item-title>
            <v-list
                class="pa-0 pl-6"
                density="compact"
                v-if="node.isExpanded && node.children && node.children.length"
                :key="node.id">
                <TreeNode
                    :nodes="node.children"
                    @load-children="loadChildren"
                    @toggle-active="toggleActive" />
            </v-list>
        </v-list-item>
    </v-list>
</template>

<script>
    export default {
        name: 'TreeNode',
        props: {
            nodes: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {};
        },
        methods: {
            loadChildren(node) {
                if (node.children?.length) {
                    node.isExpanded = !node.isExpanded;
                    return;
                }

                if (node.isLeaf) return;

                node.isLoading = true;

                this.$emit('load-children', node);
            },
            toggleActive(node) {
                this.$emit('toggle-active', node);
            },
			itemClicked(node) {
				if (node.type === 'folder') {
					this.loadChildren(node);
					this.toggleActive(node);
				}
			},
        },
        computed: {
            activeFolder: {
                get() {
                    return this.$store.getters['PROJECTS/ACTIVE_FOLDER'];
                },
                set(value) {
                    this.$store.commit('PROJECTS/SET_ACTIVE_FOLDER', value);
                },
            },
            activeFolderId() {
                return this.activeFolder?.id;
            },
        },
        watch:{
            nodes(value)
            {
                if (value && value.length>0 && value[0].name=="Project Files") {
                   this.itemClicked(value[0]);
                }
            }
        }
    };
</script>

<style lang="scss">
    .node-title {
        cursor: pointer;

        & p {
            font-size: 1rem;
        }
    }

    .node-title:hover {
        background-color: #a7a7a71d;
    }

    .node-icon:hover {
        color: rgba(252, 252, 252, 0.34);
    }

    .active-folder {
        color: #0aa2ff;
        background: #3e485455;

        & i.v-icon {
            color: #0aa2ff;
        }
    }

    .tree-container {
        overflow-y: auto;
        max-height: calc(100vh - 50px);
    }
</style>
