
import store from "@/store";

/* eslint-disable no-unused-vars */
const Autodesk = window.Autodesk;
class MarkupToolExtension extends Autodesk.Viewing.Extension {
    constructor(viewer, options) {
        super(viewer, options);
        this.viewer = viewer;
        this.options = options;
       
    }
    onToolbarCreated(){
        
        this.rotate_Translate_Button = this.createToolbarButton('show-markup', 'fa fa-pen', 'Start Markup');
        this.rotate_Translate_Button.onClick = () => {
            store.dispatch("MARKUPEDITOR/ShowMarkups");
            store.dispatch("MARKUPEDITOR/setIsNewMarkup");
            store.dispatch("MARKUPEDITOR/setShowMarkupGlobal",true);
        };
       if (this.viewer.model.is2d() || !this.viewer.model) {
        this.show_alll_markups = this.createToolbarButton('show-all', 'fa fa-eye', 'Show All Markups');
        this.show_alll_markups.onClick = () => {
            store.dispatch("MARKUPEDITOR/OpenAllMarkup");

        };
       }
    }
    load(){
        this.viewer.canvas.addEventListener('mousedown', this.down.bind(this));
        return true;

    }
    down(){
        store.dispatch("MARKUPEDITOR/changePolyLocations");

    }
    createToolbarButton(buttonId, buttonIconUrl, buttonTooltip) {
       
        let group = this.viewer.toolbar.getControl('markup-group');
        if (!group) {
            group = new Autodesk.Viewing.UI.ControlGroup('markup-group');
            this.viewer.toolbar.addControl(group);
        }
        const button = new Autodesk.Viewing.UI.Button(buttonId);
        button.setToolTip(buttonTooltip);

        group.addControl(button);
        const icon = button.container.querySelector('.adsk-button-icon');
        if (icon) {
            icon.className = buttonIconUrl;
            icon.style.fontSize = '24px';
        }
        return button;
    }
}
Autodesk.Viewing.theExtensionManager.registerExtension("MarkupToolExtension", MarkupToolExtension);
