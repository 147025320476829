<template>
    <v-container fluid class="h-100">
        <v-row>
            <v-col cols="12">
                <tree-view :nodes="buckets" :load-children="loadBucketObjects"></tree-view>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TreeView from './TreeView/TreeView.vue';
export default {
    components: {
        TreeView,
    },
    data() {
        return {
        };
    },
    methods: {
        async loadBuckets() {
            this.$store.dispatch('GetBuckets');
        },
        async loadBucketObjects(bucket) {
            return this.$store.dispatch('GetBucketObjects', bucket.name).then((objects) => {
                bucket.children = objects.map(object => ({
                    id: object.id,
                    parent: false,
                    name: object.name,
                    urn: object.urn,
                    children: []
                }));

                if (bucket.children.length === 0) {
                    bucket.children = [{ type: 'unsupported', id: 'unsupported', name: 'No supported items' }];
                }
            }).catch((error) => {
                console.error('Error fetching bucket objects:', error);
            });
        }
    },
    computed: {
        buckets() {
            return this.$store.getters.BUCKETS;
        }
    },
    mounted() {
        this.loadBuckets();
    }
};
</script>