import ModelsView from "@Models/views/ModelsView.vue";
import ProjectBrowserView from "@Models/views/ProjectBrowserView.vue";

export default [
    {
        path: "/projects/:id/user-recent",
        name: "ModelsUserRecent",
        component: ModelsView,
        meta: {
          layout: "models-layout",
          requiresAuth: true, 
          title: "Recent",
        }
      },
      {
        path: "/projects/:id/browser",
        name: "ProjectModelsBrowser",
        component: ProjectBrowserView,
        meta: {
          layout: "models-layout",
          requiresAuth: true, 
          title: "Browser",
        }
      },
      {
        path: "/projects/:id/favorites",
        name: "ModelsFavorites",
        component: ModelsView,
        meta: {
          layout: "models-layout",
          requiresAuth: true, 
          title: "Favorites",
        }
      },
]