import httpService from "@Utils/httpService";

const baseUrl = 'api/auth';

export default {
    authenticateWithAutodeskCode(code) {
        return this.exchangeCodeForToken(code).then((token) => {
            return this.getUserProfile().then(user => {
                return { user, token };
            })
        });
    },
    exchangeCodeForToken(code) {
        return httpService.get({
            url: `${baseUrl}/callback`,
            params: { code: code }
        });
    },
    getUserProfile() {
        return httpService.get({ url: `${baseUrl}/profile` });
    },
    requestAutodeskToken() {
        return httpService.get('api/forge/oauth/url');
    },
    getAccessToken() {
        return httpService.get('api/Auth/token');
    },
    logout() {
        return httpService.get('api/auth/logout');
    }
}