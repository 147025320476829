const THREE = window.THREE;
const Autodesk = window.Autodesk

// import ViewerToolkit from "@/extensions/Viewer.Toolkit";
import store from "@/store";
import { toast } from "vue3-toastify";
import { ManipulationTypes } from '@/models/ForgeModels';
export const VIEWER = {
    namespaced: true,
    state: {
        parallel3dViewer: null,
        globalOffset: null,
        transformMatrix: null,
        currentManipulationPosition: null,
        startManipulationPosition: null,
    },
    mutations: {
        SET_CURRENT_MANIPULATION_POSITION(state, position) {
            state.currentManipulationPosition = position;
        },
        SET_START_MANIPULATION_POSITION(state, position) {
            state.startManipulationPosition = position;
        },
        SET_GLOBAL_OFFSET(state, offset) {
            state.globalOffset = offset;
        },
        SET_TRANSFORM_MATRIX(state, matrix) {
            state.transformMatrix = matrix;
        },
        SET_PARALLEL_3D_VIEWER(state, viewer) {
            state.parallel3dViewer = viewer;
        },
    },
    getters: {
        CURRENT_MANIPULATION_POSITION(state) {
            return state.currentManipulationPosition;
        },
        START_MANIPULATION_POSITION(state) {
            return state.startManipulationPosition;
        },
        GLOBAL_OFFSET(state) {
            return state.globalOffset;
        },
        TRANSFORM_MATRIX(state) {
            return state.transformMatrix;
        },
        PARALLEL_3D_VIEWER(state) {
            return state.parallel3dViewer;
        },
    },
    actions: {
        StartParallel3dViewer({ commit, state, dispatch }, URN) {
            let parallel3dViewer = document.getElementById('parallel3dViewer');

            let parallelViewer = new Autodesk.Viewing.GuiViewer3D(parallel3dViewer, {
                skipPropertyDb: true,
                extensions: [
                    'Autodesk.LayerManager',
                    'Autodesk.BimWalk',
                    'Autodesk.Snapping',
                    'Autodesk.Measure',
                    'ModifyPropertiesExtension',
                    'ShowCamerasExtension',
                    'Autodesk.AEC.ViewportsExtension',
                    'Autodesk.AEC.Minimap3DExtension',
                ],
            });

            parallelViewer.addEventListener(Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT, (event) => {
                if (event.model !== parallelViewer.model) return;

                console.log(
                    `%c3D Model root loaded`,
                    'color: yellow; font-weight: bold; font-size: 1.5em; background-color: #111; padding: 5px;',
                );

                commit('SET_GLOBAL_OFFSET', event.model.getGlobalOffset());
                commit('SET_TRANSFORM_MATRIX', event.model.getInverseModelToViewerTransform());

                setTimeout(() => {
                    let currentModel = store.getters['MODELS/CURRENT_MODEL_DATA'];
                    if (
                        !currentModel.thumbnailUrl ||
                        currentModel.thumbnailUrl.includes('https://cdn.derivative.autodesk.com')
                    ) {
                        dispatch('GenerateSnapshotBase64', { viewer: parallelViewer }).then(
                            (base64) => {
                                let model = structuredClone(currentModel);
                                model.thumbnailUrl = base64;
                                store.commit('MODELS/SET_CURRENT_MODEL_DATA', model);
                                store.dispatch('MODELS/UpdateModel');
                            },
                        );
                    }
                }, 5000);
            });

            const onParallelDocumentLoadSuccess = (document) => {
                console.log(
                    `%c 3d Parallel Viewer loaded`,
                    'color: yellow; font-weight: bold; font-size: 1.5em; background-color: #111; padding: 5px;',
                );

                let nodes = document.getRoot().search(
                    {
                        type: 'geometry',
                        role: '3d',
                    },
                    true,
                );

                let defaultModel = document.getRoot().getDefaultGeometry();

                if (!defaultModel && nodes.length) {
                    defaultModel = nodes[0];
                }

                if (!defaultModel) return;

                parallelViewer.loadDocumentNode(document, defaultModel);
            };

            parallelViewer.start();

            Autodesk.Viewing.Document.load(URN, onParallelDocumentLoadSuccess, console.error);

            state.parallel3dViewer = parallelViewer;
            window.parallel3dViewer = parallelViewer;
        },
        ZoomToPoint(state, { viewer, instance }) {
            if (!window.NOP_VIEWER) {
                toast.warn('Please load a model first');
                return;
            }

            if (window.NOP_VIEWER.model.is2d()) {
                state.dispatch('ZoomToPoint2d', { viewer, instance });
            } else {
                state.dispatch('ZoomToPoint3d', { viewer, instance });
            }
        },
        ZoomToPoint2d(state, { viewer, instance }) {
            let model = viewer.model;
            if (!model) return;

            let bubbleBox;
            if(viewer == window.NOP_VIEWER){

                if (
                    instance.manipulationType === ManipulationTypes.MANIPULATE_EXISTING ||
                    instance.manipulationType === ManipulationTypes.DELETED
                ) {
                    const rotateTool =
                        window.NOP_VIEWER.toolController.getTool('Viewing.Rotate2D.Tool');
                    bubbleBox = rotateTool.getModelElementBoundingBox(instance.dbId);
                } else {
                    const tool = window.NOP_VIEWER.toolController.getTool('Viewing.Clone2D.Tool');
                    let object = tool.clonedObjects.find((obj) => obj.name == instance.dbId);
                    bubbleBox = new THREE.Box3().setFromObject(object);
                }
            }
            else{

                const rotateTool = window.NOP_VIEWER.toolController.getTool('Viewing.Rotate2D.Tool');
                bubbleBox = rotateTool.getModelElementBoundingBox2(
                    viewer,
                    model,
                    instance.originalDbId,
                );
            }
            let offset = 0.05;

            let min = new THREE.Vector3(
                bubbleBox.min.x - offset,
                bubbleBox.min.y - offset,
                bubbleBox.min.z - offset,
            );

            let max = new THREE.Vector3(
                bubbleBox.max.x + offset,
                bubbleBox.max.y + offset,
                bubbleBox.max.z + offset,
            );

            let box = new THREE.Box3(min, max);

            viewer.navigation.fitBounds(true, box);
        },
        ZoomToPoint3d(state, { viewer, instance }) {
            let model;
            let it;
            let fragList;
           let dbId

              if(viewer == window.NOP_VIEWER){
                dbId = instance.dbId;
                  if (
                      instance.manipulationType == ManipulationTypes.MANIPULATE_EXISTING ||
                      instance.manipulationType === ManipulationTypes.DELETED
                  ) {
                      model = window.NOP_VIEWER.model;
                      it = model?.getInstanceTree();
                      fragList = model.getFragmentList();
                  } else if (instance.manipulationType == ManipulationTypes.CLONED_ELEMENT) {
                      model = store.getters.MODEL_BUILDER.model;
                      it = model?.getData().instanceTree;
                      fragList = store.getters.MODEL_BUILDER.fragList;
                  }

              }
                else{
                    model = viewer.model;
                    it = model?.getInstanceTree();
                    fragList = model.getFragmentList();
                    dbId = instance.originalDbId;
                }

            var frags = [];
            it?.enumNodeFragments(dbId, (fragId) => frags.push(fragId));

            var nodebBox = geWorldBoundingBox(frags, fragList);

            let offset = 0.05;

            let min = new THREE.Vector3(
                nodebBox.min.x - offset,
                nodebBox.min.y - offset,
                nodebBox.min.z - offset,
            );

            let max = new THREE.Vector3(
                nodebBox.max.x + offset,
                nodebBox.max.y + offset,
                nodebBox.max.z + offset,
            );

            let box = new THREE.Box3(min, max);

            viewer.navigation.fitBounds(true, box);
            function geWorldBoundingBox(fragIds, fragList) {
                var fragbBox = new THREE.Box3();
                var nodebBox = new THREE.Box3();

                fragIds.forEach((fragId) => {
                    fragList.getWorldBounds(fragId, fragbBox);
                    nodebBox.union(fragbBox);
                });

                return nodebBox;
            }
        },
        GeneratePointSnapshotBase64({ dispatch }, { viewer, point, dbId, instance }) {
            return new Promise((resolve, reject) => {
                if (!point) reject(`Point is required`);

                if (!viewer) {
                    viewer = window.NOP_VIEWER;
                }
                // const is2D = viewer.model?.is2d();

                let state = viewer.getState();

                let model;
                model = viewer.model;
                // if(is2D){
                //     model = window.NOP_VIEWER.model;
                // }else{
                //     if (instance.manipulationType == ManipulationTypes.MANIPULATE_EXISTING) {
                //         model =  window.NOP_VIEWER.model;
                //     } else if (instance.manipulationType == ManipulationTypes.CLONED_ELEMENT) {
                //         model = window.NOP_VIEWER.getAllModels().find(
                //             (x) => x.id == store.getters.MODEL_BUILDER.model.id,
                //         );
                //         if (!model) {
                //             console.error('model not found');
                //         }
                //     }
                // }

                viewer.isolate([dbId], model);

                dispatch('ZoomToPoint', { viewer, instance });

                dispatch('GenerateSnapshotBase64', { viewer }).then((base64) => {
                    resolve(base64);
                    viewer.restoreState(state);
                    viewer.showAll();
                });
            });
        },
        GenerateSnapshotBase64(_, { viewer }) {
            return new Promise((resolve) => {
                let screenshot = new Image();
                screenshot.onload = async function () {
                    if (!viewer) {
                        viewer = window.NOP_VIEWER;
                    }

                    let canvas = document.createElement('canvas');
                    canvas.width = viewer.container.clientWidth;
                    canvas.height = viewer.container.clientHeight;

                    let ctx = canvas.getContext('2d');
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(screenshot, 0, 0, canvas.width, canvas.height);

                    // For testing
                    // const a = document.createElement('a');
                    // a.classList.add('snapshot-hidden');
                    // document.body.appendChild(a);
                    // a.href = canvas.toDataURL();
                    // a.download = 'snapshot.png';
                    // a.click();
                    // document.body.removeChild(a);

                    let base64 = canvas.toDataURL('image/png');

                    resolve(base64);
                };

                viewer.getScreenShot(
                    viewer.container.clientWidth,
                    viewer.container.clientHeight,
                    function (blobURL) {
                        screenshot.src = blobURL;
                    },
                );
            });
        },
        PlacingBlockUndo(){
            window.CommandManager?.undo();
        },
        PlacingBlockRedo(){
            window.CommandManager?.redo();
        },
        PlacingBlockClear(){
            window.CommandManager?.clear();
        }
    },
};

