<template>
    
    <img class="logo-icon" :src="logo" max-height="50" alt="logo" />
    <v-spacer></v-spacer>
     <version style="left: 9rem !important;top:auto !important;transform: none;"></version>
   
</template>

<script>
  import logo from '@/assets/images/logo.png';
   import Version from '@/features/Share/components/Version.vue';
  export default {
    name: 'top-bar',
    components: {
            Version,
        },
    data() {
      return {
        logo,
      };
    },
  };
</script>