<template>
    <v-container fluid class="h-100">
        <v-row justify="center" align="center" class="h-100">
            <v-col justify="center" align="center" cols="12" sm="6" md="4">
                <v-progress-circular size="64" indeterminate color="indigo"></v-progress-circular>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { toast } from 'vue3-toastify';
import { useRoute } from 'vue-router'

export default {
    data() {
        return {
            code: ''
        };
    },
    mounted() {
        const route = useRoute();

        const code = route.query.code;

        if (!code) {
            console.error('No code parameter found in URL');
            toast.error('Failed to authenticate with Autodesk. Please try again.');
            window.location.href = '/';
            window.location.reload();
            return;
        }

        this.$store.dispatch('ACCOUNT/ExchangeCodeForToken', code)
    }
};
</script>
