<template>
    <div class="container d-flex">

        <div class="c1" style="background-color:rgb(210, 210, 210);">


            <img :src="ktsIcon" height="100">
            <h1>Log In Autodesk Account</h1>

            <v-btn width="350" height="60" @click="login" color="indigo">
                <img :src="icon" height="30" width="30" />
                <span class="ps-2">Autodesk Sign in</span>
            </v-btn>
            <span style="cursor: pointer; display: flex; justify-content: center; " @click="goToURL">KTS
                website</span>


            <!-- <v-row>
                <v-col>
                    <v-btn block width="350" height="60" @click="login" color="indigo">
                        <img :src="icon" height="30" width="30" />
                        <span class="ps-2">Autodesk Sign in</span>
                    </v-btn>
                    <span style="cursor: pointer; display: flex; justify-content: center; margin-top: 10px;"
                        @click="goToURL">KTS
                        website</span>
                </v-col>
            </v-row> -->
            <!-- <v-row>
                <v-col class="text-center">
                    <span style="cursor: pointer;" @click="goToURL">KTS website</span>
                </v-col>
            </v-row> -->
        </div>

        <v-img :src="ktsBG" style="border-radius: 5px;  opacity: 0.5;" cover></v-img>
        <!-- <div class="c1">
        </div> -->

    </div>
</template>

<script>
import icon from '@/assets/images/autodesk.svg';
import ktsIcon from '@/assets/images/logo.png';
import ktsBG from '@/assets/images/commercial-engineering.png';
export default {
    data: () => ({
        icon: icon,
        ktsIcon: ktsIcon,
        ktsBG: ktsBG
    }),
    methods: {
        login() {
            this.$store.dispatch('ACCOUNT/RequestAutodeskToken');
        },
        goToURL() {
            window.open('https://ktsengineeringgroup.com/mechanical-engineering/', '_blank');
        }
    }
};
</script>

<style scoped>
.login-button {
    /* Dark blue */
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    padding: 15px 30px;
    transition: background 0.3s;
}

.container {
    background-color: rgb(230, 230, 230);
    height: 100vh;
    width: 100vw;
    /* align-items: center;
    justify-content: center;
    display: flex; */
}

.c1 {
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 30vw;
    height: 40vh;
    z-index: 5;
    top: 50%;
    left: 50%;
    margin-top: -20vh;
    margin-left: -15vw;
    opacity: 0.8;
    border-radius: 10px;
    border: 1px solid rgb(255, 144, 144);

    gap: 15px;
    /* width: 25vw;
    padding-right: 7px;
    padding-left: 15px; */
}

.c2 {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 75vw;
    padding-right: 15px;
    padding-left: 7px;

}

@media screen and (max-width: 1660px) {

    .c1 {
        justify-content: space-evenly;
        width: 50vw;
        height: 40vh;
        z-index: 5;
        top: 50%;
        left: 50%;
        margin-top: -20vh;
        margin-left: -25vw;
    }
}
</style>
