/* eslint-disable no-unreachable */
import { ManipulatedElement, ManipulationTypes } from "@/models/ForgeModels";
import store from "@/store";
import { toast } from "vue3-toastify";
import ViewerToolkit from "../Viewer.Toolkit";
import PlacingBlocksDialog from "@Home/components/PlacingBlocksDialog.vue";
import { getWorldBoundingBox } from '@/store/modules/ForgeService';
const Autodesk = window.Autodesk;
const THREE = window.THREE;
import { getModelElementBoundingBox2D } from '@/store/modules/ForgeService';
import { AddElementCommand } from "@/utils/undoRedoManager";
class Block2dLibraryExtension extends Autodesk.Viewing.Extension {
    constructor(viewer, options) {
        super(viewer, options);

        this.viewer = viewer;
        this.mouseupHandler = null;
        this.notification = null;

        this.tree = [];
        this._onClose = this.onClose.bind(this)
    }

    get menuId() {
        return 'Block2dLibrary';
    }

    static get extensionId() {
        return 'Block2dLibraryExtension';
    }
    onClose() {
        store.dispatch('PLACING_BLOCKS_DONE');
    }
    async onModelLoaded(viewer, model) {
        if (!viewer) viewer = this.viewer;

        if (!model) model = viewer.model;

        if (!model.is2d()) {
            // store.commit('SET_BLOCKS_TREE', []);
            return true;
        }

        const it = viewer.model.getInstanceTree();

        let initDbIds = await new Promise((resolve) => {
            model.getBulkProperties(
                [],
                ['Type IfcGUID'],
                (result) => {
                    resolve(result.map((item) => item.dbId));
                },
                console.log,
            );
        });
        const ignoredCategories = [
            "Revit Walls", 
            "Revit Floors", 
            "Revit Doors", 
            "Revit Stairs", 
            "Revit Windows", 
            "Revit Furniture", 
            "Revit Casework", 
            "Revit Curtain Wall Mullions", 
            "Revit Curtain Panels"
        ]
        let objects = await new Promise((resolve) => {
            model.getBulkProperties(
                initDbIds,
                ['Type Name', 'Category'],
                (result) => {
                    let map = {};

                    result.forEach((item) => {
                        let category = item.properties.find(
                            (p) => p.displayName === 'Category',
                        )?.displayValue;

                        if (ignoredCategories.find(x => x == category)) 
                            return;

                        let type = item.properties.find(
                            (p) => p.displayName === 'Type Name',
                        )?.displayValue;

                        let name = it.getNodeName(item.dbId);

                        if (!category || !type || !name) return;

                        // Category - Family - Type
                        let key = `${category}__${name}__${type}`;

                        map[key] = {
                            dbId: item.dbId,
                            family: name,
                            type: type,
                            category: category,
                        };
                    });

                    resolve(map);
                },
                console.log,
            );
        });

        Object.values(objects).forEach((item) => {
            const points = this.getObjectGeometry(viewer, model, it, item.dbId);
           let bbox = getModelElementBoundingBox2D(item.dbId, model);
           const minbbox = ViewerToolkit.sheetToWorld(
               viewer,
               bbox.min,
               model,
               store.getters['VIEWER/GLOBAL_OFFSET'],
           )
         const maxbbox = ViewerToolkit.sheetToWorld(
             viewer,
             bbox.max,
             model,
             store.getters['VIEWER/GLOBAL_OFFSET'],
         );
         bbox = new THREE.Box3(minbbox, maxbbox);

            if (points.length == 0) return;

            let node = {
                id: item.dbId,
                name: item.type,
                category: item.category,
                family: item.family,
                type: item.type,
                dbId: item.dbId,
                geometryPoints: points,
                bbox: bbox,
                onClick: () => {
                   
                    let callback = store.getters['ADD_BLOCK_CALLBACK'];
                    let blocksNotification = store.getters['BLOCKS_NOTIFICATION'];

                    if (callback) {
                        this.viewer.canvas.removeEventListener('mouseup', callback);
                    }

                    if (blocksNotification) {
                        // just simulating synchronus as the callback of onClose is called after initialization of new toast
                        this._onClose = () => {};
                        toast.remove(blocksNotification);
                        window.NOP_VIEWER.canvas.removeEventListener('mouseup', callback);
                        window.NOP_VIEWER.canvas.removeEventListener('touched', callback);
                        store.commit('SET_ADD_BLOCK_CALLBACK', null);
                        store.commit('SET_BLOCKS_NOTIFICATION', null);
                    }
                    // Notify user to select a point to place the block
                    let notification = toast.dark(PlacingBlocksDialog, {
                        position: 'top-center',
                        isLoading: true,
                        autoClose: false,
                        transition: 'none',
                        closeOnClick: false,
                        onClose: () => this._onClose(),
                        data: {
                            title: `Placing ${item.type} block`,
                        },
                    });

                    store.commit('SET_BLOCKS_NOTIFICATION', notification);

                    setTimeout(() => (this._onClose = this.onClose), 1000);

                    let mouseupHandler = async (event) => {
                        if (event.button !== 0) return;

                        if (event.changedTouches && event.changedTouches.length > 0) {
                            const touch = event.changedTouches[0];
                            event = new MouseEvent('mouseup', {
                                bubbles: true,
                                cancelable: true,
                                view: window,
                                screenX: touch.screenX,
                                screenY: touch.screenY,
                                clientX: touch.clientX,
                                clientY: touch.clientY,
                                button: 0,
                            });
                        }

                        if (event.button !== 0) return;

                        let hitTest = this.viewer.clientToWorld(event.offsetX, event.offsetY, true);

                        if (!hitTest?.point) {
                            toast.update(blocksNotification, {
                                isLoading: false,
                                content: 'Invalid point selected',
                                type: 'error',
                                duration: 2000,
                            });
                            return;
                        }

                        let worldPosition = ViewerToolkit.sheetToWorld(
                            this.viewer,
                            hitTest.point,
                            this.viewer.model,
                            store.getters['VIEWER/GLOBAL_OFFSET'],
                        );
                        if (!worldPosition) {
                            toast.error('Viewport not found');
                            return;
                        }
                        let cloneTool = this.viewer.toolController.getTool('Viewing.Clone2D.Tool');

                        if (!cloneTool) {
                            toast.update(blocksNotification, {
                                isLoading: false,
                                content: 'Clone tool not found',
                                type: 'error',
                                duration: 2000,
                            });
                            return;
                        }

                        const manipulatedElementsIds = store.getters.MANIPULATED_ELEMENTS.map(
                            (el) => el.dbId,
                        );
                        const maxDbId =
                            manipulatedElementsIds.length == 0
                                ? 0
                                : Math.max(...manipulatedElementsIds);
                        const nextDbId = maxDbId + 1;

                        item["nextDbId"]=nextDbId;
                       

                        let position = worldPosition.clone();
                        const position3d = await this.handleAddClonedElementIn3D(item.dbId);
                        if (position3d) {
                            position.z = position3d.z;
                        }
                        let _viewGuids = await ViewerToolkit.getViewabelIdsOfElement(
                            this.viewer.model,
                            item.dbId,
                            store,
                        );
                        if (
                            _viewGuids &&
                            !_viewGuids.split(',').find((x) => x == store.getters.View_Id)
                        ) {
                            _viewGuids += ',' + store.getters.View_Id;
                        }
                        this.viewer.model.getProperties(item.dbId, (props) => {
                            let manipulatedElement = new ManipulatedElement();
                            manipulatedElement.viewType = '2d';
                            manipulatedElement.viewId =
                                window.NOP_VIEWER.model.getDocumentNode()?.data?.guid;
                            manipulatedElement.manipulationType = ManipulationTypes.CLONED_ELEMENT;
                            manipulatedElement.name = props.name;
                            manipulatedElement.familyType = item.type;
                            manipulatedElement.dbId = nextDbId;
                            manipulatedElement.originalDbId = item.dbId;
                            manipulatedElement.originalExternalId = props.externalId;
                            manipulatedElement.modelId = this.viewer.model.getSeedUrn();
                            manipulatedElement.position = position;
                            manipulatedElement.originalPosition = position;
                            manipulatedElement.rotation = new THREE.Vector3(0, 0, 0);
                            manipulatedElement.viewGuids = _viewGuids;

                            store.commit('ADD_MANIPULATED_ELEMENT', manipulatedElement);

                            let cloned=cloneTool.insertClonedBlock(manipulatedElement, points, true);
                            let  command=new AddElementCommand(cloned,manipulatedElement,points,this.viewer,model);
                            let CommandManager=store.getters['COMMAND_MANAGER'];
                             CommandManager.executeCommand(command);
                             window.CommandManager=CommandManager;
                        });
                    };

                    this.viewer.canvas.addEventListener('mouseup', mouseupHandler);
                    this.viewer.canvas.addEventListener('touched', mouseupHandler);

                    store.commit('SET_ADD_BLOCK_CALLBACK', mouseupHandler);
                },
            };

            store.dispatch('ADD_BLOCK_TO_TREE', node);
        });

        return true;
    }
    async getViewabelIdsOfElement(dbId) {
        return new Promise((resolve) => {
            this.viewer.model.getProperties(dbId, (props) => {
                const viwableIds = props.properties
                    .filter((x) => x.displayCategory === '__viewable_in__')
                    .map((x) => x.displayValue);

                let viewsGuids = [];
                const views = store.getters.D2ViewItems.concat(store.getters.D3ViewItems);
                for (let i = 0; i < viwableIds.length; i++) {
                    const v = views.find((x) => x.data.viewableID == viwableIds[i]);
                    if (!v) continue;
                    viewsGuids.push(v.data.guid);
                }
                resolve(viewsGuids.join(','));
            });
        });
    }
    async handleAddClonedElementIn3D(dbId) {
        const parallelViewer = window.parallel3dViewer;

        for (let i = 0; i < store.getters.D3ViewItems.length; i++) {
            const guid = store.getters.D3ViewItems[i].data.guid;
            const position = await this.getPositionIn3D(parallelViewer, guid, dbId);
            if (position) return position;
        }
    }
    getPositionIn3D(parallelViewer, viewId, dbId) {
        return new Promise((resolve) => {
            let root = parallelViewer.model.getDocumentNode().getRootNode();

            let views = root.search({ guid: viewId });
            if (!views.length) return;

            let view = views[0];
            const doc = parallelViewer.model.getDocumentNode().getRootNode().lmvDocument;
            let callback = (event) => {
                const model = event.model;

                if (model.getInstanceTree().getNodeName(dbId)) {
                    let it = model.getInstanceTree();

                    let fragList = model.getFragmentList();

                    let fragIds = [];

                    it.enumNodeFragments(dbId, (fragId) => {
                        fragIds.push(fragId);
                    });

                    let nodebBox = getWorldBoundingBox(fragIds, fragList);

                    let center = nodebBox.getCenter();

                    resolve(center);
                }
                else {
                    resolve(null)
                }

                parallelViewer.removeEventListener(
                    Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
                    callback,
                );
            };

            parallelViewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, callback);
            parallelViewer.loadDocumentNode(doc, view);
        });
    }

    getObjectGeometry(viewer, model, it, dbId) {
        const objectId = model.reverseMapDbIdFor2D(dbId);

        const points = [];

        it.enumNodeFragments(
            dbId,
            (fragId) => {
                let renderProxy = viewer.impl.getRenderProxy(model, fragId);
                let vbr = new Autodesk.Viewing.Private.VertexBufferReader(renderProxy.geometry);

                let lines = [];

                vbr.enumGeomsForObject(objectId, {
                    onLineSegment: (x1, y1, x2, y2) => {
                        lines.push({ x1: x1 + 1, y1, x2: x2 + 1, y2 });
                    },
                });

                lines.forEach((line) => {
                    points.push(new THREE.Vector3(line.x1, line.y1, 0));
                    points.push(new THREE.Vector3(line.x2, line.y2, 0));
                });
            },
            true,
        );

        return points;
    }

    load() {
        return true;
    }

    unload() {
        return true;
    }
}

Autodesk.Viewing.theExtensionManager.registerExtension(Block2dLibraryExtension.extensionId, Block2dLibraryExtension);

export default Block2dLibraryExtension;