<template>

    <v-sheet class="projects-view h-100" theme="dark">
        <message-box
            ref="messageBox"
            :on-confirm="this.onConfirmMessage"
            title="Confirmation"
            message="Are you sure you want to delete?" />
        <v-container class="pa-8">
            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="12" xl="2" lg="3" md="4" sm="6">
                    <v-text-field
                        class="custom-input"
                        v-model="searchQuery"
                        color="primary"
                        placeholder="Search Markups"
                        hide-details
                        density="compact"
                        clearable
                        prepend-inner-icon="fa:fas fa-search fa-lg">
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <template v-if="loading">
                    <v-col v-for="i in 12" :key="i" cols="12" xl="2" lg="3" md="4" sm="6">
                        <v-skeleton-loader
                            class="mx-auto border"
                            max-width="300"
                            type="image, article"></v-skeleton-loader>
                    </v-col>
                </template>
                <v-col
                    v-for="(project, index) in paginatedMarkups"
                    :key="index"
                    cols="12"
                    xl="2"
                    lg="3"
                    md="4"
                    sm="6">
                    <v-card border class="pb-2" rounded>
                        <v-img class="recent-model-img" :src="project.imgPath" height="200"></v-img>
                        <v-card-title>
                            <v-icon small class="mr-1">fa:fa-solid fa-circle-info</v-icon
                            >{{ project.name }}</v-card-title
                        >
                        <v-card-text class="d-flex flex-column g-05 project-card-details">
                            <div class="text--gray--1 text-clipped" :title="project.hubName">
                                <v-icon x-small class="text--gray--1 mr-1"
                                    >fa:fa-solid fa-network-wired</v-icon
                                >
                                Creator: {{ project.createdByName }}
                            </div>
                            <div class="text--gray--1 text-clipped" :title="project.hubName">
                                <v-icon x-small class="text--gray--1 mr-1"
                                    >fa:fa-solid fa-network-wired</v-icon
                                >
                                Created At: {{ project.createdOn }}
                            </div>
                            <div class="text--gray--1 text-clipped" :title="project.projectName">
                                <v-icon x-small class="text--gray--1 mr-1"
                                    >fa:fa-solid fa-folder-open</v-icon
                                >
                                Project: {{ project.forgeModel.projectName }}
                            </div>
                            <div class="text--gray--1 text-clipped" :title="project.modelName">
                                <v-icon x-small class="text--gray--1 mr-1">fa:fa-solid fa-cube</v-icon>
                                Model: {{ project.forgeModel.modelName }}
                            </div>
                            <div class="text--gray--1 text-clipped" :title="project.version">
                                <v-icon x-small class="text--gray--1 mr-1"
                                    >fa:fa-solid fa-code-branch</v-icon
                                >
                                Version: {{ project.forgeModel.version }}
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn variant="tonal" color="primary" @click="openModel(project)"
                                >Open Markup</v-btn
                            >
                            <v-btn variant="tonal" color="error" @click="ConfirmDelete(project)"
                                >Delete</v-btn
                            >
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-pagination
                        v-model="currentPage"
                        :length="totalPages"
                        ></v-pagination>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>
<script>
    import MessageBox from '@/components/MessageBox.vue';
   
    export default {
        
        name: 'projects-view',
        data() {
            return {
                currentPage: 1,
                itemsPerPage: 12,
                loading: false,
                searchQuery: '',
                filteredMarkups:null,
                startIndex:0,
                onConfirmMessage: null,
                DeletedItem:''

            };
        },
        computed: {
            totalPages() {
                return Math.ceil(this.filteredMarkups?.length / this.itemsPerPage);
            },
            markups() {
                return this.$store.getters['MARKUPDATA/Markup_Elements'];
            },

            paginatedMarkups() {
                return this.filteredMarkups?.markups;
            },
            openModel() {
                return (project) => {
                    this.$router.push({
                        name: 'ModelViewer',
                        params: { id: project.forgeModelId },
                        query: { view: project.modelId, markupId: project.id },
                    });
                };
            },
            DeletedElement(){
                return this.$store.getters['MARKUPDATA/DELETED_ELEMENT'];
            }
        },
        methods: {
            Delete(){

                  this.$store.dispatch('MARKUPDATA/DeleteMarkupData', {
                    id: this.DeletedItem.id,
                    reload:false
                });
            },
            ConfirmDelete(item){
                this.DeletedItem=item;
                this.onConfirmMessage = this.Delete;
                this.$refs.messageBox.show();
            }
        },
         components: {
            MessageBox,
        },
        watch: {
            markups(value) {
                this.loading = false;
                this.filteredMarkups = value;
            },
            currentPage(value){
                 this.loading = true;
                this.filteredMarkups=[];
                 this.startIndex = (value - 1) * this.itemsPerPage;
                this.$store.dispatch('MARKUPDATA/getFilteredMarkup',{skip:this.startIndex,search:this.searchQuery,count:this.itemsPerPage}).then(res=>{
                 this.loading = false;
                this.filteredMarkups=res;
               });

            },
            searchQuery(value) {
                console.log(value);
                 this.loading = true;
                this.filteredMarkups=[];
                     this.$store.dispatch('MARKUPDATA/getFilteredMarkup',{skip:0,search:this.searchQuery,count:this.itemsPerPage}).then(res=>{
                      this.filteredMarkups=res;
                     this.loading = false;

                   });
               
            },
            DeletedElement(){
                this.loading = true;
                this.filteredMarkups=[];
                this.$store.dispatch('MARKUPDATA/getFilteredMarkup',{skip:this.startIndex,search:this.searchQuery,count:this.itemsPerPage}).then(res=>{
                 this.loading = false;
                this.filteredMarkups=res;
               });
            }
        },
        mounted() {
            this.loading = true;
            this.$store.dispatch('MARKUPDATA/getFilteredMarkup',{skip:0,search:"",count:12}).then(res=>{
             this.filteredMarkups=res;
                this.loading = false;

            });
        },
    };
</script>
<style lang="css">
    .v-card-actions .fa-solid.fa-star {
        color: yellow;
    }
    .v-card-actions .v-btn--icon:hover .fa-star {
        color: yellow;
    }

    .bottom-navigation .v-btn__content {
        gap: 5px;
    }
    i.v-icon {
        color: inherit;
    }
    #loader {
        z-index: 2;
        position: absolute;
        left: 48%;
        top: 50%;
    }
</style>
