<template lang="html">
    <div class="families-list-drawer">
        <v-list class="families-list">
            <v-list-item v-for="item in families" :key="item.id">
                <div class="card" :class="{ 'warning-card': !item.urn }">
                    <img :class="{ 'placeholder-image': !item.thumbnail }" :src="item.thumbnail ?? familyPlaceholder"
                        alt="Image">
                    <div class="card-content">
                        <span class="title">{{ item.name }}</span>
                        <span class="subtitle">{{ item.description }}</span>
                        <span class="subtitle">{{ item.createdByName }}</span>
                        <span class="subtitle">{{ formatDateTime(item.createdOn) }}</span>
                        <div class="buttons" v-if="is3d && item.urn && modelId">
                            <button @click="addToModel(item)"><i class="fas fa-plus"></i></button>
                            <!-- <button @click="deleteFamily(item)"><i class="fas fa-trash-alt"></i></button> -->
                        </div>
                        <div class="progress" v-else-if="is3d">
                            <v-progress-circular size="24" indeterminate></v-progress-circular>
                        </div>
                        <div class="progress" v-else>
                            <span class="subtitle">available in 3d</span>
                        </div>
                    </div>
                </div>
            </v-list-item>
        </v-list>
        <div class="actions">
            <input type="file" style="display: none;" accept=".rfa, .rtf" id="browseModelFile"
                @change="onFileChanged($event)">
            <v-btn color="blue-darken-1" width="150" @click="browse" :disabled="isUploading">
                <v-progress-circular v-if="isUploading" size="24" indeterminate></v-progress-circular>
                <span v-else>Add Family</span>
            </v-btn>
            <v-btn color="green-darken-1" width="150" @click="saveToModel" :disabled="isSaving">
                <v-progress-circular v-if="isSaving" size="24" indeterminate></v-progress-circular>
                <span v-else>Save</span>
            </v-btn>
        </div>
    </div>
</template>
<script>
import familyPlaceholder from '@/assets/images/autodesk.svg';
import { ManipulationTypes } from '@/models/ForgeModels';
import { toast } from 'vue3-toastify';
import { mapActions } from 'vuex';

export default {
    name: 'FamiliesList',
    components: {
    },
    data() {
        return {
            families2: [
                {
                    id: 1,
                    name: 'Family-1',
                    description: 'Family-1 description',
                    urn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6a3RzLXVzZXItMDAxLWZhbWlsaWVzL2YxLmlmYw',
                    thumbnail: familyPlaceholder
                }
            ],
            familyPlaceholder,
            selectedFile: null,
            isUploading: false,
            isSaving: false
        }
    },
    methods: {
        ...mapActions(['GetFamilies', 'UploadFamily', 'DeleteFamily', 'SaveFamilyInstanceToDB', 'AddFamilyInstanceToViewer', 'SaveLocalModelChanges']),
        toggleInstancesDrawer() {
            this.instancesDrawerOpen = !this.instancesDrawerOpen;
        },
        addToModel(family) {
            console.log(`Add to model: ${this.families[0].name}`);

            this.SaveFamilyInstanceToDB({
                id: 0,
                name: family.name,
                modelId: this.modelId,
                createdByName: this.user.name,
                manipulationType: ManipulationTypes.NEW_FAMILY_INSTANCE,
                viewType: '3d',
                viewId: window.NOP_VIEWER.model.getDocumentNode().data.guid,
                forgeFamilyId: family.id,
                position: { x: 0, y: 0, z: 0 },
                rotation: { x: 0, y: 0, z: 0 },
            }).then(this.AddFamilyInstanceToViewer).then(() => {
                toast.success('Family added to the model');
            });
        },
        onFileChanged(event) {
            if (event.target.files.length == 0) {
                this.selectedFile = null;
                return;
            }

            this.selectedFile = event.target.files[0];

            const onProgress = (progress, maxProgress) => {
                console.log(`Uploading Progress: ${(progress / maxProgress * 100).toFixed(2)}%`);
            };

            this.isUploading = true;

            this.$store.dispatch('UploadFamily', { file: this.selectedFile, onProgress })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.isUploading = false;
                    this.selectedFile = null;
                    event.target.value = null;
                });
        },
        browse() {
            document.getElementById('browseModelFile').click();
        },
        formatDateTime(date) {
            return new Date(date).toLocaleString();
        },
        saveToModel() {
            this.isSaving = true;
            this.SaveLocalModelChanges().then(() => {
                toast.success('Local changes saved to the model');
                console.log('Local changes saved to the model');
            }).catch(error => {
                console.error(error);
                toast.error('Failed to save local changes to the model');
            }).finally(() => {
                this.isSaving = false;
            });
        },
    },
    mounted() {
        if (!this.modelId) return;

        this.$store.dispatch('GetFamilies');
    },
    computed: {
        modelId: {
            get() {
                return this.$store.getters.MODEL_ID;
            }
        },
        user() {
            return this.$store.getters['ACCOUNT/USER'];
        },
        families() {
            return this.$store.getters.FAMILIES;
        },
        expandIcon() {
            return this.instancesDrawerOpen ? 'fa-solid fa-angles-left' : 'fa-solid fa-angles-right';
        },
        instancesDrawerOpen: {
            get() {
                return this.$store.getters.OPEN_INSTANCES_PANEL;
            },
            set(value) {
                this.$store.commit('SET_OPEN_INSTANCES_PANEL', value);
            }
        },
        is3d() {
            return window.NOP_VIEWER?.model?.is3d();
        }
    },
    watch: {
        modelId: {
            handler(newValue, oldValue) {
                console.log(`Model ID changed: ${oldValue} -> ${newValue}`);
                this.$store.dispatch('GetFamilies');
            }
        }
    }
}
</script>
<style lang="css" scoped>
.families-list-drawer {
    display: flex;
    flex-direction: column;
    height: calc(100% - 65px);
}

.card {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    max-width: 600px;
    column-gap: 10px;
    border: 1px solid #a4a4a473;
    background-color: #f9f9f9;
    height: 110px;
}

.card img {
    width: 40%;
    object-fit: fill;
    border-radius: 10px 0 0 10px;
}

.card-content {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 5px;
    width: 60%;
}

.card-content .title {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-content .subtitle {
    margin: 0;
    font-size: 12px;
    color: #8f8f8f;
}

.buttons {
    margin-top: auto;
    display: flex;
    gap: 5px;
    justify-content: flex-end;
}

.progress {
    margin-top: auto;
    display: flex;
    gap: 5px;
    justify-content: center;
    margin-right: 20px;
}

.buttons button {
    padding: 2px;
    width: 25px;
    border: none;
    background-color: #e2e2e25e;
    border-radius: 5px;
    cursor: pointer;
}

.buttons button:hover {
    background-color: #e2e2e2;
}

.buttons button:disabled {
    background-color: #e2e2e25e;
    cursor: not-allowed;
}


.families-list {
    position: relative;
    height: -webkit-fill-available;
    overflow: auto;
}

.actions {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 15px;
    height: 80px;
}

.warning-card {
    border-color: #ffbd5a;
    background-color: #fffdfb;
}

img.placeholder-image {
    padding: 15px;
}

.warning-card img.placeholder-image {
    border-right: 1px solid #ffedd2;
    filter: grayscale(100%);
}
</style>