<template lang="html">
    <v-container fluid class="d-flex flex-column">
        <v-card  style="overflow-y: scroll;" theme="dark">
            <v-tabs theme="dark" color="primary">
                <v-tab @click="changeTab('one')" value="one">General Settings</v-tab>
                <v-tab @click="changeTab('two')" value="two">Color Code Systems</v-tab>
                <v-tab @click="changeTab('three')" value="three">KTS Properties</v-tab>

            </v-tabs>

            <v-card-text>
                <v-tabs-window>
                    <v-tabs-window-item v-if="tab == 'one'">
                        <v-list class="settings-list">
                            <v-text-field
                                label="Rotation Snapping Degree"
                                variant="outlined"
                                theme="dark"
                                type="number"
                                hide-details
                                v-model="loadedSettings.rotationSnappingDegree"
                                class="value"></v-text-field>

                            <v-switch
                                 :true-value="true"
                                 :false-value="false"
                                  v-model="loadedSettings.nestedFamiliesSelectionMode"
                                  label="Select Nested Families Mode"
                                color="primary"></v-switch>

                            <v-switch
                                color="primary"
                                true-value="Markup"
                                false-value="Original"
                                label="Show camera image with markups by default"
                                v-model="loadedSettings.attachMarkupType"></v-switch>
                        </v-list>

                        <v-spacer></v-spacer>

                        <v-btn
                            :loading="loading"
                            @click="saveSettings"
                            class="save-btn"
                            color="success"
                            >Save</v-btn
                        >
                    </v-tabs-window-item>
                    <v-tabs-window-item v-if="tab == 'two'">
                        <ColoringSystems />
                    </v-tabs-window-item>
                    <v-tabs-window-item v-if="tab == 'three'">
                       <KtsProperties />
                    </v-tabs-window-item>
                </v-tabs-window>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import ColoringSystems from '@/features/ColoringSystems/Components/ColoringSystems.vue';
    import KtsProperties from '@/features/KTSProperties/Components/KtsProperties.vue'

    export default {
        data() {
            return {
                loading: false,
                tab: 'one',
                loadedSettings: {},
            };
        },
        methods: {
            ...mapActions('SETTINGS', ['SaveModelSettings']),
            saveSettings() {
                this.loading = true;
                this.SaveModelSettings(this.modelSettings).finally(() => {
                    this.loading = false;
                });
            },
            changeTab(value) {
                this.tab = value;
            },
        },
        mounted() {
            this.loadedSettings = this.modelSettings;
        },
        computed: {
            ...mapState('SETTINGS', ['modelSettings']),
        },
        watch: {
            modelSettings(value) {
                this.loadedSettings = value;
            },
        },
        components: {
            ColoringSystems,
            KtsProperties
        },
    };
</script>

<style lang="css">
    .settings-list {
        height: calc(100% - 50px);
    }
</style>
