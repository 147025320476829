<template>
    <slide-out
        :showMask="false"
        class="slideOut"
        dock="right"
        size="700px"
        :min-size="400"
        allow-resize
        resizable
        v-model="IS_PROPERTY_PANEL_OPENED"
        append-to="#app">
        <template v-slot:header>
            <v-card-title>
                <v-icon icon="fa:fas fa-list" size="x-small" start></v-icon>
                KTS Properties - {{ IsMultipleElements ? getHeaderName() : INSTANCE.instanceName }}
            </v-card-title>

            <v-icon @click="hidePropertyPanel">fa:fas fa-close</v-icon>
        </template>
        <v-form v-if="INSTANCE">
            <v-container class="container">
                <v-text-field
                    append-icon="fa:fas fa-magnifying-glass"
                    dense
                    placeholder="Search"
                    hide-details
                    density="compact"
                    class="mb-3"
                    solo
                    v-model="search"></v-text-field>

                <v-row>
                    <v-col
                        v-if="!IsMultipleElements"
                        cols="4"
                        class="col"
                        style="overflow-y: auto; overflow-x: auto; flex: 1">
                        <v-tabs v-model="tab" style="flex: 0" direction="vertical">
                            <v-tab
                                max-height="40"
                                color="primary"
                                v-for="(group, key) in GROUPED_PROPERTIES"
                                :key="key"
                                @click="scrollToElm(group.category)">
                                {{ group.category }}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                    <v-col
                        v-else
                        cols="4"
                        class="col"
                        style="overflow-y: auto; overflow-x: auto; flex: 1">
                        <v-tabs v-model="tab" style="flex: 0" direction="vertical">
                            <v-tab max-height="40" color="primary"> KTS Properties </v-tab>
                            <v-tab
                                max-height="40"
                                color="primary"
                                v-for="(group, key) in MultipleProps"
                                :key="key"
                                @click="scrollToElm(key)">
                                {{ key }}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                    <v-col
                        v-if="!IsMultipleElements"
                        cols="8"
                        @scroll="handleScrolling"
                        style="
                            max-height: calc(100vh - 180px);
                            overflow-y: auto;
                            overflow-x: auto;
                            flex: 1;
                        ">
                        <div
                            v-for="(group, key, index) in GROUPED_PROPERTIES"
                            :data-index="index"
                            :key="key + '-item'"
                            :id="group.category">
                            <v-card-title class="py-1">{{ group.category }}</v-card-title>
                            <v-divider></v-divider>
                            <v-list-item
                                v-for="(item, index) in group.data"
                                class="mr-3"
                                :key="index">
                                <v-text-field
                                    :label="item.displayName"
                                    class="custom-input"
                                    hide-details
                                    dense
                                    height="30px"
                                    density="compact"
                                    v-if="item.type == 20||item.type == 23"
                                    v-model="item.displayValue"
                                    theme="dark"
                                    dark
                                    variant="outlined"
                                    :disabled="item.disabled"
                                    @change="handleInputChange(item)"></v-text-field>
                      
                                    <v-text-field
                                    :label="item.displayName"
                                    class="custom-input"
                                    hide-details
                                    dense
                                    height="30px"
                                    density="compact"
                                    v-if="item.type == 3"
                                    v-model="item.displayValue"
                                    theme="dark"
                                    dark
                                    variant="outlined"
                                    :disabled="item.disabled"
                                     type="number"
                                    @change="handleInputChange(item)"></v-text-field>
                                <v-select
                                    :label="item.displayName"
                                    class="custom-input"
                                    hide-details
                                    dense
                                    height="30px"
                                    density="compact"
                                    v-if="item.type == 10"
                                    v-model="item.displayValue"
                                    theme="dark"
                                    dark
                                    variant="outlined"
                                    :items="selectOptions"
                                    @change="handleInputChange(item)"></v-select>
                            </v-list-item>
                            <v-divider class="my-0"></v-divider>
                        </div>
                    </v-col>
                    <v-col
                        v-else
                        cols="8"
                        @scroll="handleScrolling"
                        style="
                            max-height: calc(100vh - 180px);
                            overflow-y: auto;
                            overflow-x: auto;
                            flex: 1;
                        ">
                        <div
                            v-for="(group, key, index) in MultipleProps"
                            :data-index="index"
                            :key="key + '-item'"
                            :id="key">
                            <v-card-title class="py-1">{{ key }}</v-card-title>
                            <v-divider></v-divider>
                            <v-list-item
                                v-for="(item, subKey, itemIndex) in group"
                                class="mr-3"
                                :key="subKey">
                                <div class="d-flex justify-center align-center ga-md-3">
                                    <v-text-field
                                        v-if="IsMultipleElements"
                                        readonly
                                        disabled
                                        :label="subKey || item[0].displayName"
                                        v-model="groupDisplayValues[index][itemIndex]"
                                        class="custom-input"
                                        hide-details
                                        dense
                                        height="30px"
                                        density="compact"
                                        theme="dark"
                                        dark
                                        variant="outlined"></v-text-field>

                                    <v-text-field
                                        v-else
                                        :label="subKey || item[0].displayName"
                                        class="custom-input"
                                        hide-details
                                        dense
                                        height="30px"
                                        density="compact"
                                        disabled
                                        v-model="item[0].displayValue"
                                        theme="dark"
                                        dark
                                        variant="outlined"></v-text-field>

                                    <v-chip x-small v-if="item.length > 1" class="mr-1">{{
                                        item.length
                                    }}</v-chip>
                                </div>
                            </v-list-item>
                            <v-divider class="my-0"></v-divider>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
        <v-container v-else class="d-flex align-center justify-center title"
            >No Parts Selected</v-container
        >

        <template #footer>
            <v-card-actions>
                <v-btn
                    elevation="0"
                    color="green-darken-1"
                    width="150"
                    @click="saveToModel"
                    :disabled="IsMultipleElements || (!IsMultipleElements && !INSTANCE.properties)">
                    save
                </v-btn>
            </v-card-actions>
        </template>
    </slide-out>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'PropertiesList',
        components: {},
        data() {
            return {
                tab: 0,
                search: '',
                visible: true,
                varies: 'Varies',
                multipleProps: {},
                IsMultipleElements: false,
                selectOptions: ['Yes', 'No'],
            };
        },
        methods: {
            ...mapActions('InstanceProperties', ['SaveProperties']),
            saveToModel() {
                let group = this.GROUPED_PROPERTIES.filter(
                    (c) => c.category == 'KTS Properties',
                )[0];
                if (group) {
                    let data = group.data;
                    
                    if (data) {
                        let savedData = {
                            RevitId: data[0].revitId,
                            ModelId: data[0].modelId,
                            dbId: this.$store.getters['InstanceProperties/INSTANCE_DB_ID'],
                            Props: [],
                        };
                        data.filter((c) => !c.isComputed).forEach((element) => {
                            savedData.Props.push({
                                Name: element.displayName,
                                Value: element.displayValue,
                                Type: element.type,
                            });
                        });
                        savedData.Props = JSON.stringify(savedData.Props);
                        this.$store.dispatch('CUSTOMPROPERTIES/AddElementProps', savedData);
                    }
                }
                this.SaveProperties()
                    .then(() => {})
                    .catch((error) => {
                        console.error(error);
                    });
            },
            hidePropertyPanel() {
                this.$store.commit('InstanceProperties/SET_IS_PROPERTY_PANEL_OPENED', false);
            },
            handleInputChange(item) {
                item.isModified = true;
            },
            scrollToElm(selector) {
                document.getElementById(selector).scrollIntoView();
            },

            handleScrolling(e) {
                var scrollPosition = e.target.scrollTop;
                if (!this.IsMultipleElements) {
                    this.GROUPED_PROPERTIES.forEach((group, index) => {
                        var targetSection = document.getElementById(`${group.category}`);
                        if (
                            targetSection.offsetTop - 240 <= scrollPosition &&
                            targetSection.offsetTop + targetSection.offsetHeight > scrollPosition
                        ) {
                            this.tab = index;
                        }
                    });
                } else {
                    Object.keys(this.MultipleProps).forEach((group, index) => {
                        var targetSection = document.getElementById(`${group}`);
                        if (
                            targetSection.offsetTop - 240 <= scrollPosition &&
                            targetSection.offsetTop + targetSection.offsetHeight > scrollPosition
                        ) {
                            this.tab = index;
                        }
                    });
                }
            },
            async getMultipleProps() {
                this.IsMultipleElements = false;
                const selSet = window.NOP_VIEWER.getAggregateSelection();

                if (!selSet || selSet.length === 0) return {};

                if (
                    selSet[0].selection.length > 1 ||
                    window.NOP_VIEWER?.model?.selector?.selectionMode ==
                        window.Autodesk.Viewing.SelectionMode.FIRST_OBJECT
                )
                    this.IsMultipleElements = true;

                if (selSet.length > 0) {
                    const currentSelections = [];
                    selSet.forEach((entry) => {
                        if (entry.model.myData.isSceneBuilder) {
                            const instancesOriginalDbIds =
                                this.$store.getters.MANIPULATED_ELEMENTS.filter((e) =>
                                    entry.selection.includes(e.dbId),
                                ).map((c) => c.originalDbId);
                            entry.selection = instancesOriginalDbIds;
                            entry.model = window.NOP_VIEWER.model;
                        }
                        currentSelections.push({ model: entry.model, selection: entry.selection });
                    });
                    const promises = [];
                    currentSelections.forEach((entry) => {
                        promises.push(
                            entry.model.getPropertySetAsync(entry.selection, {
                                fileType: entry.model.getData()?.loadOptions?.fileExt,
                                needsExternalId:
                                    entry.model.getData()?.loadOptions?.needsExternalId,
                                ignoreHidden: true,
                            }),
                        );
                    });

                    const propSets = await Promise.all(promises);

                    const propSet = propSets[0];
                    for (let i = 1; i < propSets.length; i++) {
                        propSet.merge(propSets[i]);
                    }

                    let properties = propSet.map;

                    this.multipleProps = properties;
                }
            },
            getHeaderName() {
                if (!this.MultipleProps) {
                    return '';
                }

                let header = 'Varies';
                if (this.MultipleProps['Name']) {
                    const nameGroup = this.MultipleProps['Name'];
                    const arr = Object.values(nameGroup)[0];
                    if (arr.length > 1) {
                        header += ` (${arr.length})`;
                    }
                }

                return header;
            },
            getMultipleValue(item) {
                !item.every((obj) => obj.displayValue == item[0].displayValue)
                    ? 'Varies'
                    : item[0].displayValue;
            },
        },
        mounted() {},
        computed: {
            ...mapGetters('InstanceProperties', ['INSTANCE']),
            ...mapGetters('InstanceProperties', ['IS_PROPERTY_PANEL_OPENED']),

            IsPropertyPanelOpened() {
                return this.$store.getters['InstanceProperties/IS_PROPERTY_PANEL_OPENED'];
            },
            GROUPED_PROPERTIES() {
                if (!this.INSTANCE.properties) return [];
                this.INSTANCE.properties
                    .filter((c) => c.isComputed)
                    .forEach((element) => {
                        console.log('elementooooooo', element);
                        if (element.type == 23) {
                            let value = '';
                            element.properties.forEach((prop) => {
                                let itemprop = this.INSTANCE.properties.filter(
                                    (c) => c.displayName == prop,
                                )[0];
                                if (itemprop) {
                                    value += itemprop.displayValue;
                                } else {
                                    value += prop;
                                }
                            });
                            try {
                                value = eval(value);
                            } catch (e) {
                                value = '';
                            }
                            element.displayValue = value;
                            element['disabled'] = true;
                        } else {
                            let value = '';
                            element.properties.forEach((prop) => {
                                let itemprop = this.INSTANCE.properties.filter(
                                    (c) => c.displayName == prop,
                                )[0];
                                if (itemprop) {
                                    value += itemprop.displayValue + ' ';
                                } else {
                                    value += prop + ' ';
                                }
                            });
                            element.displayValue = value;
                            element['disabled'] = true;
                        }
                    });
                var grouped = Object.groupBy(
                    this.INSTANCE.properties,
                    ({ displayCategory }) => displayCategory,
                );
                let properties = [];

                Object.entries(grouped).forEach(([key, values]) => {
                    let searchedValues = values;

                    if (this.search) {
                        let search = this.search.toLowerCase();
                        const isInclude = key.toLowerCase().includes(search);
                        if (isInclude) {
                            searchedValues = values;
                        } else {
                            searchedValues = searchedValues.filter(
                                (x) =>
                                    String(x.displayValue)?.toLowerCase().includes(search) ||
                                    String(x.displayName)?.toLowerCase().includes(search),
                            );
                        }

                        if (!isInclude && searchedValues.length == 0) {
                            return;
                        }
                    }

                    properties.push({
                        category: key,
                        data: searchedValues,
                    });
                });
                return properties;
            },
            groupDisplayValues() {
                return Object.values(this.MultipleProps)?.map((group) =>
                    Object.values(group)?.map((item) => {
                        if (!item.every((obj) => obj.displayValue === item[0].displayValue)) {
                            return 'Varies';
                        } else {
                            return item[0].displayValue;
                        }
                    }),
                );
            },
            MultipleProps() {
                const pp = Object.keys(this.multipleProps)
                    .filter((key) => {
                        if (this.search) {
                            let search = this.search.toLowerCase();
                            let val = this.multipleProps[key];
                            return (
                                key.toLowerCase().includes(search) ||
                                (Array.isArray(val)
                                    ? val.some((elm) => {
                                          if (elm.displayValue)
                                              return String(elm.displayValue)
                                                  .toLowerCase()
                                                  .includes(search);
                                          return false;
                                      })
                                    : String(val.displayValue).toLowerCase().includes(search))
                            );
                        }
                        return true;
                    })
                    .sort((a, b) => {
                        if (a.startsWith('_') && !b.startsWith('_')) {
                            return 1; // Move strings starting with underscore to the end
                        } else if (!a.startsWith('_') && b.startsWith('_')) {
                            return -1; // Keep strings not starting with underscore at the beginning
                        } else {
                            return a.localeCompare(b); // Sort non-underscore strings lexicographically
                        }
                    })
                    .reduce((acc, curr) => {
                        const keys = curr.split('/');
                        if (!acc[keys[0]]) {
                            acc[keys[0]] = {};
                        }
                        Object.assign(acc[keys[0]], { [keys[1]]: this.multipleProps[curr] });
                        return acc;
                    }, {});

                return pp;
            },
        },
        watch: {
            IsPropertyPanelOpened: {
                handler(val) {
                    if (val) {
                        this.getMultipleProps();
                        window.NOP_VIEWER?.addEventListener(
                            window.Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
                            () => {
                                this.getMultipleProps();
                            },
                        );
                    } else {
                        window.NOP_VIEWER?.removeEventListener(
                            window.Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
                            () => {
                                this.getMultipleProps();
                            },
                        );
                    }
                },
                deep: true,
            },
        },
    };
</script>

<style lang="css" scoped>
    /* .v-input .v-input__control .v-field {
    font-size: small !important;
}

.v-input .v-input__control .v-label {
    font-size: small !important;
}

.v-input .v-input__control .v-field-label--floating {
    font-size: x-small !important;
} */

    .container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .col {
        max-height: calc(100vh - 180px);
        height: calc(100vh - 180px);
        color: #a1a1a1;
        background: #1e1e1e;
    }

    .trimmed-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .families-list-drawer {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .card {
        display: flex;
        border-radius: 10px;
        overflow: hidden;
        max-width: 600px;
        column-gap: 10px;
        border: 1px solid #a4a4a473;
        background-color: #f9f9f9;
        height: 110px;
    }

    .card img {
        width: 40%;
        object-fit: fill;
        border-radius: 10px 0 0 10px;
    }

    .card-content {
        display: flex;
        flex-direction: column;
        gap: 2px;
        padding: 5px;
        width: 60%;
    }

    .card-content .title {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        color: #333;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .card-content .subtitle {
        margin: 0;
        font-size: 12px;
        color: #8f8f8f;
    }

    .button {
        margin-right: 15px;
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
    }

    .buttons button {
        padding: 2px;
        width: 25px;
        border: none;
        background-color: #e2e2e25e;
        border-radius: 5px;
        cursor: pointer;
    }

    .buttons button:hover {
        background-color: #e2e2e2;
    }

    .buttons button:disabled {
        background-color: #e2e2e25e;
        cursor: not-allowed;
    }

    .families-list {
        position: relative;
        height: -webkit-fill-available;
        overflow: auto;
    }

    .actions {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 15px;
        height: 80px;
    }

    .panel-header {
        margin: 0;
        padding: 10px;
        font-size: 20px;
        font-weight: 600;
        color: #eee7e7;
        border-bottom: 1px solid #a4a4a473;
        margin-bottom: 5px;
        width: 100%;
        text-align: center;
    }

    .warning-card {
        border-color: #ffbd5a;
        background-color: #fffdfb;
    }

    img.placeholder-image {
        padding: 15px;
    }

    .warning-card img.placeholder-image {
        border-right: 1px solid #ffedd2;
        filter: grayscale(100%);
    }

    .expand-icon {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 12px;
        right: 20px;
        cursor: pointer;
    }

    .expand-icon:hover i {
        color: #333;
    }
</style>

<!-- <style lang="scss">
.alliedPropsTabs {
    .v-slide-group__wrapper {
        max-height: calc(100vh - 209px);
        overflow: auto;
    }

    .v-tab {
        justify-content: left;
    }
}

.vue-slideout.alliedPropsSlide {
    z-index: 101 !important;
}
</style> -->
