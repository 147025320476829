import RequestView from '@Admin/views/RequestView.vue';
import SettingsList from '@/components/SettingsList.vue'
export default [
    {
        path: '/requests',
        name: 'requests',
        component: RequestView,
        meta: {
            layout: 'projects-layout',
            requiresAuth: true,
            title: 'Requests',
        },
    },
    {
        path: '/settings',
        name: 'settings',
        component: SettingsList,
        meta: {
            layout: 'projects-layout',
            requiresAuth: true,
            title: 'Settings',
        },
    },
];
