import httpService from "@/utils/httpService"
export default{
    GetTemplates(id){
        return httpService.get({
             url: `api/Templates/`+id,
         })
     },
     AddTemplate(data){
        return httpService.post({
             url: `api/Templates`,
             data:data
         })
     },
     DeleteTemplate(id){
        return httpService.delete({
             url: `api/Templates`,
             data:id
         })
     },
     GetConditions(id)
     {
        return httpService.get({
            url: `api/ColoringSystem/`+id,
        })
     },
     AddCondition(data)
     {
        return httpService.post({
            url: `api/ColoringSystem`,
            data:data
        })
     },
     UpdateCondition(data)
     {
        return httpService.put({
            url: `api/ColoringSystem`,
            data:data
        })
     },
     DeleteCondition(id)
     {
        return httpService.delete({
            url: `api/ColoringSystem/`+id,
        })
     },
     ChangeEnableState(id)
     {
        return httpService.post({
            url: `api/ColoringSystem/ChangeEnableState/`+id,
        })
     }
}