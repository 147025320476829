<template>
    <v-card>
        <v-tabs v-model="tab">
            <v-tab @click="resetTab(0)" value="one">3D</v-tab>
            <v-tab @click="resetTab(1)" value="two">2D</v-tab>
        </v-tabs>
        <div id="searchCont">
            <v-text-field v-model="searchText" @keyup="search" label="search"></v-text-field>
        </div>

        <v-card-text>
            <div v-if="tabNumber == 0">
                <v-list>
                    <v-list-item
                        @click="changeNode(false, item, true)"
                        v-for="(item, i) in filtered3d"
                        :key="i"
                        :value="item"
                        rounded="shaped">
                        <v-list-item-title v-text="item.data.name"></v-list-item-title>
                        <img :src="item.imgUrl" style="width: 100px; height: 100px" />
                    </v-list-item>
                </v-list>
                <div
                    v-if="filtered3d.length == 0"
                    class="py-16 d-flex align-center justify-center flex-column h-99">
                    <v-img width="300" max-height="350px" contain src="/img/No Data.svg"></v-img>
                    <div class="pt-4 title">No Data Found</div>
                </div>
            </div>
            <div v-if="tabNumber == 1">
                <v-list>
                    <v-list-item
                        v-for="(item, i) in filtered2d"
                        :key="i"
                        :value="item"
                        rounded="shaped">
                        <v-sheet class="d-flex justify-space-between align-center">
                            <v-sheet @click="changeNode(true, item, true)">
                                <v-list-item-title>{{ item.data.name }}</v-list-item-title>
                                <div class="d-inline-block">
                                    <img :src="item.imgUrl" style="width: 100px; height: 100px" />
                                </div>
                            </v-sheet>
                            <v-sheet class="d-flex align-center">
                                <v-sheet v-if="is3d">
                                    <v-btn v-if="item.viewportId" icon variant="plain" key="add" @click="toggleModel(item)">
                                        <v-icon color="success" v-if="!isModelLoaded(item)">mdi-eye</v-icon>
                                        <v-icon color="warning" v-else>mdi-eye-off</v-icon>
                                    </v-btn>
                                </v-sheet>

                                <v-switch
                                    v-else
                                    :disabled="switchDisabled(item)"
                                    class="d-flex switch"
                                    v-model="item.lodNode"
                                    :label="`${model}`"
                                    false-value="true"
                                    true-value="false"
                                    color="primary"
                                    @click="changeNode(true, item)"
                                    hide-details></v-switch>
                            </v-sheet>
                        </v-sheet>
                    </v-list-item>
                </v-list>
                <div
                    v-if="filtered2d.length == 0"
                    class="py-16 d-flex align-center justify-center flex-column h-99">
                    <v-img width="300" max-height="350px" contain src="/img/No Data.svg"></v-img>
                    <div class="pt-4 title">No Data Found</div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        data: () => ({
            tab: null,
            tabNumber: 0,
            d2Items: [],
            d3Items: [],
            filtered2d: [],
            filtered3d: [],
            searchText: '',
            model: 'Add',
        }),
        methods: {
            resetTab(value) {
                this.tabNumber = value;
            },
            changeNode(is2d, item, change = false) {
                let query = { ...this.$route.query };
                delete query.markupId;
                this.$router.replace({ query: query });
                if (change) {
                    this.d2Items.forEach((element) => {
                        element.lodNode = 'true';
                    });
                    this.$store.dispatch('loadDocumentNode', { is2d: is2d, guid: item.data.guid });
                } else if (item.lodNode == 'false') {
                    this.$store.dispatch('loadDocumentNode', {
                        is2d: is2d,
                        guid: item.data.guid,
                        extra: { remove: true },
                    });
                } else {
                    this.$store.dispatch('loadDocumentNode', {
                        is2d: is2d,
                        guid: item.data.guid,
                        extra: { add: true },
                    });
                }
            },
            search() {
                if (this.searchText != '') {
                    this.filtered2d = this.d2Items.filter((c) =>
                        c.data.name
                            .toLocaleLowerCase()
                            .includes(this.searchText.toLocaleLowerCase()),
                    );
                    this.filtered3d = this.d3Items.filter((c) =>
                        c.data.name
                            .toLocaleLowerCase()
                            .includes(this.searchText.toLocaleLowerCase()),
                    );
                } else {
                    this.filtered2d = this.d2Items;
                    this.filtered3d = this.d3Items;
                }
            },
            switchDisabled(item) {
                return window.loadedModel.data.guid == item.data.guid;
            },
            canShowIn3d(item) {
                return Object.keys(this.markers).some(
                    async (key) =>
                        (await this.markers[key].viewport?.getParentSheetNode())?.data?.guid ==
                        item.data.guid,
                );
            },
            toggleModel(item) {
                let ext = window.NOP_VIEWER.getLoadedExtensions()?.ModelSheetTransitionExtension;
                ext.markers[item.viewportId].onShowHide();
            },
            isModelLoaded(item) {
                let ext = window.NOP_VIEWER.getLoadedExtensions()?.ModelSheetTransitionExtension;
                return ext.markers[item.viewportId]?.isSheetVisible;
            },
            updateMarkers() {
                if (!this.markers || !this.is3d) return;
                Object.keys(this.markers).forEach(async (key) => {
                    let item = this.markers[key];
                    if (item.viewport) {
                        let sheetNode = await item.viewport.getParentSheetNode();
                        if (sheetNode) {
                            this.d2Items.forEach((element) => {
                                if (element.data.guid == sheetNode.data.guid) {
                                    element.viewportId = key;
                                }
                            });
                        }
                    }
                });
            },
        },
        mounted() {
            this.d2Items = this.$store.getters.D2ViewItems;
            this.filtered2d = this.$store.getters.D2ViewItems;
            this.d3Items = this.$store.getters.D3ViewItems;
            this.filtered3d = this.$store.getters.D3ViewItems;
            if (this.MarkupAddedModels) {
                this.filtered2d.forEach((element) => {
                    if (this.MarkupAddedModels.filter((c) => c.modelGuid == element.data.guid)[0]) {
                        element.lodNode = 'false';
                    }
                });
            }
        },
        computed: {
            MarkupAddedModels() {
                return this.$store.getters['MARKUPEDITOR/MARKUP_ADDED_MODEL'];
            },
            is3d() {
                return this.$store.getters.IS_3D;
            },
            modelSheetTransitionExt() {
                return window.NOP_VIEWER.getLoadedExtensions()?.ModelSheetTransitionExtension;
            },
            markers() {
                return this.modelSheetTransitionExt?.markers;
            },
        },
        watch: {
            MarkupAddedModels(value) {
                this.filtered2d.forEach((element) => {
                    if (value.filter((c) => c.modelGuid == element.data.guid)) {
                        element.lodNode = false;
                    }
                });
            },
            filtered2d: {
                handler() {
                    this.updateMarkers();
                },
                immediate: true,
            },
            is3d: {
                handler() {
                    this.updateMarkers();
                },
                immediate: true,
            },
        },
    };
</script>
<style scoped>
    .v-tab.v-tab.v-btn {
        width: 50% !important;
    }
    #searchCont {
        width: 90%;
        margin: auto;
        margin-top: 2%;
        margin-bottom: -10%;
    }
    .v-card-text {
        height: calc(100% - 100px);
    }
    .switch {
        position: absolute;
        bottom: 25%;
        right: 0;
    }
</style>
