<template lang="html">
    <v-dialog v-model="projectsPopup" width="500" theme="dark">
        <v-card max-width="400" height="600">
            <v-card-title>{{ dialog.title }}</v-card-title>
            <div class="scroll-container">
                <HubsTree></HubsTree>
            </div>
            <v-spacer> </v-spacer>
            <template v-slot:actions>
                <v-btn
                    class="ms-auto"
                    color="error"
                    text="Close"
                    @click="projectsPopup = false"></v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    width="200"
                    variant="tonal"
                    :disabled="!selectedTreeNode"
                    :loading="dialog.loading"
                    color="primary"
                    @click="openSelectedProject"
                >Open</v-btn>
            </template>
        </v-card>
    </v-dialog>
</template>
<script>
    import HubsTree from '@/components/HubsTree.vue';
import projectService from '../service/project.service';
import { $handleError } from '@/utils';
    export default {
        name: 'projects-popup',
        components: {
            HubsTree,
        },
        data() {
            return {
                dialog: {
                    title: 'Open Project',
                    loading: false,
                },
            };
        },
        methods: {
            openSelectedProject() {

                this.dialog.loading = true;

                let userId = this.$store.getters["ACCOUNT/USER"]?.userId;

                projectService.checkProjectCheckIn(this.selectedTreeNode, userId).then(() => {
                    this.$store.commit('PROJECTS/SET_CURRENT_PROJECT_DATA', this.selectedTreeNode);
                    this.$router.push({ name: 'ProjectModelsBrowser', params: { id: this.selectedTreeNode.id } });
                    this.dialog.loading = false;
                    this.selectedTreeNode = null;
                    this.projectsPopup = false;
                }).catch(e => {
                    $handleError(e);
                    this.dialog.loading = false;
                });
            },
        },
        computed: {
            projectsPopup: {
                get() {
                    return this.$store.getters['PROJECTS/PROJECTS_POPUP'];
                },
                set(value) {
                    this.$store.commit('PROJECTS/SET_PROJECTS_POPUP', value);
                },
            },
            selectedTreeNode: {
                get() {
                    return this.$store.getters['SELECTED_TREE_NODE'];
                },
                set(value) {
                    this.$store.commit('SET_SELECTED_TREE_NODE', value);
                },
            },
            currentModel() {
                return this.$store.getters['MODELS/CURRENT_MODEL_DATA'];
            },
        },
    };
</script>
<style lang="css">
    .scroll-container {
        height: 100%;
        overflow-y: auto;
    }
</style>
