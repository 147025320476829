import store from '@/store';
import modelService from '@Models/service/model.service';
import { $handleError } from '@Utils';

export const MODELS = {
  namespaced: true,
  state: {
    currentModelData: null,
    modelsList: [],
  },
  getters: {
    CURRENT_MODEL_DATA(state) {
      return state.currentModelData;
    },
    MODELS_LIST(state) {
      return state.modelsList;
    },
  },
  mutations: {
    SET_CURRENT_MODEL_DATA(state, value) {
      state.currentModelData = value;
    },
    SET_MODELS_LIST(state, value) {
      state.modelsList = value;
    },
  },
  actions: {
    UpdateModel({ state, commit, dispatch }) {
      let model = structuredClone(state.currentModelData);

      if (!model) return;

      let user = store.getters['ACCOUNT/USER'];

      model.userId = user.userId
      model.userName = user.userName

      modelService.updateModel(model)
        .then((data) => {
          commit('SET_CURRENT_MODEL_DATA', data)
          dispatch('UserCheckInModel');
        })
        .catch((error) => {
          $handleError(error);
        });
    },
    GetModelData({ commit }, modelId) {
      modelService.getById(modelId)
        .then((data) => {
          commit('SET_CURRENT_MODEL_DATA', data)
        })
        .catch((error) => {
          $handleError(error);
        });
    },
    UserCheckInModel({ getters }) {
      modelService.userCheckInModel({
        userId: store.getters["ACCOUNT/USER"]?.userId,
        modelId: getters["CURRENT_MODEL_DATA"].id
      })
    },
    GetRecentModels({ commit }, { query, projectId }) {
      return modelService.getRecentModels(projectId, query).then(models => {
        commit('SET_MODELS_LIST', models)
      })
    },
    GetUserRecentModels({ commit }, { query, projectId }) {
      let userId = store.getters["ACCOUNT/USER"]?.userId;
      return modelService.getUserRecentModels(userId, projectId, query).then(models => {
        commit('SET_MODELS_LIST', models)
      })
    },
    GetFavoriteModels({ commit }, { query, projectId }) {
      let userId = store.getters["ACCOUNT/USER"]?.userId;
      return modelService.getFavoriteModels(userId, projectId, query).then(models => {
        commit('SET_MODELS_LIST', models)
      })
    },
    ToggleFavorite(_, model) {
      let userId = store.getters["ACCOUNT/USER"]?.userId;
      return modelService.updateUserModelFavorite({
        userId,
        modelId: model.id,
        isFavorite: !model.isFavorite
      });
    },
    OPEN_SELECTED_MODEL({ getters }) {
      let model = getters.CURRENT_MODEL_DATA;
      if (!model) return;
      store.dispatch("InitializeViewer", {
        id: model.storageUrl,
        urn: model.urn,
        ref: model.ref,
      });
    }
  },
}