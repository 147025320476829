import { toast } from 'vue3-toastify';
import CustomPropertiesService from '../services/CustomProperties.service';
import store from '@/store';
export const CUSTOMPROPERTIES = {
    namespaced: true,
    state: {
        customProp: [],
        selectedElementProps:"",
    },
    getters: {
        CUSTOM_PROP(state) {
            return state.customProp;
        },
        SELECTED_ELEMENT_PROPS(state){
            return state.selectedElementProps;
        }
    },
    mutations: {
        SET_CUSTOM_PROP(state, value) {
            state.customProp = value;
        },
        SET_SELECTED_ELEMENT_PROPS(state,value){
            state.selectedElementProps=value;
        }
    },
    actions: {
        GetCustomProps(store) {
            CustomPropertiesService.Get().then((res) => {
                res.forEach(element => {
                    if (element.properties.length>0) {
                        element.properties=element.properties.split(',')
                      }
                });
               
                store.commit('SET_CUSTOM_PROP', res);
            });
        },
        AddCustomProp(_, data) {
            let props=store.getters['COLORINGSYSTEMS/MODEL_PROPS'];
            if (props.find(c=>c==data.Name)) {
                toast.error('there is a property with same name');
            }
            else{
                CustomPropertiesService.Add(data).then(() => {
                    store.dispatch('CUSTOMPROPERTIES/GetCustomProps');
                    store.dispatch('COLORINGSYSTEMS/AddPropToColorProperties', data.Name);
                    toast.success('Property added successfully');
                });
            }
           
        },
        UpdateCustomProp(_, data) {
            CustomPropertiesService.Update(data).then(() => {
                store.dispatch('CUSTOMPROPERTIES/GetCustomProps');
                store.dispatch('COLORINGSYSTEMS/AddPropToColorProperties', data.Name);
                toast.success('Property updated successfully');
            });
        },
        DeleteTemplate(store, id) {
            CustomPropertiesService.Delete(id).then(() => {
                store.dispatch('GetCustomProps');
                toast.success('Property Deleted successfully');
            });
        },
        GetElementProps(store,data){
            CustomPropertiesService.GetElementProps(data.modelId,data.revitId).then((res) => {
                store.commit('SET_SELECTED_ELEMENT_PROPS', res);
            });
        },
        AddElementProps(_,data){
            CustomPropertiesService.AddElementProps(data).then(() => {
   
                store.dispatch('COLORINGSYSTEMS/ReApplyTemplateColors');
                //toast.success('Properties Added successfully');
            });
        }
    },
};