import store from '@/store';
export const MODELBROWSER = {
    namespaced: true,
    state: {
        modelBrowserTree: {},
        modelBrowserTreeArray: [],
    },
    getters: {
        MODEL_BROWSER_TREE(state) {
            return state.modelBrowserTree;
        },
        MODEL_BROWSER_TREE_ARR(state) {
            return state.modelBrowserTreeArray;
        },
    },
    mutations: {
        SET_MODEL_BROWSER_TREE(state, value) {
            state.modelBrowserTree = value;
        },
    },
    actions: {
        BuilModelTree(store, data) {
            if (!window.NOP_VIEWER.model?.getInstanceTree()) return;
            window.NOP_VIEWER.model.getInstanceTree().enumNodeChildren(
                data.parent.key,
                (childId) => {
                    let child = {
                        key: childId,
                        text:
                            window.NOP_VIEWER.model
                                .getInstanceTree()
                                .getNodeName(childId)
                                .substring(0, 14) + '...',
                        originalText: window.NOP_VIEWER.model
                            .getInstanceTree()
                            .getNodeName(childId),
                        children: [],
                        parents: [],
                    };
                    child.parents.push(...data.parent.parents);
                    child.parents.push(data.parent.key);
                    store.dispatch('BuilModelTree', { parent: child, nodes: data.nodes });
                    data.parent.children.push(childId.toString());
                    data.nodes[childId] = child;
                    store.state.modelBrowserTreeArray.push(child);
                },
                false,
            );
        },
        InitiateModelBrowser(store) {
            let parent = {
                key: 1,
                text: window.NOP_VIEWER.model.getInstanceTree().getNodeName(1),
                originalText: window.NOP_VIEWER.model.getInstanceTree().getNodeName(1),
                children: [],
                parents: [],
            };
            let modelBrowserTree = {};
            modelBrowserTree[1] = parent;
            store.state.modelBrowserTreeArray = [];
            store.state.modelBrowserTreeArray.push(parent);
            setTimeout(() => {
                store
                    .dispatch('BuilModelTree', { parent: parent, nodes: modelBrowserTree })
                    .then(() => {
                        store.commit('SET_MODEL_BROWSER_TREE', modelBrowserTree);
                    });
            }, 2000);
        },
        Isolate(_, node) {
            window.NOP_VIEWER.isolate(parseInt(node.id));
            window.NOP_VIEWER.fitToView();
        },
        Select(_, node) {
            if (node.select) {
                let item=parseInt(node.id);
                let preventedSelection = store.getters.Prevented_Selection_DBIDS;
                const index = preventedSelection.indexOf(item);
                if (index > -1) {
                    preventedSelection.splice(index, 1); 
                }
                store.dispatch('SetPreventSelectionDbIds', { dbIds: preventedSelection });
                window.NOP_VIEWER.unlockSelection(parseInt(node.id));
                node['select'] = false;
            } else {
                window.NOP_VIEWER.lockSelection(parseInt(node.id), true);
                let preventedSelection = store.getters.Prevented_Selection_DBIDS;
                preventedSelection = [...preventedSelection, parseInt(node.id)];
                store.dispatch('SetPreventSelectionDbIds', { dbIds: preventedSelection });
                node['select'] = true;
            }
        },
        Hide(_, node) {
            if (node.hide) {
                window.NOP_VIEWER.show(parseInt(node.id));
                node['hide'] = false;
            } else {
                node['hide'] = true;
                window.NOP_VIEWER.hide(parseInt(node.id));
            }
        },
    },
};
