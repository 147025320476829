<template lang="html">
    <v-sheet class="projects-view h-100" theme="dark">
        <v-container class="pa-8">
            <v-row v-if="paginatedProjects.length || searchQuery">
                <v-spacer></v-spacer>
                <v-col cols="12" xl="2" lg="3" md="4" sm="6">
                    <v-text-field class="custom-input" v-model="searchQuery" color="primary" :loading="isLoading"
                        placeholder="Search Projects" hide-details density="compact" clearable
                        prepend-inner-icon="fa:fas fa-search fa-lg">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <template v-if="isLoading">
                    <v-col v-for="i in 4" :key="i" cols="12">
                        <v-skeleton-loader class="mx-auto border" type="article"></v-skeleton-loader>
                    </v-col>
                </template>
                <template v-for="(project, index) in paginatedProjects" :key="`project-${project.id}-${index}`" v-else>
                    <v-col cols="12" class="py-1">
                        <v-card class="pa-2 d-flex rounded-lg" border="primary md" v-ripple link rounded>
                            <v-card-title class="pa-2">
                                <v-avatar size="50" class="rounded-lg">
                                    <v-tooltip right :z-index="999" nudge-right="-4px">
                                        <template v-slot:activator="{ props }">
                                            <div v-bind="props" style="width: 100%; height: 100%">
                                                <div style="width: 100%; height: 100%">
                                                    <img width="100%" height="100%" style="cursor: pointer !important"
                                                        :src="
                                                            project.thumbnailUrl ||
                                                            '/img/project-default.png'
                                                        " :key="`thumbnail-${project.id}`" />
                                                </div>
                                            </div>
                                        </template>
                                        <span>
                                            <v-img :src="
                                                    project.thumbnailUrl ||
                                                    '/img/project-default.png'
                                                " width="auto" max-width="250px" contain>
                                            </v-img>
                                        </span>
                                    </v-tooltip>
                                </v-avatar>
                            </v-card-title>
                            <v-list-item-title style="width:200px" class="mx-2 mt-1 d-flex flex-column">
                                <v-list-item-title class="ml-2">{{ project.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <v-chip class="white--text my-1 mr-1" link hide-details size="small" :elevation="1"
                                        color="primary">{{ project.hubName }}</v-chip>
                                </v-list-item-subtitle>
                            </v-list-item-title>
                            <v-spacer></v-spacer>
                            <ul style="list-style-type: none">
                                <li @click="openLatestVersion(project, item)" v-for="item in project.lastOpenedModels"
                                    :key="item.version">

                                    <a class="modelLink">{{item.modelName}}</a>


                                </li>
                            </ul>
                            <v-spacer></v-spacer>
                            <v-card-actions>
                                <v-btn :color="project.isFavorite ? 'yellow' : 'grey'" icon v-if="
                                        $route.name === 'Recent' ||
                                        $route.name === 'UserRecent' ||
                                        $route.name === 'Favorites'
                                    " @click="toggleFavorite(project)">
                                    <v-icon>{{
                                        project.isFavorite
                                        ? 'fa:fa-solid fa-star'
                                        : 'fa:fa-regular fa-star'
                                        }}</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn color="success" variant="tonal" @click="openProjectContent(project)">Open
                                    Project</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </template>
            </v-row>
            <v-row v-if="!isLoading">
                <v-col cols="12">
                    <v-pagination v-if="paginatedProjects.length" v-model="currentPage" :length="totalPages"
                        next-icon="mdi:mdi-chevron-right" prev-icon="mdi:mdi-chevron-left" @input="onPageChange">
                    </v-pagination>
                    <v-alert v-if="!paginatedProjects.length" icon="mdi:mdi-alert-circle" type="warning" outlined>No
                        projects found</v-alert>
                </v-col>
            </v-row>
            <v-bottom-navigation height="40" v-model="$router.name" theme="dark" class="bottom-navigation"
                color="primary">
                <v-btn to="recent">
                    <v-icon>fa:fa-regular fa-clock</v-icon>
                    Recent
                </v-btn>
                <v-btn to="user-recent">
                    <v-icon>fa:fa-regular fa-user</v-icon>
                    My Projects
                </v-btn>
                <v-btn to="favorites">
                    <v-icon>fa:fa-solid fa-star</v-icon>
                    Favorites
                </v-btn>
            </v-bottom-navigation>
        </v-container>
    </v-sheet>
</template>
<script>
    import { debounce } from 'lodash';
import modelService from '@Models/service/model.service';
    export default {
        name: 'projects-view',
        components: {},
        data() {
            return {
                currentPage: 1,
                itemsPerPage: 6,
                isLoading: false,
                searchQuery: '',
            };
        },
        computed: {
            totalPages() {
                return Math.ceil(this.projects.length / this.itemsPerPage);
            },
            projects() {
                return this.$store.getters['PROJECTS/PROJECTS_LIST'];
            },
            paginatedProjects() {
                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = this.currentPage * this.itemsPerPage;
                return this.projects.slice(startIndex, endIndex);
            },
            currentProject() {
                return this.$store.getters['PROJECTS/CURRENT_PROJECT_DATA'];
            },
        },
        methods: {
            onPageChange(page) {
                this.currentPage = page;
            },
            getProjectTitle(project) {
                return `${project.modelName} - ${project.version.split(':')[0] || project.version}`;
            },
            openModel(project) {
                this.$router.push({ name: 'ModelViewer', params: { id: project.id } });
            },
            openLatestVersion(project, item) {
                let user = this.$store.getters['ACCOUNT/USER'];
                const modelDetails = { urn: item.urn, itemId: item.itemId, projectId: project.projectId }
                this.$store.dispatch('GetLatestVersion2', modelDetails).then(latestVersion => {

                    let model = {
                        hubName: project.hubName,
                        projectId: latestVersion.projectId,
                        projectName: project.name,
                        modelName: project.lastOpenedModels[0]?.modelName,
                        version: latestVersion.name,
                        urn: latestVersion.urn,
                        storageUrl: latestVersion.ref,
                        userId: user.userId,
                        userName: user.userName,
                        itemId: latestVersion.itemId
                    };
    
                    modelService.updateModel(model)
                        .then((data) => {
                            this.$router.push({ name: 'ModelViewer', params: { id: data.id }, query: { ...this.$route.query } })
                        })
                        .catch(() => {
                            console.error('Failed to save local changes to the model');
                        });
                })

            },
            updateProjectsList() {
                this.isLoading = true;
                let action =
                    this.$route.name === 'Favorites'
                        ? 'PROJECTS/GetFavoriteProjects'
                        : this.$route.name === 'UserRecent'
                        ? 'PROJECTS/GetUserRecentProjects'
                        : 'PROJECTS/GetRecentProjects';
                let payload = { query: this.searchQuery,favCount:4 };

                this.$store.dispatch(action, payload).then(() => {
                    this.isLoading = false;
                });
            },
            toggleFavorite(project) {
                this.$store.dispatch('PROJECTS/ToggleFavorite', project).then(() => {
                    project.isFavorite = !project.isFavorite;
                    this.updateProjectsList();
                });
            },
            debouncedUpdateProjectsList: debounce(function () {
                this.updateProjectsList();
            }, 500),
            openProjectContent(project) {
                this.$store.commit('PROJECTS/SET_CURRENT_PROJECT_DATA', project);
                this.$router.push({ name: 'ProjectModelsBrowser', params: { id: project.id } });
            },
            getItemImg(item){
                return item.thumbnailUrl || '/img/project-default.png';
            }
        },
        created() {
            this.isLoading = true;
            this.$store.dispatch('ACCOUNT/CheckAuth').then(() => {
                this.updateProjectsList();
            });
        },
        watch: {
            $route() {
                this.updateProjectsList();
            },
            searchQuery() {
                this.debouncedUpdateProjectsList();
            },
            currentProject(value) {
                if (!value) this.updateProjectsList();
            },
        },
    };
</script>
<style lang="css">
    .recent-model-img .v-img__img {
        object-fit: cover;
    }

    .v-card-actions .fa-solid.fa-star {
        color: yellow;
    }

    .v-card-actions .v-btn--icon:hover .fa-star {
        color: yellow;
    }

    .bottom-navigation .v-btn__content {
        gap: 5px;
    }

    i.v-icon {
        color: inherit;
    }

    .text--gray--1 {
        color: #cccccc;
        font-size: 1rem;
    }
    .project-chip {
        width: 50px;
        place-content: center;
    }
    .modelLink{
        color: lightblue;
    }
     .modelLink:hover{
        color: cornflowerblue;
        cursor: pointer;

    }
</style>
