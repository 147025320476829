/* eslint-disable no-unused-vars */
const Autodesk = window.Autodesk;
const THREE = window.THREE;
const LevelsExtension = "Autodesk.AEC.LevelsExtension";

const e = e => e.impl.is2d && e.impl.get2DModels()[0];
const t = async (e, t, i) => {
	const n = new THREE.Vector3
		, o = new THREE.Quaternion
		, s = new THREE.Vector3;
	t.decompose(n, o, s);
	const r = await e.getExtensionAsync("Autodesk.Section")
		, A = new THREE.Vector3(0, 0, 1);
	if (A.applyQuaternion(o),
		i) {
		var a;
		const t = e.getExtension("Autodesk.AEC.LevelsExtension");
		null == t || null === (a = t.floorSelector) || void 0 === a || a.selectFloor(void 0, false)
	}
	const l = e.toolController.setIsLocked(false);
	r.setSectionPlane(A, n, false),
		e.toolController.setIsLocked(l)
}
const o = (() => {
	let e = null;
	return async (t, i, n, o) => {
		var s;
		e && (e.skip(),
			e = null);
		const r = new THREE.Box3
			, A = i.getBoundingBox(true, false).clone()
			, a = (null === (s = i.getViewportBounds()) || void 0 === s ? void 0 : s.clone()) || A;
		var l;
		n = (!(l = n) || l instanceof THREE.Box3 ? l : (l.min.z = l.max.z = 0,
			new THREE.Box3(l.min, l.max))) || A;
		const d = () => {
			t.impl.setViewportBounds(i, r)
		}
			, h = e => {
				e = Autodesk.Viewing.Private.smootherStep(e),
					r.min.lerpVectors(a.min, n.min, e),
					r.max.lerpVectors(a.max, n.max, e),
					d()
			}
			;
		return new Promise((t => {
			const i = () => {
				e = null,
					setTimeout(t)
			}
				;
			0 === o ? (r.copy(n),
				d(),
				t()) : e = Autodesk.Viewing.Private.fadeValue(0, 1, o, h, i)
		}
		))
	}
})()
const s = (e, t) => {
	e.impl.setPlacementTransform(t, new THREE.Matrix4),
		t.changePaperVisibility(true),
		e.impl.setDoNotCut(t, true),
		o(e, t, null, 0)
}
const loadModel = (v, e) => {
	let globalOffset = (new THREE.Vector3).copy(v.model.myData.globalOffset);
	let s = e.getRootNode().getDocument();
	const l = {
		preserveView: true,
		keepCurrentModels: true,
		globalOffset: globalOffset,
		applyRefPoint: true,
		applyScaling: 'm',
		isAEC: true,
		// loadAsHidden: true,
		bubbleNode: e,
		disablePrecomputedNodeBoxes: true,
		disable3DModelLayers: true,
		leafletOptions: {
			fitPaperSize: true
		}
	}
	return v.loadDocumentNode(s, e, l)
}
const showModel = (v, e) => {
	return v.showModel(e.id, true)
}
const hideModel = (v, e) => {
	v.hide(e)
	return v.unloadDocumentNode(e)
}

import ViewportBoundsPlane from './ViewportBoundsPlane';
import Transition2DTo3D from './Transition2DTo3D';
import Transition3DTo2D from './Transition3DTo2D';
import SheetMarker from './SheetMarker';
import SheetContextMenu from './SheetContextMenu';

class ModelSheetTransitionExtension extends Autodesk.Viewing.Extension {
	constructor(e, options) {
		options = options || {};
		super(e, options);
		this.loadDocument = options.loadDocument;
		this.loadModel = (e => loadModel(this.viewer, e));
		this.showModel = (e => showModel(this.viewer, e));
		this.hideModel = (e => hideModel(this.viewer, e));
		this.displayError = options.displayError || this._displayError.bind(this);
		this.updateUI = this.updateUI.bind(this);
		this.updateMarkersClientLocation = this.updateMarkersClientLocation.bind(this);
		this.updateMarkers = this.updateMarkers.bind(this);
		this.addMarkersForModel = this.addMarkersForModel.bind(this);
		this.removeMarkersForModel = this.removeMarkersForModel.bind(this);
		this.onViewportDataFetched = this.onViewportDataFetched.bind(this);
		this.onSelectedFloorChanged = this.onSelectedFloorChanged.bind(this);
		this.onExtensionLoaded = this.onExtensionLoaded.bind(this);
		this.onExtensionUnloaded = this.onExtensionUnloaded.bind(this);
		this.sheetToModel = new Transition2DTo3D(this);
		this.modelToSheet = new Transition3DTo2D(this);
		this.defaultPosition2D = new THREE.Vector3(0, 0, 1);
		this.defaultTarget2D = new THREE.Vector3(0, 0, 0);
		this.areMarkersVisible = false;
		this.markers = {};
	}

	async load() {
		if (!this.loadModel || !this.showModel || !this.hideModel) {
			console.error("One of ModelSheetTransition callbacks is missing.");
			return false;
		}

		this.cache = this.getCache();

		this.cache.nodesAndViewports = this.cache.nodesAndViewports || new Map();
		this.cache.transformedModels = this.cache.transformedModels || new Map();

		try {
			this.viewportsExtension = await this.viewer.loadExtension("Autodesk.AEC.ViewportsExtension");
			this._setupEventListeners();
			this.onExtensionLoaded({ extensionId: LevelsExtension });
			return true;
		} catch (error) {
			console.error("Failed to load extension:", error);
			return false;
		}
	}

	_setupEventListeners() {
		// this.viewer.addEventListener(Autodesk.Viewing.MODEL_ADDED_EVENT, this.updateUI);
		// this.viewer.addEventListener(Autodesk.Viewing.MODEL_REMOVED_EVENT, this.updateUI);
		this.viewportsExtension.addEventListener(Autodesk.AEC.ViewportsExtension.Events.VIEWPORT_DATA_FETCHED_EVENT, this.onViewportDataFetched);
		this.viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, this.onExtensionLoaded);
		this.viewer.addEventListener(Autodesk.Viewing.EXTENSION_UNLOADED_EVENT, this.onExtensionUnloaded);
	}

	unload() {
		// this.viewer.removeEventListener(Autodesk.Viewing.MODEL_ADDED_EVENT, this.updateUI);
		// this.viewer.removeEventListener(Autodesk.Viewing.MODEL_REMOVED_EVENT, this.updateUI);
		this.viewportsExtension.removeEventListener(Autodesk.AEC.ViewportsExtension.Events.VIEWPORT_DATA_FETCHED_EVENT, this.onViewportDataFetched);
		this.viewer.removeEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, this.onExtensionLoaded);
		this.viewer.removeEventListener(Autodesk.Viewing.EXTENSION_UNLOADED_EVENT, this.onExtensionUnloaded);
		this.onExtensionUnloaded({
			extensionId: LevelsExtension
		});
		this.hideSelectedSheet();
		this.destroyUIFor2D();
		this.destroyUIFor3D();
		this.viewer = null;
		this.options = null;
		this.cache.nodesAndViewports.clear();
		
		return true;
	}
	static get extensionId() {
		return 'ModelSheetTransitionExtension';
	}
	onViewportDataFetched() {
		this.updateUI({
			resetTransformAndViewport: false
		})
	}
	async updateUI() {
		let { resetTransformAndViewport: t = true } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
		const i = e(this.viewer);
		if (i) {
			this.destroyUIFor3D(),
				this.createUIfor2D(),
				this.resetModelIfNeeded(i, t);
			const e = this.getViewportsForModel(i);
			if (e.length) {
				const t = e[e.length - 1];
				try {
					t instanceof Autodesk.AEC.ViewportsExtension.AlignmentServiceViewport && await t.getRelatedModel3DInfo(),
						this.setButtonEnabled(true)
				} catch (e) {
					this.setButtonEnabled(false)
				}
			} else
				this.setButtonEnabled(false)
		} else
			this.destroyUIFor2D(),
				this.viewer.impl.is2d || (this.createUIfor3D(),
					this.viewer.getHiddenModels().forEach(this.removeMarkersForModel),
					this.viewer.getVisibleModels().forEach(this.addMarkersForModel))
	}
	onToolbarCreated(e) {
		this.updateUI()
	}
	createUIfor2D() {
		const t = this.viewer.getToolbar && this.viewer.getToolbar();
		if (!t || this.toolbarButton)
			return;
		const i = t.getControl(Autodesk.Viewing.TOOLBAR.MODELTOOLSID);
		i && (this.toolbarButton = new Autodesk.Viewing.UI.Button("toolbar-model-sheet-transition"),
			this.toolbarButton.setToolTip("Fetching alignment information..."),
			this.toolbarButton.setIcon("viewericon-model-sheet-transition"),
			this.toolbarButton.setState(Autodesk.Viewing.UI.Button.State.DISABLED),
			this.toolbarButton.onClick = async () => {
				if (this.transitionStarted)
					return;
				this.transitionStarted = true;
				const t = e(this.viewer);
				t ? (this.setButtonEnabled(false, true),
					await this.sheetToModel.transition2Dto3D(t),
					this.cache.transformedModels.set(t, t),
					this.setButtonEnabled(true, true),
					this.transitionStarted = false) : console.warn("No suitable 2D model found for sheet to model transition")
			}
			,
			i.addControl(this.toolbarButton))
	}
	setButtonEnabled(e) {
		let t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : e;
		if (!this.toolbarButton)
			return;
		this.toolbarButton.setState(e ? Autodesk.Viewing.UI.Button.State.INACTIVE : Autodesk.Viewing.UI.Button.State.DISABLED),
			this.toolbarButton.setToolTip(t ? "View drawing in 3D" : "Current view is not supported by View drawing in 3D")
	}
	destroyUIFor2D() {
		if (!this.toolbarButton)
			return;
		this.viewer.getToolbar && this.viewer.getToolbar() && (this.toolbarButton.removeFromParent(),
			this.toolbarButton = null)
	}
	createUIfor3D() {
		if (this.ui3DCreated)
			return;
		const e = () => {
			if (this.markers) {
				for (let e in this.markers)
					this.markers[e].setDefaultStyle();
				this.boundsPlane.lock = false,
					this.boundsPlane.setVisible(false)
			}
		}
			;
		this.ui3D = this.options.ui3D && new this.options.ui3D(e) || new SheetContextMenu(this.viewer, e),
			this.boundsPlane = new ViewportBoundsPlane(this.viewer, "ModelSheetTransition_Extension_Overlay");
		this.viewer.impl.get3DModels().forEach(this.addMarkersForModel),
			this.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.updateMarkersClientLocation),
			this.viewer.addEventListener(Autodesk.Viewing.VIEWER_RESIZE_EVENT, this.updateMarkersClientLocation),
			this.viewer.addEventListener(Autodesk.Viewing.MODEL_VIEWPORT_BOUNDS_CHANGED_EVENT, this.updateMarkers),
			this.viewer.addEventListener(Autodesk.Viewing.MODEL_TRANSFORM_CHANGED_EVENT, this.updateMarkers),
			this.ui3DCreated = true
	}
	async addMarkersForModel(e) {
		if (!e.is3d() || !e.getDocumentNode())
			return;
		const t = this.getViewportsForModel(e);
		if (this.duringModelToSheetTransition)
			return;
		if (!this.viewer.impl.findModel(e.id))
			return;
		const i = t.map((e => e.getGuid()));
		this.getMarkersKeysForModel(e).filter((e => !i.includes(e))).forEach((e => this.removeMarker(e))),
			t.forEach((t => {
				const i = t.getGuid();
				if (this.markers[i])
					return;
				const n = new SheetMarker(this, t, e, this.ui3D, this.areMarkersVisible);
				this.markers[i] = n,
					this.getSelectedViewportGuid() === i && n.setMarkerSelected(true)
			}
			))
	}
	getSelectedViewportGuid() {
		var e;
		return "string" == typeof this._selectedViewportOrGuid ? this._selectedViewportOrGuid : null === (e = this._selectedViewportOrGuid) || void 0 === e ? void 0 : e.getGuid()
	}
	getSelectedViewport() {
		if (!("string" == typeof this._selectedViewportOrGuid))
			return this._selectedViewportOrGuid;
		const e = this.viewportsExtension.findViewportByGuid(this._selectedViewportOrGuid);
		return e ? (this._selectedViewportOrGuid = e,
			e) : null
	}
	setSelectedViewport(e) {
		this._selectedViewportOrGuid = e
	}
	removeMarkersForModel(e) {
		if (!e.getDocumentNode())
			return;
		this.getMarkersKeysForModel(e).forEach((e => this.removeMarker(e)))
	}
	getMarkersKeysForModel(e) {
		return e.is3d() ? Object.keys(this.markers).filter((t => this.markers[t].model3D === e)) : []
	}
	removeMarker(e) {
		this.markers[e].destroy(),
			delete this.markers[e]
	}
	async showSheetAndSetTransform(e, i, n) {
		this.setSelectedViewport(i);
		const o = await this.loadModel(e);
		this.cache.transformedModels.set(o, o);
		const s = o.getUnitScale()
			, r = i.getViewportBounds(s);
		let A = i.get2DTo3DMatrix(s);
		if (i.isMatrixInLocalCoords()) {
			const e = n.getModelToViewerTransform();
			e && (A = (new THREE.Matrix4).multiplyMatrices(e, A))
		}
		o.setPlacementTransform(A),
			o.setViewportBounds(this.viewer.impl.matman(), r);
		const a = i.isTopViewViewport();
		return t(this.viewer, A, a),
			o.changePaperVisibility(false),
			this.viewer.impl.setDoNotCut(o, true),
			this.showModel(e),
			o
	}
	hideSelectedSheet() {
		var e;
		null === (e = this.selectedMarker) || void 0 === e || e.hideSheet()
	}
	onMarkerSelected(e) {
		this.hideSelectedSheet(),
			this.selectedMarker = e
	}
	onMarkerDeselected(e) {
		e === this.selectedMarker && (this.selectedMarker = null)
	}
	setAllMarkersVisible(e) {
		if (this.areMarkersVisible !== e) {
			this.areMarkersVisible = e;
			for (let t in this.markers)
				this.markers[t].setVisible(e)
		}
	}
	hideSheet(e) {
		this.setSelectedViewport(null);

		if (!this.viewer?.impl) return;

		const t = this.viewer.impl.findModel(e);
		t && s(this.viewer, t),
			this.hideModel(e)
	}
	destroyUIFor3D() {
		if (this.ui3DCreated) {
			this.viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.updateMarkersClientLocation),
				this.viewer.removeEventListener(Autodesk.Viewing.VIEWER_RESIZE_EVENT, this.updateMarkersClientLocation),
				this.viewer.removeEventListener(Autodesk.Viewing.MODEL_VIEWPORT_BOUNDS_CHANGED_EVENT, this.updateMarkers),
				this.viewer.removeEventListener(Autodesk.Viewing.MODEL_TRANSFORM_CHANGED_EVENT, this.updateMarkers);
			for (let e in this.markers)
				this.markers[e].destroy();
			this.boundsPlane.destroy(),
				this.boundsPlane = null,
				this.markers = {},
				this.selectedMarker = null,
				this.setSelectedViewport(null),
				this.ui3DCreated = false
		}
	}
	updateMarkersClientLocation() {
		if (this.markers)
			for (let e in this.markers)
				this.markers[e].updateClientLocation()
	}
	updateMarkers() {
		if (this.markers)
			for (let e in this.markers)
				this.markers[e].update()
	}
	resetModelIfNeeded(e) {
		let t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
		return !!this.cache.transformedModels.has(e) && (t && (this.viewer.navigation.setView(this.defaultPosition2D, this.defaultTarget2D),
			this.viewer.navigation.orientCameraUp(true),
			s(this.viewer, e),
			((e, t) => {
				const i = t.getVisibleBounds();
				e.navigation.fitBounds(true, i, false, true),
					e.impl.invalidate(true)
			}
			)(this.viewer, e)),
			setTimeout((() => this.viewer.impl.controls.recordHomeView())),
			this.cache.transformedModels.delete(e),
			true)
	}
	async getViewportsForModelAsync(e) {
		let t = await this.viewportsExtension.getViewportsAsync(e.getDocumentNode(), {
			onlyTopViewPlans: false
		});
		return this.options.useOnlyAecModelDataViewports && (t = t.filter((e => e instanceof Autodesk.AEC.ViewportsExtension.AecModelDataViewport))),
			t
	}
	getViewportsForModel(e) {
		let t = this.viewportsExtension.getViewports(e.getDocumentNode(), {
			onlyTopViewPlans: false
		});
		return this.options.useOnlyAecModelDataViewports && (t = t.filter((e => e instanceof Autodesk.AEC.ViewportsExtension.AecModelDataViewport))),
			t
	}
	async getNodeAndViewport(e, t) {
		const i = await this.getViewportsForModelAsync(e)
			, n = t ? i.find((e => e.getGuid() === t)) : i[i.length - 1];
		if (!this.cache.nodesAndViewports.has(n)) {
			const t = (await n.get3DNodesForViewport())[0];
			if (!t)
				return;
			e.regionId = n.getGuid(),
				this.cache.nodesAndViewports.set(n, {
					viewport: n,
					bubbleNode: t
				})
		}
		return this.cache.nodesAndViewports.get(n)
	}
	_displayError(e) {
		Autodesk.Viewing.Private.AlertBox.displayError(this.viewer.container, e, "Model Sheet Transition")
	}
	async transitionModelToSheet(e) {
		this.duringModelToSheetTransition = true,
			this.destroyUIFor3D(),
			await this.modelToSheet.transition3Dto2D(e),
			this.duringModelToSheetTransition = false
	}
	onExtensionLoaded(e) {
		let { extensionId: t } = e;
		if (t === LevelsExtension) {
			const e = this.viewer.getExtension(LevelsExtension);
			e && e.floorSelector.addEventListener(Autodesk.AEC.FloorSelector.SELECTED_FLOOR_CHANGED, this.onSelectedFloorChanged)
		}
	}
	onExtensionUnloaded(e) {
		let { extensionId: t } = e;
		if (t === LevelsExtension) {
			const e = this.viewer.getExtension(LevelsExtension);
			var i;
			if (e)
				null === (i = e.floorSelector) || void 0 === i || i.removeEventListener(Autodesk.AEC.FloorSelector.SELECTED_FLOOR_CHANGED, this.onSelectedFloorChanged)
		}
	}
	onSelectedFloorChanged() {
		var e;
		if (null !== (e = this.getSelectedViewport()) && void 0 !== e && e.isTopViewViewport()) {
			const e = this.viewer.getExtension("Autodesk.Section");
			null == e || e.setSectionFromPlane(null)
		}
	}
}

Autodesk.Viewing.theExtensionManager.registerExtension('ModelSheetTransitionExtension', ModelSheetTransitionExtension);

export default ModelSheetTransitionExtension;

// Transition2DTo3D 	=> M
// Transition3Dto2D 	=> B
// SheetContextMenu 	=> X
// SheetMarker      	=> V
// ViewportBoundsPlane 	=> D
// ModelSheetTransition => S