/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import store from '../../store';
//import ViewerToolkit from '../Viewer.Toolkit';
import cameraImg from '@/assets/images/camera.png';
import moveImg from '@/assets/images/move.png';
const Autodesk = window.Autodesk;
const THREE = window.THREE;
export const SensorSpritesExtensionID = 'IoT.SensorSprites';
import { isTouchDevice } from '@/services/compat';

export default class AddCameraTool extends Autodesk.Viewing.ToolInterface {
    constructor(viewer, dataVizExt) {
        super();
        this.names = ['add-camera-tool'];
 
        this.viewer = viewer
        this._dataVizExt = dataVizExt
        this._style = this._createVisualStyle()
        this.active = false
        this.hoveredId = null
        this.selectedId =  null;


        
        delete this.activate;
        delete this.deactivate;
        delete this.getPriority;

        delete this.handleSingleClick;
        delete this.handleSingleTap;
    }
 
    getPriority() {
        return 42; 
    }
 
    handleSingleClick(event, button) {

        if (button === 0) {
            const result = this.viewer.clientToWorld(event.offsetX, event.offsetY);
            if(result?.point){
                this._addSprite(result.point)    
                return true; 
            }
        }

        return false;
    }
    handleSingleTap(event){
        const result = this.viewer.clientToWorld(event.canvasX, event.canvasY);
        if(result?.point){
                this._addSprite(result.point)   
               
                return true; 
        }
        return false
    }

    activate() {
        if (!this.active) {
			this.active = true
		}
        console.log('add-camera-tool', 'activated.');
    }
    deactivate() {
		if (this.active) {
			this.active = false
		}
        console.log('add-camera-tool', 'deactivated.');
	}

    deactivateTool(){
        const addCameraButton = this.viewer.toolbar.getControl('dashboard-toolbar-group').getControl('add-camera-button')
        addCameraButton.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
        this.viewer.toolController.deactivateTool('add-camera-tool');
    }

    _createVisualStyle() {
        const DataVizCore = Autodesk.DataVisualization.Core;
        const viewableType = DataVizCore.ViewableType.SPRITE;
        const spriteColor = new THREE.Color(0xffffff);
        const spriteIconUrl = cameraImg; 
        return new DataVizCore.ViewableStyle(viewableType, spriteColor, spriteIconUrl);
    }


    _addSprite(location){
        
        const allCameras = store.getters['ALL_Camera_Elements']  

        this.viewableData = new Autodesk.DataVisualization.Core.ViewableData();
        this.viewableData.spriteSize = 24;   

        if(!this._style){
            this._style = this._createVisualStyle();
        }
        
        for (let i = 0; i < allCameras.length; i++) {
           const currentSprite = allCameras[i]

           const dbid =  currentSprite.dbId;
           const viewable = new Autodesk.DataVisualization.Core.SpriteViewable(new THREE.Vector3(currentSprite.position.x, currentSprite.position.y, currentSprite.position.z), this._style, dbid);
           this.viewableData.addViewable(viewable);

        }


        let maxNumber = Math.max(...this.viewableData.viewables.map(x => x._dbId), 100000);
  
        
        maxNumber += 10
        const dbid =  maxNumber;
  
        const viewable = new Autodesk.DataVisualization.Core.SpriteViewable(new THREE.Vector3(location.x, location.y, location.z), this._style, dbid);
        this.viewableData.addViewable(viewable);
      
        store.commit('SET_View_Id', this.viewer.model.getDocumentNode().data.guid);
        store.commit('SET_Added_Camera_Location', location);
        store.commit('SET_Added_Camera_DBID', dbid);
        
        store.commit('SET_Show_InputField', true);      
        
        
        this.viewableData.finish().then(() => {
            this._dataVizExt.removeAllViewables();
            this._dataVizExt.addViewables(this.viewableData);
            this.deactivateTool()  
        });
          
         
    }

    _addAllSpriteToView(allSprite){
        this.viewableData = new Autodesk.DataVisualization.Core.ViewableData();
        this.viewableData.spriteSize = 24;   

        if(!this._style){
            this._style = this._createVisualStyle();
        }
        
        for (let i = 0; i < allSprite.length; i++) {
           const currentSprite = allSprite[i]

           const dbid =  currentSprite.dbId;
           const viewable = new Autodesk.DataVisualization.Core.SpriteViewable(new THREE.Vector3(currentSprite.position.x, currentSprite.position.y, currentSprite.position.z), this._style, dbid);
           this.viewableData.addViewable(viewable);

        //    console.log(this.viewableData)
        }
        this.viewableData.finish().then(() => {
            //this._dataVizExt.removeAllViewables();
            this._dataVizExt.addViewables(this.viewableData);

            
        });
    }
}