<template>
    <v-card class="pa-2" theme="dark">
        <v-card title="Templates">
            <v-row align="center" justify="center" class="mt-2">
                <v-col cols="11">
                    <v-select
                        density="compact"
                        item-title="name"
                        item-value="id"
                        v-model="selectedTemp"
                        :items="templates"></v-select>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        @click="Tempdialog = true"
                        v-bind="activatorProps"
                        density="compact"
                        icon="mdi-plus"></v-btn>

                    <v-dialog v-model="Tempdialog" max-width="500">
                        <template v-slot:default="{ isActive }">
                            <v-card class="pa-2" title="Add Template">
                                <v-text-field
                                    dense
                                    placeholder="Template Name"
                                    hide-details
                                    v-model="template.name"
                                    solo></v-text-field>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text="Save" @click="addTemplate()"></v-btn>
                                    <v-btn text="Close" @click="isActive.value = false"></v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                    <v-btn density="compact" icon="mdi-delete"></v-btn>
                </v-col>
            </v-row>
        </v-card>
        <v-card title="Conditions">
            <v-table theme="dark">
                <thead>
                    <tr>
                        <th class="text-left">Prop</th>
                        <th class="text-left">Condition</th>
                        <th class="text-left">value</th>
                        <th class="text-left">Color</th>
                        <th class="text-left">On / Off</th>

                        <th class="text-left">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in conditionsData" :key="item.prop">
                        <td>{{ item.prop }}</td>
                        <td>{{ item.operator }}</td>
                        <td>{{ item.value }}</td>

                        <td>
                            <div
                                :style="{
                                    backgroundColor: item.color,
                                    width: '20px',
                                    height: '20px',
                                    'border-radius': '50%',
                                }"></div>
                        </td>
                        <td>
                            <v-switch
                                color="primary"
                                :model-value="item.enabled"
                                @click="ChangeEnableState(item)"
                                hide-details></v-switch>
                        </td>
                        <td>
                            <v-btn
                                density="compact"
                                @click="update(item)"
                                icon="mdi-pencil"></v-btn>
                            <v-btn
                                density="compact"
                                @click="ConfirmDelete(item)"
                                icon="mdi-delete"></v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-card>

        <v-btn
            @click="Add()"
            :disabled="selectedTemp == ''"
            color="primary"
            text="Add"
            class="mt-2"></v-btn>

        <v-dialog v-model="dialog" max-width="500">
            <template v-slot:default="{ isActive }">
                <v-card class="pa-2" title="Add Condition">
                    <v-autocomplete
                        label="Properties"
                        v-model="data.prop"
                        :items="props"></v-autocomplete>

                    <v-autocomplete
                        label="Condition"
                        v-model="data.operator"
                        :items="conditions"></v-autocomplete>
                    <v-text-field
                        dense
                        placeholder="value"
                        v-model="data.value"
                        hide-details
                        solo></v-text-field>
                    <div>
                        <div style="display: inline-block">
                            <v-color-picker
                                v-model="data.color"
                                hide-canvas
                                hide-inputs></v-color-picker>
                        </div>
                        <div :style="{ backgroundColor: data.color }" class="color-chooser"></div>
                    </div>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            :disabled="ConditionDisabled"
                            text="Save"
                            @click="addCondition()"></v-btn>
                        <v-btn text="Close" @click="isActive.value = false"></v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
        <message-box
            ref="messageBox"
            :on-confirm="this.onConfirmMessage"
            title="Confirmation"
            message="Are you sure you want to delete?" />
    </v-card>
</template>
<script>
    import MessageBox from '@/components/MessageBox.vue';

    export default {
        data() {
            return {
                tableData: [],
                data: {
                    prop: '',
                    operator: '',
                    value: '',
                    color: '#ff0000',
                },
                conditions: [
                    'Equal',
                    'Not Equal',
                    'Greater Than',
                    'Less Than',
                    'Greater Than or Equal',
                    'Less Than or Equal',
                    'Contains',
                    'Begins With',
                    'Ends With',
                ],

                TemplateActive: false,
                template: { name: '' },
                selectedTemp: '',
                dialog: false,
                Tempdialog: false,
                onConfirmMessage: null,
                DeletedItem:null
            };
        },
        components: {
            MessageBox,
        },
        methods: {
            addCondition() {
                this.dialog = false;
                if (this.data.id) {
                    this.$store.dispatch('COLORINGSYSTEMS/UpdateCondition', this.data);
                } else {
                    let conditionData = {
                        Prop: this.data.prop,
                        Operator: this.data.operator,
                        Value: this.data.value,
                        Color: this.data.color,
                        TemplateId: this.selectedTemp,
                    };
                    this.$store.dispatch('COLORINGSYSTEMS/AddCondition', conditionData);
                    // this.$store.dispatch('COLORINGSYSTEMS/SetModelPropColor', this.tableData);
                }
            },
            addTemplate() {
                this.Tempdialog = false;
                this.template['ForgeModelId'] = this.$route.params.id;
                this.$store.dispatch('COLORINGSYSTEMS/AddTemplate', this.template);
            },
            update(item) {
                this.dialog = true;
                this.data = {...item};
            },
            Add() {
                this.dialog = true;
                this.data = {
                    prop: '',
                    operator: '',
                    value: '',
                    color: '#ff0000',
                };
            },
            Delete() {
                this.$store.dispatch('COLORINGSYSTEMS/DeleteCondition', this.DeletedItem);
            },
            ChangeEnableState(item) {
                item.enabled = !item.enabled;
                this.$store.dispatch('COLORINGSYSTEMS/ChangeEnableState', item);
            },
             
            ConfirmDelete(item){
                this.DeletedItem=item;
                this.onConfirmMessage = this.Delete;
                this.$refs.messageBox.show();
            }
        },
        computed: {
            props() {
                return this.$store.getters['COLORINGSYSTEMS/MODEL_PROPS'];
            },
            templates() {
                return this.$store.getters['COLORINGSYSTEMS/TEMPLATES'];
            },
            conditionsData() {
                return this.$store.getters['COLORINGSYSTEMS/CONDITIONS'];
            },
            AddedTemplate() {
                return this.$store.getters['COLORINGSYSTEMS/ADDED_TEMPLATE'];
            },
            ConditionDisabled() {
                return (
                    (this.data.value == '' &&
                        this.data.operator != 'Equal' &&
                        this.data.operator != 'Not Equal') ||
                    this.data.prop == '' ||
                    this.data.operator == ''
                );
            },
        },
        mounted() {
            const modelId = this.$route.params.id;
            this.$store.dispatch('COLORINGSYSTEMS/GetTemplates', modelId);
            if (this.$store.getters['COLORINGSYSTEMS/SELECTED_TEMP'] > 0) {
                this.selectedTemp = this.$store.getters['COLORINGSYSTEMS/SELECTED_TEMP'];
            }
        },
        unmounted() {
            //this.$store.dispatch('COLORINGSYSTEMS/GetConditions', -1);
        },
        watch: {
            selectedTemp(value) {
                this.$store.dispatch('COLORINGSYSTEMS/GetConditions', value);
            },
            AddedTemplate(value) {
                this.selectedTemp = value.id;
                this.templates.push(value);
            },
            templates(value) {
                if (value.length > 0 && !(this.selectedTemp > 0)) {
                    this.selectedTemp = value[0].id;
                }
            },
        },
    };
</script>
<style>
    .color-chooser {
        display: inline-block;
        width: 50px;
        height: 50px;
        margin-top: 4vh;
        margin-right: 2vh;
        float: right;
        border-radius: 50%;
    }
</style>
