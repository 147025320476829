import httpService from "@Utils/httpService";

const hubsUrl = 'api/hubs';
const projectsUrl = 'api/ForgeProjects';

export default {
    getProjectFolders(hubId, projectId) {
        return httpService.get({
            url: `${hubsUrl}/${hubId}/projects/${projectId}`,
        }).then(folders => {
            return folders;
        }).catch(error => {
            return error;
        });
    },
    getFolderItems(projectId, folderId) {
        return httpService.get({
            url: `api/projects/${projectId}/folders/${folderId}`,
        }).then(items => {
            return items;
        }).catch(error => {
            return error;
        });
    },
    checkProjectCheckIn(project, userId) {
        return httpService.post({
            url: `${projectsUrl}/checkProjectCheckIn/${userId}`,
            data: project
        });
    },
    updateProject(project) {
        return httpService.post({
            url: `${projectsUrl}/update`,
            data: project
        });
    },
    getById(projectId) {
        return httpService.get({
            url: `${projectsUrl}/${projectId}`
        });
    },
    userCheckInProject({ userId, projectId }) {
        return httpService.post({
            url: `${projectsUrl}/UserCheckInProject`,
            data: {
                userId, projectId
            }
        });
    },
    getRecentProjects(query,favCount) {
        return httpService.get({
            url: `${projectsUrl}/getRecentProjects/${favCount}`,
            params: { query }
        });
    },
    getFavoriteProjects(userId, query,favCount) {
        return httpService.get({
            url: `${projectsUrl}/getFavoriteProjects/${favCount}`,
            params: { userId, query }
        });
    },
    getUserRecentProjects(userId, query,favCount) {
        return httpService.get({
            url: `${projectsUrl}/getUserRecentProjects/${favCount}`,
            params: { userId, query }
        });
    },
    updateUserProjectFavorite({ userId, projectId, isFavorite }) {
        return httpService.post({
            url: `${projectsUrl}/updateUserProjectFavorite`,
            data: {
                userId, projectId, isFavorite
            }
        });
    },
    getProjectData(projectId) {
        return httpService.get({
            url: `${projectsUrl}/getProjectData`,
            params: { id: projectId }
        });
    },
}