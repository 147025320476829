<template>
    <ul>
        <StaticTreeNode v-for="node in nodes" :key="node.id" :node="node"></StaticTreeNode>
    </ul>
</template>

<script>
import StaticTreeNode from './StaticTreeNode.vue';

export default {
    props: {
        nodes: {
            required: true
        },
    },
    components: {
        StaticTreeNode
    },
    methods: {

    }
};
</script>

<style scoped>
li {
    list-style-type: none;
}

ul {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
</style>