<template>
    <v-card class="pa-2">
        <v-text-field
            dense
            placeholder="Search(3 character min...)"
            hide-details
            density="compact"
            solo
            v-model="searchText"></v-text-field>
        <tree :config="config" @nodeFocus="move" :nodes="mdata || data">
            <template #after-input="props">
                <div style="margin-left: auto; z-index: 5">
                    <i
                        v-if="!props.node.hide"
                        style="color: #fbb65a; font-size: 14px"
                        @click.stop="hide(props.node)"
                        class="ml-2 fa-solid fa-eye-slash"></i>
                    <i
                        v-if="props.node.hide"
                        style="color: white; font-size: 14px"
                        @click.stop="hide(props.node)"
                        class="ml-2 fa-regular fa-eye"></i>
                    <i
                        v-if="!props.node.select"
                        style="color: #f06e6e"
                        @click.stop="select(props.node)"
                        class="ml-2 fa-solid fa-lock"></i>
                    <i
                        v-if="props.node.select"
                        style="color: #9af19a"
                        @click.stop="select(props.node)"
                        class="ml-2 fa-solid fa-unlock"></i>
                </div>
            </template>
        </tree>
          <div v-for="index in 7" :key="index">
            <v-skeleton-loader
                :loading="loading"
                v-if="loading"
                type="list-item-three-line">
            </v-skeleton-loader>
        </div>
    </v-card>
</template>

<script>
    import treeview from 'vue3-treeview';
    import 'vue3-treeview/dist/style.css';
    export default {
        components: {
            tree: treeview,
        },
        computed: {
            data() {
                return this.$store.getters['MODELBROWSER/MODEL_BROWSER_TREE'];
            },
            originalDataArray() {
                return this.$store.getters['MODELBROWSER/MODEL_BROWSER_TREE_ARR'];
            },
            PreventedSelection() {
                return this.$store.getters['Prevented_Selection_DBIDS'];
            },
            
        },
        data: function () {
            return {
                searchText: '',
                config: {
                    roots: ['1'],
                    openedIcon: {
                        type: 'shape',
                        stroke: 'black',
                        fill: 'white',
                        viewBox: '0 0 123.958 123.959',
                        draw: 'M117.979,28.017h-112c-5.3,0-8,6.4-4.2,10.2l56,56c2.3,2.3,6.1,2.3,8.401,0l56-56C125.979,34.417,123.279,28.017,117.979,28.017z',
                    },
                    closedIcon: {
                        type: 'shape',
                        stroke: 'black',
                        fill: 'white',
                        viewBox: '0 0 123.958 123.959',
                        draw: 'M38.217,1.779c-3.8-3.8-10.2-1.1-10.2,4.2v112c0,5.3,6.4,8,10.2,4.2l56-56c2.3-2.301,2.3-6.101,0-8.401L38.217,1.779z',
                    },
                },
                mdata: {},
                preventedArray: [],
                loading:true
            };
        },
        methods: {
            move(node) {
                this.$store.dispatch('MODELBROWSER/Isolate', node);
            },
            hide(node) {
                this.$store.dispatch('MODELBROWSER/Hide', node);
            },
            select(node) {
                this.$store.dispatch('MODELBROWSER/Select', node);
            },
            GetSearchData(value) {
                if (value && value.length >= 3) {
                    let stateOpened = true;
                    if (value == '') {
                        stateOpened = false;
                    }
                    let filtered = this.originalDataArray.filter((c) =>
                        c.originalText.toLowerCase().includes(value.toLowerCase()),
                    );
                    console.log(filtered);
                    let allParents = [];
                    filtered.forEach((element) => {
                        allParents.push(...element.parents);
                    });
                    let filteredParents = [...new Set(allParents)];
                    let addedParents = this.originalDataArray.filter((c) =>
                        filteredParents.includes(c.key),
                    );
                    filtered.push(...addedParents);
                    this.mdata = {};
                    filtered.forEach((element) => {
                        this.mdata[element.key] = {
                            text: element.text,
                            children: element.children,
                            parents: element.parents,
                            key: element.key,
                            originalText: element.originalText,
                        };
                        this.mdata[element.key]['state'] = { opened: stateOpened };
                    });
                    this.$store.getters['Prevented_Selection_DBIDS'].forEach((element) => {
                        if (this.mdata[element]) {
                            this.mdata[element]['select'] = true;
                        }
                    });
                } else {
                    this.mdata = this.data;
                    this.preventedArray.forEach((element) => {
                        if (this.mdata[element]) {
                            this.mdata[element]['select'] = false;
                        }
                    });
                    let PreventedSelectedFromTool =
                        this.$store.getters['Prevented_Selection_DBIDS'];
                    PreventedSelectedFromTool.forEach((element) => {
                        if (this.mdata[element]) {
                            this.mdata[element]['select'] = true;
                        }
                    });
                    this.preventedArray = PreventedSelectedFromTool;
                }
            },
        },
        watch: {
            searchText(value) {
                this.GetSearchData(value);
            },
            data(value) {
                this.mdata = value;
            },
            PreventedSelection() {
                this.GetSearchData(this.searchText);
            },
        },
        mounted() {
            setTimeout(() => {
                this.loading=false;
                this.preventedArray = this.$store.getters['Prevented_Selection_DBIDS'];
                this.preventedArray?.forEach((element) => {
                    if (this.data[element]) {
                        this.data[element]['select'] = true;
                    }
                });
                this.mdata = this.data;
            }, 3000);
        },
        unmounted() {
             this.$store.dispatch('MODELBROWSER/SET_MODEL_BROWSER_TREE', []);
        },

    };
</script>
<style>
    .input-wrapper {
        color: inherit !important;
    }
    .node-wrapper:hover {
        background-color: #4e4e4e !important;
    }
    .node-wrapper:focus {
        background-color: #4e4e4e !important;
    }
    .level-enter-active,
    .level-leave-active {
        transition: opacity 0.5s ease;
    }

    .level-enter-from,
    .level-leave-to {
        opacity: 0;
    }
</style>
