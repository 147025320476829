/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import store from '../../store';

const Autodesk = window.Autodesk;
import { toast } from 'vue3-toastify';

class PreventSelectionExtension extends Autodesk.Viewing.Extension {
    constructor(viewer, options) {
        super(viewer, options);

        this.preventSelectionButton = null;
        this.resetPreventSelectionButton = null;

        this.viewer = viewer;

    }

    get menuId() {
        return 'Prevent Selection';
    }

    load() {
        return true;
    }

    unload() {
        if (this.preventSelectionButton) {
            this.removeToolbarButton(this.preventSelectionButton);
            this.preventSelectionButton = null;
        }
        if (this.resetPreventSelectionButton) {
            this.removeToolbarButton(this.resetPreventSelectionButton);
            this.resetPreventSelectionButton = null;
        }
        return true;
    }

    onToolbarCreated() {
        const controller = this.viewer.toolController;

        this.preventSelectionButton = this.createToolbarButton(
            'prevent-selection-button',
            'fa fa-ban',
            'Prevent Selection',
        );
        this.preventSelectionButton.onClick = () => {
            const selections = this.viewer.getAggregateSelection();
            if(selections.length){
                let preventedSelection = store.getters.Prevented_Selection_DBIDS;
                 const selection = selections[0].selection;
                  if (!this.viewer.isSelectionLocked(selection)) {
                      this.viewer.lockSelection(selection, true);
                      preventedSelection = [...preventedSelection, ...selection]
                      store
                          .dispatch('SetPreventSelectionDbIds', { dbIds: preventedSelection })
                          .then(() => {
                              toast.success('elements added to prevent selection set');
                          })
                          .catch((error) => {
                              toast.error('Failed to add elements to prevent selection set');
                          });
                  }
            }
        };

        this.resetPreventSelectionButton = this.createToolbarButton(
            'reset-prevent-selection-button',
            'fa fa-refresh',
            'Reset Selection',
        );
        this.resetPreventSelectionButton.onClick = () => {
             const preventedSelection = store.getters.Prevented_Selection_DBIDS;
             this.viewer.unlockSelection(preventedSelection);
              store
                  .dispatch('SetPreventSelectionDbIds', { dbIds: [] })
                  .then(() => {
                      toast.success('reset prevent selection set done successfully');
                  })
                  .catch((error) => {
                      toast.error('Failed to reset prevent selection set');
                  });
        };
    }

    createToolbarButton(buttonId, buttonIconUrl, buttonTooltip) {
        let group = this.viewer.toolbar.getControl('selection-toolbar-group');
        if (!group) {
            group = new Autodesk.Viewing.UI.ControlGroup('selection-toolbar-group');
            this.viewer.toolbar.addControl(group);
        }
        const button = new Autodesk.Viewing.UI.Button(buttonId);
        button.setToolTip(buttonTooltip);

        group.addControl(button);
        const icon = button.container.querySelector('.adsk-button-icon');
        if (icon) {
            icon.className = buttonIconUrl;
            icon.style.fontSize = '20px';
        }
        return button;
    }

    removeToolbarButton(button) {
        const group = this.viewer.toolbar.getControl('selection-toolbar-group');
        group.removeControl(button);
    }
}

Autodesk.Viewing.theExtensionManager.registerExtension(
    'PreventSelectionExtension',
    PreventSelectionExtension,
);
