<template lang="html">
    <div class="side-bar">
        <v-navigation-drawer
            :style="`z-index:${ZIndex + 2}`"
            @touchstart="onMouseEnter"
            @mouseenter="onMouseEnter"
            @mouseleave="onMouseLeave"
            :expand-on-hover="expanded"
            rail
            permanent
            theme="dark"
            width="250"
            railWidth="50">
            <v-list>
                <v-list-item
                    v-if="user"
                    :prepend-avatar="user.profilePicture"
                    :subtitle="user.userName"
                    :title="user.name"></v-list-item>
                <version></version>
            </v-list>

            <v-divider></v-divider>

            <v-tabs class="side-bar-tabs" color="primary" direction="vertical">
                <v-tab
                    v-model="selectedPanel"
                    v-for="panel of panels"
                    :disabled="panel.disabled"
                    :key="panel.name"
                    :text="panel.title"
                    :value="panel"
                    :prepend-icon="panel.icon"
                    :mandatory="false"
                    @click="updateSelectedPanel(panel)"
                    height="35"></v-tab>
            </v-tabs>

            <v-list class="settings">
                <v-list-item @click="updateSelectedPanel(settings)" title="Settings">
                    <template v-slot:prepend>
                        <v-icon>
                            <i class="fa-solid fa-cog"></i>
                        </v-icon>
                    </template>
                </v-list-item>
                <v-spacer></v-spacer>
                <v-divider></v-divider>

                <v-list-item @click="logout" color="orange-darken-4" title="Logout">
                    <template v-slot:prepend>
                        <v-icon>
                            <i class="fa-solid fa-arrow-right-from-bracket"></i>
                        </v-icon>
                    </template>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        
            <slide-out
                v-show="!hidePanels"
                :showMask="false"
                class="slideOut"
                dock="left"
                size="330px"
                :min-size="330"
                allow-resize
                resizable
                @resize="syncComponentWidths"
                style="top: 50px; height: calc(100% - 30px) !important; margin-left: 50px"
                :z-index="ZIndex"
                v-model="ShowPanel"
                append-to="#app">
                <div
                    :style="{ width: slideOutWidth + 'px !important' }"
                    :class="selectedClass"
                    v-if="selectedPanel">
                    <v-card theme="dark" style="height: 100%">
                        <div class="panel-header">
                            <h6>{{ selectedPanel.title }}</h6>
                            <div class="close-panel-icon">
                                <i class="fa-solid fa-times" @click="selectedPanel = null"></i>
                            </div>
                        </div>

                        <component
                            :is="selectedPanel.component"
                            class="panel-wrapper"
                            :class="selectedPanel.name + '-wrapper'">
                        </component>
                    </v-card>
                </div>
            </slide-out>
       

        <v-navigation-drawer
            v-if="settingsSelected"
            :style="{ 'z-index': ZIndex+1 }"
            :class="selectedClass"
            permanent
            theme="dark">
            <div class="panel-header">
                <h6>{{ settings.title }}</h6>
                <div class="close-panel-icon">
                    <i class="fa-solid fa-times" @click="settingsSelected = false"></i>
                </div>
            </div>

            <component
                :is="settings.component"
                class="panel-wrapper"
                :class="settings.name + '-wrapper'">
            </component>
        </v-navigation-drawer>
    </div>
</template>
<script>
    import HubsTree from '@/components/HubsTree.vue';
    import BucketsTree from '@/components/BucketsTree.vue';
    import FamiliesList from '@/components/FamiliesList.vue';
    import InstancesList from '@/components/InstancesList.vue';
    import BlocksTreeList from '@/components/BlocksTreeList/BlocksTreeList.vue';
    import CameraAttachementList from '@/components/CameraAttachementList.vue';
    import SettingsList from '@/components/SettingsList.vue';
    import DocumentBrowser from '@/components/DocumentBrowser';
    import MarkupDocuments from './../../Markups/components/MarkupDocuments.vue';
    import Version from './Version.vue';
    import ModelBrowser from '@/features/ModelBrowser/Components/ModelBrowser.vue';
    import ColoringSystems from '@/features/ColoringSystems/Components/ColoringSystems.vue';
    export default {
        name: 'SideBar',

        components: {
            HubsTree,
            BucketsTree,
            FamiliesList,
            InstancesList,
            BlocksTreeList,
            CameraAttachementList,
            SettingsList,
            DocumentBrowser,
            MarkupDocuments,
            Version,
            ModelBrowser,
            ColoringSystems,
        },
        data() {
            return {
                slideOutWidth: 320,
                expanded: false,
                expandTimeoutMS: 2000,
                expandTimeout: null,
                selectedPanel: null,
                settingsSelected: false,
                hidePanels: false,
                selectedClass: 'defaultSide',
                settings: {
                    name: 'settingsPanel',
                    title: 'Settings',
                    icon: 'fa:fa-solid fa-cog',
                    component: 'SettingsList',
                    disabled: false,
                },
                panels: [
                    // {
                    //     name: 'hubs',
                    //     title: 'Hubs',
                    //     icon: 'fa-solid fa-folder-tree',
                    //     component: 'HubsTree'
                    // },
                    // {
                    //     name: 'buckets',
                    //     title: 'Buckets',
                    //     icon: 'fa-solid fa-inbox',
                    //     component: 'BucketsTree'
                    // },
                    {
                        name: 'documents',
                        title: 'Document Browser',
                        icon: 'fa:fa fa-folder-closed',
                        component: 'DocumentBrowser',
                        disabled: false,
                    },
                    {
                        name: 'uploaded-families',
                        title: 'Uploaded Families',
                        icon: 'fa:fa-solid fa-folder-plus',
                        component: 'FamiliesList',
                        disabled: false,
                    },
                    {
                        name: 'families-instances',
                        title: 'Instances',
                        icon: 'fa:fa-solid fa-clone',
                        component: 'InstancesList',
                        disabled: false,
                    },
                    {
                        name: 'cameraPanel',
                        title: 'Cameras',
                        icon: 'fa:fa-solid fa-camera',
                        component: 'CameraAttachementList',
                        disabled: false,
                    },
                    {
                        name: 'blocksPanel',
                        title: 'Model Families & Types',
                        icon: 'fa:fa-solid fa-cube',
                        component: 'BlocksTreeList',
                        disabled: false,
                    },
                    // {
                    //     name: 'settingsPanel',
                    //     title: 'Settings',
                    //     icon: 'fa:fa-solid fa-cog',
                    //     component: 'SettingsList'
                    // },
                    {
                        name: 'markUp',
                        title: 'Markups',
                        icon: 'fa:fa-solid fa-pen',
                        component: 'MarkupDocuments',
                        disabled: false,
                    },
                    {
                        name: 'ModelBrowser',
                        title: 'Model Browser',
                        icon: 'fa:fa-solid fa-folder-tree',
                        component: 'ModelBrowser',
                        disabled: false,
                    },
                ],
            };
        },
        mounted() {
            this.selectedPanel = null;
        },
        methods: {
            syncComponentWidths(event) {
                this.slideOutWidth = event.size - 10;
            },
            updateSelectedPanel(panel) {
                if (panel.name == 'settingsPanel') {
                    this.selectedClass = 'settingsSide';
                    this.settingsSelected = true;
                    this.hidePanels = true;

                } else {
                    this.selectedClass = 'defaultSide';
                    this.settingsSelected = false;
                    this.hidePanels = false;
                    if (this.selectedPanel?.name === panel?.name) {
                    this.selectedPanel = null;
                    return;
                }
                this.selectedPanel = panel;

                }
                
            },
            onMouseLeave() {
                if (this.expandTimeout) clearTimeout(this.expandTimeout);

                this.expanded = false;
            },
            onMouseEnter() {
                if (this.expandTimeout) clearTimeout(this.expandTimeout);

                this.expanded = true;

                if (!this.isTouchDevice) return;

                this.expandTimeout = setTimeout(() => {
                    this.expanded = false;
                }, this.expandTimeoutMS);
            },
            logout() {
                this.$store.dispatch('ACCOUNT/Logout');
            },
        },
        computed: {
            user() {
                return this.$store.getters['ACCOUNT/USER'];
            },
            isTouchDevice() {
                return this.$store.getters.IS_TOUCH_DEVICE;
            },
            is2d() {
                return !!window.NOP_VIEWER?.model?.is2d();
            },
            is3d() {
                return !!window.NOP_VIEWER?.model?.is3d();
            },
            viewId() {
                return this.$store.getters['View_Id'];
            },
            ZIndex() {
                return this.$store.getters['MARKUPDATA/OVERLYA_ZINDEX'];
            },
            ShowPanel() {
                return this.selectedPanel ? true : false;
            },
            MarkupSelectedPanel(){
                return this.$store.getters['MARKUPDATA/MARKUP_SELECTED_PANEL'];
            }
        },
        watch: {
            selectedPanel() {
                setTimeout(() => {
                    window.NOP_VIEWER?.resize();
                }, 200);

                if (this.expandTimeout) clearTimeout(this.expandTimeout);

                this.expandTimeout = setTimeout(() => {
                    this.expanded = false;
                }, this.expandTimeoutMS);
            },
            MarkupSelectedPanel(value){
             if (value) {
               this.selectedPanel = this.panels[5];
                
             }
            },
            viewId: {
                immediate: true,
                handler() {
                    this.panels.forEach((panel) => {
                        if (panel.name === 'blocksPanel')
                            panel.disabled = !window.NOP_VIEWER?.model?.is2d();

                        if (panel.name === 'uploaded-families')
                            panel.disabled = !window.NOP_VIEWER?.model?.is3d();

                        if (panel.name === 'ModelBrowser')
                            panel.disabled = !window.NOP_VIEWER?.model?.is3d();
                    });

                    if (this.selectedPanel?.disabled) this.selectedPanel = null;
                },
            },
        },
    };
</script>
<style lang="css">
    .panel-header {
        padding: 10px;
        border-bottom: 1px solid #8f8f8f85;
        margin: 0;
        font-size: 1.8rem;
        font-weight: 600;
        color: #333;
        width: 100%;
        text-align: center;
        height: 65px;
    }

    .panel-header h6 {
        font-weight: 500;
    }

    .close-panel-icon {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 17px;
        right: 15px;
        cursor: pointer;
    }

    .v-theme--dark .close-panel-icon i {
        color: #fff;
        font-size: 20px;
    }

    .v-theme--dark .close-panel-icon i:hover {
        color: #7d7d7d;
    }

    .panel-wrapper {
        height: calc(100% - 65px) !important;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .settings {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .cameraPanel-wrapper {
        padding: 0px;
    }

    .side-bar i.fa,
    .side-bar i.fa-solid {
        color: inherit;
        font-size: 16px;
    }

    .v-list-item--nav .v-list-item-title {
        font-size: 1rem;
    }

    .side-bar-tabs .v-slide-group--vertical .v-tab__slider {
        box-shadow: -20px -18px 20px 20px rgb(1 122 255);
    }

    .side-bar-tabs .v-btn--size-default {
        padding: 0 20px;
        gap: 10px;
        color: #c2c2c2;
    }

    .side-bar-tabs .v-btn--size-default .v-btn__content {
        overflow: hidden;
        padding: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: normal;
        word-wrap: break-word;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.009375em;
        line-height: 1.5rem;
        text-transform: none;
        display: block;
    }
    .defaultSide {
        /* width: 320px !important; */
        top: 0px !important;
        left: 0px !important;
        height: 100% !important;
    }
    .settingsSide {
        width: calc(100% - 50px) !important;
    }
</style>
