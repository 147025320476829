<template>
  <li class="tree-node">
    <ContextMenu ref="menu" v-if="expanded || (node.children && node.children.length > 0)">
      <ul>
        <li @click="onRefresh">
          <i class="fas fa-refresh"></i>
          <span>Refresh</span>
        </li>
      </ul>
    </ContextMenu>
    <div
      @click="handleClick"
      @contextmenu="openContext"
      class="node"
      :class="{ 'selected-node': isSelectedNode }">
      <span class="icon">
        <i v-if="loadingChildren" class="fas fa-spinner fa-spin"></i>
        <img v-else-if="isIconImage" :src="icon" alt="" />
        <i v-else :class="icon"></i>
      </span>
      <span class="v-list-item-title" :title="node.name">{{ node.name }}</span>
    </div>
    <ul v-show="expanded && node.children && node.children.length > 0" class="children">
      <TreeNode
        v-for="childNode in node.children"
        :key="childNode.id"
        :path="nodePath"
        :node="childNode"
        :load-children="loadChildren"></TreeNode>
    </ul>
  </li>
</template>

<script>
  import TreeNode from './TreeNode.vue';
  import ContextMenu from '@/components/common/context-menu.vue';

  export default {
    props: {
      node: {
        type: Object,
        required: true,
      },
      loadChildren: {
        type: Function,
        required: true,
      },
      path: {
        type: String,
        default: '',
      },
    },
    components: {
      TreeNode,
      ContextMenu,
    },
    data() {
      return {
        expanded: false,
        loadingChildren: false,
        types: {
          default: { icon: 'fas fa-question' },
          '#': { icon: 'fas fa-user' },
          folder: { icon: 'fas fa-folder' },
          file: { icon: 'fas fa-file' },
          hubs: { icon: 'fas fa-cubes' },
          bucket: { icon: 'fas fa-folder-closed' },
          personalHub: { icon: 'fas fa-user' },
          bim360Hubs: { icon: 'fas fa-boxes-stacked' },
          bim360projects: { icon: 'fas fa-building' },
          a360projects: { icon: 'fas fa-building' },
          accprojects: { icon: 'fas fa-building' },
          versions: { icon: 'fas fa-code-branch' },
          unsupported: { icon: 'fas fa-ban' },
        },
      };
    },
    methods: {
      handleClick() {
        
        if (this.node.type.includes('projects')) {
          this.$store.commit('SET_SELECTED_TREE_NODE', this.node);
          return;
        }

        if (this.node.parent) {
          this.toggleChildren();
        }

        this.$refs.menu?.close();
      },
      openContext(e) {
        e.preventDefault();
        this.$refs.menu?.open(e);
      },
      async onRefresh() {
        this.$refs.menu?.close();
        this.loadingChildren = true;
        try {
          await this.loadChildren(this.node);
          this.expanded = true;
        } catch (error) {
          console.error(`Error loading children for node ${this.node.id}:`, error);
        } finally {
          this.loadingChildren = false;
        }
      },
      async toggleChildren() {
        if (!this.node.parent) return;

        if (this.expanded) {
          this.expanded = false;
          return;
        }

        if (this.node.children && this.node.children.length > 0) {
          this.expanded = !this.expanded;
        } else {
          this.loadingChildren = true;
          try {
            await this.loadChildren(this.node);
            this.expanded = true;
          } catch (error) {
            console.error(`Error loading children for node ${this.node.id}:`, error);
          } finally {
            this.loadingChildren = false;
          }
        }
      },
    },
    computed: {
      isIconImage() {
        return this.types[this.node.type] && this.types[this.node.type].icon.startsWith('http');
      },
      icon() {
        return this.types[this.node.type]
          ? this.types[this.node.type].icon
          : this.types['default'].icon;
      },
      newVersionFileName: {
        get() {
          return this.$store.getters.NEW_VERSION_FILE_NAME;
        },
        set(name) {
          this.$store.commit('SET_NEW_VERSION_FILE_NAME', name);
        },
      },
      currentModel() {
        return this.$store.getters['MODELS/CURRENT_MODEL_DATA'];
      },
      selectedTreeNode() {
        return this.$store.getters['SELECTED_TREE_NODE'];
      },
      isSelectedNode() {
        return this.selectedTreeNode && this.selectedTreeNode?.id === this.node?.id;
      },
      nodePath() {
        return this.path + (this.path ? ' / ' : '') + this.node.name;
      },
    },
    watch: {
      newVersionFileName: function (name) {
        if (name) {
          if (this.node.name === name) {
            this.$store.commit('SET_NEW_VERSION_FILE_NAME', null);
            this.onRefresh();
          }
        }
      },
    },
  };
</script>

<style scoped>
  .node {
    --hover-color: #a1ffa1;
  }

  .node {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .node:hover {
    color: var(--hover-color);
  }

  .node:hover .icon i {
    color: var(--hover-color);
  }

  .icon {
    margin-right: 5px;
    color: #757575;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
  }

  .icon img {
    width: -webkit-fill-available;
  }

  .name {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.5rem;
    /* font-weight: bold; */
  }

  .children .name::before {
    content: '';
    position: absolute;
    top: 12px;
    left: -32px;
    width: 28px;
    height: 2px;
    border-top: 2px dashed#b0b0b0;
  }

  .children {
    position: relative;
    padding-left: 10px;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 10px;
  }

  .children::before {
    content: '';
    position: absolute;
    top: 0;
    left: -2px;
    width: 2px;
    height: calc(100% - 5px);
    border-left: 2px solid #b0b0b0;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .tree-node .v-list-item-title {
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.2rem;
  }

  .selected-node {
    color: #6cf170;
  }

  .node.selected-node > span {
    font-weight: bold;
  }

  .selected-node .icon i {
    color: #6cf170;
  }
</style>
