<template lang="html">
    <v-container fluid class="h-100">
        <v-row>
            <v-col cols="12">
                <tree-view :nodes="hubs" :load-children="loadChildren"></tree-view>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import TreeView from './TreeView/TreeView.vue';
import { mapActions } from 'vuex';
export default {
    components: {
        TreeView,
    },
    data() {
        return {
        };
    },
    methods: {
        ...mapActions(['GetHubs', 'GetHubProjects', 'GetProjectContents', 'GetFolderContents', 'GetFileVersions']),
        async loadHubs() {
            this.GetHubs();
        },
        loadChildren(node) {
            return new Promise((resolve) => {
    
                const hubTypes = ['bim360Hubs', 'hubs', 'personalHub'];            
                if (hubTypes.includes(node.type)) {
                    const ref = this.extractHubPathFromUrl(node.ref);
                    this.GetHubProjects(ref).then(projects => {

                        projects.forEach(project => {
                            project.hubName = node.name;
                        })
                        
                        node.children = projects;
                        resolve();
                    });    
                    return;
                }
    
                const projectTypes = ['a360projects', 'projects', 'accprojects', 'bim360projects'];
                if (projectTypes.includes(node.type)) {
                    const ref = this.extractHubPathFromUrl(node.ref);
                    this.GetProjectContents(ref).then(contents => {
                        node.children = contents;
                        resolve();
                    });
                    return;
                }
    
                if (node.type === 'folder') {
                    const ref = this.extractProjectPathFromUrl(node.ref);
                    this.GetFolderContents(ref).then(contents => {
                        node.children = contents;
                        if (node.children.length === 0) {
                            node.children = [{ type: 'unsupported', id: 'unsupported', name: 'No supported items' }];
                        }
                        resolve();
                    });
                    return;
                }
    
                if (node.type === 'file') {
                    const ref = this.extractProjectPathFromUrl(node.ref);
                    this.GetFileVersions(ref).then(versions => {
                        node.children = versions;
                        resolve();
                    });
                    return;
                }                
            });
        },
        extractHubPathFromUrl(url) {
            // Regular expression to match the desired part in the URL
            // eslint-disable-next-line no-useless-escape
            const pathRegex = /hubs\/.*/;
            
            // Match the desired part in the URL
            const match = url.match(pathRegex);
            
            // Return the matched part
            return match ? match[0] : null;
        },
        extractProjectPathFromUrl(url) {
            // Regular expression to match the desired part in the URL
            // eslint-disable-next-line no-useless-escape
            const pathRegex = /projects\/.*/;
            
            // Match the desired part in the URL
            const match = url.match(pathRegex);
            
            // Return the matched part
            return match ? match[0] : null;
        },
    },
    computed: {
        hubs() {
            return this.$store.getters.HUBS;
        }
    },
    mounted() {
        this.loadHubs();
    }
}
</script>
<style lang="css">
    
</style>