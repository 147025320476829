import { HubConnectionBuilder } from "@microsoft/signalr";
import config from "../appConfig";

class HubService {
    
    constructor() {
        this.connection = new HubConnectionBuilder()
            .withUrl(`${config.API_URL}api/call-hub`)
            .withAutomaticReconnect()
            .build();
    }

    startConnection = async () => {
        try {
            await this.connection.start();
        } catch (err) {
            console.log("Error while starting connection: " + err);
        }
    };

    onReceiveMessage = (callback) => {
        this.connection.on("ReceiveMessage", (user, message) => {
            console.log("Received message: ", user, message);
            callback(user, message);
        });
    };

    sendMessage = (user, message) => {
        this.connection.invoke("SendMessage", user, message).catch((err) => {
            console.log(err);
        });
    };
}

export default new HubService();