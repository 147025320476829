import store from "@/store";

/*
    * New Family Instance
        - forgeFamilyId
    * Manipulated Existing Element
        - originalDbId === dbId
        - originalExternalId
    * Cloned Element
        - originalDbId !== dbId
        - originalExternalId ❌

    * manipulationType => 'New Family Instance' | 'Cloned Element' | 'Manipulated Existing Element' | 'Deleted Element
*/
export class ManipulatedElement {
    constructor() {
        this.id = 0;
        (this.createdByName = store.getters['ACCOUNT/USER']?.name), (this.viewType = null);
        this.viewId = '';
        this.manipulationType = null;
        this.name = '';
        this.familyType = '';
        this.runStatus = 'Pending';
        this.modelId = ''; // Model URN
        this.position = null; // Vector3
        this.originalPosition = null; // Vector3
        this.rotation = null; // Vector3 // Z only
        this.forgeFamilyId = null;
        this.dbId = null;
        this.originalDbId = null;
        this.originalExternalId = null;
        this.properties = '';
        this.runStatus = 'Pending';
        this.viewGuids = '';
    }
}

export const ManipulationTypes = {
    NEW_FAMILY_INSTANCE: 'New Family Instance',
    CLONED_ELEMENT: 'Cloned Element',
    MANIPULATE_EXISTING: 'Manipulated Existing Element',
    DELETED: 'Deleted',
};

export class ForgeSettings {
    constructor() {
        this.modelGuid = "";
        this.rotationSnappingDegree = null;
        this.nestedFamiliesSelectionMode = false;
    }
}