<template>
  <li v-show="visible">
    <div @click="handleClick" class="node">
      <span class="icon">
        <i :class="icon"></i>
      </span>
      <span class="name" :title="node.name">{{ node.name }}</span>
    </div>
    <ul v-show="isExpanded" class="children">
      <StaticTreeNode
        v-for="childNode in node.children"
        :key="childNode.id"
        :node="childNode"></StaticTreeNode>
    </ul>
  </li>
</template>

<script>
  import StaticTreeNode from './StaticTreeNode.vue';

  export default {
    props: {
      node: {
        type: Object,
        required: true,
      },
    },
    components: {
      StaticTreeNode,
    },
    data() {
      return {
        expanded: false,
      };
    },
    methods: {
      handleClick() {
        if (this.isParent) {
          this.toggleChildren();
        } else {
          this.node?.onClick();
        }
      },
      toggleChildren() {
        if (!this.isParent) return;

        this.expanded = !this.expanded;
      },
    },
    computed: {
      icon() {
        if (!this.isParent) return 'fa-solid fa-circle';
        return this.isExpanded ? 'fa-solid fa-caret-down' : 'fa-solid fa-caret-right';
      },
      isParent() {
        return this.node.children && this.node.children.length > 0;
      },
      searchText: {
        get() {
          return this.$store.getters['BLOCKS_TREE_SEARCH'];
        },
        set(value) {
          this.$store.commit('SET_BLOCKS_TREE_SEARCH', value);
        },
      },
      visible() {
        return this.node.matched || !this.searchText;
      },
      isExpanded() {
        if (!this.isParent) return false;

        return this.expanded;
      },
    },
    watch: {
      searchText(newVal) {
        this.expanded = !!newVal;
      },
    },
  };
</script>

<style scoped>
  .node {
    --hover-color: #a1ffa1;
  }

  .v-card-text {
    padding-top: 0px;
  }

  .node {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
  }

  .node:hover {
    color: var(--hover-color);
  }

  .node:hover .icon i {
    color: var(--hover-color);
  }

  .icon {
    margin-right: 5px;
    color: #757575;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
  }

  .icon img {
    width: -webkit-fill-available;
  }

  .name {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    max-width: 90%;
  }

  .children .name::before {
    content: '';
    position: absolute;
    top: 12px;
    left: -32px;
    width: 28px;
    height: 2px;
    border-top: 2px dashed#b0b0b0;
  }

  .children {
    position: relative;
    padding-left: 20px;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 10px;
  }

  .children::before {
    content: '';
    position: absolute;
    top: 0;
    left: -2px;
    width: 2px;
    height: calc(100% - 5px);
    border-left: 2px solid #b0b0b0;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
</style>
