/* eslint-disable no-unreachable */
import { ManipulatedElement, ManipulationTypes } from "@/models/ForgeModels";
import store from "@/store";
import { getWorldBoundingBox } from "@/store/modules/ForgeService";
import { toast } from "vue3-toastify";
/* eslint-disable no-unused-vars */
const Autodesk = window.Autodesk;
const THREE = window.THREE;
import ViewerToolkit from '../Viewer.Toolkit';
class ManipulationExtension extends Autodesk.Viewing.Extension {
    constructor(viewer, options) {
        super(viewer, options);
        this.viewer = viewer;
        this.options = options;
        this.menu = null;
        this.selection = null;
        this.sceneBuilder = null;
        this.modelBuilder = null;

        this.saveTimeout = null;

        this.isFollowing = false;
        this.startPointer = null;
        this.currentMousePosition = null;

        this.onAggregateSelectionChanged = this.onAggregateSelectionChanged.bind(this);
        this.addClonedElement = this.addClonedElement.bind(this);

        store.state.clone3dTool = this;

        this.boxMaterial = this.viewer.model.loader.viewer3DImpl.matman().defaultMaterial.clone();
    }

    static get ExtensionId() {
        return 'Viewing.Extension.Manipulation';
    }

    get CallbackId() {
        return 'Manipulation-Extension-Callback';
    }

    async load() {
        this.modifyContextMenu();
        this.viewer.addEventListener(
            Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
            this.onAggregateSelectionChanged,
        );
        this.sceneBuilder = await this.viewer.loadExtension('Autodesk.Viewing.SceneBuilder');
        await this.checkModelBuilder();
        window.addEventListener('keydown', this.handleKeyDown.bind(this));
        this.viewer.canvas.addEventListener('mousemove', this.globalMouseMoveHandler.bind(this));

        return true;
    }

    unload() {
        this.viewer.removeEventListener(
            Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
            this.onAggregateSelectionChanged,
        );
        this.viewer.unregisterContextMenuCallback(this.CallbackId);
        this.viewer.unloadExtension('Autodesk.Viewing.SceneBuilder');
        this.sceneBuilder = null;
        window.removeEventListener('keydown', this.handleKeyDown.bind(this));
        this.viewer.canvas.removeEventListener('mousemove', this.globalMouseMoveHandler.bind(this));

        return true;
    }

    globalMouseMoveHandler(event) {
        this.currentMousePosition = new THREE.Vector2(event.clientX, event.clientY);
    }

    onAggregateSelectionChanged(event) {
        if (event.selections.length === 0) {
            this.selection = null;
            this.stopFollowing();
            return;
        }

        this.selection = event.selections[0];
    }

    handleKeyDown(event) {
        if (!this.selection) return;

        if (event.ctrlKey) {
            if (event.key === 'c') {
                this.onCloneClicked();
            } else if (event.key === 'm') {
                this.onMoveClicked();
            } else if (event.key === 'r') {
                event.preventDefault();
                this.onRotateClicked();
            }
        }
    }

    modifyContextMenu() {
        this.viewer.registerContextMenuCallback(this.CallbackId, (menu, status) => {
            if (!status.hasSelected) return;

            let model = this.viewer.getAggregateSelection()[0].model;

            if (!model.myData?.isSceneBuilder) {
                menu.push({
                    title: 'Clone Element',
                    target: this.onCloneClicked.bind(this),
                });
            }

            menu.push({
                title: 'Move Element',
                target: this.onMoveClicked.bind(this),
            });

            menu.push({
                title: 'Rotate Element',
                target: this.onRotateClicked.bind(this),
            });

            // menu.push({
            //     title: 'Delete Element',
            //     target: this.onDeleteClicked.bind(this)
            // });
        });
    }

    onDeleteClicked() {
        // if (!this.selection.model.myData?.isSceneBuilder) {
        //     toast.error('Cannot delete a model element.');
        //     return;
        // }
    }

    onRotateClicked() {
        this.viewer.getExtension('Viewing.Extension.Transform').activateRotateTool();
    }

    onMoveClicked() {
        this.viewer.getExtension('Viewing.Extension.Transform').activateMoveTool();
    }

    async deleteManipulatedElement(dbId) {
        let mesh = this.modelBuilder.fragList.vizmeshes.filter((vizmesh) => vizmesh?.dbId === dbId);

        if (!mesh) return;

        for (let i = 0; i < mesh.length; i++) {
            this.modelBuilder.removeMesh(mesh[i]);
        }

        this.modelBuilder.sceneUpdated(true, false);
    }
    generateGUID() {
        // Generate a 32-character string in the form xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0, // Random integer from 0 to 15
                v = c === 'x' ? r : (r & 0x3) | 0x8; // If 'x' use r, if 'y' use (r & 0x3 | 0x8)
            return v.toString(16); // Convert to hexadecimal
        });
    }

    createBox(position, rotation, builderDbId, name, width, length, height) {
        let center = new THREE.Vector3(0, 0, 0);
        let translate = null;
        if (position) {
            translate = new THREE.Vector3(
                position.x - center.x,
                position.y - center.y,
                position.z - center.z,
            );
        }

        let geom = new THREE.Geometry();

        const vertices = [
            new THREE.Vector3(-width / 2, -length / 2, -height / 2),
            new THREE.Vector3(width / 2, -length / 2, -height / 2),
            new THREE.Vector3(width / 2, length / 2, -height / 2),
            new THREE.Vector3(-width / 2, length / 2, -height / 2),
            new THREE.Vector3(-width / 2, -length / 2, height / 2),
            new THREE.Vector3(width / 2, -length / 2, height / 2),
            new THREE.Vector3(width / 2, length / 2, height / 2),
            new THREE.Vector3(-width / 2, length / 2, height / 2),
        ];

        vertices.forEach((vertex) => geom.vertices.push(vertex));

        const faces = [
            // Front face
            new THREE.Face3(0, 1, 2),
            new THREE.Face3(2, 3, 0),
            // Back face
            new THREE.Face3(4, 5, 6),
            new THREE.Face3(6, 7, 4),
            // Top face
            new THREE.Face3(3, 2, 6),
            new THREE.Face3(6, 7, 3),
            // Bottom face
            new THREE.Face3(0, 1, 5),
            new THREE.Face3(5, 4, 0),
            // Right face
            new THREE.Face3(1, 2, 6),
            new THREE.Face3(6, 5, 1),
            // Left face
            new THREE.Face3(0, 3, 7),
            new THREE.Face3(7, 4, 0),
        ];

        faces.forEach((face) => geom.faces.push(face));

        try {
            geom.computeFaceNormals();
        } catch (error) {
            console.log(`Error computing face normals`);
        }

        let meshGeometry = new THREE.BufferGeometry().fromGeometry(geom);

        meshGeometry.isLine = true;
        meshGeometry.isPoint = true;

        let mesh = new THREE.Mesh(meshGeometry, this.boxMaterial);

        mesh.name = `${name}-${this.generateGUID()}`;
        mesh.dbId = builderDbId;

        let translationMatrix = mesh.matrixWorld.clone();
        if (position) {
            let pos = translationMatrix.getPosition();
            pos = new THREE.Vector3(pos.x + translate.x, pos.y + translate.y, pos.z + translate.z);
            translationMatrix = translationMatrix.setPosition(pos);
        }

        if (rotation) {
            let rotationMatrix = new THREE.Matrix4().multiply(
                new THREE.Matrix4().makeRotationZ(-rotation.z),
            );

            translationMatrix = translationMatrix.multiply(rotationMatrix);
        }
        mesh.matrix = translationMatrix;
        return mesh;
    }

    async addClonedElement(
        dbId,
        builderDbId,
        position,
        rotation,
        name,
        familyType,
        ignoreAddToStore = false,
    ) {
        dbId = parseInt(dbId);
        builderDbId = parseInt(builderDbId);
        let model = this.viewer.model;

        let it = model.getInstanceTree();

        let unitScale = model.getUnitScale();

        let nodeName = it.getNodeName(dbId);

        let fragList = model.getFragmentList();

        let fragIds = [];

        it.enumNodeFragments(dbId, (fragId) => {
            fragIds.push(fragId);
        });

        let center;
        let savedPosition;

        if (!fragIds.length) {
            let matchedBlock = await store.dispatch('GET_BLOCK_NODE_BY_SEARCH', {
                name: familyType,
                family: name,
            });

            let length = 1;
            let width = 1;
            let height = 1;
            if (matchedBlock) {
                let bbox = matchedBlock.bbox;
                width = bbox.max.x - bbox.min.x;
                length = bbox.max.y - bbox.min.y;
            } else {
                return; //wait for blocks to be loaded
            }

            let mesh = this.createBox(position, rotation, builderDbId, name, width, length, height);

            let added = this.modelBuilder.addMesh(mesh);

            if (!added) {
                console.log(`Failed to add mesh to model builder`);
            }
        } else {
            let nodebBox = getWorldBoundingBox(fragIds, fragList);

            center = nodebBox.getCenter();

            savedPosition = new THREE.Vector3(center.x + 15 * unitScale, center.y, center.z);

            let translate = null;
            if (position) {
                translate = new THREE.Vector3(
                    position.x - center.x,
                    position.y - center.y,
                    position.z - center.z,
                );
            }
            it.enumNodeFragments(
                dbId,
                (fragId) => {
                    var fragProxy = this.viewer.impl.getFragmentProxy(model, fragId);

                    fragProxy.getAnimTransform();

                    let renderProxy = this.viewer.impl.getRenderProxy(model, fragId);

                    let geom = new THREE.Geometry();

                    let VE = Autodesk.Viewing.Private.VertexEnumerator;

                    VE.enumMeshVertices(renderProxy.geometry, (v, i) => {
                        geom.vertices.push(new THREE.Vector3(v.x, v.y, v.z));
                    });

                    VE.enumMeshIndices(renderProxy.geometry, (a, b, c) => {
                        geom.faces.push(new THREE.Face3(a, b, c));
                    });

                    try {
                        geom.computeFaceNormals();
                    } catch (error) {
                        console.log(`Error computing face normals`);
                    }

                    let meshGeometry = new THREE.BufferGeometry().fromGeometry(geom);

                    meshGeometry.isLine = true;
                    meshGeometry.isPoint = true;

                    let mesh = new THREE.Mesh(meshGeometry, renderProxy.material);

                    mesh.name = `${nodeName}-${this.getHashDate()}`;

                    let translationMatrix = renderProxy.matrixWorld.clone();

                    if (position) {
                        let pos = translationMatrix.getPosition();
                        pos = new THREE.Vector3(
                            pos.x + translate.x,
                            pos.y + translate.y,
                            pos.z + translate.z,
                        );
                        translationMatrix = translationMatrix.setPosition(pos);
                    } else {
                        let p = translationMatrix.getPosition();
                        p = new THREE.Vector3(p.x + 15 * unitScale, p.y, p.z);
                        translationMatrix = translationMatrix.setPosition(p);
                    }

                    if (rotation) {
                        translationMatrix = ViewerToolkit.getRotatedMatrix(translationMatrix, -rotation.z);
                    }

                    mesh.matrix = translationMatrix;

                    mesh.dbId = builderDbId;

                    let added = this.modelBuilder.addMesh(mesh);

                    if (!added) {
                        console.log(`Failed to add mesh to model builder`);
                    }
                },
                true,
            );
        }

        if (ignoreAddToStore) return;
        const _viewGuids = await ViewerToolkit.getViewabelIdsOfElement(model, dbId, store);
        model.getProperties(dbId, (props) => {
            let typeName = props.properties.find((prop) => prop.displayName === 'Type Name');
            let manipulatedElement = new ManipulatedElement();
            manipulatedElement.viewType = '3d';
            manipulatedElement.viewId = window.NOP_VIEWER.model.getDocumentNode()?.data?.guid;
            manipulatedElement.manipulationType = ManipulationTypes.CLONED_ELEMENT;
            manipulatedElement.name = nodeName;
            manipulatedElement.familyType = typeName?.displayValue;
            manipulatedElement.dbId = builderDbId;
            manipulatedElement.originalDbId = dbId;
            manipulatedElement.originalExternalId = props.externalId;
            manipulatedElement.modelId = model.getSeedUrn();
            manipulatedElement.position = savedPosition;
            manipulatedElement.originalPosition = savedPosition;
            manipulatedElement.viewGuids = _viewGuids;

            store.commit('ADD_MANIPULATED_ELEMENT', manipulatedElement);
        });

        if (this.saveTimeout) {
            clearTimeout(this.saveTimeout);
        }

        this.saveTimeout = setTimeout(() => {
            store.dispatch('SaveLocalModelChanges');
        }, 2000);
    }
    async onCloneClicked() {
        if (this.selection.model.myData?.isSceneBuilder) {
            toast.error('Cannot clone a cloned element.');

            return;
        }

        let familyInstance = store.state.familiesInstances.find(
            (family) => family.loadedModelId === this.selection?.model?.id,
        );

        if (familyInstance) {
            toast.error('Cannot clone a new family instance element.');

            return;
        }

        const manipulatedElements = store.getters.MANIPULATED_ELEMENTS;

        const startDbId = -110;

        const nextDbId = startDbId + manipulatedElements.length;

        let dbId = this.selection.dbIdArray[0];

        this.addClonedElement(dbId, nextDbId);
    }

    enableFollowMode() {
        console.log(`Enabling follow mode`);
        this.isFollowing = true;

        this.startPointer = new THREE.Vector2(
            this.currentMousePosition.x,
            this.currentMousePosition.y,
        );

        this.viewer.canvas.addEventListener('mousemove', this.onMouseMove.bind(this));
    }

    view3dToWorld(point) {
        let globalOffset = this.viewer.model.getData().globalOffset;
        let unitScale = this.viewer.model.getData().unitScale;

        return point.clone().add(globalOffset).multiplyScalar(unitScale);
    }

    stopFollowing(event) {
        // console.log(`Disabling follow mode`)

        this.isFollowing = false;

        this.viewer.canvas.removeEventListener('mousemove', this.onMouseMove.bind(this));
    }

    getMousePositionInViewer(event, viewer) {
        // Get the mouse position in normalized device coordinates (NDC)
        const rect = viewer.container.getBoundingClientRect();
        const mouse = new THREE.Vector2(
            ((event.clientX - rect.left) / rect.width) * 2 - 1,
            -((event.clientY - rect.top) / rect.height) * 2 + 1,
        );

        // Get the viewer's camera and compute the ray direction manually
        const camera = viewer.getCamera();
        const vector = new THREE.Vector3(mouse.x, mouse.y, 0.25);
        vector.unproject(camera);
        const dir = vector.sub(camera.position).normalize();

        // Now that we have the direction, create a ray
        const ray = new THREE.Ray(camera.position, dir);

        // Define your planes
        const horizontalPlane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
        const verticalPlaneX = new THREE.Plane(new THREE.Vector3(1, 0, 0), 0);
        const verticalPlaneZ = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);

        // Find intersections with the planes
        const intersectionHorizontal = ray.intersectPlane(horizontalPlane, new THREE.Vector3());
        const intersectionVerticalX = ray.intersectPlane(verticalPlaneX, new THREE.Vector3());
        const intersectionVerticalZ = ray.intersectPlane(verticalPlaneZ, new THREE.Vector3());

        // Check if intersections are valid before comparing distances
        if (!intersectionHorizontal && !intersectionVerticalX && !intersectionVerticalZ) {
            console.log('All intersections are null.');
            return null;
        }

        let intersections = [];

        if (intersectionHorizontal) intersections.push(intersectionHorizontal);
        if (intersectionVerticalX) intersections.push(intersectionVerticalX);
        if (intersectionVerticalZ) intersections.push(intersectionVerticalZ);

        if (intersections.length === 0) {
            console.log('No intersections found.');
            return null;
        }

        let placementPoint = intersections.reduce((prev, current) => {
            return prev.distanceTo(camera.position) > current.distanceTo(camera.position)
                ? prev
                : current;
        });

        return placementPoint;
    }

    onMouseMove = (event) => {
        if (!this.isFollowing) return;

        if (!this.selection?.dbIdArray?.length) return;

        const modelBuilder = store.getters.MODEL_BUILDER;

        let mesh = modelBuilder.fragList.vizmeshes.find(
            (vizmesh) => vizmesh?.dbId === this.selection.dbIdArray[0],
        );

        if (!this.isFollowing || !mesh) return;

        let placementPoint = this.getMousePositionInViewer(event, this.viewer);

        if (!placementPoint) return;

        console.log(
            `Placement Point: ${placementPoint.x}, ${placementPoint.y}, ${placementPoint.z}`,
        );

        let previousPosition = mesh.matrixWorld.getPosition();

        this.previousDistance = previousPosition.distanceTo(placementPoint);

        if (
            this.previousDistance &&
            previousPosition.distanceTo(placementPoint) > this.previousDistance
        )
            return;

        let matrix = mesh.matrixWorld.clone().setPosition(placementPoint);

        mesh.matrix = matrix;

        // this.startPointer = currentPointer;

        mesh.matrixAutoUpdate = false;

        modelBuilder.updateMesh(mesh, false, false);

        modelBuilder.sceneUpdated(true, false);
    };

    async checkModelBuilder() {
        if (!this.modelBuilder) {
            this.modelBuilder = await this.sceneBuilder.addNewModel({
                conserveMemory: false,
                modelNameOverride: `Cloned Elements`,
            });

            store.commit('SET_MODEL_BUILDER', this.modelBuilder);
        }
    }

    generateGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    getHashDate() {
        return new Date().getTime().toString(16);
    }
}

Autodesk.Viewing.theExtensionManager.registerExtension(ManipulationExtension.ExtensionId, ManipulationExtension);

export default ManipulationExtension;