<template lang="html">
    <div class="instances-list-drawer">
        <div class="d-flex justify-center align-center pa-2" style="gap: 5px">
            <v-text-field
                dense
                placeholder="Search"
                hide-details
                density="compact"
                solo
                v-model="search"></v-text-field>
            <v-btn
               
                @click="removeMultipleInstance()"
                v-if="allInstancesChecked > 0"
                size="30">
                 <v-icon color="error">fa:fas fa-trash-alt</v-icon>
            </v-btn>
         
            <v-btn size="30">
                <v-icon>mdi-filter-check-outline</v-icon>

                <v-menu activator="parent" location="end">
                    <v-list>
                        <v-list-item @click="filterText = ''">
                            <v-chip border class="subtitle" color="white"> All </v-chip>
                        </v-list-item>
                        <v-list-item
                            v-for="option in filterOptions"
                            :key="option"
                            @click="filterText = option">
                            <v-chip border class="subtitle" :color="getItemColorByType(option)">
                                {{ option }}
                            </v-chip>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-btn>
            <v-btn size="30" @click="isList = false">
                <i class="fa-solid fa-folder"></i>
            </v-btn>
            <v-btn size="30" @click="isList = true">
                <i class="fa-solid fa-list"></i>
            </v-btn>
        </div>
        <div v-if="this.allInstances.length > 0">
            <v-checkbox
                theme="primary"
                v-model="selected"
                hide-details
                :label="`Select All (${allInstancesChecked} items selected)`"></v-checkbox>
        </div>
        <v-expansion-panels v-if="viewsInstances.length > 0 && !isList" v-model="panel" multiple>
            <v-expansion-panel
                v-for="item in viewsInstances"
                :key="item.id"
                :title="item.name"
                :id="item.id">
                <template v-slot:header>
                    <div>Item</div>
                </template>
                <v-expansion-panel-text class="py-2">
                    <v-list-item v-for="item in item.instances" :key="item.id">
                        <div class="text--primary">
                            <v-hover v-slot="{ isHovering, props }">
                                <v-card
                                    v-bind="props"
                                    @click="zoomToInstance(item)"
                                    :elevation="isHovering ? 24 : 6"
                                    max-height="100"
                                    height="100"
                                    v-ripple
                                    link
                                    :border="`${getItemColor(item)} md`"
                                    class="card">
                                    <v-sheet
                                        v-bind="props"
                                        class="border-e-lg border-info d-flex align-center h-100"
                                        :class="{
                                            'pa-2': !item.thumbnailUrl,
                                        }"
                                        color="transparent">
                                        <v-img
                                            width="100"
                                            style="transition: width 0.3s ease-in-out"
                                            height="110"
                                            aspect-ratio="16/9"
                                            cover
                                            lazy-src="/img/autodesk.svg"
                                            :src="item.thumbnailUrl ?? '/img/autodesk.svg'"></v-img>
                                        <v-checkbox
                                            @click.stop="hey()"
                                            v-model="item.checked"
                                            color="secondary"
                                            class="instances-checkbox"></v-checkbox>
                                    </v-sheet>
                                    <v-card-text class="card-content">
                                        <div class="title mb-1">{{ item.name }}</div>
                                        <div class="subtitle">{{ item.createdByName }}</div>
                                        <div class="subtitle">
                                            {{ formatDateTime(item.createdOn) }}
                                        </div>
                                        <div class="subtitle">
                                            {{ formatPosition(item.position) }}
                                        </div>
                                        <v-card-actions class="buttons">
                                            <v-chip
                                                size="small"
                                                density="compact"
                                                border
                                                :color="getItemColor(item)"
                                                class="subtitle"
                                                width="100px">
                                                {{ getItemType(item) }}
                                            </v-chip>
                                            <v-chip
                                                size="small"
                                                density="compact"
                                                border
                                                :color="getRunStatusColor(item)"
                                                class="subtitle ml-1"
                                                width="100px">
                                                {{ item.runStatus }}
                                            </v-chip>
                                            <v-spacer></v-spacer>
                                            <!-- <v-btn
                                                color="success"
                                                icon
                                                @click="zoomToInstance(item)">
                                                <v-icon>fa:fas fa-maximize</v-icon>
                                            </v-btn> -->
                                            <v-btn
                                                color="error"
                                                icon
                                                @click.stop="removeInstance(item)">
                                                <v-icon>fa:fas fa-trash-alt</v-icon>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </div>
                    </v-list-item>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-list-item
            v-else-if="viewsInstances.length > 0"
            v-for="item in allInstances"
            :key="item.id">
            <div class="text--primary">
                <v-hover v-slot="{ isHovering, props }">
                    <v-card
                        v-bind="props"
                        @click="zoomToInstance(item)"
                        :elevation="isHovering ? 24 : 6"
                        max-height="130"
                        height="110"
                        v-ripple
                        link
                        :border="`${getItemColor(item)} md`"
                        class="card">
                        <v-sheet
                            v-bind="props"
                            class="border-e-lg border-info d-flex align-center h-100"
                            :class="{
                                'pa-2': !item.thumbnailUrl,
                            }"
                            color="transparent">
                            <v-img
                                width="100"
                                style="transition: width 0.3s ease-in-out"
                                height="110"
                                aspect-ratio="16/9"
                                cover
                                lazy-src="/img/autodesk.svg"
                                :src="item.thumbnailUrl ?? '/img/autodesk.svg'"></v-img>
                            <v-checkbox
                                @click.stop="hey()"
                                v-model="item.checked"
                                color="secondary"
                                class="instances-checkbox"></v-checkbox>
                        </v-sheet>
                        <v-card-text class="card-content">
                            <div class="title mb-1">{{ item.name }}</div>
                            <div class="subtitle">{{ item.createdByName }}</div>
                            <div class="subtitle">
                                {{ formatDateTime(item.createdOn) }}
                            </div>
                            <div class="subtitle">
                                {{ formatPosition(item.position) }}
                            </div>
                            <v-card-actions class="buttons">
                                <v-chip
                                    size="small"
                                    density="compact"
                                    border
                                    :color="getItemColor(item)"
                                    class="subtitle"
                                    width="100px">
                                    {{ getItemType(item) }}
                                </v-chip>
                                <v-chip
                                    size="small"
                                    density="compact"
                                    border
                                    :color="getRunStatusColor(item)"
                                    class="subtitle ml-1"
                                    width="100px">
                                    {{ item.runStatus }}
                                </v-chip>
                                <v-spacer></v-spacer>
                                <!-- <v-btn
                                                color="success"
                                                icon
                                                @click="zoomToInstance(item)">
                                                <v-icon>fa:fas fa-maximize</v-icon>
                                            </v-btn> -->
                                <v-btn color="error" icon @click.stop="removeInstance(item)">
                                    <v-icon>fa:fas fa-trash-alt</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </div>
        </v-list-item>
        <div v-else class="py-16 d-flex align-center justify-center flex-column h-100">
            <v-img width="300" max-height="350px" contain src="/img/No Data.svg"></v-img>
            <div class="pt-4 title">No Instances Found</div>
        </div>

        <confirm-delete ref="confirmDeleteDialog"></confirm-delete>
    </div>
</template>
<script>
    import '@mdi/font/css/materialdesignicons.css';
    import { mapActions } from 'vuex';
    import ConfirmDelete from '@Share/components/ConfirmDelete.vue';
    import { toast } from 'vue3-toastify';
    import { debounce } from 'lodash';
    import { ManipulationTypes } from '@/models/ForgeModels';
    import ViewerToolkit from '../../src/extensions/Viewer.Toolkit';
    // const Autodesk = window.Autodesk;

    export default {
        name: 'InstancesList',
        components: { ConfirmDelete },
        data() {
            return {
                viewsInstances: [],
                isList: false,
                search: '',
                panel: [],
                filterOptions: ['Added', 'Modified', 'Deleted'],
                filterText: '',
                selected: false,
            };
        },

        methods: {
            ...mapActions([
                'ZoomToModelInstance',
                'RemoveFamilyInstance',
                'RemoveManipulatedElement',
            ]),
            ...mapActions('VIEWER', ['GeneratePointSnapshotBase64']),
            getItemType(item) {
                if (!item.position && !item.rotaion && item.properties) {
                    return 'Modified Properties';
                } else if (item.manipulationType === ManipulationTypes.NEW_FAMILY_INSTANCE) {
                    return 'Added';
                } else if (item.manipulationType === ManipulationTypes.CLONED_ELEMENT) {
                    return 'Added';
                } else if (item.manipulationType === ManipulationTypes.MANIPULATE_EXISTING) {
                    return 'Modified';
                } else if (item.manipulationType === ManipulationTypes.DELETED) {
                    return 'Deleted';
                }

                return 'Unknown';
            },
            getRunStatusColor(item) {
                if (item.runStatus === 'Done') {
                    return 'success';
                } else if (item.runStatus === 'Pending') {
                    return 'warning';
                }

                return 'error';
            },
            getItemColor(item) {
                let type = this.getItemType(item);

                if (type === 'Added') {
                    return 'success';
                } else if (type === 'Modified' || type === 'Modified Properties') {
                    return 'warning';
                }

                return 'error';
            },
            getItemColorByType(type) {
                if (type === 'Added') {
                    return 'success';
                } else if (type === 'Modified' || type === 'Modified Properties') {
                    return 'warning';
                }

                return 'error';
            },
            formatDateTime(date) {
                return new Date(date).toLocaleString();
            },
            formatPosition(position) {
                let x = position?.x.toFixed(2) ?? 0;
                let y = position?.y.toFixed(2) ?? 0;
                let z = position?.z.toFixed(2) ?? 0;
                return `X: ${x}, Y: ${y}, Z: ${z}`;
            },
            selectInstance(instance) {
                const tool = window.NOP_VIEWER.toolController.getTool('Viewing.Clone2D.Tool');
                tool?.clearSelection();
                window.NOP_VIEWER.clearSelection();

                const type = this.getItemType(instance);
                if (type == 'Deleted' || type == 'Unknown') return;

                if (
                    instance.manipulationType == ManipulationTypes.CLONED_ELEMENT &&
                    instance.viewType == '2d'
                ) {
                    let object = tool.clonedObjects.find((obj) => obj.name == instance.dbId);
                    tool.selectObject(object);
                } else {
                    const viewer = window.NOP_VIEWER;
                    const dbId = instance.dbId;
                    const visibleModels = viewer.getVisibleModels();
                    let modelWithObject = null;
                    for (const model of visibleModels) {
                        try {
                            const allDbIdsStr = Object.keys(
                                model.getData().instanceTree.nodeAccess.dbIdToIndex,
                            );
                            const dbIds = allDbIdsStr.map((id) => parseInt(id));
                            if (dbIds.includes(dbId)) {
                                modelWithObject = model;
                                break;
                            }
                        } catch (error) {
                            console.error('Error getting object tree:', error);
                        }
                    }

                    if (modelWithObject) {
                        viewer.select(dbId, modelWithObject);
                    }
                }
            },
            zoomToInstance(instance) {
                if (instance.viewId === this.$route.query?.view) {
                    if (!instance.position) return;
                    this.$store.dispatch('VIEWER/ZoomToPoint', { viewer: window.NOP_VIEWER, instance }).then(() => {
                        this.selectInstance(instance);
                    });
                } else {
                    let callback = () => {
                        if (instance.viewId !== this.$route.query?.view) return;

                        if (!instance.position) return;

                        this.$store.dispatch('VIEWER/ZoomToPoint', { viewer: window.NOP_VIEWER, instance }).then(() => {
                            this.selectInstance(instance);
                        });

                        // window.NOP_VIEWER.removeEventListener(
                        //     Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
                        //     callback,
                        // );
                        this.$store.commit('REMOVE_ON_MANIPULATION_DONE_CALLBACK', callback);
                    };
                    this.$store.commit('ADD_ON_MANIPULATION_DONE_CALLBACK', callback);
                    // window.NOP_VIEWER.addEventListener(
                    //     Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
                    //     callback,
                    // );

                    this.$router.push({
                        query: {
                            view: instance.viewId,
                        },
                    });
                }
            },
            removeInstance(instance) {
                this.$refs.confirmDeleteDialog.open(() =>{
                    window.NOP_VIEWER.clearSelection();
                    this.deleteInstance(instance)
                } );
            },
            removeMultipleInstance() {
                this.$refs.confirmDeleteDialog.open(() => {
                    window.NOP_VIEWER.clearSelection();
                    this.allInstances
                        .filter((c) => c['checked'])
                        .forEach((elem,index) => {
                            if (index>0) {
                            this.deleteInstance(elem,false);
                            }
                            else{
                            this.deleteInstance(elem,true);
                            }
                        });
                });
            },
            deleteInstance(instance,showPopup=true) {
                if (this.getItemType(instance) == 'Modified Properties') {
                    this.$store.dispatch('RemoveManipulatedElement', instance.dbId).then(() => {
                        this.$refs.confirmDeleteDialog.close();
                    });
                    return;
                }
                if (instance.manipulationType === ManipulationTypes.DELETED) {
                    this.$store.dispatch('RemoveManipulatedElement', instance.dbId).then(() => {
                        this.$refs.confirmDeleteDialog.close();
                    });
                } else if (instance.manipulationType === 'New Family Instance') {
                    this.RemoveFamilyInstance(instance.id).then(() => {
                        this.$refs.confirmDeleteDialog.close();
                        if (showPopup) {
                          toast.success('Family instance deleted successfully');
                        }
                    });
                } else {
                    this.RemoveManipulatedElement(instance.dbId).then(() => {
                        if (window.NOP_VIEWER.model.is2d()) {
                            if (instance.manipulationType === 'Cloned Element') {
                                this.clone2dTool.deleteManipulatedElement(instance.dbId);
                            } else {
                                this.clone2dTool.resetManipulatedElement(instance.dbId);

                                if (instance.rotation){
                                    const center = ViewerToolkit.worldToSheet(
                                        new window.THREE.Vector3().copy(instance.originalPosition),
                                        window.NOP_VIEWER.model,
                                        this.$store.getters['VIEWER/TRANSFORM_MATRIX'],
                                    );
                                    this.rotate2dTool.rotateModelElement(
                                        instance.dbId,
                                        'Z',
                                        -instance.rotation.z,
                                        center,
                                    );
                                }
                            }
                        }

                        if (window.NOP_VIEWER.model.is3d()) {
                            let transformExtension = window.NOP_VIEWER.getExtension(
                                'Viewing.Extension.Transform',
                            );

                            if (transformExtension) {
                                transformExtension.translateTool.resetTranslationAndRotation(
                                    instance.dbId,
                                    instance.position,
                                    instance.originalPosition,
                                );
                            }

                            if (instance.manipulationType === 'Cloned Element') {
                                let manipulationExtension = window.NOP_VIEWER.getExtension(
                                    'Viewing.Extension.Manipulation',
                                );

                                if (manipulationExtension) {
                                    manipulationExtension.deleteManipulatedElement(instance.dbId);
                                }
                            }
                        }

                        this.$refs.confirmDeleteDialog.close();
                  
                          if (showPopup) {
                            toast.success('Block deleted successfully');
                        }
                    });
                }
            },
             hey() {},
            updateViewsInstances: debounce(function () {
                if (!window.NOP_VIEWER?.model) {
                    toast.warn('Model is not loaded yet');
                    return;
                }

                let views = [];
                let elements = [...this.familiesInstances, ...this.manipulatedElements];
                if (this.filterText) {
                    elements = elements.filter((x) =>
                        this.getItemType(x).includes(this.filterText),
                    );
                }
                elements.forEach((e) => {
                    if (this.search && !e.name.toLowerCase().includes(this.search)) return;

                    let view = views.find((v) => v.id === e.viewId);

                    if (!view) {
                        let node = window.NOP_VIEWER.model
                            .getDocumentNode()
                            .getRootNode()
                            .findByGuid(e.viewId);

                        if (!node) return;

                        view = {
                            id: e.viewId,
                            name: node.data.name,
                            instances: [e],
                        };

                        views.push(view);
                        return;
                    }

                    view.instances.push(e);
                });

                this.viewsInstances = views;
                if (this.search) {
                    this.panel = Array.from(Array(this.viewsInstances.length).keys());
                }
            }, 500),
        },
        created() {
            this.updateViewsInstances();
        },
        watch: {
            familiesInstances: {
                handler: function () {
                    this.updateViewsInstances();
                },
                immediate: true,
                deep: true,
            },
            manipulatedElements: {
                handler: function () {
                    this.updateViewsInstances();
                },
                immediate: true,
                deep: true,
            },
            modelId: {
                handler: function () {
                    this.updateViewsInstances();
                },
                immediate: true,
                deep: true,
            },
            search: {
                handler: function () {
                    this.updateViewsInstances();
                },
                immediate: true,
                deep: true,
            },
            filterText: {
                handler: function () {
                    this.updateViewsInstances();
                },
                immediate: true,
                deep: true,
            },
            selected(value) {
                this.allInstances.forEach((elem) => {
                    elem['checked'] = value;
                });
            },
        },
        computed: {
            modelId: {
                get() {
                    return this.$store.getters.MODEL_ID;
                },
            },
            families() {
                return this.$store.getters.FAMILIES;
            },
            familiesInstances() {
                return this.$store.getters.FAMILIES_INSTANCES;
            },
            manipulatedElements() {
                return this.$store.getters.MANIPULATED_ELEMENTS;
            },
            clone2dTool() {
                return window.NOP_VIEWER.toolController.getTool('Viewing.Clone2D.Tool');
            }, 
            rotate2dTool() {
                return window.NOP_VIEWER.toolController.getTool('Viewing.Rotate2D.Tool');
            },
            allInstances() {
                let instances = [];
                instances = this.viewsInstances.map((x) => x?.instances).flat(1);
                return instances;
            },
            allInstancesChecked() {
                return this.allInstances.filter((c) => c['checked']).length;
            },
        },
    };
</script>
<style lang="css">
    .instances-list-drawer {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .instances-list-drawer .card {
        display: flex;
        overflow: hidden;
        max-width: 600px;
        border: 1px solid #69696973;
    }

    .instances-list-drawer .card-content {
        display: flex;
        flex-direction: column;
        padding: 8px 4px 0 4px;
        width: 100%;
    }

    .instances-list-drawer .card-content .buttons button {
        width: 25px;
        height: 25px;
    }

    .card-content .title {
        margin: 0;
        font-size: 0.9rem;
        font-weight: 600;
        color: #333;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }

    .card-content .subtitle {
        margin: 0;
        font-size: 0.7rem;
        color: #8f8f8f;
        line-height: 1rem;
    }

    .instances-list-drawer .buttons {
        margin-top: 2px;
        display: flex;
        justify-content: flex-end;
        min-height: auto;
        padding: 2px 0;
    }

    .instances-list-drawer .buttons button {
        padding: 2px;
        width: 25px;
        border: none;
        background-color: #e2e2e25e;
        border-radius: 5px;
        cursor: pointer;
    }

    .instances-list-drawer .buttons button:hover {
        background-color: #e2e2e2;
    }

    .instances-list-drawer .buttons button:disabled {
        background-color: #e2e2e25e;
        cursor: not-allowed;
    }

    .instances-list {
        position: relative;
        height: -webkit-fill-available;
        overflow: auto;
    }

    .actions {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 15px;
        height: 80px;
    }

    .warning-card {
        border-color: #ffbd5a;
        background-color: #fffdfb;
    }
    img.placeholder-image {
        padding: 15px;
    }
    .warning-card img.placeholder-image {
        border-right: 1px solid #ffedd2;
        filter: grayscale(100%);
    }

    /* ****************************** */

    .instances-list-drawer .v-expansion-panel--active:not(:first-child),
    .instances-list-drawer .v-expansion-panel--active + .v-expansion-panel {
        margin-top: 0px;
        border-top: 1px solid #3c3c3c;
    }

    .instances-list-drawer
        .v-expansion-panel--active
        > .v-expansion-panel-title:not(.v-expansion-panel-title--static) {
        min-height: 48px;
        border-bottom: 1px solid #2a2a2a;
    }

    .instances-list-drawer .v-expansion-panel-text__wrapper {
        padding: 0px !important;
    }

    .instances-list-drawer .v-card .v-icon {
        font-size: 1.2rem;
    }
    .instances-checkbox {
        position: absolute;
        top: -21%;
        left: -3%;
    }
    .e-checkbox-wrapper .e-frame.e-check {
        /* csslint allow: adjoining-classes */
        background-color: #e03872;
    }
</style>
