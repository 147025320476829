<template>
    <div class="image-viewer-container">
        <message-box
            ref="messageBox"
            :on-confirm="this.onConfirmMessage"
            title="Confirmation"
            message="Are you sure you want to delete?" />

        <div class="container-background" @mouseup="closeCameraAttachementl"></div>
        <div class="image-viewer" style="background-color: black; color: white">
            <div>
                <div class="switch-button">
                    <v-switch
                        :disabled="switchDisabled"
                        v-model="model"
                        :label="`${model}`"
                        false-value="Original"
                        true-value="Markup"
                        color="primary"
                        hide-details></v-switch>
                </div>
                <div class="close-button">
                    <span
                        class="d-flex align-center justify-center"
                        style="width: -webkit-fill-available">
                        {{ this.SELECTED_CAMERA_ELEMENT.cameraName }}</span
                    >
                    <v-icon class="vicon" @click="closeCameraAttachementl">mdi-close</v-icon>
                </div>
                <div class="close-button">
                    <span
                        class="d-flex align-center justify-center"
                        style="width: -webkit-fill-available">
                        {{ currentMediaName }}</span
                    >

                    <v-icon
                        class="vicon"
                        v-if="model == 'Original'"
                        @click="download(currentMedia.originalPath)"
                        style="font-size: x-large; margin-right: 2px"
                        >mdi-download-box</v-icon
                    >

                    <v-icon
                        class="vicon"
                        v-if="model == 'Markup'"
                        @click="download(currentMedia.path)"
                        style="font-size: x-large; margin-right: 2px"
                        >mdi-download-box</v-icon
                    >

                    <v-icon
                        class="vicon"
                        @click="confirmDeleteCameraAttachement"
                        style="font-size: x-large"
                        >mdi-trash-can</v-icon
                    >
                </div>
                <!-- <div  class="close-button">
                    <i  @click="openMarkup" class="fa-solid fa-md fa-pen"></i>
                </div> -->
            </div>

            <div v-if="currentMedia" class="media-container">
                <v-progress-linear
                    v-if="isUploading"
                    id="main-progress-bar"
                    :model-value="loadingPercentage"
                    absolute
                    striped
                    rounded
                    color="light-green-darken-2"
                    height="20"
                    style="width: 50%; left: 25%"></v-progress-linear>
                <img
                    v-if="isImage"
                    :src="model == 'Original' ? currentMedia.originalPath : currentMedia.path"
                    class="media-item" />
                <video
                    v-else
                    :src="model == 'Original' ? currentMedia.originalPath : currentMedia.path"
                    class="media-item"
                    controls></video>
            </div>
            <div class="media-container" v-else>
                No Data Avaialable
                <v-progress-linear
                    v-if="isUploading"
                    id="main-progress-bar"
                    :model-value="loadingPercentage"
                    absolute
                    striped
                    rounded
                    color="light-green-darken-2"
                    height="20"
                    style="width: 50%; left: 25%"></v-progress-linear>
            </div>
            <div class="carousel-controls">
                <div class="arrow left" @click="previous">
                    <i class="fas fa-arrow-left"></i>
                </div>
                <div class="info">{{ currentIndex + 1 }} / {{ totalFiles }}</div>
                <div class="arrow right" @click="next">
                    <i class="fas fa-arrow-right"></i>
                </div>
            </div>

            <div class="btn">
                <v-btn :disabled="checkIfMarkupDisabled" color="warning" @click="openMarkup"
                    >Markup</v-btn
                >

                <v-btn color="primary" style="margin-left: 10px" @click="openFilePicker"
                    >Upload</v-btn
                >
                <v-btn color="error" style="margin-left: 10px" @click="this.confirmDeleteCamera"
                    >Delete Camera</v-btn
                >
                <input
                    type="file"
                    ref="fileInput"
                    style="display: none"
                    @change="handleFileUpload"
                    multiple
                    accept="image/*, video/*" />
            </div>
        </div>
    </div>
</template>

<script>
    import { toast } from 'vue3-toastify';
    import { mapActions, mapGetters } from 'vuex';
    import MessageBox from '@/components/MessageBox.vue';
    import '@mdi/font/css/materialdesignicons.css';
    import { $download } from '@Utils';

    export default {
        components: {
            MessageBox,
        },
        data() {
            return {
                file: null,
                currentIndex: 0,
                onConfirmMessage: null,
                isUploading: false,
                loadingPercentage: 0,
                model: 'Original',
            };
        },
        methods: {
            ...mapActions([
                'uploadFile',
                'GetCameraElementById',
                'DeleteCameraElementById',
                'DeleteCameraAttachementById',
                'ChangeCameraName',
            ]),
            ...mapActions('MARKUPDATA', ['setOpenMarkupImg', 'setMarkupImg', 'SetOvelayZIndex']),
            openFilePicker() {
                this.$refs.fileInput.click();
            },
            handleFileUpload(event) {
                this.isUploading = true;
                const files = event.target.files;
                const _onUploadProgress = this.onUploadProgress;
                this.uploadFile({ files, _onUploadProgress })
                    .then(() => {
                        toast.success('file Uploaded successfully');
                        this.isUploading = false;
                        this.loadingPercentage = 0;
                    })
                    .then(() => {
                        this.GetCameraElementById();
                    })
                    .catch((error) => {
                        console.error(error);
                        this.isUploading = false;
                        this.loadingPercentage = 0;
                        toast.error('Failed to Upload file');
                    });
            },

            onUploadProgress(progressEvent) {
                this.loadingPercentage = progressEvent.progress * 100;
            },

            previous() {
                if (this.currentIndex > 0) {
                    this.currentIndex--;
                }
            },
            next() {
                if (this.currentIndex < this.totalFiles - 1) {
                    this.currentIndex++;
                }
            },
            closeCameraAttachementl() {
                this.$store.commit('SET_Show_Camera_Attachement', false);
                this.SetOvelayZIndex(1004);
            },
            confirmDeleteCamera() {
                this.onConfirmMessage = this.deleteCamera;
                this.$refs.messageBox.show();
            },
            deleteCamera() {
                this.$store.commit('SET_Show_Camera_Attachement', false);

                this.DeleteCameraElementById(this.SELECTED_CAMERA_ELEMENT.id).then(() => {
                    const extension = window.NOP_VIEWER.getExtension('ShowCamerasExtension');
                    let elements = this.ALL_Camera_Elements_IN_Model.filter(
                        (x) => x.viewId == this.SELECTED_CAMERA_ELEMENT.viewId,
                    );

                    extension._removeAllSprites();
                    extension.addCameraTool._addAllSpriteToView(elements);
                });
            },
            confirmDeleteCameraAttachement() {
                if (this.SELECTED_CAMERA_ELEMENT.cameraAttachements.length == 0) {
                    return;
                }
                this.onConfirmMessage = this.deleteCameraAttachement;
                this.$refs.messageBox.show();
            },
            deleteCameraAttachement() {
                const id = this.currentMedia?.id;
                if (id) {
                    this.DeleteCameraAttachementById(id).then(() => {
                        this.previous();
                    });
                }
            },
            openMarkup() {
                this.$store.dispatch('MARKUPEDITOR/setAttachData', {
                    attachId: this.SELECTED_CAMERA_ELEMENT.cameraAttachements[this.currentIndex].id,
                    name: this.currentMediaName,
                });
                this.setOpenMarkupImg(true);
            },
            async download(path) {
                await $download(path);
            },
        },
        mounted() {
            this.currentIndex = this.CAMERA_INDEX ?? 0;
            this.SetOvelayZIndex(500);
          this.$store.dispatch('MARKUPDATA/GetMarkupAttchmentImageOptions')
            this.model = this.MarkupAttch;
        },
        unmounted(){
                this.SetOvelayZIndex(1004);
        },

        computed: {
            ...mapGetters([
                'SELECTED_CAMERA_ELEMENT',
                'ALL_Camera_Elements_IN_Model',
                'CAMERA_INDEX',
            ]),
            ...mapGetters([
                'SELECTED_CAMERA_ELEMENT',
                'ALL_Camera_Elements_IN_Model',
                'CAMERA_INDEX',
            ]),
            ...mapGetters('MARKUPDATA', ['MARKUP_ATTACH_SETTING']),

            totalFiles() {
                return this.SELECTED_CAMERA_ELEMENT.cameraAttachements.length;
            },

            currentMedia() {
                if (
                    this.SELECTED_CAMERA_ELEMENT.cameraAttachements.length > 0 &&
                    this.SELECTED_CAMERA_ELEMENT.cameraAttachements[this.currentIndex]
                ) {
                    this.setMarkupImg(
                        this.SELECTED_CAMERA_ELEMENT.cameraAttachements[this.currentIndex]
                            ?.originalPath,
                    );
                }
                return this.SELECTED_CAMERA_ELEMENT.cameraAttachements[this.currentIndex];
            },
            isImage() {
                return (
                    this.currentMedia?.attachementType &&
                    this.currentMedia.attachementType?.startsWith('image')
                );
            },
            currentMediaName() {
                return this.currentMedia?.name ?? '';
            },
            currentIndexx() {
                return this.CAMERA_INDEX;
            },
            checkIfMarkupDisabled() {
                return (
                    !this.SELECTED_CAMERA_ELEMENT ||
                    !this.SELECTED_CAMERA_ELEMENT.cameraAttachements.length > 0
                );
            },
            switchDisabled() {
                return (
                    !this.currentMedia ||
                    !this.currentMedia.originalPath ||
                    this.currentMedia.originalPath == this.currentMedia.path
                );
            },
            MarkupAttch() {
                return this.MARKUP_ATTACH_SETTING;
            },
        },
        watch: {
            currentIndexx(value) {
                this.currentIndex = value;
            },
            MarkupAttch(value) {
                this.model = value;
            },
        },
    };
</script>

<style lang="css" scoped>
    .image-viewer-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -30vh;
        margin-left: -20vw;
        width: 40vw;
        height: 60vh;
        z-index: 6;
    }

    .image-viewer {
        border-radius: 2%;
        padding: 10px;
        z-index: 6;
    }

    .media-container {
        margin-bottom: 10px;
        width: auto;
        min-width: 20vw;
        height: 60vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container-background {
        width: 100vw;
        height: 100vh;
        background: #161616ad;
        position: fixed;
        left: 0;
        top: 0%;
        z-index: 5;
    }

    .media-item {
        width: auto;
        height: 60vh;
        max-height: 60vh;
        max-width: 60vw;

        object-fit: contain;
        background-position: center;
    }

    .carousel-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .arrow {
        width: 50px;
        height: 50px;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .arrow i {
        color: white;
    }

    .info {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;

        margin-bottom: 10px;
        margin-top: 10px;
    }

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        padding: 10px;
        z-index: 6;
    }

    .close-button {
        display: flex;
        justify-content: right;
        align-items: center;
        cursor: pointer;
        min-height: 30px;
    }
    .switch-button {
        display: flex;
        justify-content: left;
        align-items: center;
        cursor: pointer;
        min-height: 30px;
        position: absolute;
    }
    .vicon:hover {
        opacity: 0.4;
    }
</style>
