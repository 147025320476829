<template>
    <div>
        <v-dialog v-model="showLoader" width="auto" persistent>
            <v-progress-circular indeterminate :size="128" :width="12" color="success"></v-progress-circular>
        </v-dialog>
    </div>
</template>



<script>
export default {
    data() {
        return {
            // dialog: true,
        };
    },
    methods: {
        openReport() {
        },
    },
    created() {
    },

    computed: {
        showLoader() {
            
            return this.$store.getters.SHOW_LOADER;
        },
    },
    watch: {
    }
};
</script>
