import LoginView from "@Account/views/LoginView.vue";
import ForgeAuthCallback from "@Account/views/ForgeAuthCallback.vue";

export default [
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      layout: "auth-layout",
      requiresAuth: false,
      title: "Login",
    },
  },
  {
    path: '/api/auth/callback',
    name: 'forgeAuthCallback',
    component: ForgeAuthCallback,
    meta: {
      layout: "auth-layout",
      requiresAuth: false,
      title: "Redirecting..."
    }
  },
  {
    path: '/callback/oauth',
    name: 'forgeOAuth',
    component: ForgeAuthCallback,
    meta: {
      layout: "auth-layout",
      requiresAuth: false,
      title: "Redirecting..."
    }
  },
];
