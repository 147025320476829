/* eslint-disable no-unused-vars */
/* eslint-disable */
import HttpService from '@Utils/httpService';
import ViewerToolkit from './../../extensions/Viewer.Toolkit/Viewer.Toolkit';
const THREE = window.THREE;
import { toast } from 'vue3-toastify';
import store from '..';
import { getModelElementBoundingBox2D } from '@/store/modules/ForgeService';
import { ManipulatedElement, ManipulationTypes } from '@/models/ForgeModels';
import CustomPropertiesService from '@/features/KTSProperties/services/CustomProperties.service';

export const InstanceProperties = {
    namespaced: true,
    state() {
        return {
            id: null,
            dbId: '',
            originalDbId: '',
            externalId: '',
            instanceName: '',
            properties: [],
            isPropertyPanelOpened: false,
            multipleProperties: [],
        };
    },
    getters: {
        INSTANCE_ID(state) {
            return state.id;
        },
        INSTANCE_DB_ID(state) {
            return state.dbId;
        },
        INSTANCE_ORIGINAL_DB_ID(state) {
            return state.originalDbId;
        },
        INSTANCE_EXTERNAL_ID(state) {
            return state.externalId;
        },
        INSTANCE_NAME(state) {
            return state.instanceName;
        },

        INSTANCE_PROPERTIES(state) {
            return state.properties;
        },
        MULTIPLE_PROPERTIES(state) {
            return state.multipleProperties;
        },
        IS_PROPERTY_PANEL_OPENED(state) {
            return state.isPropertyPanelOpened;
        },
        INSTANCE(state) {
            return {
                id: state.id,
                dbId: state.dbId,
                originalDbId: state.originalDbId,
                externalId: state.externalId,
                instanceName: state.instanceName,
                properties: state.properties,
            };
        },
        MODEL_ID(state, getters, rootState, rootGetters) {
            return rootGetters['MODEL_ID'];
        },
        VIEW_ID(state, getters, rootState, rootGetters) {
            return rootGetters['View_Id'];
        },
    },
    mutations: {
        SET_INSTANCE_PROPERTIES(state, properties) {
            state.properties = properties;
        },
        SET_MULTIPLE_PROPERTIES(state, properties) {
            state.multipleProperties = properties;
        },
        SET_INSTANCE(state, instance) {
            state.id = instance.id;
            state.dbId = instance.dbId;
            state.originalDbId = instance.originalDbId;
            state.externalId = instance.externalId;
            state.instanceName = instance.instanceName;
            state.properties = instance.properties;
        },
        SET_IS_PROPERTY_PANEL_OPENED(state, isOpened) {
            state.isPropertyPanelOpened = isOpened;
        },
    },
    actions: {
        async SaveProperties({ commit, dispatch, getters }) {
            const _inst = getters.INSTANCE;
            if (!_inst) return;

            if (_inst.id) {
                return new Promise((resolve, reject) => {
                    HttpService.post({
                        url: `api/familyInstance/updatePropertiesById`,
                        data: {
                            id: _inst.id,
                            propertiesAsJson: JSON.stringify(
                                _inst.properties.filter((x) => x.isModified),
                            ),
                        },
                    })
                        .then((response) => {
                            resolve(response);
                            toast.success('Properties saved successfully');

                             //store.dispatch('COLORINGSYSTEMS/ReApplyTemplateColors');

                        })
                        .catch((error) => {
                            toast.error('Failed to save properties');

                            reject(error);
                        });
                });
            } else {
                const manipulatedElements = store.getters.MANIPULATED_ELEMENTS;
                const existing = manipulatedElements.find(
                    (x) =>
                        x.dbId == _inst.dbId &&
                        x.originalDbId == _inst.originalDbId &&
                        x.originalExternalId == _inst.externalId,
                );

                if (!existing) {
                    const _viewGuids = await ViewerToolkit.getViewabelIdsOfElement(
                        window.NOP_VIEWER.model,
                        _inst.dbId,
                        store,
                    );
                    window.NOP_VIEWER.getProperties(_inst.dbId, (props) => {
                        const manipulatedElement = new ManipulatedElement();
                        manipulatedElement.viewType = window.NOP_VIEWER.model.is2d() ? '2d' : '3d';
                        manipulatedElement.viewId =
                            window.NOP_VIEWER.model.getDocumentNode()?.data?.guid;
                        manipulatedElement.manipulationType = ManipulationTypes.MANIPULATE_EXISTING;
                        manipulatedElement.name = props.name;
                        manipulatedElement.modelId = window.NOP_VIEWER.model.getData().urn;
                        manipulatedElement.dbId = _inst.dbId;
                        manipulatedElement.originalDbId = _inst.dbId;
                        manipulatedElement.originalExternalId = _inst.externalId;
                        manipulatedElement.position = null;
                        manipulatedElement.originalPosition = null;
                        manipulatedElement.rotation = null;
                        manipulatedElement.viewGuids = _viewGuids;
                        manipulatedElement.properties = JSON.stringify(
                            _inst.properties.filter((x) => x.isModified),
                        );

                        store.commit('ADD_MANIPULATED_ELEMENT', manipulatedElement);

                        store.dispatch('UpdateModelManipulatedElements');
                    });
                } else {
                    existing.properties = JSON.stringify(
                        _inst.properties.filter((x) => x.isModified),
                    );
                    store.dispatch('UpdateModelManipulatedElements');
                }
                // var toBeSaved = {
                //     dbId: _inst.dbId,
                //     originalDbId: _inst.originalDbId,
                //     externalId: _inst.externalId,
                //     modelId: getters.MODEL_ID,
                //     viewId: getters.VIEW_ID,
                //     viewType: window.NOP_VIEWER.model.is2d() ? '2d' : '3d',
                //     position: null,
                //     propertiesAsJson: JSON.stringify(_inst.properties.filter((x) => x.isModified)),
                // };

                // return new Promise((resolve, reject) => {
                //     HttpService.post({
                //         url: `api/familyInstance/updateFamilyInstanceProperties`,
                //         data: toBeSaved,
                //     })
                //         .then((response) => {
                //             resolve(response);
                //         })
                //         .catch((error) => {
                //             reject(error);
                //         });
                // });
            }
        },

        GetInstancePropertiesById({ commit, dispatch, getters }, id) {
            return new Promise((resolve, reject) => {
                HttpService.get({
                    url: `api/familyInstance/${getters.MODEL_ID}/getPropertiesById/${id}`,
                })
                    .then((response) => {
                        let properties = [];

                        if (response.propertiesAsJson) {
                            properties = JSON.parse(response.propertiesAsJson);
                        }

                        resolve(properties);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        GetModelElementProperties({ commit, dispatch, getters }, { fakeDbId, originalDbId }) {
            return new Promise((resolve, reject) => {
                HttpService.get({
                    url: `api/familyInstance/${getters.MODEL_ID}/getFamilyInstanceProperties/${originalDbId}/${fakeDbId}`,
                })
                    .then((response) => {
                        let properties = [];

                        if (response.propertiesAsJson) {
                            properties = JSON.parse(response.propertiesAsJson);
                        }

                        resolve(properties);
                    })
                    .catch((error) => {
                        //console.log(error)
                        reject(error);
                    });
            });
        },
        SetSelection({ commit, dispatch, getters }, { instance, fakeDbId, selectedModelId }) {
            return new Promise((resolve, reject) => {
                commit('SET_IS_PROPERTY_PANEL_OPENED', true);

                let familyInstance = store.getters.FAMILIES_INSTANCES.find(
                    (x) => x.loadedModelId == selectedModelId,
                );

                if (familyInstance) {
                    dispatch('GetInstancePropertiesById', familyInstance.id).then((properties) => {
                        var _props = instance.properties.filter((x) => x.hidden == false);

                        for (const element of properties) {
                            var needToChange = _props.filter(
                                (x) =>
                                    x.displayCategory == element.displayCategory &&
                                    x.displayName == element.displayName,
                            );

                            if (needToChange && needToChange.length == 1) {
                                needToChange[0].isModified = true;

                                needToChange[0].displayValue = element.displayValue;
                            }
                        }

                        let obj = {
                            id: familyInstance.id,
                            instanceName: instance.name,
                            properties: _props,
                        };

                        commit('SET_INSTANCE', obj);
                    });
                } else {
                    dispatch('GetModelElementProperties', {
                        originalDbId: instance.dbId,
                        fakeDbId: fakeDbId,
                    }).then((properties) => {
                        var _props = instance.properties.filter((x) => x.hidden == false);

                        for (const element of properties) {
                            var needToChange = _props.filter(
                                (x) =>
                                    x.displayCategory == element.displayCategory &&
                                    x.displayName == element.displayName,
                            );

                            if (needToChange && needToChange.length == 1) {
                                needToChange[0].isModified = true;

                                needToChange[0].displayValue = element.displayValue;
                            }
                        }

                        let obj = {
                            dbId: fakeDbId,
                            originalDbId: instance.dbId,
                            instanceName: instance.name,
                            externalId: instance.externalId,
                            modelId: getters.MODEL_ID,
                            properties: _props,
                        };
                        let splitArr = instance.externalId.split('-');
                        let revitId = parseInt(splitArr[splitArr.length - 1], 16);
                        if (revitId && getters.MODEL_ID) {
                            CustomPropertiesService.Get().then((AllProps) => {
                                CustomPropertiesService.GetElementProps(
                                    getters.MODEL_ID,
                                    revitId,
                                ).then((res) => {
                                    console.log('AllProps', res);

                                    if (res['items']) {
                                        res = res['items'];
                                    }
                                    res.reverse().forEach((element) => {
                                        
                                
                                        let found =
                                            AllProps.filter((c) => c.name == element.Name).length >
                                            0;
                                        if (found) {
                                            let type=20;
                                            if (element.Type&&(element.Type=="Number" || element.Type==3)) {
                                                type=3;
                                            }
                                            else if(element.Type&&(element.Type=="Yes or No" || element.Type==10)){
                                                type=10;
                                            }
                                            obj.properties.unshift({
                                                displayName: element.Name,
                                                displayValue: element.Value ?? '',
                                                type: type,
                                                displayCategory: 'KTS Properties',
                                                custom: true,
                                                modelId: getters.MODEL_ID,
                                                revitId: revitId,
                                            });
                                        }
                                    });
                        
                                    AllProps.forEach((element) => {
                                 

                                        let found =
                                        res.filter((c) => c.Name == element.name).length >
                                            0;
                                        if (!found) {
                                            let type=20;
                                            if (element.type&&element.type=="Number") {
                                                type=3;
                                            }
                                            else if(element.type&&element.type=="Yes or No"){
                                                type=10;
                                            }
                                            else if(element.type&&element.type=="ComputedFomrula"){
                                                type=23;
                                            }
                                            obj.properties.push({
                                                displayName: element.name,
                                                displayValue: '',
                                                isComputed:element.isComputed,
                                                properties:element.properties.split(','),
                                                type:  type,
                                                displayCategory: 'KTS Properties',
                                                custom: true,
                                                modelId: getters.MODEL_ID,
                                                revitId: revitId,
                                            });
                                        }
                                    });
                                    commit('SET_INSTANCE', obj);
                                });
                            });
                        }
                    });
                }
            });
        },
    },
};
