import { createApp } from 'vue'
import App from './App.vue'
import { loadFonts } from './plugins/webfontloader'
import '@/styles/main.css'
import 'vue3-toastify/dist/index.css';
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store';
import hubService from './plugins/hubService'
import toastify from './plugins/toastify'
import slideout from './plugins/slideout';

import "@/extensions/Viewing.Extension.Transform/Viewing.Extension.Transform"
import './registerServiceWorker'

loadFonts()

createApp(App)
    .use(vuetify)
    .use(hubService)
    .use(toastify)
    .use(slideout)
    .use(store)
    .use(router)
    .mount('#app')
