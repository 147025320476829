import store from '../../store';
const Autodesk = window.Autodesk;
import editImg from '@/assets/images/edit.png';

class ModifyPropertiesExtension extends Autodesk.Viewing.Extension {
    constructor(viewer, options) {
        super(viewer, options);

        this.viewer = viewer;

        this._onSelectionChanged = (ev) => this.onSelectionChanged(ev.model, ev.dbIdArray);

        this.showPropertiesPanel = this.showPropertiesPanel.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);

        this.onBuildingContextMenuItem = this.onBuildingContextMenuItem.bind(this);

        this.modifyPropertiesButton = null;
    }

    get menuId() {
        return 'Modify Properties';
    }

    onBuildingContextMenuItem(menu, status) {

        if (!status.hasSelected) return;

        menu.push({
            title: 'Modify Properties',
            target: () => this.showPropertiesPanel()
        });
    }

    showPropertiesPanel() {
        
        const selSet = this.viewer.getAggregateSelection();

        if (!selSet || selSet.length === 0) return;
        

        if(selSet[0].selection.length > 1){
            store.commit('InstanceProperties/SET_IS_PROPERTY_PANEL_OPENED', true);
            return
        }
        const selection = selSet[0];

        const dbId = selection.selection[0];

        let model = selection.model;

        let originalDbId = dbId;

        const isSceneBuilder = model.myData.isSceneBuilder;

        if (isSceneBuilder) {
                
            const instance = store.getters.MANIPULATED_ELEMENTS.find(e => e.dbId == dbId);

            if (instance) {
                originalDbId = instance.originalDbId;
                model = this.viewer.model;
            }

        }

        console.log(model.id)
                                
        model.getProperties(originalDbId, (result) => {

            if (!result.properties) return;

            store.dispatch('InstanceProperties/SetSelection', {
                instance: result,
                fakeDbId: dbId,
                selectedModelId: selection.model.id
            });

        }, console.log);
    }

    onSelectionChanged(ev) {
        if(ev.selections.length == 1){
            if(!this.modifyPropertiesButton){
                this.showModifyPropertiesButton()
            }
        }else{
            let group = this.viewer.toolbar.getControl('modify-properties-group');
            if(group){
                group.removeControl(this.modifyPropertiesButton);
                this.modifyPropertiesButton = null;
            }
            store.commit('InstanceProperties/SET_INSTANCE', {});
        }
       
        let isPropertyPanelOpened = store.getters['InstanceProperties/IS_PROPERTY_PANEL_OPENED']

        if (isPropertyPanelOpened) this.showPropertiesPanel();



    }



    load() {
        this.viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);

        // Add my owned menu items
        this.viewer.registerContextMenuCallback(
            this.menuId,
            this.onBuildingContextMenuItem
        );

        return true;
    }

    unload() {
        if (this.modifyPropertiesButton) {
            this.removeToolbarButton(this.modifyPropertiesButton);
            this.modifyPropertiesButton = null;
        }

        this.viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged);

        this.viewer.unregisterContextMenuCallback(this.menuId);

        return true;
    }



    showModifyPropertiesButton() {
 
        this.modifyPropertiesButton = this.createToolbarButton('modify-properties-button', editImg, 'modify Properties');
        this.modifyPropertiesButton.onClick = () => {
            this.showPropertiesPanel()
            if(this.modifyPropertiesButton._state == 0){
                this.modifyPropertiesButton.setState(1)
            }else{
                this.modifyPropertiesButton.setState(0)
            }
            //console.log(this.modifyPropertiesButton._state)
            // store.commit('TOGGLE_Add_Camera_Mode_On');
            // const number = store.getters.Add_Camera_Mode_On == true ? 0 : 1
            // this.addCameraButton.setState(number)
        };
    }

    getToolbarGroup(){
        let group = this.viewer.toolbar.getControl('modify-properties-group');
        if (!group) {
            group = new Autodesk.Viewing.UI.ControlGroup('modify-properties-group');
            this.viewer.toolbar.addControl(group);
        }
        return group;
    }
    createToolbarButton(buttonId, buttonIconUrl, buttonTooltip) {
       
        let group = this.getToolbarGroup();

        const button = new Autodesk.Viewing.UI.Button(buttonId);
        button.setToolTip(buttonTooltip);

        group.addControl(button);
        const icon = button.container.querySelector('.adsk-button-icon');
        if (icon) {
            icon.style.backgroundImage = `url(${buttonIconUrl})`; 
            icon.style.backgroundSize = `24px`; 
            icon.style.backgroundRepeat = `no-repeat`; 
            icon.style.backgroundPosition = `center`; 
        }
        return button;
    }

    removeToolbarButton(button) {
        const group =  this.getToolbarGroup();
        group.removeControl(button);
    }

}

Autodesk.Viewing.theExtensionManager.registerExtension('ModifyPropertiesExtension', ModifyPropertiesExtension);