import { createRouter, createWebHistory } from 'vue-router'
// import MainView from '../views/MainView.vue'
import store from '@Store'
import accountRoutes from '@Account/routes/account.routes'
import homeRoutes from '@Home/routes/home.routes'
import modelsRoutes from '@Models/routes/models.routes'
import adminRoutes from '@Admin/routes/admin.routes';

const routes = [...accountRoutes, ...homeRoutes, ...modelsRoutes, ...adminRoutes];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach((to, from, next) => {
    const isAuth = store.getters["ACCOUNT/GET_IS_AUTH"];
    if (to.meta.requiresAuth && !isAuth) next("/login");
    else if (!to.meta.requiresAuth && isAuth) {
        next("/");
    }
    next();
});
router.afterEach((to) => {
    // change page title
    if (to.meta && to.meta.title) {
        document.querySelector(
            "title"
        ).innerText = `KTS - ${to.meta.title}`;
    } else {
        document.querySelector("title").innerText = `KTS`;
    }
});

export default router
