<template lang="html">

    <div>
        <message-box ref="messageBox" :on-confirm="this.onConfirmMessage" title="Confirmation"
            message="Are you sure you want to delete?" />

        <input-field2 :title="inputFieldTitle" :label="inputFieldLabel" :value="inputFieldValue"
            :onConfirm="inputFieldHandleConfirm" :onDiscard="onCancelRenameClicked" ref="textInputDialog" />

        <div style="display: flex; justify-content: center; align-items: center;gap: 5px; padding: 10px;">
            <v-text-field dense placeholder="Search" hide-details density="compact" solo
                v-model="search"></v-text-field>
            <v-btn size="30" @click="isList = false">
                <i class="fa-solid fa-folder"></i>
            </v-btn>
            <v-btn size="30" @click="isList = true">
                <i class="fa-solid fa-list"></i>
            </v-btn>
        </div>
        <!-- <v-container fluid> -->


        <v-skeleton-loader type="list-item, image" v-if="isLoading" class=" px-10 py-1">
        </v-skeleton-loader>

        <v-expansion-panels v-if="!isLoading && groupedCameras.length > 0 && !isList" v-model="panel" multiple>
            <v-expansion-panel v-for="(group, i) in groupedCameras" :key="i" :title="group.view" :id="i">
                <v-expansion-panel-text class="py-2">
                    <v-list-item v-for="item in group.data" :key="item.id">
                        <v-card class="mx-auto" max-width="300" outlined>
                            <div v-if="item.cameraAttachements[0]" class="media-container">
                                <div class="camera-name">{{ item?.cameraName }}</div>
                                <v-img v-if="isImage(item)" class="align-end text-white" height="100"
                                    :src="item.cameraAttachements[item.currentIndex]?.path">
                                </v-img>
                                <video v-else :src="item.cameraAttachements[item.currentIndex]?.path" class="media-item"
                                    height="100" controls></video>

                                <div class="carousel-controls">
                                    <div class="arrow left" @click="previous(item)">
                                        <i class="fas fa-arrow-left"></i>
                                    </div>
                                    <div class="info">
                                        {{ item.currentIndex + 1 }} / {{ item.cameraAttachements.length }}
                                    </div>
                                    <div class="arrow right" @click="next(item)">
                                        <i class="fas fa-arrow-right"></i>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="camera-name">{{ item?.cameraName }}</div>
                                <v-card-text>
                                    <div>No Available Data</div>
                                </v-card-text>
                            </div>
                            <v-card-subtitle class="pt-4">
                                {{ item.cameraAttachements[item.currentIndex]?.name }}
                            </v-card-subtitle>
                            <v-card-actions>
                                <div class="btn-container">
                                    <div class="btn" @click="this.SetShowRenameField(true, item)"><i
                                            class="fas fa-pen-to-square"></i></div>
                                    <div class="btn" width="30px" @click="OpenCamera(item)"><i
                                            class="fas fa-maximize"></i>
                                    </div>
                                    <div class="btn" @click="confirmDeleteCamera(item)"><i class="fas fa-trash-alt"></i>
                                    </div>
                                </div>
                            </v-card-actions>
                        </v-card>

                        <!-- <v-card v-else class="mx-auto" max-width="300">
                            <div class="camera-name">{{ item?.cameraName }}</div>
                            <v-card-text>
                                <div>No Available Data</div>
                            </v-card-text>

                            <v-card-actions>
                                <div class="btn-container">
                                    <div class="btn" @click="this.SetShowRenameField(true, item)"><i
                                            class="fas fa-pen-to-square"></i></div>
                                    <div class="btn" @click="OpenCamera(item)"><i class="fas fa-maximize"></i></div>
                                    <div class="btn" @click="confirmDeleteCamera(item)"><i class="fas fa-trash-alt"></i>
                                    </div>
                                </div>
                            </v-card-actions>
                        </v-card> -->
                    </v-list-item>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-list-item v-else-if="!isLoading && groupedCameras.length > 0" v-for=" item in allCameras" :key="item.id">
            <v-card class="mx-auto" max-width="300" outlined>
                <div v-if="item.cameraAttachements[0]" class="media-container">
                    <div class="camera-name">{{ item?.cameraName }}</div>
                    <v-img v-if="isImage(item)" class="align-end text-white" height="100"
                        :src="item.cameraAttachements[item.currentIndex]?.path">
                    </v-img>
                    <video v-else :src="item.cameraAttachements[item.currentIndex]?.path" class="media-item"
                        height="100" controls></video>

                    <div class="carousel-controls">
                        <div class="arrow left" @click="previous(item)">
                            <i class="fas fa-arrow-left"></i>
                        </div>
                        <div class="info">
                            {{ item.currentIndex + 1 }} / {{ item.cameraAttachements.length }}
                        </div>
                        <div class="arrow right" @click="next(item)">
                            <i class="fas fa-arrow-right"></i>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="camera-name">{{ item?.cameraName }}</div>
                    <v-card-text>
                        <div>No Available Data</div>
                    </v-card-text>
                </div>
                <v-card-subtitle class="pt-4">
                    {{ item.cameraAttachements[item.currentIndex]?.name }}
                </v-card-subtitle>
                <v-card-actions>
                    <div class="btn-container">
                        <div class="btn" @click="this.SetShowRenameField(true, item)"><i
                                class="fas fa-pen-to-square"></i>
                        </div>
                        <div class="btn" width="30px" @click="OpenCamera(item)"><i class="fas fa-maximize"></i>
                        </div>
                        <div class="btn" @click="confirmDeleteCamera(item)"><i class="fas fa-trash-alt"></i>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>

            <!-- <v-card v-else class="mx-auto" max-width="300">
                <div class="camera-name">{{ item?.cameraName }}</div>
                <v-card-text>
                    <div>No Available Data</div>
                </v-card-text>

                <v-card-actions>
                    <div class="btn-container">
                        <div class="btn" @click="this.SetShowRenameField(true, item)"><i
                                class="fas fa-pen-to-square"></i></div>
                        <div class="btn" @click="OpenCamera(item)"><i class="fas fa-maximize"></i></div>
                        <div class="btn" @click="confirmDeleteCamera(item)"><i class="fas fa-trash-alt"></i>
                        </div>
                    </div>
                </v-card-actions>
            </v-card> -->
        </v-list-item>

        <div v-else-if="!isLoading" class=" py-16 d-flex align-center justify-center flex-column h-99">
            <v-img width="300" max-height="350px" contain src="/img/No Data.svg"></v-img>
            <div class="pt-4 title">No Cameras Found</div>
        </div>
    </div>
    <!-- </v-container> -->
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { toast } from "vue3-toastify";
import InputField2 from '@/components/InputField2.vue';
import MessageBox from '@/components/MessageBox.vue';

export default {
    components: {
        InputField2,
        MessageBox
    },
    data() {
        return {
            file: null,
            currentIndex: 0,
            groupedCameras: [],
            curentCamera: null,

            onConfirmMessage: null,

            inputFieldTitle: '',
            inputFieldValue: '',
            inputFieldLabel: '',
            inputFieldHandleConfirm: null,
            isLoading:false,

            isList: false,
            search: '',
            panel: []
        };
    },
    methods: {
        ...mapActions(['uploadFile', 'GetCameraElementById', 'GetAllCameraElementsInTheModel', 'DeleteCameraElementById', 'ChangeCameraName']),

        previous(cameraItem) {
            if (cameraItem.currentIndex > 0) {
                cameraItem.currentIndex--;
            }
        },
        next(cameraItem) {
            if (cameraItem.currentIndex < cameraItem.cameraAttachements.length - 1) {
                cameraItem.currentIndex++;
            }
        },
        isImage(cameraItem) {
            return cameraItem.cameraAttachements[cameraItem.currentIndex]?.attachementType &&
                cameraItem.cameraAttachements[cameraItem.currentIndex]?.attachementType?.startsWith('image');
        },
        OpenCamera(cameraElement) {

            if (window.NOP_VIEWER.model.getDocumentNode().data.guid != cameraElement.viewId) {
                const doc = window.NOP_VIEWER.model.getDocumentNode().getRootNode().lmvDocument;
                window.NOP_VIEWER.loadDocumentNode(doc, doc.getRoot().findByGuid(cameraElement.viewId));
            }

            this.$store.commit('SET_SELECTED_CAMERA_ELEMENT', cameraElement);
            this.$store.commit('SET_Show_Camera_Attachement', true);
        },
        confirmDeleteCamera(cameraElement) {
            this.curentCamera = cameraElement;
            this.onConfirmMessage = this.removeCamera;
            this.$refs.messageBox.show();
        },
        removeCamera() {
            const cameraElement = this.curentCamera

            this.DeleteCameraElementById(cameraElement.id).then(() => {

                if (window.NOP_VIEWER.model.getDocumentNode().data.guid != cameraElement.viewId) {
                    const doc = window.NOP_VIEWER.model.getDocumentNode().getRootNode().lmvDocument;
                    window.NOP_VIEWER.loadDocumentNode(doc, doc.getRoot().findByGuid(cameraElement.viewId));
                }
                else {
                    const extension = window.NOP_VIEWER.getExtension('ShowCamerasExtension')
                    let elements = this.ALL_Camera_Elements_IN_Model.filter(x => x.viewId == cameraElement.viewId)
                    extension._removeAllSprites()
                    extension.addCameraTool._addAllSpriteToView(elements)
                }
            })

        },
        GROUPED_CAMERAS(){
            if (!this.ALL_Camera_Elements_IN_Model?.length) { this.groupedCameras = []; return;}

            if (!window.NOP_VIEWER || !window.NOP_VIEWER.model) { this.groupedCameras = []; return; }


            const doc = window.NOP_VIEWER.model.getDocumentNode().getRootNode().lmvDocument;

            var grouped = Object.groupBy(this.ALL_Camera_Elements_IN_Model, ({ viewId }) => viewId);
            let _groupedCameras = []

            Object.entries(grouped).forEach(([key, values]) => {

                const v = doc.getRoot().findByGuid(key).data.name

                for (let i = 0; i < values.length; i++) {
                    if (!values[i].currentIndex)
                        values[i].currentIndex = 0;
                }
                if (this.search) {
                    values = values.filter(x => x.cameraName.toLowerCase().includes(this.search))
                    if(values.length < 1) 
                        return
                }
                _groupedCameras.push({
                    view: v,
                    data: values
                })
            });

            if (this.search) {
                this.panel = Array.from(Array(_groupedCameras.length).keys());
            } 
            this.groupedCameras = _groupedCameras;
        },

        SetShowRenameField(val, cameraElement) {
            if (window.NOP_VIEWER.model.getDocumentNode().data.guid != cameraElement.viewId) {
                const doc = window.NOP_VIEWER.model.getDocumentNode().getRootNode().lmvDocument;
                window.NOP_VIEWER.loadDocumentNode(doc, doc.getRoot().findByGuid(cameraElement.viewId));
            }

            this.$store.commit('SET_SELECTED_CAMERA_ELEMENT', cameraElement);



            this.curentCamera = cameraElement;

            if (val == true) {
                this.inputFieldTitle = "Please enter camera name"
                this.inputFieldValue = this.curentCamera.cameraName


                this.inputFieldLabel = "Name"
                this.inputFieldHandleConfirm = this.renameShowRenameField
                this.$refs.textInputDialog.show();
            } else {
                this.$refs.textInputDialog.hide();
            }
        },
        renameShowRenameField(name) {

            if (!name) {
                toast.error('please enter a valid name')
                return
            }


            if (name == this.curentCamera.cameraName) {
                this.$refs.textInputDialog.hide();
                return
            }

            const sameName = this.$store.getters['ALL_Camera_Elements'].find(x => x.cameraName == name)
            if (sameName) {
                toast.error('camera name already exist')
                return
            }

            this.$refs.textInputDialog.hide();

            const Id = this.curentCamera.id

            this.ChangeCameraName({ Id, name }).then(() => {

            })

        },
        onCancelRenameClicked() {
            this.$refs.textInputDialog.hide();
        }
    },
    mounted() {  
        this.isLoading = true;
        this.$store.dispatch('GetAllCameraElementsInTheModel').then(()=>{
            this.isLoading = false;
        })   
        //this.GROUPED_CAMERAS();
    },

    computed: {
        ...mapGetters(['ALL_Camera_Elements_IN_Model']),

        allCameras() {
            let instances = []
            instances = this.groupedCameras.map(x => x?.data).flat(1)
            return instances
        },
       
    },
    watch: {
        ALL_Camera_Elements_IN_Model: {
            handler() {
                this.GROUPED_CAMERAS()
            },
            immediate: true,
            deep: true
        }  ,
        search: {
            handler: function () {
                 this.GROUPED_CAMERAS()
            },
            immediate: true,
            deep: true
        },
    }
};
</script>

<style scoped>
.v-card-actions {
    border-top: 1px solid #ffffff21;
    margin-top: 10px;
}


.btn-container {
    width: -webkit-fill-available;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.camera-name {
    text-align: center;
    border-bottom: 1px solid #ffffff21;
    padding-bottom: 5px;
    display: block;
    flex: none;
    font-size: 0.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
    white-space: nowrap;
}

.carousel-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mx-auto {
    padding-left: 5px;
    padding-Right: 5px;
    padding-top: 10px;
    border: 2px solid rgb(90, 62, 62);
    border-radius: 5px;
}

.arrow {
    width: 25px;
    height: 25px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.arrow i {
    color: white;
}

.info {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    margin-bottom: 10px;
    margin-top: 10px;
}

.media-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0 10px;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 3px;
}

.btn:hover {
    cursor: pointer;
    background: #4444;
    transition: all 0.3s ease 0s;
    border-color: #7171;
}

.v-card-subtitle {
    text-align: center;
}
</style>