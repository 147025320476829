<template>
    <v-card>
        <v-card title="Custom Properties" class="pa-2" theme="dark">
            <v-table theme="dark">
                <thead>
                    <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Type</th>
                        <th class="text-left">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in properties" :key="item.prop">
                        <td>{{ item.name }}</td>
                        <td>{{ item.type }}</td>
                        <td>
                            <v-btn
                                @click="DeleteProp(item.id)"
                                density="compact"
                                icon="mdi-delete"></v-btn>
                            <v-btn
                                density="compact"
                                @click="update(item)"
                                icon="mdi-pencil"></v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <v-btn class="mt-2" text="Add" color="primary" @click="AddDialog()"></v-btn>
        </v-card>
        <v-dialog v-model="dialog" max-width="500">
            <template v-slot:default="{ isActive }">
                <v-card title="Add Custom Property" class="pa-5" theme="dark">
                    <v-text-field
                        dense
                        v-model="Name"
                        class="mb-2"
                        placeholder="Property Name (*required)"
                        variant="outlined"
                        hide-details
                        solo></v-text-field>

                    <v-select
                        v-model="ChooseType"
                        placeholder="Template Name"
                        variant="outlined"
                        :items="Type"></v-select>
                    <v-select
                        v-if="ChooseType == 'Computed'"
                        v-model="ChooseComputedType"
                        placeholder="Type"
                        variant="outlined"
                        :items="ComputedType"></v-select>
                    <v-combobox
                        v-if="ChooseType == 'Computed' && ChooseComputedType == 'Text'"
                        label="Properties"
                        v-model="selected"
                        variant="outlined"
                        clearable
                        ref="prop"
                        :items="props">
                        <template v-slot:selection="data">
                            <v-chip v-if="data.item" v-bind="data.attrs" small>
                                {{ data.item.title }}
                            </v-chip>
                        </template>
                    </v-combobox>
                    <v-autocomplete
                        v-if="ChooseType == 'Computed' && ChooseComputedType == 'Formula'"
                        label="Properties"
                        v-model="formulaselected"
                        variant="outlined"
                        clearable
                        ref="prop"
                        :items="props">
                        <template v-slot:selection="data">
                            <v-chip  v-if="data.item" v-bind="data.attrs" small>
                                {{ data.item.title }}
                            </v-chip>
                        </template>
                    </v-autocomplete>
                    <v-chip-group
                        v-if="ChooseType == 'Computed' && ChooseComputedType == 'Formula'">
                        <v-chip
                            style="background-color: #3ca3f4; font-weight: 500"
                            @click="addSign(sign)"
                            v-for="sign in signs"
                            :key="sign"
                            :text="sign"></v-chip>
                    </v-chip-group>
                    <v-chip-group v-if="ChooseType == 'Computed'">
                        <v-chip
                            v-for="(tag,index) in tags"
                            :closable="index==tags.length-1 || ChooseComputedType=='Text' ? true:false"
                            @click:close="removeFomrula(tag)"
                            :key="tag.id"
                            :text="tag.value"></v-chip>
                    </v-chip-group>
                    <!-- <span style="color: red">Not Accepted Formula</span> -->
                    <!-- <v-alert text="Not Accepted Formula"  v-if="ChooseType == 'Computed' && ChooseComputedType == 'Formula'
                    &&checkFormula" type="error"></v-alert> -->

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="this.selectedId <= 0"
                            text="Save"
                            :disabled="
                                Name == '' ||
                                (tags.length == 0 && ChooseType == 'Computed') ||
                                checkFormula
                            "
                            @click="AddProp()"></v-btn>
                        <v-btn
                            v-else
                            text="Save"
                            :disabled="
                                Name == '' ||
                                (tags.length == 0 && ChooseType == 'Computed') ||
                                checkFormula
                            "
                            @click="UpdateProp()"></v-btn>

                        <v-btn text="Close" @click="isActive.value = false"></v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
        <confirm-delete ref="confirmDeleteDialog"></confirm-delete>
    </v-card>
</template>
<script>
    import ConfirmDelete from '@Share/components/ConfirmDelete.vue';

    export default {
        data: function () {
            return {
                ChooseType: 'Text',
                Name: '',
                Type: ['Text', 'Number', 'Yes or No', 'Computed'],
                signs: ['+', '-', '*', '/'],
                ComputedType: ['Text', 'Formula'],
                ChoosePropType: 'Ordinary',
                dialog: false,
                computedProp: '',
                selected: [],
                formulaselected: [],
                selectedId: -1,
                ChooseComputedType: 'Text',
                tags: [],
            };
        },
        methods: {
            GetAlllProp() {
                this.$store.dispatch('CUSTOMPROPERTIES/GetCustomProps');
            },
            AddProp() {
                if (this.ChooseComputedType == 'Formula') {
                    this.AddFormulaProp();
                } else {
                    this.AddOrigianlProp();
                }
            },
            AddOrigianlProp() {
                let isComputed = false;
                if (this.tags.length > 0) {
                    isComputed = true;
                }
                this.$store.dispatch('CUSTOMPROPERTIES/AddCustomProp', {
                    Name: this.Name,
                    Type: this.ChooseType,
                    IsComputed: isComputed,
                    Properties: this.tags.map((a) => a.value).toString(),
                });
                this.tags = [];
                this.dialog = false;
            },
            AddFormulaProp() {
                let isComputed = false;
                if (this.tags.length > 0) {
                    isComputed = true;
                }
                this.$store.dispatch('CUSTOMPROPERTIES/AddCustomProp', {
                    Name: this.Name,
                    Type: this.ChooseType + 'Fomrula',
                    IsComputed: isComputed,
                    Properties: this.tags.map((a) => a.value).toString(),
                });
                this.tags = [];
                this.dialog = false;
            },
            UpdateProp() {
                let isComputed = false;
                if (this.tags.length > 0) {
                    isComputed = true;
                }
                if (this.ChooseComputedType == 'Formula') {
                    this.ChooseType = this.ChooseType + 'Fomrula';
                }
                this.$store.dispatch('CUSTOMPROPERTIES/UpdateCustomProp', {
                    Name: this.Name,
                    Type: this.ChooseType,
                    IsComputed: isComputed,
                    Properties: this.tags.map((a) => a.value).toString(),
                    Id: this.selectedId,
                });
                this.dialog = false;
            },
            DeleteProp(id) {
                this.$refs.confirmDeleteDialog.open(() => {
                    this.$store.dispatch('CUSTOMPROPERTIES/DeleteTemplate', id);
                    this.$refs.confirmDeleteDialog.close();
                });
            },
            update(item) {
                this.dialog = true;

                this.ChooseType = item.type;
                if (item.type == 'ComputedFomrula') {
                    this.ChooseType = 'Computed';
                    this.ChooseComputedType = 'Formula';
                }
                else{
                    this.ChooseComputedType = 'Text';

                }
                this.Name = item.name;
                let PropTags = [];
                item.properties.map((a, index) => PropTags.push({ value: a, id: index }));
                this.tags = PropTags;
                this.selectedId = item.id;
                console.log(this.selectedId);
            },
            AddDialog() {
                this.dialog = true;

                this.ChooseType = 'Text';
                this.Name = '';
                this.selected = null;
                this.formulaselected = null;
                this.tags = [];
                this.selectedId = -1;
            },
            removeFomrula(tag) {
                const index = this.tags.indexOf(tag);
                if (index > -1) {
                    this.tags.splice(index, 1);
                }
            },
            addSign(sign) {
                if (this.tags.length > 0) {
                    let lastSign = this.tags[this.tags.length - 1].value;
                    if (
                        lastSign &&
                        (lastSign == '+' || lastSign == '-' || lastSign == '*' || lastSign == '/')
                    ) {
                        this.tags[this.tags.length - 1].value = sign;
                    } else {
                        this.tags.push({ value: sign, id: this.tags.length + 1 });
                    }
                }
            },
        },
        mounted() {
            this.GetAlllProp();
        },

        computed: {
            properties() {
                return this.$store.getters['CUSTOMPROPERTIES/CUSTOM_PROP'];
            },
            props() {
                let ndata = this.$store.getters['COLORINGSYSTEMS/MODEL_PROPS'];
                return ndata;
            },
            FormulaProps() {
                let data = this.$store.getters['COLORINGSYSTEMS/FORMULA_MODEL_PROPS'];
                return data;
            },
            checkFormula() {
                let lastElement = this.tags[this.tags.length - 1];

                if (this.tags.length > 0 && this.signs.includes(lastElement.value)) {
                    return true;
                } else {
                    return false;
                }
            },
        },
        watch: {
            selected(value) {
                if (this.selected != null) {
                    this.tags.push({ value: value, id: this.tags.length + 1 });
                }
                this.selected = null;
            },
            formulaselected(value) {
                let lastElement = this.tags[this.tags.length - 1];

                if (this.formulaselected != null) {
                    if (lastElement && !this.signs.includes(lastElement.value)) {
                        this.tags[this.tags.length - 1].value = value;
                    } else {
                        this.tags.push({ value: value, id: this.tags.length + 1 });
                    }
                }
                this.formulaselected = null;
            },
        },
        components: { ConfirmDelete },
    };
</script>
