/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import store from '../../store';

const Autodesk = window.Autodesk;
import { toast } from 'vue3-toastify';

class NestedFamilySelectionExtension extends Autodesk.Viewing.Extension {
    constructor(viewer, options) {
        super(viewer, options);

        this.processStart = false;
        this.resetPreventSelectionButton = null;
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.viewer = viewer;
        this.newEvent = null;
    }

    get menuId() {
        return 'Prevent Selection';
    }

    load() {
        this.viewer.addEventListener(
            Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
            this.onSelectionChanged,
        );
        return true;
    }

    unload() {
        this.viewer.addEventListener(
            Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
            this.onSelectionChanged,
        );
        return true;
    }
    onSelectionChanged(ev) {
        if (!store.getters['SETTINGS/MODEL_SETTINGS'].nestedFamiliesSelectionMode) return;
        
        if (ev.selections.length == 1 && !this.processStart) {
            this.processStart = true;
            const model = ev.selections[0].model;
            let dbIds = ev.selections[0].dbIdArray;

            let dbIdsToSelect = []
            for(let i = 0; i< dbIds.length; i++ ){
                const dbId = dbIds[i];
         
                if (!store.getters.NESTED_FAMILY_TREE[dbId]) {
                    let tree = model.getData().instanceTree;
                     dbIdsToSelect.push(...this.onSuccess(tree, model, dbId, ev));
                } else {
                    const _dbIds = store.getters.NESTED_FAMILY_TREE[dbId];
                    dbIdsToSelect.push(..._dbIds);
                }
            }
            if (dbIdsToSelect.length) {
                this.viewer.select(dbIdsToSelect, model);

            ev.selections[0] = this.newEvent.selections[0];
            this.newEvent = null;
        }
            this.processStart = false;
        }else{
            this.newEvent = ev
        }
    }
    onSuccess(objectTree, model, dbId, ev) {
        while (dbId != model.getRootId()) {

            dbId = objectTree.getNodeParentId(dbId);    

            if (store.getters.NESTED_FAMILY_TREE[dbId]) {
                break;
            }
        }
        if(dbId == model.getRootId()) 
        {
            return []
        }
        if (store.getters.NESTED_FAMILY_TREE[dbId]) {
            const dbIds = store.getters.NESTED_FAMILY_TREE[dbId];
            return dbIds

        }
    }
}

Autodesk.Viewing.theExtensionManager.registerExtension(
    'NestedFamilySelectionExtension',
    NestedFamilySelectionExtension,
);
