<template lang="html">
  <v-card class="mx-auto" max-width="500">
    <v-sheet class="px-4 py-3 bg-primary-lighten-2">
      <v-text-field
        class="custom-input"
        v-model="searchText"
        label="Search Type"
        variant="outlined"
        density="compact"
        clearable
        prepend-inner-icon="fa:fas fa-search fa-md"
        dark
        hide-details></v-text-field>
    </v-sheet>
    <v-card-text class="pt-0">
      <static-tree-view :nodes="filteredItems"></static-tree-view>
    </v-card-text>
  </v-card>
</template>
<script>
  import StaticTreeView from '../StaticTreeView/StaticTreeView.vue';

  export default {
    name: 'BlocksTreeList',
    components: {
      StaticTreeView,
    },
    data: () => ({
      search: null,
      loading: false,
    }),
    methods: {
      
    },
    computed: {
      filter() {
        return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined;
      },
      searchText: {
        get() {
          return this.$store.getters['BLOCKS_TREE_SEARCH'];
        },
        set(value) {
          this.$store.commit('SET_BLOCKS_TREE_SEARCH', value);
        },
      },
      items() {
        return this.$store.getters.BLOCKS_TREE;
      },
      filteredItems() {
        if (!this.searchText) return this.items;

        const searchRegex = new RegExp(this.searchText, 'i');

        const markChildrenAsMatched = (children) => {
          children.forEach((c) => {
            c.matched = true;
            if (c.children) markChildrenAsMatched(c.children);
          });
        };

        const checkMatching = (children) => {
          if (!children) return false;

          let result = false;

          children.forEach((c) => {
            c.matched = searchRegex.test(c.name);

            if (c.matched) markChildrenAsMatched(c.children ?? []);
            else c.matched = checkMatching(c.children ?? []);

            result = result || c.matched;
          });

          return result;
        };

        checkMatching(this.items);

        return this.items.filter((item) => item.matched);
      }
    },
  };
</script>
<style lang="css" scoped>
  .v-card-text {
    height: calc(100vh - 190px);
    max-height: calc(100vh - 190px);
    overflow: auto;
  }
</style>
