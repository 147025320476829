<template lang="html">
  <v-app-bar :style="`z-index:${ZIndex}`" class="tool-bar" height="50" theme="dark">
    <img class="logo-icon" :src="logo" max-height="40" alt="logo" />

    <div class="middle-container">
      <div>
        <p class="text-uppercase gray2--text" @click="backToProjects">
          <v-icon class="mr-2" size="18">fa:fa-solid fa-arrow-left-long</v-icon>
          <span>Projects</span>
        </p>

        <span class="gray2--text mx-2"> / </span>

        <div class="project-title opacity-54 d-flex flex-column justify-start align-center" v-if="currentProject">
          <span class="v-breadcrumbs-item" :title="currentProject">{{ currentProject }}

          </span>
        </div>

        <span class="ml-2" v-if="currentVersionName && AllVersions">
          <v-select color="success" v-model="selectedVersion" style="color: #fb8c00;" :items="AllVersions"
            :item-props="itemProps" hide-details></v-select>
          <!-- <v-chip label color='success'>
              <div class="d-flex flex-column" style="font-size: 0.8rem;">
                <div class="success--text" v-if="currentVersionName">
                  Model Version: {{ currentVersionName }}
                </div>
                <div class="success--text" v-if="SyncVersion">
                  Sync Version: v{{ SyncVersion }}
                </div>
              </div>
            </v-chip> -->

        </span>

        <span class="gray2--text mx-2" v-if="currentViewName"> / </span>

        <div v-if="currentViewName">
          <span class="view-title v-breadcrumbs-item" :title="currentViewName">{{
            currentViewName
            }}</span>
        </div>
        <span class="gray2--text ml-2">
          <v-btn v-if="!isLatestVersion && !LatestVersionLoading" color='warning'
            @click="openLatestVersion(this.LatestVersion)">
            Last Updated {{ LatestVersionName }}
          </v-btn>
          <v-progress-circular v-if="LatestVersionLoading" class="mx-2" width="2" color="warning" size="20"
            indeterminate></v-progress-circular>
        </span>

        <template v-if="currentProject">
          <v-progress-circular v-if="isSaving" class="mx-2" width="2" color="warning" size="20"
            indeterminate></v-progress-circular>
          <v-icon v-else size="20" class="mx-2" :color="savingError ? 'error' : 'success'">
            {{ savingError ? 'fa-solid fa-exclamation-circle' : 'fa-solid fa-check-circle'}}
          </v-icon>
        </template>
      </div>
    </div>

    <template v-slot:append>
      <div class="d-flex ga-3 align-center">
        <v-btn height="30" variant="tonal" color="green-lighten-1" class="d-flex v-btn-icon-hover"
          :disabled="!isNewVersionAvailable" @click="saveModel(false)">
          <v-icon>mdi-sync</v-icon>
          <span class="button-text">Sync</span>
        </v-btn>
        <v-btn height="30" variant="tonal" color="green-lighten-1" class="d-flex v-btn-icon-hover"
          :disabled="!isNewVersionAvailable" @click="saveModel(true)">
          <v-icon>mdi-source-branch-sync</v-icon>
          <span class="button-text">Save a New Version</span>
        </v-btn>
        <!-- <v-btn
          height="30"
          variant="tonal"
          color="green-lighten-1"
          class="d-flex v-btn-icon-hover"
          @click="saveLocal">
          <i class="fa-solid fa-floppy-disk"></i>
          <span class="button-text">Save</span>
        </v-btn> -->

        <v-btn icon @click="logout" class="ml-5">
          <i class="fa-solid fa-arrow-right-from-bracket"></i>
        </v-btn>
      </div>
    </template>


    <div>
      <!-- <v-btn @click="openReport()" size="small" color="success">
        <v-icon class="mr-1">mdi-file-chart</v-icon>
        <span>Report</span>
      </v-btn> -->
      <v-dialog v-model="dialog" width="auto" persistent>
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            <div class="d-flex flex-column">
              Save/Sync is applied only on latest version of the project
              <v-divider></v-divider>
            </div>
          </v-card-title>
          <v-card-text>
            Do you want to open latest version?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#D50000" text @click="dialog = false">Cancel</v-btn>
            <v-btn color="success" text @click="openLatestVersion(this.LatestVersion)">OK</v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </div>
  </v-app-bar>
</template>
<script>
import logo from '@/assets/images/logo.png';
import { mapActions } from 'vuex';
import { toast } from 'vue3-toastify';
import modelService from '@Models/service/model.service';
export default {
  name: 'TopBar',
  data() {
    return {
      logo,
      newVersionRequestLoading: false,
      dialog: false,
      selectedVersion: ''
    };
  },
  methods: {
    ...mapActions(['SaveNewModelVersion', 'SaveLocalModelChanges']),
    itemProps(item) {
      return {
        title: item.name?.split(':')[0],
      }
    },
    openLatestVersion(version){
      let currentModelData = this.$store.getters['MODELS/CURRENT_MODEL_DATA'];
      let user = this.$store.getters['ACCOUNT/USER'];

      let model = {
        hubName: currentModelData.hubName,
        projectId: version.projectId,
        projectName: currentModelData.projectName,
        modelName: currentModelData.modelName,
        version: version.name,
        urn: version.urn,
        storageUrl: version.ref,
        userId : user.userId,
        userName : user.userName,
        itemId: version.itemId
      };

      modelService.updateModel(model)
      .then((data) => {
        console.log(data);
        this.dialog = false;
        this.$router.push({ name: 'ModelViewer', params: { id: data.id }, query: { ...this.$route.query } }).then(() => {
          window.location.reload();
        });
        })
        .catch((error) => {
          console.error(error);
          this.dialog = false;
          toast.error('Failed to save local changes to the model');
        });
    },
    saveLocal() {
      this.SaveLocalModelChanges()
        .then(() => {
          toast.success('Local changes saved to the model');
          console.log('Local changes saved to the model');
        })
        .catch((error) => {
          console.error(error);
          toast.error('Failed to save local changes to the model');
        });
    },
    saveModel(saveNewVersion) {
      if(!this.isLatestVersion){
        this.dialog = true;
        toast.warn('Should work on latest version');
        return
      }
      if (this.newVersionRequestLoading) return;

      this.newVersionRequestLoading = true;

      this.SaveNewModelVersion(saveNewVersion)
        .then(() => {
          toast.success(
            'New version is under processing.\nYou will be notified when it is ready.',
          );
        })
        .catch((e) => {
          console.log(e);
          toast.error('Failed to save new version');
        })
        .finally(() => {
          this.newVersionRequestLoading = false;
        });
    },
    backToProjects() {
      this.$store.dispatch('InvalidateViewers');
      this.$router.push({ name: 'Projects' });
    },
    logout() {
      this.$store.dispatch('ACCOUNT/Logout');
    },
  },
  computed: {
    instances: {
      get() {
        return this.$store.getters.FAMILIES_INSTANCES;
      },
    },
    manipulatedELements: {
      get() {
        return this.$store.getters.MANIPULATED_ELEMENTS;
      },
    },
    isNewVersionAvailable() {
      return (
        (!!this.instances.length || !!this.manipulatedELements.length) &&
        !this.newVersionRequestLoading
      );
    },
    currentNodePath() {
      let path = this.$store.getters.VIEWER_NODE_PATH;

      return path?.split('/')?.filter(p => p) ?? "";
    },
    currentProject() {
      let currentModel = this.$store.getters['MODELS/CURRENT_MODEL_DATA'];
      let modelName = this.$store.getters['MARKUPDATA/LOADED_MODEL_NAME'];
      return modelName ?? currentModel?.modelName ?? "";
    },
    currentVersionName() {
      let _currentversion = this.$store.getters['MODELS/CURRENT_MODEL_DATA']?.version.split(':')[0];
      return _currentversion;
    },
    currentViewName() {
      return this.$store.getters['CURRENT_VIEW_NAME'];
    },
    isSaving() {
      return this.$store.getters['IS_SAVING'];
    },
    savingError() {
      return this.$store.getters['SAVING_ERROR'];
    },
    ZIndex(){
        return this.$store.getters['MARKUPDATA/OVERLYA_ZINDEX'];

      },
    SyncVersion() {
      return this.$store.getters['SYNC_VERSION'];
    },
    LatestVersion: {
      get() {
        return this.$store.getters['LATEST_VERSION'];
      },
      set(value) {
        this.$store.commit('SET_LATEST_VERSION', value);
      }
    },
    LatestVersionLoading() {
      return this.$store.getters['LATEST_VERSION_LOADING'];
    },
    isLatestVersion(){
      if (!this.LatestVersionName) return true
        if (this.LatestVersionName == this.currentVersionName) {
          return true
        } else {
          return false
        }
    },
    LatestVersionName(){
      return this.LatestVersion?.name?.split(':')[0]
    },
    AllVersions(){
      return this.$store.getters['ALL_VERSIONS'];
    }
  },
  watch:{
    AllVersions: {
      handler(val) {
        if (!val) return
        let currentVersionNode = val.find(x => x.name?.split(':')[0] ==  this.currentVersionName)
        if (currentVersionNode) this.selectedVersion = currentVersionNode
      },
      immediate: true,
    },
    selectedVersion: {
      handler(val) {
        if(!val) return
        if (val.name?.split(':')[0] == this.currentVersionName) return
        if(!val.ref || !val.urn){
          toast.error("Can't open this version")
          return
        }
        this.openLatestVersion(val)
      },
      immediate: true,
    },
  }
}
</script>
<style lang="css">
img.logo-icon {
  object-fit: contain;
  max-height: 40px;
  width: auto;
  padding: 5px 30px;
  filter: brightness(2.5);
}

.middle-container {
  display: grid;
}

.middle-container div {
  display: flex;
  align-items: center;
}

.middle-container .tabs-container {
  display: flex;
  gap: 15px;
}

.middle-container .tabs-container .v-btn {
  width: 150px;
}

.v-breadcrumbs-item {
  /* width: 100px; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.v-breadcrumbs-item:last-child {
  /* width: 350px; */
  /* color: #a3ffc1; */
}

.v-btn-icon-hover {
  transition: all 0.3s ease;
  overflow: hidden;
  width: auto;
}

.v-btn-icon-hover {
  width: auto;
}

.v-btn-icon-hover .button-text {
  display: inline-block;
  margin-left: 8px;
}

.tool-bar i.fa,
.tool-bar i.fa-solid {
  color: inherit;
  font-size: 16px;
}

.gray2--text {
  color: #8b8b8b;
  font-weight: 600;
  cursor: pointer;
}

.gray2--text:hover {
  color: #a3ffc1;
}

.project-title {
  justify-content: flex-start;
}

.project-title span {
  font-size: 1.1rem;
  font-weight: 500;
  text-align: start;
  width: 100%;
  color: #a3ffc1;
}

.project-title .view-title {
  font-size: 1rem;
  font-weight: 500;
  color: #8b8b8b;
}
</style>
