import { set } from "vue";
import { toast } from "vue3-toastify";

export function $getFromLocal(name, parse = false, defaultValue) {
  let item = localStorage.getItem("KTS-" + name);
  if (item) {
    item = parse ? JSON.parse(item) : item;
    return item;
  }
  return defaultValue;
}

export function $setToLocal(name, value, stringify = false) {
  localStorage.setItem("KTS-" + name, stringify ? JSON.stringify(value) : value);
}

export function $removeFromLocal(name) {
  if (Array.isArray(name)) {
    name.forEach((item) => {
      localStorage.removeItem("KTS-" + item);
    });
  } else {
    localStorage.removeItem("KTS-" + name);
  }
}

export function $handleError(error, validationErrorHandler) {
  if (error == "Error: wait for token.") {
    return;
  }
  
  if (error.response && error.response.status === 401) {
    // var data = error.response.data;
    // if (data.ErrorType == 'Relogin') return;
    return;
  }
  let finalErrorText = "";
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    let errObj = error.response.data;
    switch (errObj.errorType) {
      case "Validation":
        if (validationErrorHandler) {
          //  validationErrorHandler(errObj, error);
          return;
        }
        toast.error(parseErrorsList(errObj.errors) || "Unknown error occured!", {
          position: "top-right",
          timeout: 6 * 1000,
        });
        finalErrorText =
          parseErrorsList(errObj.errors) || "Unknown error occured!";
        break;
      case "BadRequest":
      default:
        toast.error(
          errObj.errors ||
          errObj.message ||
          error.response.data ||
          "Unknown error occured!", {
          position: "top-right",
          timeout: 6 * 1000,
        })
        finalErrorText =
          errObj.errors ||
          errObj.message ||
          error.response.data ||
          "Unknown error occured!";
        break;
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    toast.error("Error receiving a response!", {
      position: "top-right",
      timeout: 10 * 1000,
    });
    finalErrorText = "Error receiving a response!";
  } else {
    // Something happened in setting up the request that triggered an Error
    toast.error(error.message, {
      position: "top-right",
      timeout: 10 * 1000,
    });
    finalErrorText = error.message;
  }

  return finalErrorText;
}

function parseErrorsList(errors) {
  var list = [];
  for (const err in errors) {
    list.push(errors[err]);
  }
  return list.join(",");
}

export function $getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function $isEqual(x, y) {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  return x && y && tx === "object" && tx === ty
    ? ok(x).length === ok(y).length &&
    ok(x).every((key) => $isEqual(x[key], y[key]))
    : x === y;
}

export function $addToArr(arr, element, onTop = false) {
  if (onTop) arr.unshift(element);
  else set(arr, arr.length, element);
}

export function $updateArr(arr, element, key = "id") {
  const index = arr.findIndex((elm) => elm[key] == element[key]);
  if (index > -1) {
    set(arr, index, element);
  }
}

export function $updateOrAdd(arr, element, key = "id", onTop = false) {
  const index = arr.findIndex((elm) => elm[key] == element[key]);
  if (index > -1) {
    set(arr, index, element);
  } else {
    $addToArr(arr, element, onTop);
  }
}

export function $deleteFromArray(arr, id, key = "id") {
  const index = arr.findIndex((elm) => elm[key] == id);
  if (index !== -1) arr.splice(index, 1);
}

export function $base64ToUTF8(base64String) {
  // Replace _ with /
  base64String = base64String.replace(/_/g, '/');

  // Decode the Base64 string
  let decodedString = atob(base64String);

  // Convert to UTF-8
  let utf8String = decodeURIComponent(escape(decodedString));

  return utf8String;
}
export async function  $download(path){
  const image = await fetch(path)
  const imageBlog = await image.blob()
  const imageURL = URL.createObjectURL(imageBlog)

  const link = document.createElement('a')
  link.href = imageURL
  link.download = 'markup.png'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}