/* eslint-disable */
import store from '../store';
import { ManipulationTypes } from '@/models/ForgeModels';
const THREE = window.THREE;

const green = new THREE.Color(0x008000);
const NewFamilyColor = new THREE.Vector4(green.r, green.g, green.b, 1);

const orange = new THREE.Color(0xffa500);
const ManipulatedColor = new THREE.Vector4(orange.r, orange.g, orange.b, 1);

// const yellow = new THREE.Color(0xffd700);
const ClonedColor = new THREE.Vector4(green.r, green.g, green.b, 1);

const red = new THREE.Color(0xff0000);
const DeletedColor = new THREE.Vector4(red.r, red.g, red.b, 1);

let material = new THREE.MeshPhongMaterial({
    color: green,
    opacity: 0.8,
    transparent: true,
});
//   window.NOP_VIEWER?.impl.matman().addMaterial('CustomMaterialRed', material, true);

const UpdateForgeElementsColors = () => {
    if (window.NOP_VIEWER?.model?.is2d()) {
        UpdateForgeElementsColors2D();
    } else if (window.NOP_VIEWER?.model?.is3d()) {
        UpdateForgeElementsColors3D();
    }
};
const UpdateForgeElementsColors3D = () => {
    const isDefault = store.getters.SHOW_DEFAULT_COLORS;
    window.NOP_VIEWER?.clearThemingColors();
    if (isDefault) {
        const deleted = store.getters.MANIPULATED_ELEMENTS.filter(
            (x) => x.manipulationType == ManipulationTypes.DELETED,
        );
        for (let i = 0; i < deleted.length; i++) {
            const element = deleted[i];
            const dbId = element.dbId;
            window.NOP_VIEWER?.setThemingColor(dbId, DeletedColor);
        }

        const cloned = store.getters.MANIPULATED_ELEMENTS.filter(
            (x) => x.manipulationType == ManipulationTypes.CLONED_ELEMENT,
        );
        for (let i = 0; i < cloned.length; i++) {
            store.getters.MODEL_BUILDER.removeMaterial([material]);
        }
        material = new THREE.MeshPhongMaterial({
            color: green,
            opacity: 0.8,
            transparent: true,
        });
    } else {
        const manipulated = store.getters.MANIPULATED_ELEMENTS;
        for (let i = 0; i < manipulated.length; i++) {
            const element = manipulated[i];
            const dbId = element.dbId;
            if (element.manipulationType == ManipulationTypes.NEW_FAMILY_INSTANCE) {
                window.NOP_VIEWER?.setThemingColor(dbId, NewFamilyColor);
            } else if (element.manipulationType == ManipulationTypes.MANIPULATE_EXISTING) {
                window.NOP_VIEWER?.setThemingColor(dbId, ManipulatedColor);
            } else if (element.manipulationType == ManipulationTypes.CLONED_ELEMENT) {
                const model = store.getters.MODEL_BUILDER.model;
                const instanceTree = model.getData().instanceTree;
                const fragIds = [];

                instanceTree.enumNodeFragments(dbId, function (fragId) {
                    fragIds.push(fragId);
                });
                fragIds.forEach(function (fragId) {
                    store.getters.MODEL_BUILDER.changeFragmentMaterial(fragId, material);
                });
                // window.NOP_VIEWER?.setThemingColor(element.id, ClonedColor, model);
            } else if (element.manipulationType == ManipulationTypes.DELETED) {
                window.NOP_VIEWER?.setThemingColor(dbId, DeletedColor);
            }
        }
    }
    window.NOP_VIEWER?.impl.invalidate(true);
};

const UpdateForgeElementsColors2D = () => {
    const isDefault = store.getters.SHOW_DEFAULT_COLORS;
    window.NOP_VIEWER?.clearThemingColors();
    if (isDefault) {
        const deleted = store.getters.MANIPULATED_ELEMENTS.filter(
            (x) => x.manipulationType == ManipulationTypes.DELETED,
        );
        for (let i = 0; i < deleted.length; i++) {
            const element = deleted[i];
            const dbId = element.dbId;
            window.NOP_VIEWER?.setThemingColor(dbId, DeletedColor);
        }
    } else {
        const manipulated = store.getters.MANIPULATED_ELEMENTS;
        for (let i = 0; i < manipulated.length; i++) {
            const element = manipulated[i];
            const dbId = element.dbId;
            if (element.manipulationType == ManipulationTypes.NEW_FAMILY_INSTANCE) {
                window.NOP_VIEWER?.setThemingColor(dbId, NewFamilyColor);
            } else if (element.manipulationType == ManipulationTypes.MANIPULATE_EXISTING) {
                window.NOP_VIEWER?.setThemingColor(dbId, ManipulatedColor);
            } else if (element.manipulationType == ManipulationTypes.CLONED_ELEMENT) {
            } else if (element.manipulationType == ManipulationTypes.DELETED) {
                window.NOP_VIEWER?.setThemingColor(dbId, DeletedColor);
            }
        }
    }
    window.NOP_VIEWER?.impl.invalidate(true);
};

export { NewFamilyColor, ManipulatedColor, ClonedColor, DeletedColor, UpdateForgeElementsColors };
