import ColoringSystemsService from '../services/ColoringSystems.service';
const THREE = window.THREE;
import { toast } from 'vue3-toastify';
import CustomPropertiesService from '@/features/KTSProperties/services/CustomProperties.service';

export const COLORINGSYSTEMS = {
    namespaced: true,
    state: {
        modelProps: [],
        formulaModelProps: [],
        modelPropsElements: {},
        templates: [],
        addedTemplate: {},
        conditions: [],
        selectedTemplateId: '',
        KtsPropsELements: [],
        computedProp: [],
        computedPropValues: {},
        selectedTemp: -1,
        appliedTemp: -1,
    },
    getters: {
        MODEL_PROPS(state) {
            return state.modelProps;
        },
        FORMULA_MODEL_PROPS(state) {
            let props = [];
            props.push(...state.modelProps)
            props.unshift("+");
            props.unshift("-");
            props.unshift("*");
            props.unshift("/");
            return props;
        },
        TEMPLATES(state) {
            return state.templates;
        },
        ADDED_TEMPLATE(state) {
            return state.addedTemplate;
        },
        CONDITIONS(state) {
            return state.conditions;
        },
        MODEL_ID(state, getters, rootState, rootGetters) {
            return rootGetters['MODEL_ID'];
        },
        SELECTED_TEMP(state) {
            return state.selectedTemp;
        },
    },
    mutations: {
        SET_MODEL_PROPS(state, value) {
            state.modelProps = value;
        },
        SET_FORMULA_MODEL_PROPS(state, value) {
            state.formulaModelProps = value;
        },
        SET_TEMPLATES(state, value) {
            state.templates = value;
        },
        SET_ADDED_TEMPLATE(state, value) {
            state.addedTemplate = value;
        },
        SET_CONDITIONS(state, value) {
            state.conditions = value;
        },
        SET_SELECTED_TEMPLATE_ID(state, value) {
            state.selectedTemplateId = value;
        },
    },
    actions: {
        GetModelProps(store, nodeId) {
            window.NOP_VIEWER.model.getInstanceTree().enumNodeChildren(
                nodeId,
                (childId) => {
                    store.dispatch('GetModelProps', childId);

                    window.NOP_VIEWER.model.getProperties(
                        childId,
                        (res) => {
                            res.properties.forEach((ele) => {
                                if (!store.state.modelPropsElements[ele.displayName]) {
                                    store.state.modelPropsElements[ele.displayName] = [childId];
                                } else {
                                    store.state.modelPropsElements[ele.displayName].push(childId);
                                }
                                if (store.state.modelProps.indexOf(ele.displayName) === -1) {
                                    store.state.modelProps.push(ele.displayName);
                                }
                                //  store.state.modelProps=[...new Set(store.state.modelProps)]
                            });
                        },
                        (res) => {
                            console.log('erorrrrrrrr', res);
                        },
                    );
                },
                false,
            );
        },
        GetModelComputedProps(store, nodeId) {
            window.NOP_VIEWER.model.getInstanceTree().enumNodeChildren(
                nodeId,
                (childId) => {
                    store.dispatch('GetModelComputedProps', childId);

                    window.NOP_VIEWER.model.getProperties(
                        childId,
                        (res) => {
                            store.state.computedProp.forEach((computed) => {

                                let value = '';
                                if (computed.type == "Computed") {
                                    computed.properties.forEach((element) => {
                                        let Prop = res.properties.find((z) => z.displayName == element);
                                        if (Prop) {
                                            value += Prop.displayValue + ' ';
                                        } else {
                                            value += element + ' ';
                                        }
                                    });
                                }
                                else if (computed.type == "ComputedFomrula") {
                                    computed.properties.forEach((element) => {
                                        let Prop = res.properties.find((z) => z.displayName == element);
                                        if (Prop) {
                                            value += Prop.displayValue;
                                        } else {
                                            value += element;
                                        }
                                    });
                                    try {
                                        value = eval(value);

                                    } catch (e) {
                                        value = "";
                                    }
                                }

                                if (!store.state.computedPropValues[computed.name]) {
                                    store.state.computedPropValues[computed.name] = [
                                        { dbid: childId, displayValue: value },
                                    ];
                                } else {
                                    store.state.computedPropValues[computed.name].push({
                                        dbid: childId,
                                        displayValue: value,
                                    });
                                }
                            });
                        },
                        (res) => {
                            console.log('Error', res);
                        },
                    );
                },
                false,
            );
        },
        GetCustomProp(store) {
            CustomPropertiesService.Get().then((response) => {
                response.forEach((ele) => {
                    if (store.state.modelProps.indexOf(ele.name) === -1 && ele.isComputed) {
                        store.state.computedProp.push({
                            name: ele.name,
                            type: ele.type,
                            properties: ele.properties.split(','),
                        });
                    }
                    if (store.state.modelProps.indexOf(ele.name) === -1) {
                        store.state.modelProps.push(ele.name);
                    }
                });
                store.dispatch('GetModelComputedProps', 1);
            });
        },
        SetModelPropColor(store, Props) {
            function trimStr(str) {
                if (!str) return str;
                return str.replace(/^\s+|\s+$/g, '');
            }
            window.NOP_VIEWER.clearThemingColors();
            const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string);
            store.state.KtsPropsELements = [];
            CustomPropertiesService.GetALlElementProps().then((res) => {
                res.forEach((element) => {
                    element.properties = JSON.parse(element.properties);
                    element.properties.forEach((prop) => {
                        if (!store.state.modelPropsElements[prop.Name]) {
                            store.state.modelPropsElements[prop.Name] = [element.dbId];
                        } else {
                            store.state.modelPropsElements[prop.Name].push(element.dbId);
                        }
                        store.state.KtsPropsELements.push({
                            displayValue: prop.Value,
                            dbId: element.dbId,
                            displayName: prop.Name,
                        });
                    });
                });

                Props.forEach((ele) => {
                    let color = new THREE.Color(ele.color);
                    store.state.modelPropsElements[ele.prop]?.forEach((c) => {
                        window.NOP_VIEWER.getProperties(
                            c,
                            (res) => {
                                let found = res.properties.find((z) => z.displayName == ele.prop);
                                if (!found) {
                                    found = store.state.KtsPropsELements.find(
                                        (z) => z.displayName == ele.prop && c == z.dbId,
                                    );
                                }
                                if (!found) {
                                    found = store.state.computedPropValues[ele.prop]?.filter(
                                        (c) => c.dbid == c,
                                    )[0];
                                }
                                if (found) {
                                    let condition = false;


                                    switch (ele.operator) {
                                        case 'Equal':
                                            if (ele.value != "") {
                                                if (isNumeric(ele.value)) {
                                                    condition =
                                                        parseFloat(found.displayValue) ==
                                                        parseFloat(ele.value);
                                                } else {
                                                    condition =
                                                        found.displayValue.toLowerCase() ==
                                                        ele.value.toLowerCase();
                                                }
                                            }
                                            else {
                                                condition =
                                                    found.displayValue.toString().toLowerCase() ==
                                                    ele.value.toString().toLowerCase();
                                            }

                                            break;
                                        case 'Not Equal':
                                            if (ele.value != "") {
                                                if (isNumeric(ele.value)) {
                                                    condition =
                                                        parseFloat(found.displayValue) !=
                                                        parseFloat(ele.value);
                                                } else {
                                                    condition =
                                                        found.displayValue.toLowerCase() !=
                                                        ele.value.toLowerCase();
                                                }
                                            }
                                            else {
                                                condition =
                                                    found.displayValue.toString().toLowerCase() !=
                                                    ele.value.toString().toLowerCase();
                                            }
                                            break;
                                        case 'Greater Than':
                                            if (isNumeric(ele.value)) {
                                                condition =
                                                    parseFloat(found.displayValue) >
                                                    parseFloat(ele.value);
                                            }
                                            break;
                                        case 'Less Than':
                                            if (isNumeric(ele.value)) {
                                                condition =
                                                    parseFloat(found.displayValue) <
                                                    parseFloat(ele.value);
                                            }
                                            break;
                                        case 'Greater Than or Equal':
                                            if (isNumeric(ele.value)) {
                                                condition =
                                                    parseFloat(found.displayValue) >=
                                                    parseFloat(ele.value);
                                            }
                                            break;
                                        case 'Less Than or Equal':
                                            if (isNumeric(ele.value)) {
                                                condition =
                                                    parseFloat(found.displayValue) <=
                                                    parseFloat(ele.value);
                                            }
                                            break;
                                        case 'Contains':
                                            condition = found.displayValue
                                                .toLowerCase()
                                                .includes(ele.value.toLowerCase());
                                            break;
                                        case 'Begins With':
                                            condition = found.displayValue
                                                .toLowerCase()
                                                .startsWith(ele.value.toLowerCase());
                                            break;
                                        case 'Ends With':
                                            condition = found.displayValue
                                                .toLowerCase()
                                                .endsWith(ele.value.toLowerCase());
                                            break;
                                    }
                                    if (condition) {
                                        window.NOP_VIEWER.setThemingColor(
                                            c,
                                            new THREE.Vector4(color.r, color.g, color.b, 1),
                                        );
                                    }
                                }
                            },
                            (err) => {
                                console.log(err);
                            },
                        );
                    });
                    store.state.computedPropValues[ele.prop]?.forEach(
                        (c) => {
                            let found = c;
                            if (!isNumeric(found.displayValue)) {
                                found.displayValue = trimStr(found.displayValue);

                            }
                            let condition = false;
                            switch (ele.operator) {
                                case 'Equal':
                                    if (isNumeric(ele.value)) {
                                        condition =
                                            parseFloat(found.displayValue) == parseFloat(ele.value);
                                    } else {
                                        condition =
                                            found.displayValue.toLowerCase() ==
                                            ele.value.toLowerCase();
                                    }
                                    break;
                                case 'Not Equal':
                                    if (isNumeric(ele.value)) {
                                        condition =
                                            parseFloat(found.displayValue) != parseFloat(ele.value);
                                    } else {
                                        condition =
                                            found.displayValue.toLowerCase() !=
                                            ele.value.toLowerCase();
                                    }
                                    break;
                                case 'Greater Than':
                                    if (isNumeric(ele.value)) {
                                        condition =
                                            parseFloat(found.displayValue) > parseFloat(ele.value);
                                    }
                                    break;
                                case 'Less Than':
                                    if (isNumeric(ele.value)) {
                                        condition =
                                            parseFloat(found.displayValue) < parseFloat(ele.value);
                                    }
                                    break;
                                case 'Greater Than or Equal':
                                    if (isNumeric(ele.value)) {
                                        condition =
                                            parseFloat(found.displayValue) >= parseFloat(ele.value);
                                    }
                                    break;
                                case 'Less Than or Equal':
                                    if (isNumeric(ele.value)) {
                                        condition =
                                            parseFloat(found.displayValue) <= parseFloat(ele.value);
                                    }
                                    break;
                                case 'Contains':
                                    condition = found.displayValue
                                        .toLowerCase()
                                        .includes(ele.value.toLowerCase());
                                    break;
                                case 'Begins With':
                                    condition = found.displayValue
                                        .toLowerCase()
                                        .startsWith(ele.value.toLowerCase());
                                    break;
                                case 'Ends With':
                                    condition = found.displayValue
                                        .toLowerCase()
                                        .endsWith(ele.value.toLowerCase());
                                    break;
                            }
                            if (condition) {
                                window.NOP_VIEWER.setThemingColor(
                                    found.dbid,
                                    new THREE.Vector4(color.r, color.g, color.b, 1),
                                );
                            }
                        },
                        (err) => {
                            console.log(err);
                        },
                    );
                });
            });
        },
        GetTemplates(store, id) {
            ColoringSystemsService.GetTemplates(id).then((res) => {
                store.commit('SET_TEMPLATES', res);
            });
        },
        AddTemplate(store, data) {
            ColoringSystemsService.AddTemplate(data).then((res) => {
                store.commit('SET_ADDED_TEMPLATE', res);
                //store.dispatch('GetTemplates', data.ForgeModelId);

                toast.success('Template added successfully');
            });
        },
        DeleteTemplate(_, id) {
            ColoringSystemsService.DeleteTemplate(id).then(() => {
                toast.success('Template Deleted successfully');
            });
        },
        GetConditions(store, id) {
            store.state.selectedTemp = id;
            ColoringSystemsService.GetConditions(id).then((res) => {
                console.log("conditions", res)
                store.commit('SET_CONDITIONS', res);
            });
        },
        AddCondition(store, data) {
            ColoringSystemsService.AddCondition(data).then(() => {
                toast.success('Condition added successfully');
                store.dispatch('GetConditions', data.TemplateId);
                if (data.TemplateId == store.state.selectedTemplateId) {
                    store.dispatch('ApplyTemplateColors', { id: data.TemplateId });
                }
            }).catch(() => {
                toast.error('Condition not added please check values');

            });
        },
        UpdateCondition(store, data) {
            ColoringSystemsService.UpdateCondition(data).then(() => {
                toast.success('Condition updated successfully');
                store.dispatch('GetConditions', data.templateId);
                if (data.templateId == store.state.selectedTemplateId) {
                    store.dispatch('ApplyTemplateColors', { id: data.templateId });
                }
            });
        },
        DeleteCondition(store, data) {
            ColoringSystemsService.DeleteCondition(data.id).then(() => {
                toast.success('Condition deleted successfully');
                store.dispatch('GetConditions', data.templateId);
                if (data.templateId == store.state.selectedTemplateId) {
                    store.dispatch('ApplyTemplateColors', { id: data.templateId });
                }
            });
        },
        ApplyTemplateColors(store, data) {
            ColoringSystemsService.GetConditions(data.id).then((res) => {
                store.state.appliedTemp = data.id;
                res = res.filter((c) => c.enabled);
                store.dispatch('SetModelPropColor', res);
            });
        },
        ReApplyTemplateColors(store) {
            if (store.state.appliedTemp > 0) {
                ColoringSystemsService.GetConditions(store.state.appliedTemp).then((res) => {
                    res = res.filter((c) => c.enabled);
                    store.dispatch('SetModelPropColor', res);
                });
            }
        },
        ClearTemplateColors(store) {
            store.state.appliedTemp = -1;
        },
        ChangeEnableState(store, data) {
            ColoringSystemsService.ChangeEnableState(data.id).then(() => {
                if (data.templateId == store.state.selectedTemplateId) {
                    store.dispatch('ApplyTemplateColors', { id: data.templateId });
                }
            });
        },
        AddPropToColorProperties(store, name) {
            if (store.state.modelProps.indexOf(name) === -1) {
                store.state.modelProps.push(name);
            }
        },
    },
};
