<template lang="html">
    <v-sheet class="projects-view h-100" theme="dark" style="overflow: auto;">
        <v-container class="pa-8">
            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="12" xl="2" lg="3" md="4" sm="6">
                    <v-text-field
                        class="custom-input"
                        v-model="searchQuery"
                        color="primary"
                        :loading="isLoading"
                        placeholder="Search Projects"
                        hide-details
                        density="compact"
                        clearable
                        prepend-inner-icon="fa:fas fa-search fa-lg">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <template v-if="isLoading">
                    <v-col v-for="i in 12" :key="i" cols="12" xl="2" lg="3" md="4" sm="6">
                        <v-skeleton-loader
                            class="mx-auto border"
                            max-width="300"
                            type="image, article"></v-skeleton-loader>
                    </v-col>
                </template>
                <template
                    v-for="(model, index) in paginatedModels"
                    :key="`model-${model.id}-${index}`"
                    v-else>
                    <v-col cols="12" xl="2" lg="3" md="4" sm="6">
                        <v-card border="primary md" rounded>
                            <v-img
                                class="recent-model-img"
                                :src="model.thumbnailUrl"
                                height="200"></v-img>
                            <v-card-title class="my-1 px-2" :title="getModelTitle(model)">
                                <!-- <v-icon small class="mr-1">mdi:mdi-information-outline</v-icon> -->
                                <!-- <v-chip small class="mx-1" color="success">
                                    {{  model.version.split(':')[0] || model.version }}
                                </v-chip> -->
                                {{ model.modelName }}
                            </v-card-title>
                            <v-card-actions>
                                <v-btn variant="tonal" color="primary" @click="openLatestVersion(model)"
                                    >Open Model</v-btn
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    :color="model.isFavorite ? 'yellow' : 'grey'"
                                    icon
                                    @click="toggleFavorite(model)">
                                    <v-icon>{{
                                        model.isFavorite
                                            ? 'fa:fa-solid fa-star'
                                            : 'fa:fa-regular fa-star'
                                    }}</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </template>
            </v-row>
            <v-row v-if="!isLoading">
                <v-col cols="12">
                    <v-pagination
                        v-if="paginatedModels.length"
                        v-model="currentPage"
                        :length="totalPages"
                        next-icon="mdi:mdi-chevron-right"
                        prev-icon="mdi:mdi-chevron-left"
                        @input="onPageChange">
                    </v-pagination>
                    <v-alert v-if="!paginatedModels.length" type="warning" outlined
                        >No projects found</v-alert
                    >
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>
<script>
    import { debounce } from 'lodash';
    import modelService from '@Models/service/model.service';
    export default {
        name: 'models-view',
        components: {
        },
        data() {
            return {
                currentPage: 1,
                itemsPerPage: 8,
                isLoading: false,
                searchQuery: '',
            };
        },
        computed: {
            totalPages() {
                return Math.ceil(this.projects.length / this.itemsPerPage);
            },
            projects() {
                return this.$store.getters['MODELS/MODELS_LIST'];
            },
            paginatedModels() {
                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                const endIndex = this.currentPage * this.itemsPerPage;
                return this.projects.slice(startIndex, endIndex);
            },
            currentProject() {
                return this.$store.getters['PROJECTS/CURRENT_PROJECT_DATA'];
            },
        },
        methods: {
            onPageChange(page) {
                this.currentPage = page;
            },
            getModelTitle(model) {
                return `${model.modelName}`;
            },
            openLatestVersion(model) {
                console.log('bbbbbb', model)
                let user = this.$store.getters['ACCOUNT/USER'];
                const modelDetails = { urn: model.urn, itemId: model.itemId, projectId: model.projectId }
                this.$store.dispatch('GetLatestVersion2', modelDetails).then(latestVersion => {

                    let _model = {
                        hubName: model.hubName,
                        projectId: latestVersion.projectId,
                        projectName: model.projectName,
                        modelName: model.modelName,
                        version: latestVersion.name,
                        urn: latestVersion.urn,
                        storageUrl: latestVersion.ref,
                        userId: user.userId,
                        userName: user.userName,
                        itemId: model.itemId
                    };

                    modelService.updateModel(_model)
                        .then((data) => {
                            this.$router.push({ name: 'ModelViewer', params: { id: data.id }, query: { ...this.$route.query } })
                            this.$emit('close');
                        })
                        .catch(() => {
                            console.error('Failed to save local changes to the model');
                        });
                })

            },
            updateProjectModelsList() {
                this.isLoading = true;
                let action =
                    this.$route.name === 'ModelsFavorites'
                        ? 'MODELS/GetFavoriteModels'
                        : this.$route.name === 'ModelsUserRecent'
                        ? 'MODELS/GetUserRecentModels'
                        : 'MODELS/GetRecentModels';
                let payload = { query: this.searchQuery, projectId: this.currentProject.id };

                this.$store.dispatch(action, payload).then(() => {
                    this.isLoading = false;
                });
            },
            toggleFavorite(model) {
                this.$store.dispatch('MODELS/ToggleFavorite', model).then(() => {
                    model.isFavorite = !model.isFavorite;
                    this.updateProjectModelsList();
                });
            },
            debouncedUpdateProjectsList: debounce(function () {
                this.updateProjectModelsList();
            }, 500),
        },
        created() {
            this.isLoading = true;
            this.$store.dispatch('ACCOUNT/CheckAuth').then(() => {
                this.updateProjectModelsList();
            });
        },
        watch: {
            $route() {
                this.updateProjectModelsList();
            },
            searchQuery() {
                this.debouncedUpdateProjectsList();
            },
        },
    };
</script>
<style lang="css">
    .recent-model-img .v-img__img {
        object-fit: cover;
    }

    .v-card-actions .fa-solid.fa-star {
        color: yellow;
    }

    .v-card-actions .v-btn--icon:hover .fa-star {
        color: yellow;
    }

    .bottom-navigation .v-btn__content {
        gap: 5px;
    }

    i.v-icon {
        color: inherit;
    }

    .text--gray--1 {
        color: #cccccc;
        font-size: 1rem;
    }
</style>
