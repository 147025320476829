/* eslint-disable no-unused-vars */
import HttpService from '@Utils/httpService';


export const PreventSelectionStore = {
    state() {
        return {
            preventedSelectionDbIds: [],
        };
    },
    getters: {
        Prevented_Selection_DBIDS(state) {
            return state.preventedSelectionDbIds;
        },
    },
    mutations: {
        SET_Prevented_Selection_DBIDS(state, dbids) {
            state.preventedSelectionDbIds = dbids;
        },
    },

    actions: {
        GetPreventSelectionDbIds({ commit, dispatch, getters }) {
            const urn = getters['MODELS/CURRENT_MODEL_DATA'].urn;

            return new Promise((resolve, reject) => {
                HttpService.get({
                    url: `api/ForgeModel/${urn}/preventSelection`,
                })
                    .then((response) => {
                        commit('SET_Prevented_Selection_DBIDS', response);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        SetPreventSelectionDbIds({ commit, dispatch, getters }, { dbIds }) {
            const urn = getters['MODELS/CURRENT_MODEL_DATA'].urn;

            return new Promise((resolve, reject) => {
                HttpService.post({
                    url: `api/ForgeModel/${urn}/preventSelection`,
                    data: {
                        preventSelectionDbIdsAsJson: JSON.stringify(dbIds),
                    },
                })
                    .then((response) => {
                        commit('SET_Prevented_Selection_DBIDS', dbIds);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    },
};
