<template lang="html">
  <v-app-bar class="home-top-bar" height="50" theme="dark">
    <img class="logo-icon" :src="logo" max-height="50" alt="logo" />
    
    <v-btn variant="plain" class="gray2--text px-0" link :to="`/projects`">
      <v-icon class="mr-2" size="18">fa:fa-solid fa-arrow-left-long</v-icon>
      <span>Projects</span>
    </v-btn>

    <p class="d-flex align-center">
      <span class="mx-2"> / </span>
      <span class="project-title opacity-54 d-flex align-center">
        <span class="v-breadcrumbs-item" :title="projectName">{{ projectName }}</span>
      </span>
    </p>

    <v-spacer></v-spacer>
    <template v-slot:append>
      <div class="d-flex ga-3 align-center">
        <v-btn icon @click="logout" class="ml-5">
          <i class="fa-solid fa-arrow-right-from-bracket"></i>
        </v-btn>
      </div>
    </template>
  </v-app-bar>
</template>

<script>
  import logo from '@/assets/images/logo.png';
  export default {
    name: 'top-bar',
    data() {
      return {
        logo,
      };
    },
    methods: {
      logout() {
        this.$store.dispatch('ACCOUNT/Logout');
      },
      openProjectsPopup() {
        this.$store.commit('PROJECTS/SET_PROJECTS_POPUP', true);
      },
    },
    computed: { 
      currentProject() {
        return this.$store.getters['PROJECTS/CURRENT_PROJECT_DATA'];
      },
      projectName() {
        return this.currentProject ? this.currentProject.name : '';
      },
    }
  };
</script>

<style lang="css">
  .home-top-bar .v-btn__content,
  .home-top-bar .v-btn__prepend,
  .home-top-bar .v-btn__append {
    gap: 8px;
  }

  .home-top-bar i {
    color: inherit !important;
  }
</style>
