<template lang="html">
    <v-bottom-navigation
        height="40"
        v-model="$router.name"
        theme="dark"
        class="bottom-navigation"
        color="primary">
        <v-btn to="browser">
            <v-icon>fa:fas fa-window-restore</v-icon>
            Browser
        </v-btn>
        <v-btn to="user-recent">
            <v-icon>fa:fa-regular fa-clock</v-icon>
            Recent
        </v-btn>
        <v-btn to="favorites">
            <v-icon>fa:fa-solid fa-star</v-icon>
            Favorites
        </v-btn>
    </v-bottom-navigation>
</template>
<script>
    export default {};
</script>
<style lang="scss"></style>
