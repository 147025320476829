/* eslint-disable no-unused-vars */
const THREE = window.THREE;
const Autodesk = window.Autodesk;

class Transition3DTo2D {
    constructor(extension) {
        this.ext = extension;
        this.viewer = extension.viewer;
    }

    async transition3Dto2D(model3D) {
        if (this.viewer.impl.is2d) return;

        const placementTransform = model3D.getPlacementTransform();
        const inverseTransform = new Autodesk.Viewing.Private.LmvMatrix4(placementTransform.isDoublePrecision)
            .copy(placementTransform)
            .invert();

        this.viewer.setNavigationLock(true);
        await this._transitionCamera(this.viewer, placementTransform);
        await this._transitionToOrthographic(this.viewer, model3D);

        this.viewer.impl.setPlacementTransform(model3D, new THREE.Matrix4());
        this.viewer.impl.invalidate(true);
        this._updateCamera(this.viewer, inverseTransform);

        this._hideOtherModels(model3D);
        this.viewer.impl.changePaperVisibility(model3D, true, true, null, 1.5);
        await this._fitToView(this.viewer, model3D, null, 1.5);

        this.ext.updateUI({
            resetTransformAndViewport: false
        });

        this.viewer.impl.setDoNotCut(model3D, false);
        this.viewer.setNavigationLock(false);
    }

    async _transitionCamera(viewer, matrix) {
        return new Promise((resolve) => {
            const position = new THREE.Vector3().setFromMatrixPosition(matrix);
            const target = new THREE.Vector3().setFromMatrixColumn(matrix, 2).add(position);
            const up = new THREE.Vector3().setFromMatrixColumn(matrix, 1);

            const newView = { position, target, up };

            Autodesk.Viewing.Private.flyToView(viewer, newView, 0.5, () => {
                setTimeout(resolve);
            }, false);
        });
    }

    async _transitionToOrthographic(viewer, model) {
        viewer.navigation.toOrthographic();
        const bbox = model.getBoundingBox(true, false).clone();
        const transform = model.getModelToViewerTransform() || new THREE.Matrix4();
        
        const center = bbox.getCenter(new THREE.Vector3());
        const eye = center.clone();
        eye.z += bbox.getSize(new THREE.Vector3()).y;
        eye.applyMatrix4(transform);

        const target = center.applyMatrix4(transform);
        const rotationMatrix = new THREE.Matrix4().extractRotation(transform);
        const up = new THREE.Vector3(0, 1, 0).applyMatrix4(rotationMatrix).normalize();

        bbox.applyMatrix4(transform);

        const {fov, aspect} = viewer.getCamera();
        const fit = viewer.navigation.computeFit(eye, target, fov, bbox, aspect);
        
        const newView = {
            position: fit.position,
            target: fit.target,
            up: up
        };

        return new Promise((resolve) => {
            Autodesk.Viewing.Private.flyToView(viewer, newView, 0.5, () => {
                setTimeout(resolve);
            }, false);
        });
    }

    _updateCamera(viewer, matrix) {
        const camera = viewer.getCamera();
        const position = new THREE.Vector3().setFromMatrixPosition(matrix);
        const target = new THREE.Vector3().setFromMatrixColumn(matrix, 2).add(position);
        const up = new THREE.Vector3().setFromMatrixColumn(matrix, 1);

        camera.position.copy(position);
        camera.target.copy(target);
        camera.up.copy(up);
        camera.lookAt(target);
    }

    _hideOtherModels(currentModel) {
        this.viewer.getVisibleModels()
            .filter(model => model !== currentModel)
            .forEach(model => this.ext.hideModel(model.getDocumentNode()));
    }

    async _fitToView(viewer, model, bounds, duration) {
        return new Promise((resolve) => {
            viewer.fitToView([model], bounds, duration, resolve);
        });
    }
}

export default Transition3DTo2D;