<template>
    <div id="controls-wrapper">
        <!-- First row -->
        <div class="row">
            <button @click="rotateCW"><i class="fas fa-undo"></i></button>
            <button @touchstart="startUpArrow" @touchend="stopMovement"><i class="fas fa-arrow-up"></i></button>
            <button @click="rotateACW"><i class="fas fa-redo"></i></button>
        </div>
        <!-- Second row -->
        <div class="row">
            <button @touchstart="startLeft" @touchend="stopMovement"><i class="fas fa-arrow-left"></i></button>
            <button @touchstart="startBottom" @touchend="stopMovement"><i class="fas fa-arrow-down"></i></button>
            <button @touchstart="startRight" @touchend="stopMovement"><i class="fas fa-arrow-right"></i></button>
        </div>
        <!-- Third row -->
        <div class="row">
            <input type="range" min="1" max="100" v-model="sensitivity" @change="changeSensitivity">
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';


export default {
    data() {
        return {
            sensitivity: 10,
        };
    },
    methods: {
        // Methods for continuous movement
        startContinuousMovement(action) {
            this[action]();
            this.movementInterval = setInterval(() => {
                this[action]();
            }, 10);
        },
        stopMovement() {
            clearInterval(this.movementInterval);
        },
        startRotateACW() {
            this.startContinuousMovement('rotateACW');
        },
        startUpArrow() {
            this.startContinuousMovement('upArrow');
        },
        startRotateCW() {
            this.startContinuousMovement('rotateCW');
        },
        startLeft() {
            this.startContinuousMovement('left');
        },
        startBottom() {
            this.startContinuousMovement('bottom');
        },
        startRight() {
            this.startContinuousMovement('right');
        },
        changeSensitivity() {
            console.log("Sensitivity changed to:", this.sensitivity);
        },
        // Actions to be performed continuously
        rotateACW() {
            this.tool2d?.rotateElementWithAngle(-this.modelSettings.rotationSnappingDegree)
        },
        rotateCW() {
            this.tool2d?.rotateElementWithAngle(this.modelSettings.rotationSnappingDegree)
        },
        upArrow() {

            const extension = this.viewer.getExtension('ShowCamerasExtension')
            if (extension.moveCameraTool?.active) {
                extension.moveCameraTool.moveElementToDirection('up', this.sensitivity)
                return
            }

            let selection = this.viewer.getSelection();

            if (!selection?.length) {
                this.tool2d?.moveElementToDirection('up', this.sensitivity)
            } else {
                this.translate2d?.translateSelectionToDirection('up', this.sensitivity)
            }

        },
        left() {
            const extension = this.viewer.getExtension('ShowCamerasExtension')
            if (extension.moveCameraTool?.active) {
                extension.moveCameraTool.moveElementToDirection('left', this.sensitivity)
                return
            }

            let selection = this.viewer.getSelection();

            if (!selection?.length) {
                this.tool2d?.moveElementToDirection('left', this.sensitivity)
            } else {
                this.translate2d?.translateSelectionToDirection('left', this.sensitivity)
            }
        },
        bottom() {
            const extension = this.viewer.getExtension('ShowCamerasExtension')
            if (extension.moveCameraTool?.active) {
                extension.moveCameraTool.moveElementToDirection('down', this.sensitivity)
                return
            }

            let selection = this.viewer.getSelection();

            if (!selection?.length) {
                this.tool2d?.moveElementToDirection('down', this.sensitivity)
            } else {
                this.translate2d?.translateSelectionToDirection('down', this.sensitivity)
            }
        },
        right() {
            const extension = this.viewer.getExtension('ShowCamerasExtension')
            if (extension.moveCameraTool?.active) {
                extension.moveCameraTool.moveElementToDirection('right', this.sensitivity)
                return
            }

            let selection = this.viewer.getSelection();

            if (!selection?.length) {
                this.tool2d?.moveElementToDirection('right', this.sensitivity)
            } else {
                this.translate2d?.translateSelectionToDirection('right', this.sensitivity)
            }
        }
    },
    computed: {
        ...mapState('SETTINGS', ['modelSettings']),
        viewer: function () {
            return window.NOP_VIEWER;
        },
        tool2d: function () {

            return this.viewer.toolController.getTool('Viewing.Clone2D.Tool');

        },
        translate2d: function () {
            return this.viewer.toolController.getTool('Viewing.Translate2DTool.Tool');
        }
    }
};
</script>

<style scoped>
#controls-wrapper {
    position: absolute;
    z-index: 4;
    bottom: 130px;
    left: calc(50% - 100px);
    width: 200px;
    background: #dadadab0;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

button {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #c2c2c2;
    border-radius: 5px;
    border: 1px solid #b6b6b6;
}

button:hover,
button:focus {
    background: #d9d9d9;
}

button i {
    font-size: 20px;
    /* Adjust icon size as needed */
}

input[type="range"] {
    width: -webkit-fill-available;
    margin-top: 5px;
}
</style>