<template>
    <v-container fluid>
        <v-row justify="center" align="center" style="height: 100vh;">
            <v-col cols="12" sm="6" md="8">
                <v-card class="text-center" flat title="Page Not Found" rounded>
                    <v-img :src="notFound" aspect-ratio="1.5"></v-img>
                    <v-card-actions>
                        <v-row>
                            <v-col cols="12">
                                <v-btn color="info" class="px-8 py-3" height="auto" size="xl" prepend-icon="mdi-home" border rounded="l" @click="goHome">Go to Home</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        notFound: require('@/assets/images/not-found.svg')
    }),
    methods: {
        goHome() {
            this.$router.push('/');
        }
    }
};
</script>

<style scoped>
</style>