/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import store from '../../store';
const Autodesk = window.Autodesk;
import { isTouchDevice } from '@/services/compat';

export default class MoveCameraTool extends Autodesk.Viewing.ToolInterface {
    constructor(viewer, dataVizExt) {
        super();
        this.names = ['move-camera-tool'];
 
        this.viewer = viewer
        this._dataVizExt = dataVizExt
        this.active = false
        this.hoveredId = null
        this.selectedId =  null;

        this._onSpriteHovering = this._onSpriteHovering.bind(this);
        this._onSpriteClicked = this._onSpriteClicked.bind(this);
        this._onSpriteClickedOut = this._onSpriteClickedOut.bind(this);

        
        delete this.activate;
        delete this.deactivate;
        delete this.getPriority;
        delete this.handleMouseMove;
        delete this.handleButtonDown;
        delete this.handleButtonUp;       
    }
 
    getPriority() {
        return 42; 
    }
 
    handleMouseMove(event) {

        if (!isTouchDevice()) {        
            if(!this.selectedId) return false;
                        
               const result = this.viewer.clientToWorld(event.offsetX, event.offsetY);
               
               if(result?.point){
               
                this._dataVizExt.invalidateViewables([this.selectedId], (viewable) => {
                              
                    return {
                        position: result.point,
                    };
                });

                store.getters['SELECTED_CAMERA_ELEMENT'].position = result.point
               }
            return true;
        } 

        return false;
    }
 
    handleButtonDown(event, button) {
        if (button === 0 && !isTouchDevice() ) {

                if(this.hoveredId){
                    this.selectedId = this.hoveredId 
                    const selected = store.getters['ALL_Camera_Elements'].find(x=>x.dbId == this.selectedId)
                    store.commit('SET_SELECTED_CAMERA_ELEMENT', selected);
                }

                return true; 
            }

            return false;
    }
 
    handleButtonUp(event, button) {

        if (button === 0 && this.selectedId) {
            this.hoveredId = null
            this.selectedId = null
            
            store.dispatch('ChangeCameraLocation', 
            {Id: store.getters['SELECTED_CAMERA_ELEMENT'].id , location: store.getters['SELECTED_CAMERA_ELEMENT'].position}) 
            return true; 
        }

        return false;
    }
 

    activate() {
        if (!this.active) {
			this.active = true
			this.viewer.addEventListener(Autodesk.DataVisualization.Core.MOUSE_HOVERING, this._onSpriteHovering);
            this.viewer.addEventListener(Autodesk.DataVisualization.Core.MOUSE_CLICK, this._onSpriteClicked);
            this.viewer.addEventListener(Autodesk.DataVisualization.Core.MOUSE_CLICK_OUT, this._onSpriteClickedOut);
            console.log('move-camera-tool', 'activated.');
		}
    }
    deactivate() {
		if (this.active) {
			this.active = false
            this.viewer.removeEventListener(Autodesk.DataVisualization.Core.MOUSE_HOVERING, this._onSpriteHovering);
            this.viewer.removeEventListener(Autodesk.DataVisualization.Core.MOUSE_CLICK, this._onSpriteClicked);
            this.viewer.removeEventListener(Autodesk.DataVisualization.Core.MOUSE_CLICK_OUT, this._onSpriteClickedOut);
            console.log('move-camera-tool', 'deactivated.');
		}
	}

    
    _onSpriteHovering(event){
        if(isTouchDevice()) return;

        const targetDbId = event.dbId;
        if (event.hovering) {
            this.hoveredId  = targetDbId
        } else {
            this.hoveredId = null
        }
    }
    _onSpriteClicked(ev) {
        if (ev.dbId != 0){
            this.selectedId =  ev.dbId;
            const selected = store.getters['ALL_Camera_Elements'].find(x=>x.dbId == ev.dbId)
            store.commit('SET_SELECTED_CAMERA_ELEMENT', selected);
        }
        else if(this.selectedId){
            this.selectedId = null
            store.dispatch('ChangeCameraLocation', {Id: store.getters['SELECTED_CAMERA_ELEMENT'].id , location: store.getters['SELECTED_CAMERA_ELEMENT'].position})    
            this.deactivateTool()
        }
        else{
            this.deactivateTool()
        }
    }
    _onSpriteClickedOut(event) {
        event.hasStopped = true;

        const viewablesToUpdate = [event.dbId];
        this._dataVizExt.invalidateViewables(viewablesToUpdate, () => {
            return {
                scale: 1.0, 
            };
        });
    }

     deactivateTool(){
         const moveCameraButton = this.viewer.toolbar.getControl('dashboard-toolbar-group').getControl('move-camera-button')
         moveCameraButton.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
         this.viewer.toolController.deactivateTool('move-camera-tool');
     }

    moveElementToDirection(direction, sensitivity){
        let value = sensitivity / 1000;

        switch (direction) {
            case 'up':
                store.getters['SELECTED_CAMERA_ELEMENT'].position.y += value;
                break;
            case 'down':
                store.getters['SELECTED_CAMERA_ELEMENT'].position.y -= value;
                break;
            case 'left':
                store.getters['SELECTED_CAMERA_ELEMENT'].position.x -= value;
                break;
            case 'right':
                store.getters['SELECTED_CAMERA_ELEMENT'].position.x += value;
                break;                
        }     
        
        this._dataVizExt.invalidateViewables([this.selectedId], () => {
            return {
                position: store.getters['SELECTED_CAMERA_ELEMENT'].position,
            };
        });

        this.viewer.impl.invalidate(true);
    }
}