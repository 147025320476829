/* eslint-disable no-unused-vars */
const THREE = window.THREE;
const Autodesk = window.Autodesk;

class ViewportBoundsPlane {
    constructor(viewer, overlayName) {
        this.viewer = viewer;
        this.overlayName = overlayName;
        this.materialManager = viewer.impl.matman();
        this.isVisible = false;
        this.lock = false;
    }

    createBoundsPlane() {
        this.viewer.impl.createOverlayScene(this.overlayName);

        const planeGeometry = new THREE.PlaneBufferGeometry(1, 1);
        const outlineGeometry = new THREE.Geometry();
        const vertices = [
            new THREE.Vector3(-0.5, -0.5, 0),
            new THREE.Vector3(-0.5, 0.5, 0),
            new THREE.Vector3(0.5, 0.5, 0),
            new THREE.Vector3(0.5, -0.5, 0)
        ];

        outlineGeometry.vertices.push(
            vertices[0], vertices[1],
            vertices[1], vertices[2],
            vertices[2], vertices[3],
            vertices[3], vertices[0]
        );

        this.fillMaterial = new THREE.MeshPhongMaterial({
            color: 0xCDDCF0,
            side: THREE.DoubleSide,
            specular: 0,
            transparent: true,
            opacity: 0.7,
            polygonOffset: true,
            polygonOffsetFactor: 1,
            polygonOffsetUnits: 5,
            depthTest: false,
            depthWrite: false
        });

        this.edgeMaterial = new THREE.LineBasicMaterial({
            color: 0x000000,
            side: THREE.DoubleSide,
            opacity: 1,
            transparent: true,
            depthTest: false,
            depthWrite: false
        });

        this.fillMaterial.doNotCut = true;
        this.edgeMaterial.doNotCut = true;

        this.solidName = this.materialManager._getMaterialHash(null, "ModelSheetTransition.solid");
        this.materialManager.addMaterial(this.solidName, this.fillMaterial, true);

        this.edgesName = this.materialManager._getMaterialHash(null, "ModelSheetTransition.edges");
        this.materialManager.addMaterialNonHDR(this.edgesName, this.edgeMaterial);

        const planeMesh = new THREE.Mesh(planeGeometry, this.fillMaterial);
        const outlineMesh = new THREE.Line(outlineGeometry, this.edgeMaterial, THREE.LinePieces);

        const group = new THREE.Group();
        group.add(planeMesh);
        group.add(outlineMesh);
        group.matrixAutoUpdate = false;

        return group;
    }

    getBoundsPlane() {
        if (!this.boundsPlane) {
            this.boundsPlane = this.createBoundsPlane();
        }
        return this.boundsPlane;
    }

    setFromMatrix(matrix) {
        const boundsPlane = this.getBoundsPlane();
        boundsPlane.matrix.copy(matrix);
        boundsPlane.matrixWorldNeedsUpdate = true;
    }

    setMaterialsOpacity(opacity) {
        this.fillMaterial.opacity = 0.7 * opacity;
        this.edgeMaterial.opacity = 1 * opacity;
        this.viewer.impl.invalidate(false, false, true);
    }

    setVisible(visible, animate = true) {
        if (this.isVisible === visible) return;

        if (this.fadeAnim) {
            this.fadeAnim.stop();
            this.fadeAnim = null;
        }

        this.isVisible = visible;
        const boundsPlane = this.getBoundsPlane();
        const startOpacity = visible ? 0 : 1;

        if (visible) {
            this.viewer.impl.addOverlay(this.overlayName, boundsPlane);
        }

        if (animate) {
            const onUpdate = (value) => {
                value = Autodesk.Viewing.Private.smootherStep(value);
                this.setMaterialsOpacity(value);
            };

            const onComplete = () => {
                if (!visible) {
                    this.viewer.impl.removeOverlay(this.overlayName, boundsPlane);
                }
                this.fadeAnim = null;
            };

            this.fadeAnim = Autodesk.Viewing.Private.fadeValue(startOpacity, 1 - startOpacity, 0.3, onUpdate, onComplete);
        } else {
            if (visible) {
                this.setMaterialsOpacity(1);
            } else {
                this.viewer.impl.removeOverlay(this.overlayName, boundsPlane);
            }
        }

        this.viewer.impl.invalidate(false, false, true);
    }

    destroy() {
        this.setVisible(false);
        if (this.solidName) {
            this.materialManager.removeMaterial(this.solidName);
            this.materialManager.removeNonHDRMaterial(this.edgesName);
        }
        this.viewer.impl.removeOverlayScene(this.overlayName);
    }
}

export default ViewportBoundsPlane;