//import ViewerToolkit from "../Viewer.Toolkit"
import Rotate2DTool from './Viewing.Tool.Rotate2D';
import Translate2DTool from './Viewing.Tool.Translate2D';
import ElementClone2D from '@/extensions/Viewing.Extension.Manipulation2D/tool.js';
const Autodesk = window.Autodesk
import { ManipulatedElement, ManipulationTypes } from '@/models/ForgeModels';
import { getModelElementBoundingBox2D } from '@/store/modules/ForgeService';
import ViewerToolkit from './../../extensions/Viewer.Toolkit/Viewer.Toolkit';
// const THREE = window.THREE
import store from '../../store';
// const RotateToolName = 'Viewing.Rotate2D.Tool'
// const TranslateToolName = 'Viewing.Translate2DTool.Tool'
const manipulateElementToolName = 'Viewing.Clone2D.Tool'

class Manipulation2DExtension extends Autodesk.Viewing.Extension {
    constructor(viewer, options = {}) {
        super(viewer, options);

        this._viewer = viewer;
        this._options = options;

        this.addContextMenuOption = this.addContextMenuOption.bind(this);
        this.moveElement = this.moveElement.bind(this);
        this.onAggregateSelectionChanged = this.onAggregateSelectionChanged.bind(this);
        this.selectedObj = null;
        this.btnClicked = false;
        this.deleteButton = null;
    }

    static get ExtensionId() {
        return 'Viewing.Extension.Manipulation2D';
    }

    onToolbarCreated() {
        this.rotate_Translate_Button = this.createToolbarButton(
            'toolbar-rotate-2d',
            'fa fa-arrows-alt',
            'Move/Rotate',
        );
        this.rotate_Translate_Button.onClick = () => {
            if (this.btnClicked) {
                this.btnClicked = false;
                this.rotate_Translate_Button.container.classList.remove('active');
                this._viewer.clearSelection();
                if (this.tool.selectedObjects) {
                    this.tool.rotate2dTool.clearSelection();
                    this.tool.selectedObjects.forEach((x) =>
                        x.material.color.setHex(this.tool.clonedObjectColor),
                    );
                    this.tool.selectedObjects = [];
                    this.tool.clearControls();
                }
                store.commit('VIEWER/SET_CURRENT_MANIPULATION_POSITION', null);
                store.commit('VIEWER/SET_START_MANIPULATION_POSITION', null);
            } else {
                this.btnClicked = true;
                this.rotate_Translate_Button.container.classList.add('active');
                this.operate();
            }
        };

        // this.deleteButton = this.createToolbarButton('toolbar-delete-2d', 'fa fa-trash', 'Delete');
        // this.deleteButton.onClick = () => {
        //     if (this.btnClicked) {
        //         this.btnClicked = false;
        //         this.rotate_Translate_Button.container.classList.remove('active');
        //     }

        //     if (this.selectedObj) {
        //         console.log(this.selectedObj);
        //         if (this.selectedObj.dbIdArray) {
        //             this.deleteInstance(this.selectedObj.dbIdArray[0]);
        //         } else {
        //             this.deleteInstance(this.selectedObj.name);

        //         }

        //         this._viewer.clearSelection();
        //     }
        // };
        return true;
    }
    createDeleteButton() {
        this.deleteButton = this.createToolbarButton('toolbar-delete-2d', 'fa fa-trash', 'Delete');
        this.deleteButton.onClick = () => {
            if (this.btnClicked) {
                this.btnClicked = false;
                this.rotate_Translate_Button.container.classList.remove('active');
            }

            if (this.selectedObj) {
                if (this.selectedObj[0]?.dbIdArray) {
                    this.deleteInstances(this.selectedObj[0].dbIdArray);
                    this._viewer.clearSelection();
                } else {
                    this.deleteInstances([this.selectedObj.name]);
                    this.removeToolbarButton(this.deleteButton);
                    this.deleteButton = null;
                }
            }
        };
    }
    deleteInstances(dbIds) {
         store.commit('SET_IS_SAVING', true);
         store.commit('SET_SAVING_ERROR', null);

        const manipulatedElements = store.getters.MANIPULATED_ELEMENTS;
        
        const instances = store.getters.FAMILIES_INSTANCES;
        
        let elements = [...instances, ...manipulatedElements];
        
        let instancesToBeDeleted = elements.filter((x) => dbIds.includes(x.dbId));
        
        let existingNotManipulated = dbIds.filter((x) => !elements.find((c) => c.dbId == x));     

        const manipulatedExisingToBeDeleted = instancesToBeDeleted
            .filter((x) => x.manipulationType == ManipulationTypes.MANIPULATE_EXISTING)
            .map((x) => x.dbId);

        const existing = [...existingNotManipulated, ...manipulatedExisingToBeDeleted];

      let existingPromise = new Promise((resolve)=> resolve());
        if (existing.length > 0) {
             existingPromise = this.deleteExistingElements(existing);
        }
     
        const FamilyInstanceToBeDeleted = instancesToBeDeleted.filter(
            (x) => x.manipulationType == ManipulationTypes.NEW_FAMILY_INSTANCE,
        );
        if (FamilyInstanceToBeDeleted.length > 0) {
              FamilyInstanceToBeDeleted.forEach((x) => {
                  store.dispatch('RemoveFamilyInstance', x.dbId).then(() => {});
              });
        }

      let clonedPromise = new Promise((resolve) => {
        const clonedToBeDeleted = instancesToBeDeleted.filter(
            (x) => x.manipulationType == ManipulationTypes.CLONED_ELEMENT,
        );
        if (clonedToBeDeleted.length > 0) {
                 store.dispatch('RemoveManipulatedElements', clonedToBeDeleted.map((x) => x.dbId),).then(() => {
                    clonedToBeDeleted.forEach((cl) =>
                                 this.tool.deleteManipulatedElement(cl.dbId),
                            );
                    resolve()
                 })
        }else{
            resolve()
        }
    })
           Promise.all([existingPromise, clonedPromise]).then(() => {
               store.commit('SET_IS_SAVING', false);
               store.commit('SET_SAVING_ERROR', null);
           });
    }
    deleteExistingElements(dbIds) {
         return new Promise((resolve, reject) => {
        let propTasks = [];
        dbIds.forEach((dbId) => {
            var nodebBox = getModelElementBoundingBox2D(dbId, this._viewer.model);

            let elementCenter = nodebBox.getCenter();

            let elementNewSheetPosition = elementCenter.clone();

            let elementNewWorldPosition = ViewerToolkit.sheetToWorld(
                this._viewer,
                elementNewSheetPosition.clone(),
                this._viewer.model,
                store.getters['VIEWER/GLOBAL_OFFSET'],
            );

            let manipulatedElement = store.getters.MANIPULATED_ELEMENTS.find(
                (element) => element.dbId == dbId,
            );

            if (!manipulatedElement) {
                 propTasks.push(this.getPropertiesAsync(dbId, elementNewWorldPosition));
                // this._viewer.getProperties(dbId, (props) => {
                //     manipulatedElement = new ManipulatedElement();
                //     manipulatedElement.viewType = '2d';
                //     manipulatedElement.viewId =
                //         window.NOP_VIEWER.model.getDocumentNode()?.data?.guid;
                //     manipulatedElement.manipulationType = ManipulationTypes.DELETED;
                //     manipulatedElement.name = props.name;
                //     manipulatedElement.modelId = this._viewer.model.getData().urn;
                //     manipulatedElement.dbId = dbId;
                //     manipulatedElement.originalDbId = dbId;
                //     manipulatedElement.originalExternalId = props.externalId;
                //     manipulatedElement.position = elementNewWorldPosition.clone();
                //     manipulatedElement.rotation = null;

                //     store.commit('ADD_MANIPULATED_ELEMENT', manipulatedElement);

                //     store.dispatch('UpdateModelManipulatedElements').then(() => {
                //         this._viewer.impl.visibilityManager.setNodeOff(dbId, true);
                //     });
                // });
            } else {
                manipulatedElement.manipulationType = ManipulationTypes.DELETED;
                // store.dispatch('UpdateModelManipulatedElements').then(() => {
                //     this._viewer.impl.visibilityManager.setNodeOff(dbId, true);
                // });
            }
        });
       
        if (propTasks.length > 0) {
            Promise.all(propTasks)
                .then((results) => {
                    results.forEach((r) => store.commit('ADD_MANIPULATED_ELEMENT', r));
                    store.dispatch('UpdateModelManipulatedElements').then(() => {
                        resolve();
                    });
                })
                .catch((err) => reject(err));
        } else {
            store
                .dispatch('UpdateModelManipulatedElements')
                .then(() => {
                    resolve();
                })
                .catch((err) => reject(err));
        }

    })
    }
    async getPropertiesAsync( dbId, elementNewWorldPosition) {
          const _viewGuids = await ViewerToolkit.getViewabelIdsOfElement(
              this._viewer.model,
              dbId,
              store,
          );
        return new Promise((resolve, reject) => {
            this._viewer.getProperties(
                dbId,
                (props) => {
                    const manipulatedElement = new ManipulatedElement();
                    manipulatedElement.viewType = '2d';
                    manipulatedElement.viewId =
                        window.NOP_VIEWER.model.getDocumentNode()?.data?.guid;
                    manipulatedElement.manipulationType = ManipulationTypes.DELETED;
                    manipulatedElement.name = props.name;
                    manipulatedElement.modelId = this._viewer.model.getData().urn;
                    manipulatedElement.dbId = dbId;
                    manipulatedElement.originalDbId = dbId;
                    manipulatedElement.originalExternalId = props.externalId;
                    manipulatedElement.position = elementNewWorldPosition.clone();
                    manipulatedElement.originalPosition = elementNewWorldPosition.clone();
                    manipulatedElement.rotation = null;
                    manipulatedElement.viewGuids = _viewGuids;
                    resolve(manipulatedElement);
                },
                (error) => {
                    reject(error);
                },
            );
        });
    }
    deleteInstance(dbId) {
        const manipulatedElements = store.getters.MANIPULATED_ELEMENTS;
        const instances = store.getters.FAMILIES_INSTANCES;
        let elements = [...instances, ...manipulatedElements];
        let instance = elements.filter((x) => x.dbId == dbId);

        if (instance.length == 0) {
            this.deleteExistingElement(dbId);
            // this._viewer.impl.visibilityManager.setNodeOff(dbId, true);
            return;
        }
        instance = instance[0];
        if (instance.manipulationType === 'New Family Instance') {
            store.dispatch('RemoveFamilyInstance', instance.dbId).then(() => {});
        } else {
            store.dispatch('RemoveManipulatedElement', instance.dbId).then(() => {
                if (instance.manipulationType === 'Cloned Element') {
                    this.tool.deleteManipulatedElement(instance.dbId);
                } else {
                    this.deleteExistingElement(dbId);
                    // this._viewer.impl.visibilityManager.setNodeOff(dbId, true);
                }
            });
        }
    }
    async deleteExistingElement(dbId) {
        var nodebBox = getModelElementBoundingBox2D(dbId, this._viewer.model);

        let elementCenter = nodebBox.getCenter();

        let elementNewSheetPosition = elementCenter.clone();

        let elementNewWorldPosition = ViewerToolkit.sheetToWorld(
            this._viewer,
            elementNewSheetPosition.clone(),
            this._viewer.model,
            store.getters['VIEWER/GLOBAL_OFFSET'],
        );

        let manipulatedElement = store.getters.MANIPULATED_ELEMENTS.find(
            (element) =>
                element.dbId == dbId &&
                element.viewType == '2d' &&
                element.manipulationType == ManipulationTypes.DELETED,
        );

        if (!manipulatedElement) {
              const _viewGuids = await ViewerToolkit.getViewabelIdsOfElement(
                  this._viewer.model,
                  dbId,
                  store,
              );
            this._viewer.getProperties(dbId, (props) => {
                manipulatedElement = new ManipulatedElement();
                manipulatedElement.viewType = '2d';
                manipulatedElement.viewId = window.NOP_VIEWER.model.getDocumentNode()?.data?.guid;
                manipulatedElement.manipulationType = ManipulationTypes.DELETED;
                manipulatedElement.name = props.name;
                manipulatedElement.modelId = this._viewer.model.getData().urn;
                manipulatedElement.dbId = dbId;
                manipulatedElement.originalDbId = dbId;
                manipulatedElement.originalExternalId = props.externalId;
                manipulatedElement.position = elementNewWorldPosition.clone();
                manipulatedElement.originalPosition = elementNewWorldPosition.clone();
                manipulatedElement.rotation = null;
                manipulatedElement.viewGuids = _viewGuids;
                store.commit('ADD_MANIPULATED_ELEMENT', manipulatedElement);

                store.dispatch('UpdateModelManipulatedElements').then(() => {
                    this._viewer.impl.visibilityManager.setNodeOff(dbId, true);
                });
            });
        } else {
            manipulatedElement.manipulationType = ManipulationTypes.DELETED;
            store.dispatch('UpdateModelManipulatedElements').then(() => {
                this._viewer.impl.visibilityManager.setNodeOff(dbId, true);
            });
        }
    }
    createToolbarButton(buttonId, buttonIconUrl, buttonTooltip) {
        let group = this._viewer.toolbar.getControl('transform');
        if (!group) {
            group = new Autodesk.Viewing.UI.ControlGroup('transform');
            this._viewer.toolbar.addControl(group);
        }
        const button = new Autodesk.Viewing.UI.Button(buttonId);
        button.setToolTip(buttonTooltip);

        group.addControl(button);
        const icon = button.container.querySelector('.adsk-button-icon');
        if (icon) {
            icon.className = buttonIconUrl;
            icon.style.fontSize = '20px';
        }
        return button;
    }
    addContextMenuOption(menu, status) {
        if (!status.hasSelected) return;

        menu.push({
            title: 'Move Element',
            target: () => this.moveElement(),
        });
    }
    moveElement() {
        if (!this._viewer.toolController.isToolActivated(manipulateElementToolName)) {
            this.tool.enable(true);
            this.translate2dTool.enable(true);
        }
        this.translate2dTool.moveElement();
    }
    load() {
        this._viewer.addEventListener(
            Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
            this.onAggregateSelectionChanged,
        );

        this._viewer.toolController.deactivateTool(manipulateElementToolName);

        this.tool = new ElementClone2D(this._viewer);
        this._viewer.toolController.registerTool(this.tool);

        this.translate2dTool = new Translate2DTool(this._viewer, this.tool);
        this._viewer.toolController.registerTool(this.translate2dTool);

        this.rotate2dTool = new Rotate2DTool(this._viewer, this.tool);
        this._viewer.toolController.registerTool(this.rotate2dTool);

        this.tool.translate2dTool = this.translate2dTool;
        this.tool.rotate2dTool = this.rotate2dTool;

        this.tool.enable(true);

        this._viewer.registerContextMenuCallback('Move Element', this.addContextMenuOption);
        console.log('Viewing.Extension.Manipulation2D loaded');

        return true;
    }
    unload() {
        if (this.rotate_Translate_Button) {
            this.removeToolbarButton(this.rotate_Translate_Button);
            this.rotate_Translate_Button = null;
            this.tool.btnClicked = false;
        }
        if (this.deleteButton) {
            this.removeToolbarButton(this.deleteButton);
            this.deleteButton = null;
        }
        this._viewer.removeEventListener(
            Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
            this.onAggregateSelectionChanged,
        );
        this._viewer.unregisterContextMenuCallback('Move Element');
        this._viewer.toolController.deregisterTool(this.tool);
        this._viewer.toolController.deregisterTool(this.translate2dTool);
        this._viewer.toolController.deregisterTool(this.rotate2dTool);
    }
    removeToolbarButton(button) {
        const group = this.viewer.toolbar.getControl('transform');
        group.removeControl(button);
    }

    onAggregateSelectionChanged(event) {
        if (
            (event.selections && event.selections.length > 0) ||
            (this.tool.selectedObjects && this.tool.selectedObjects.length > 0)
        ) {
            if (!this.deleteButton) this.createDeleteButton();
        } else {
            this.removeToolbarButton(this.deleteButton);
            this.deleteButton = null;
        }

        if (event.selections && event.selections.length) {
            this.selectedObj = event.selections;
        } else if (this.tool.selectedObjects) {
            this.selectedObj = this.tool.selectedObjects[0];
            if (this.tool.selectedObjects.length == 1) {
                let id = this.selectedObj.name;
                let element = store.getters.MANIPULATED_ELEMENTS.find((el) => el.dbId == id);
                if (element) {                    
                    this._viewer.model.getBulkProperties([element.originalDbId], { propFilter: ['Type Name'] }, result => {
                        if (result.length == 0) return;
                        let type = result[0].properties[0].displayValue;
                        let name = `${element.name} - (${type})`;
                        store.commit('SET_SELECTED_ELEMENT_NAME', name);
                    });
                }
            }
        } else {
            this.selectedObj = null;
        }

        this.operate();
    }
    operate() {
        if (!this.btnClicked) return;
        if (!this.selectedObj) return;

        if (this.selectedObj[0]?.dbIdArray) {
            if (this.tool.selectedObjects) {
                this.tool.rotate2dTool.clearSelection();
                this.tool.selectedObjects.forEach(x=>x.material.color.setHex(this.tool.clonedObjectColor));
                this.tool.selectedObjects = [];
                this.tool.clearControls();
            }
            this.translate2dTool.moveElement();
        } else {
            this.tool.startManipulatingElement();
        }
    }
}
Autodesk.Viewing.theExtensionManager.registerExtension(Manipulation2DExtension.ExtensionId, Manipulation2DExtension)

export default Manipulation2DExtension;
