
import httpService from "@/utils/httpService"
export default{
    getMarkupData(id){
       return httpService.get({
            url: `api/MarkupsData/${id}`,
        })
    },
    addMarkup(data){
        return httpService.post({
            url: `api/MarkupsData`,
            data: data
        })
    },
    updateMarkup(data){
        return httpService.post({
            url: `api/MarkupsData/update`,
            data: data
        })
    },
    loadSavedMarkups(){
       return httpService.get({
            url: `api/Markups/GetAll`,
        })
    },
    getMarkupById(id){
        return httpService.get({
            url: `api/Markups/GetById/${id}`,
        })
    },
    getFilteredMarkup(filter){
        return httpService.post({
            url: `api/Markups/GetAllFilter`,
            data: filter
        })
    },
    getGroupedMarkupById(data){
        return httpService.post({
            url: `api/Markups/GetAllGrouped`,
            data: data
        })
    },
    getAllSearch(data){
        return httpService.post({
            url: `api/Markups/GetAllSearch`,
            data: data
        })
    },
    getMarkupAddedModels(id){
        return httpService.get({
            url: `api/Markups/GetMarkupModels/${id}`,
        })
    },
    GetMarkupAttachSetting(){
        let AttachImg='General';
        return httpService.get({
            url: `api/ForgeSettings/${AttachImg}`,
        })
    },
    AddMarkupAttachSetting(data){
        return httpService.post({
            url: `api/ForgeSettings`,
            data: data
        })
    },
    DeleteMarkupById(id){
        return httpService.delete({
            url: `api/Markups/${id}`,
        })
    }
}