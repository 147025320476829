<template>
    <v-dialog transition="fade-transition" v-model="model.active" max-width="400" persistent>
        <v-card>
            <v-card-title class="d-flex flex-row align-center">
                <v-icon class="d-flex mr-2">
                    fas fa-trash fa-sm
                </v-icon>
                <span>Delete Item</span>
            </v-card-title>
             <v-divider></v-divider>
             <v-card-text class="d-flex flex-column ga-2">
                <span class="text-h6">Are you sure you want to delete this item?</span>
                <small class="text-caption text-medium-emphasis">This action cannot be undone.</small>
             </v-card-text>
             <v-divider></v-divider>
            <v-card-actions class="px-2">
                <v-spacer></v-spacer>
                <v-btn color="grey" elevation="0" text @click="close"> close </v-btn>
                <v-btn width="100" variant="tonal" density="comfortable" :loading="model.loading" color="red" elevation="12" @click="confirmDelete"> Confirm </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: 'ConfirmDelete',
        data() {
            return {
                model: {
                    active: false,
                    loading: false,
                },
                deleteCallback: null,
            };
        },
        methods: {
            open(deleteCallback) {
                this.model.active = true;
                this.deleteCallback = deleteCallback;
            },
            close() {
                this.model.active = false;
                this.model.loading = false;
                this.deleteCallback = null;
            },
            confirmDelete() {
                this.model.loading = true;
                if (this.deleteCallback) {
                    this.deleteCallback();
                } else {
                    this.close();
                }
            },
        },
    };
</script>
<style lang=""></style>
