import MarkupDataService from '../service/MarkupData.service';
import { toast } from 'vue3-toastify';

export const MARKUPDATA = {
    namespaced: true,
    state: {
        markupData: '',
        isdataloaded: false,
        savedMarkupElements: '',
        markupViewData: '',
        loadedModelName: '',
        markupOpened: false,
        selectedMarkupImg: '',
        openMarkupImg: '',
        GroupedMarkup: [],
        SearchMarkup: [],
        overlayZIndex: 1003,
        GroupedMarkupLoaded: false,
        ListdMarkupLoaded: false,
        MarkupAttachSetting: 'Original',
        DeletedElement: -1,
        MarkupSelectedPanel: false
    },
    getters: {
        MARKUP_DATA(state) {
            return state.markupData;
        },
        DATA_LOADED(state) {
            return state.isdataloaded;
        },
        Markup_Elements(state) {
            return state.savedMarkupElements;
        },
        MARKUP_VIEW_DATA(state) {
            return state.markupViewData;
        },
        LOADED_MODEL_NAME(state) {
            return state.loadedModelName;
        },
        MARKUP_OPENED(state) {
            return state.markupOpened;
        },
        SELECTED_MARKUP_IMG(state) {
            return state.selectedMarkupImg;
        },
        OPEN_MARKUP_IMG(state) {
            return state.openMarkupImg;
        },
        GROUPED_MARKUP(state) {
            return state.GroupedMarkup;
        },
        SEARCH_MARKUP(state) {
            return state.SearchMarkup;
        },
        OVERLYA_ZINDEX(state) {
            return state.overlayZIndex;
        },
        GROUPED_MARKUPS_LOADED(state) {
            return state.GroupedMarkupLoaded;
        },
        List_MARKUPS_LOADED(state) {
            return state.ListdMarkupLoaded;
        },
        MARKUP_ATTACH_SETTING(state) {
            return state.MarkupAttachSetting;
        },
        DELETED_ELEMENT(state) {
            return state.DeletedElement;
        },
        MARKUP_SELECTED_PANEL(state) {
            return state.MarkupSelectedPanel;
        }
    },
    mutations: {
        SET_MARKUP_DATA(state, data) {
            state.markupData = data;
        },
        SET_Markup_Elements(state, data) {
            state.savedMarkupElements = data;
        },
        SET_DATA_LOADED(state) {
            state.isdataloaded = true;
        },
        SET_MARKUP_VIEW_DATA(state, data) {
            state.markupViewData = data;
        },
        SET_LOADED_MODEL_NAME(state, name) {
            state.loadedModelName = name;
        },
        SET_MARKUP_OPENED(state, value) {
            state.markupOpened = value;
        },
        SET_SELECTED_MARKUP_IMG(state, value) {
            state.selectedMarkupImg = value;
        },
        SET_OPEN_MARKUP_IMG(state, value) {
            state.openMarkupImg = value;
        },
        SET_GROUPED_MARKUP(state, value) {
            state.GroupedMarkup = value;
        },
        SET_SEARCH_MARKUP(state, value) {
            state.SearchMarkup = value;
        },
        SET_OVERLYA_ZINDEX(state, value) {
            state.overlayZIndex = value;
        },
        SET_GROUPED_MARKUPS_LOADED(state, value) {
            state.GroupedMarkupLoaded = value;
        },
        SET_List_MARKUPS_LOADED(state, value) {
            state.ListdMarkupLoaded = value;
        },
        SET_MARKUP_ATTACH_SETTING(state, value) {
            state.MarkupAttachSetting = value;
        },
        SET_DELETED_ELEMENT(state, value) {
            state.DeletedElement = value;
        },
        SET_MARKUP_SELECTED_PANEL(state, value) {
            state.MarkupSelectedPanel = value;
        }
    },
    actions: {
        addMarkupData(store, data) {
            MarkupDataService.addMarkup(data).then(() => {
                toast.success(`Markups data saved successfully`);
            });
        },
        updateMakrupData(store, data) {
            MarkupDataService.updateMarkup(data).then(() => {
                toast.success(`Markups data updated successfully`);
            });
        },
        getMarkupData(store, data) {
            MarkupDataService.getMarkupData(data.id).then((res) => {
                store.commit('SET_MARKUP_DATA', res);
                store.commit('SET_DATA_LOADED');
            });
        },
        getSavedMarkups(store) {
            MarkupDataService.loadSavedMarkups().then((res) => {
                store.commit('SET_Markup_Elements', res);
            });
        },
        getMarkupById(store, data) {
            store.commit('SET_MARKUP_SELECTED_PANEL', false);
            return MarkupDataService.getMarkupById(data.id).then((res) => {
                store.commit('SET_MARKUP_VIEW_DATA', res);
                if (!res.isAttach) {

                    store.commit('SET_MARKUP_SELECTED_PANEL', true);

                }
                return res;
            });
        },
        getFilteredMarkup(store, filter) {
            if (store) {
                return MarkupDataService.getFilteredMarkup(filter).then((res) => {
                    //store.commit('SET_MARKUP_VIEW_DATA',res)
                    return res;
                });
            }
        },
        setMarkupOpened(store) {
            store.commit('SET_MARKUP_OPENED', true);
        },
        setMarkupClosed(store) {
            store.commit('SET_MARKUP_OPENED', false);
        },
        setMarkupImg(store, value) {
            store.commit('SET_SELECTED_MARKUP_IMG', value);
        },
        setOpenMarkupImg(store, value) {
            store.commit('SET_OPEN_MARKUP_IMG', value);
        },
        resetMarkupViewData(store) {
            store.commit('SET_MARKUP_VIEW_DATA', null);
        },
        getGroupedMarkupMarkupById(store, data) {
            store.commit('SET_GROUPED_MARKUPS_LOADED', true);
            store.commit('SET_GROUPED_MARKUP', []);
            MarkupDataService.getGroupedMarkupById(data).then((res) => {
                store.commit('SET_GROUPED_MARKUP', res);
                store.commit('SET_GROUPED_MARKUPS_LOADED', false);
                return res;
            });
        },
        getAllSearch(store, data) {
            store.commit('SET_List_MARKUPS_LOADED', true);
            store.commit('SET_SEARCH_MARKUP', []);
            MarkupDataService.getAllSearch(data).then((res) => {
                store.commit('SET_SEARCH_MARKUP', res);
                store.commit('SET_List_MARKUPS_LOADED', false);
                return res;
            });
        },
        SetOvelayZIndex(store, value) {
            store.commit('SET_OVERLYA_ZINDEX', value);
        },
        GetMarkupAttchmentImageOptions(store) {
            MarkupDataService.GetMarkupAttachSetting().then((res) => {
                if (res) {
                    store.commit('SET_MARKUP_ATTACH_SETTING', res.attachMarkupType);
                }
            });
        },
        ChangeMarkupAttchmentImageOptions(store, data) {
            MarkupDataService.AddMarkupAttachSetting(data).then(() => {
                if (data) {
                    toast.success(`Status updated successfully`);
                    store.commit('SET_MARKUP_ATTACH_SETTING', data.AttachMarkupType);
                }
            });
        },
        DeleteMarkupData(store, data) {
            MarkupDataService.DeleteMarkupById(data.id).then(() => {
                toast.success(`Markup deleted successfully`);
                if (data.reload) {
                    store.dispatch('getAllSearch', {
                        search: "",
                        id: data.modelId,
                    });
                    store.dispatch('getGroupedMarkupMarkupById', {
                        search: "",
                        id: data.modelId,
                    });
                }

                store.commit('SET_DELETED_ELEMENT', data.id);
            });
        },
    },
};
