/* eslint-disable no-unused-labels */
import HttpService from "@Utils/httpService"

export const Hubs = {
    state() {
        return {
            hubs: [],
            selectedTreeNode: null
        }         
    },
    getters: {
        HUBS(state) {
            return state.hubs;
        },
        SELECTED_TREE_NODE(state) {
            return state.selectedTreeNode;
        }
    },
    mutations: {
        SET_HUBS(state, hubs) {
            state.hubs = hubs;
        },
        SET_SELECTED_TREE_NODE(state, node) {
            state.selectedTreeNode = node;
        }
    },
    actions: {
        GetHubs({ commit }) {
            HttpService.get('api/hubs')
                .then(hubs => {
                    commit('SET_HUBS', hubs.map(hub => ({
                        id: hub.ref,
                        ref: hub.ref,
                        name: hub.name,
                        type: hub.type,
                        parent: hub.children,
                        children: []
                    })))
                })
                .catch(error => {
                    console.log(error)
                })
        },
        GetHubProjects(_, ref) {
            return new Promise((resolve, reject) => {
                HttpService.get(`api/${ref}`)
                    .then(projects => {
                        projects = projects.map(project => ({
                            id: project.id,
                            hubId: project.hubId,
                            projectId: project.projectId,
                            ref: project.ref,
                            type: project.type,
                            name: project.name,
                            parent: project.children,
                            children: []
                        }))
                        
                        resolve(projects)
                    })
                    .catch(error => {
                        reject(error)
                    })
            });
        },
        GetProjectContents(_, ref) {
            return new Promise((resolve, reject) => {
                HttpService.get(`api/${ref}`)
                    .then(contents => {
                        contents = contents.map(content => ({
                            id: content.id,
                            ref: content.ref,
                            type: content.type,
                            name: content.name,
                            parent: content.children,
                            children: []
                        }))

                        resolve(contents)
                    })
                    .catch(error => {
                        reject(error)
                    })
            });
        },
        GetFolderContents(_, ref) {
            return new Promise((resolve, reject) => {
                HttpService.get(`api/${ref}`)
                    .then(contents => {
                        contents = contents.map(content => ({
                            id: content.id,
                            ref: content.ref,
                            type: content.type,
                            name: content.name,
                            urn: content.urn,
                            parent: content.children,
                            children: []
                        }))

                        resolve(contents)
                    })
                    .catch(error => {
                        reject(error)
                    })
            });
        },
        GetFileVersions(_, ref) {
            return new Promise((resolve, reject) => {
                HttpService.get(`api/${ref}`)
                    .then(versions => {
                        versions = versions.map(version => ({
                            id: version.id,
                            ref: version.ref,
                            type: version.type,
                            name: version.name,
                            urn: version.urn,
                            parent: version.children,
                            children: []
                        }))

                        resolve(versions)
                    })
                    .catch(error => {
                        reject(error)
                    })
            });
        }
    }
}