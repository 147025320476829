/* eslint-disable no-unused-vars */
import HttpService from '@Utils/httpService';


export const AdminStore = {
    // namespaced: true,
    state() {
        return {
            allRequestData: [],
            allRequestDatatCount:0
        };
    },
    getters: {
        ALL_REQUEST_DATA(state) {
            return state.allRequestData;
        },
        ALL_REQUEST_DATA_COUNT(state) {
            return state.allRequestDatatCount;
        },
        REQUEST_TABLE_COUNT(_) {
            return localStorage.getItem("TablesRowsCount");
        }
    },
    mutations: {
        SET_ALL_REQUEST_DATA(state, data) {
            state.allRequestData = data;
        },
        SET_ALL_REQUEST_DATA_COUNT(state, value) {
            state.allRequestDatatCount = value;
        },
        SET_REQUEST_TABLE_COUNT(_,value) {
            return localStorage.setItem("TablesRowsCount",value);
        }
    },

    actions: {
        GetAllRequestsDataFiltered({ commit, dispatch, getters },filter) {
            return new Promise((resolve, reject) => {
                HttpService.post({
                    url: `api/requests/getAllFiltered`,
                    data:filter
                })
                    .then((response) => {
                        commit('SET_ALL_REQUEST_DATA', response.data);
                        commit('SET_ALL_REQUEST_DATA_COUNT', response.length);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        GetAllRequestsData({ commit, dispatch, getters }) {
            return new Promise((resolve, reject) => {
                HttpService.get({
                    url: `api/requests/getAll`,
                })
                    .then((response) => {
                        commit('SET_ALL_REQUEST_DATA', response);
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        DownloadRequestReport({ commit, dispatch, getters }, requestId) {
            return new Promise((resolve, reject) => {
                HttpService.get({
                    url: `api/requests/downloadReport/${requestId}`,
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        UpdateRequestForgeModel(
            { commit, dispatch, getters },
            { requestId, forgeModelId, version },
        ) {
            return new Promise((resolve, reject) => {
                HttpService.put({
                    url: `api/requests/updateForgeModel/${requestId}/${forgeModelId}/${version}`,
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        SetRequestTableCount(store,value){
            store.commit('SET_REQUEST_TABLE_COUNT', value);
        }
    },
};
