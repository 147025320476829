<template>
  <div>
    <v-text-field class="ssss" variant="underlined" v-model="data.markup.name" label="Name"></v-text-field>
    <v-text-field variant="underlined" v-model="data.assignedTo" label="Assigned To"></v-text-field>
    <v-text-field variant="underlined" v-model="data.location" label="Location"></v-text-field>
    <v-text-field
    variant="underlined"
      v-model="data.locationDetails"
      label="Location Details"
    ></v-text-field>
    <v-text-field
    variant="underlined"
      v-model="data.startDate"
      :value="data.startDate"
      type="date"
      label="Due Date"
    ></v-text-field>
    <v-text-field
    variant="underlined"
      v-model="data.dueDate"
      :value="data.dueDate"
      type="date"
      label="Start Date"
    ></v-text-field>
    <v-text-field variant="underlined" v-model="data.placment" label="Placement"></v-text-field>
    <v-text-field variant="underlined" v-model="data.rootCause" label="Root Cause"></v-text-field>
    <v-btn color="warning" @click="save()"> Save </v-btn>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    
    data: {
      assignedTo: "",
      location: "",
      locationDetails: "",
      startDate: "",
      dueDate: "",
      placment: "",
      rootCause: "",
      markupId: 0,
      id:0,
      markup:{
        name:""
      }
    },
   isdataloaded:false
  }),
   props: {
    markupId: Number
  },
    computed: {
    ...mapGetters({
      myData: 'MARKUPDATA/MARKUP_DATA'
    })
  },
  watch:{
   myData(value){
    
    if (value) {
    this.data=value
    }
   }
  },
   methods:{
      save(){
        this.data.markupId=this.markupId;
        if (this.data.id>0) {
         this.$store.dispatch("MARKUPDATA/updateMakrupData", { ...this.data,Name:this.data.markup.name});
          
        }
        else{
        this.$store.dispatch("MARKUPDATA/addMarkupData", { ...this.data,Name:this.data.markup.name});
        
        }
        this.$store.dispatch("MARKUPEDITOR/SaveMarkupsViewData",false)
      
          setTimeout(()=>{
         this.$store.dispatch("MARKUPEDITOR/updateMarkup",{title:this.data.markup.name}).then(()=>{
            this.$store.dispatch("MARKUPEDITOR/ExitMarkup")
          })
          },2000)
          
    

        
      }
    },
      mounted() {
         this.$store.dispatch("MARKUPDATA/getMarkupData", {id:this.markupId})
         this.isdataloaded=this.$store.getters.DATA_LOADED;

         
      }
};
</script>
<style >

.v-input--density-default .v-field__input {
  font-size: 1.1rem !important;
  font-weight: 500 !important;
}
</style>
